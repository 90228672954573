import React, { useState } from "react";
import usersService from "../../../services/UsersService";
import Icons from "../../Helpers/Icons";
import InputCom from "../../Helpers/Inputs/InputCom";
import LoadingSpinner from "../../Spinners/LoadingSpinner";
import AddFundDollars from "./AddFundDollars";

function AddFundPop({
  _payment,
  input,
  setInput,
  onClose,
  confirmCredit,
  setConfirmCredit,
  walletItem,
}) {
  const apiCall = new usersService();
  let countryWallet = walletItem?.country;
  const { currency } = _payment;

  const [inputError, setInputError] = useState("");
  let __awaitComponent = confirmCredit.show.awaitConfirm;

  const handleChange = ({ target: { name, value } }) => {
    setInput(value);
  };

  const handleSubmit = async () => {
    try {
      // Clear any previous input error and set the loading spinner to be shown
      setInputError("");
      setConfirmCredit((prev) => ({
        ...prev,
        show: { awaitConfirm: { loader: true } },
      }));

      // Perform validation checks on the input amount
      if (!input || input === "0") {
        // Handle input validation error
        setConfirmCredit((prev) => ({
          ...prev,
          show: { awaitConfirm: { loader: false } },
        }));
        setInputError("Please Enter Amount");
        setTimeout(() => setInputError(""), 5000);
        return;
      }

      if (Number(input) * 100 > Number(walletItem?.transfer_limit)) {
        // Handle credit limit exceeded error
        setConfirmCredit((prev) => ({
          ...prev,
          show: { awaitConfirm: { loader: false } },
        }));
        setInputError("Credit limit has been exceeded");
        setTimeout(() => setInputError(""), 5000);
        return;
      }

      if (isNaN(input)) {
        // Handle invalid input error
        setConfirmCredit((prev) => ({
          ...prev,
          show: { awaitConfirm: { loader: false } },
        }));
        setInputError("Amount must be a Number");
        setTimeout(() => setInputError(""), 5000);
        return;
      }

      // Prepare state data for API call
      let stateData = {
        amount: Number(input) * 100,
        card_uid: "", //added card_uid as empty string
        currency: walletItem?.code,
      };

      // Make API call to start credit process
      const res = await apiCall.getStartCredit(stateData);

      if (res.data.internal_return < 0) {
        // Handle API error
        setConfirmCredit((prev) => ({
          ...prev,
          show: { awaitConfirm: { loader: false } },
        }));
        setInputError("An Error Occurred");
        setTimeout(() => setInputError(""), 5000);

        // use commented code when you when to display pop for failed start credit API
        // setConfirmCredit((prev) => ({
        //   ...prev,
        //   show: {
        //     awaitConfirm: { loader: false, state: false },
        //     acceptConfirm: { loader: false, state: true },
        //   },
        //   data: {internal_return: -1}
        // }));
        return;
      }

      // Update state with response data
      const _response = res.data;
      stateData.amount = Number(input);
      stateData.currency = currency;
      stateData = { ...stateData, ..._response };

      setTimeout(() => {
        setConfirmCredit((prev) => ({
          ...prev,
          show: {
            awaitConfirm: { loader: false, state: true },
            acceptConfirm: { loader: false, state: false },
          },
          data: stateData,
        }));
      }, 1500);
    } catch (error) {
      // Handle API call error
      setConfirmCredit((prev) => ({
        ...prev,
        show: { awaitConfirm: { loader: false } },
      }));
      console.log(error);
    }
  };

  return (
    <div className="w-full">
      <div className="content-wrapper w-full lg:flex xl:space-x-8 lg:space-x-4 bottomMargin">
        <div className="lg:w-2/2 w-full mb-10 lg:mb-0">
          <div className="add-fund w-full bg-white dark:bg-dark-white rounded-2xl">
            <form className="md:px-8 md:pt-4 px-4 pt-2 add-fund-info flex items-center gap-[2.1rem]">
              <h1 className="job-label my-1">
                Amount({currency})
              </h1>
              <div className="field w-full max-w-[250px]">
                <InputCom
                  fieldClass="px-6 text-right"
                  type="text"
                  name="amount"
                  placeholder="0"
                  value={input}
                  inputHandler={handleChange}
                  tabIndex={0}
                />
                <p className="text-base text-red-500 italic h-5">
                  {inputError && inputError}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>

      {countryWallet === "US" && (
        <div className="w-full bg-white dark:bg-dark-white rounded-2xl">
          <AddFundDollars
            setInputError={setInputError}
            walletItem={walletItem}
            input={input}
            setInput={setInput}
            currency={currency}
            onClose={onClose}
            confirmCredit={confirmCredit}
            setConfirmCredit={setConfirmCredit}
          />
        </div>
      )}

      {countryWallet == "NG" && <div className="h-[19rem]"></div>}

      {countryWallet == "NG" && (
        <div className="modal-footer-wrapper grid grid-cols-1 xxs:grid-cols-3">
          <div className="w-full col-span-1 xxs:col-span-2 xxs:col-start-2 flex justify-between items-center">
            <button
              className="custom-btn bg-[#f5a430] text-black text-base"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="custom-btn btn-gradient text-base text-white"
            >
              {__awaitComponent.loader ? (
                <LoadingSpinner size="6" color="sky-blue" />
              ) : (
                <>
                  <span className="text-white">Continue</span>{" "}
                  <Icons name="chevron-right" />
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddFundPop;
