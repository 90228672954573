import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../Partials/Layout";
import MyJobTable from "./MyJobTable";
import CommonHead from "../UserHeader/CommonHead";
import AddJobPage from "../../views/AddJobPage";
import CustomBreadcrumb from "../Breadcrumb/CustomBreadcrumb";

export default function MyJobs(props) {
  let { state } = useLocation();
  const navigate = useNavigate();
  const [popUp, setPopUp] = useState(false);
  const popUpHandler = () => {
    setPopUp((prev) => !prev);

    if (state?.popup) navigate("/", { replace: true })
    else return
  };

  const categoryOptions = props.MyJobList?.data?.categories;

  useEffect(() => {
    if (!state?.popup) {
      setPopUp(false);
    } else {
      setPopUp(true);
    }
  }, [state?.popup]);

  return (
    <Layout>
      <CommonHead commonHeadData={props.commonHeadData} />
      <div className="notification-page w-full mb-10">
        <div className="notification-wrapper w-full">
          {/* heading */}
          <div className="sm:flex items-center mb-2">
            <div className="w-full">
              <h1 className="text-26 font-bold flex items-center space-x-1  text-dark-gray dark:text-white gap-2">
                <span>My Jobs</span>

                <button
                  className="text-white btn-gradient text-lg tracking-wide px-5 py-2 rounded-full"
                  onClick={popUpHandler}
                >
                  Add Job
                </button>
              </h1>
            </div>
          </div>
          <div className="mb-5">
              <CustomBreadcrumb 
              // title = 'My Jobs'
              breadcrumb={
                  [
                  { link: "/", title: "Home" },
                  { link: "/myjobs", title: "My Jobs", active: true},
                  ]
              }
              />
          </div>
          <MyJobTable MyJobList={props.MyJobList} />
        </div>
      </div>

      {/* Add Job List Popout */}
      {popUp && (
        <AddJobPage
          action={popUpHandler}
          situation={popUp}
          categories={categoryOptions}
        />
      )}
      {/* End of Add Job List Popout */}
    </Layout>
  );
}
