import React, { useEffect, useState } from 'react'
import RelativeTable from './RelativeTable'
import InviteRelative from './InviteRelative'
import usersService from '../../../../services/UsersService'
import LoadingSpinner from '../../../Spinners/LoadingSpinner'

const Relatives = ({familyList}) => {

  const api = new usersService()

  const [reloadRelList, setReloadRelList] = useState(false)

  const [invitePopout, setInvitePopout] = useState(false)

  const [relativeList, setRelativeList] = useState({loading: true, result_list:[], family_types:[]})

  const showInviteMemberPopout = () => {
      setInvitePopout(true)
  }

  const getRelativeList = () => {
    setRelativeList(prev => ({...prev, loading: true}))
    api.getFamilyRelativeList().then(response => {
      let {status, data} = response
      if(status != 200 || !data){
        setRelativeList({loading:false, result_list:[], family_types:[]})
        return
      }
      setRelativeList({loading:false, result_list:data?.result_list, family_types:data?.family_types})
    }).catch(error => {
      setRelativeList({loading:false, result_list:[], family_types:[]})
    })
  }

  useEffect(()=>{
    getRelativeList()
  },[reloadRelList])

  return (
    <>
      <div className=''>
        <div className='py-4'>
          <button onClick={showInviteMemberPopout} className='text-white btn-gradient text-lg tracking-wide px-5 py-2 rounded-full'>Invite</button>
          <div className='my-4 border-b-2'></div>
        </div>
        <div className='min-h-[500px]'>
          {relativeList.loading ?
          <LoadingSpinner size='8' height='h-full' />
          :
          <RelativeTable relativeList={relativeList.result_list} familyList={familyList} />
          }
        </div>
      </div>

      {/* INVITE RELATIVE POPOUT */}
        {invitePopout &&
        <InviteRelative 
            action={()=>setInvitePopout(false)}
            situation={invitePopout}
            setReloadRelList={setReloadRelList}
            relativeList={relativeList}
        />
        }
      {/* END OF INVITE RELATIVE POPOUT */}
    </>
  )
}

export default Relatives