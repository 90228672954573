export const apiConst = {
    LOGIN_MODE_GENERAL: 1100,
    LOGIN_MODE_FAMILY:  1105,
    ADD_JOB:    10010,
    UPDATE_JOB: 10020,
    PHP_API_OK: 0,
    PHP_LOGIN_OK: 100,
    PHP_CREATED_OK: 10,
    PHP_API_BAD_PARAM: -1,
    PHP_INVALID_SESSION: -9999,
    SESSION_TIMEOUT_LOGOUT:6000,
    MOBILE_LOGIN: 1101,
    SUPPORT_PHONE:'+234 (818)-3866434',
    UPLOAD_FILE_SIZE:1,
    OFFER_ACCEPT:100,
    OFFER_REJECT:333,
    OFFER_CANCEL:222,
    OFFER_EXPIRE:444,
    JOB_INTEREST_ACCEPT:100,
    JOB_INTEREST_REJECT:200,
    JOB_INTEREST_CANCEL:300,
    SMONEY_PROCC_MANUAL:100,
    SMONEY_PROCC_AUTO:200,
    SMONEY_PROCC_INTERSWITCH:300,
    ACCOUNT_AGREE_JOBS:100,
    ACCOUNT_AGREE_REFER:200,
    WRENCHBOARD_SESSION_CHECK: 299,
    WRENCHBOARD_USER_LOGIN: 300,
    WRENCHBOARD_USER_LOGOUT: 301,
    WRENCHBOARD_CREATE_ACCOUNT: 320,
    CONTRACT_CANCEL_CONTRACT: 7,
    CONTRACT_EXTEND_TIMELINE: 9,
    CONTRACT_NOTIFY_COMPLETE: 4,
    CONTRACT_REQUEST_CANCEL:  3,
    CONTRACT_REQUEST_TIMELINE: 2,
    CONTRACT_ACCEPT_COMPLETE: 5,
    CONTRACT_REJECT_COMPLETE: 1,
    WRENCHBOARD_SIGNUP_COUNTRY : 648,
    WRENCHBOARD_GET_MEDIA: 650,
    WRENCHBOARD_SAVE_MEDIA: 652,
    WRENCH_FILE_PROFILE:     100,
    WRENCH_FILE_MYFILE:     200,
    WRENCH_FILE_CONTRACTS:   300,
    WRENCH_FILE_FAMILY:     400,
    WRENCH_FILE_FAMILYBANNER:    410,
    WRENCH_FILE_JOB :    500,
    JOB_MANAGER_FILES: 13012,
    WRENCHBOARD_BKO_START: 10000,
    WRENCHBOARD_BKO_LOGIN: 10010,
    WRENCHBOARD_BKO_CREATEUSER: 10015,
    WRENCHBOARD_BKO_UPDATEUSER: 10020,
    WRENCHBOARD_BKO_END: 10999,
    WRENCHBOARD_ACCOUNT_START: 11000,
    WRENCHBOARD_PROFILE_RESETPASS: 11005,
    WRENCHBOARD_ACCOUNT_TESTEMAIL: 11001,
    WRENCHBOARD_ACCOUNT_PENDING: 11010,
    WRENCHBOARD_CREATE_MOBILEUSER:   11011,
    WRENCHBOARD_COMPLETE_MOBILEUSER: 11012,
    WRENCHBOARD_RESET_PASSWORD: 11013 ,
    WRENCHBOARD_ACCOUNT_JOBLIST:11014 ,
    WRENCHBOARD_VERIFY_PENDING_LINK: 11015,
    WRENCHBOARD_ACCOUNT_RESETPASS: 11016,
    WRENCHBOARD_ACCOUNT_SETPASSWD: 11017,
    WRENCHBOARD_DELETE_PENDING_LINK: 11018,
    WRENCHBOARD_RESEND_PENDING_LINK: 11019,
    LOGIN_GOOGLE: 990010,
    LOGIN_FACEBOOK: 990011,
    LOGIN_APPLE: 990012,
    WRENCHBOARD_ACCOUNT_DASHRECENT: 11206,
    WRENCHBOARD_ACCOUNT_QRLOGIN:    11022,
    WRENCHBOARD_ACCOUNT_CREATEACC:  11020,
    WRENCHBOARD_ACCOUNT_AUXSTART:   11023,
    WRENCHBOARD_ACCOUNT_AUXLOGIN:   11024,
    WRENCHBOARD_ACCOUNT_LOGIN:      11025,
    WRENCHBOARD_FACEBOOK_LOGIN:     11026,
    WRENCHBOARD_GOOGLE_LOGIN:       11027,
    WRENCHBOARD_APPLE_LOGIN:        11031,
    WRENCHBOARD_START_JOBLIST:      11028,
    WRENCHBOARD_ACCOUNT_DASHDATA:   11029,

    WRENCHBOARD_SEND_CONTACTUS:      11030,
    WRENCHBOARD_ACCOUNT_SENDREFER:   11032,
    WRENCHBOARD_ACCOUNT_REFERLINK:   11033,
    WRENCHBOARD_SESSION_VERIFY:      11034,
    WRENCHBOARD_UPDATE_PROFILE:      11035,
    WRENCHBOARD_ACCOUNT_TERMS:       11036,
    WRENCHBOARD_ACCOUNT_MDESCRIPTION:11037,
    WRENCHBOARD_ACCOUNT_SAVESKILL:   11038,
    WRENCHBOARD_ACCOUNT_DELSKILL:    11039,
    WRENCHBOARD_LOG_MEMBER:          11040,
    WRENCHBOARD_DELETE_RECACC:       11041,
    WRENCHBOARD_SAVE_GALLERY:        11042,
    WRENCHBOARD_ACCOUNT_PENDJOB:     11043,
    WRENCHBOARD_ACCT_NOTIFICATIONS:  11046,
    WRENCHBOARD_ACCT_HELPITEMS:     11047,
    WRECNH_CREDIT_HOOKS_FULUTTER:   11045,

    WRENCHBOARD_USER_GETBANKLIST: 11050,
    WRENCHBOARD_USER_SENDMONEY:   11051,
    WRENCHBOARD_USER_SENDJOBINT:  11052,

    WRENCHBOARD_USER_STARTCREDIT: 11053,
    WRENCHBOARD_USER_ADNEWCC:     11054,
    WRENCHBOARD_USER_CARDLIST:    11055,
    WRENCHBOARD_USER_USESAVEDCC:  11056,
    WRENCHBOARD_USER_CARDREMV:    11057,

    WRENCHBOARD_USER_SETTINGS:    11058,
    WRENCHBOARD_GETUSER_SETTINGS: 11059,

    WRENCHBOARD_ACCOUNT_WALLETS: 11060,

    WRENCHBOARD_ACCOUNT_TOPUP_RESULT:     11061,
    WRENCHBOARD_ACCOUNT_PREPARE_TOPUP:    11062,
    WRENCHBOARD_ACCOUNT_COMPLETE_TOPUP:   11063,
    WRENCHBOARD_ACCOUNT_REFFERHX:         11064,

    WRENCHBOARD_ACCOUNT_PREFERENCES:      11065,


    WRENCHBOARD_ACCOUNT_MYPAGE:      11070,
    WRENCHBOARD_ACCOUNT_PAGEINTRO:   11071,

    WRENCHBOARD_ACCOUNT_PRICE_COMBO:      11171,
    WRENCHBOARD_ACCOUNT_JOBPOST_DURATION: 11173,
    WRENCHBOARD_ACCOUNT_USER_RECIPIENT:   11175,
    WRENCHBOARD_ACCOUNT_ADD_RECIPIENT:    11176,
    WRENCHBOARD_ACCOUNT_ACCOUNT_TYPES:    11177,
    WRENCHBOARD_ACCOUNT_COUNTRY_COMBO:    11179,
    WRENCHBOARD_ACCOUNT_JOB_COUNTRY:      11181,
    WRENCHBOARD_ACCOUNT_COUNTRY_BANKS:    11183,


    WRENCHBOARD_ACCOUNT_HOMEBANNERS:   11200,
    WRENCHBOARD_ACCOUNT_PLAYGROUND:    11201,
    WRENCHBOARD_ACCOUNT_FAMILY_BANNERS: 22005,
    WRENCHBOARD_ACCOUNT_FAMILY_RESOURCES: 22017,

    WRENCHBOARD_ACCOUNT_RECENTS:       11202,
    WRENCHBOARD_ACCOUNT_NOTIFICATIONS: 11205,

    WRENCHBOARD_PICTURE_PROFILE: 11300,
    WRENCHBOARD_PICTURE_JOB: 11303,
    WRENCHBOARD_PICTURE_FAMBANNER: 11304,
    WRENCHBOARD_PICTURE_FAMMEMBER: 11305,
    WRENCHBOARD_RESOURCE_MYFILES: 11307,
    WRENCHBOARD_MYFILES_LIST: 11309,

    WRENCHBOARD_RELATIVE_LIST: 22032,
    WRENCHBOARD_RELATIVE_INVITE: 22031,

    WRENCHBOARD_USER_DELETEACC: 11990,
    WRENCHBOARD_ACCOUNT_END:    11999,
    WRENCHBOARD_JOB_POSTAGREE:  13002,
    WRENCHBOARD_JOB_LISTJOBS:   13005,
    WRENCHBOARD_JOB_OFFERS:     13006,
    WRENCHBOARD_JOB_ACTIVE:     13008,
    WRENCHBOARD_JOB_USERACTIVE: 13009,

    WRENCHBOARD_JOB_CREATEJOB:  13010 ,
    WRENCHBOARD_JOB_DELETEJOB:  13011 ,

    WRENCHBOARD_JOB_GROUP_MEMBER: 13015,
    WRENCHBOARD_JOB_DELETE_GROUPMEMBER:13017,
    WRENCHBOARD_JOB_CREATE_GROUP: 13020,
    WRENCHBOARD_JOB_DELETE_GROUP:13023,

    WRENCHBOARD_JOB_OFFER_INTLIST:   13024,
    WRENCHBOARD_JOB_OFFER_SYSTEM:  13025,
    WRENCHBOARD_JOB_OFFER_FAMILY:  13029,
    WRENCHBOARD_JOB_OFFER_INDVI:13030,
    WRENCHBOARD_JOB_OFFER_GROUP:13031,
    WRENCHBOARD_JOB_OFFER_PUBLIC:13032,
    WRENCHBOARD_JOB_SEND_INTEREST:13033,
    WRENCHBOARD_JOB_PROC_INTEREST:13034,
    WRENCHBOARD_JOB_OFFER_CONCLUDE:13035,
    WRENCHBOARD_JOB_SEND_QUESTION:13036,
    WRENCHBOARD_JOB_MRKTINT_QUEST:13037,
    WRENCHBOARD_JOB_REPLY_QUESTION:13038,
    WRENCHBOARD_JOB_INT_QUESTLST:  13039,
    WRENCHBOARD_JOB_WAITING_INT:  13040,

    WRENCHBOARD_JOB_EXTEND_EXPIRE: 13041,
    WRENCHBOARD_JOB_RESEND_MESSAGE: 13042,
    WRENCHBOARD_JOB_CANCEL_OFFER: 13043,
    WRENCHBOARD_VERIFY_COMPLETED_TASK: 13033,

    WRENCHBOARD_JOB_JOBGROUPS:    13045,
    WRENCHBOARD_JOB_JOBGROUPADD:  13046,
    WRENCHBOARD_JOB_REPORT:  13047,

    WRENCHBOARD_INTEREST_STATS:  13048,

    WRENCHBOARD_RELATIVE_SETTINGS:  22033,
    WRENCHBOARD_RELATIVE_EDIT_KIDS:  22034,
    WRENCHBOARD_RELATIVE_REMINDER:  22035,

    WRENCHBOARD_GROUP_START: 12000,
    WRENCHBOARD_GROUP_CREATEGROUP: 12010,
    WRENCHBOARD_GROUP_INVITEGROUP: 12015,
    WRENCHBOARD_GROUP_ACCEPTGROUP: 12020,
    WRENCHBOARD_CONTRACT_MESSAGE: 14010,
    WRENCHBOARD_CONTRACT_MSGLIST: 14011,
    WRENCHBOARD_CONTRACT_STATUS:  14015,
    WRENCHBOARD_MOBILE_OFFERSLIST: 15010,
    WRENCHBOARD_MOBILE_OFFERSRESP:  15012,
    WRENCHBOARD_MOBILE_ACTIVEJOB: 15020,
    WRENCHBOARD_MOBILE_LOADPROFILE:  15030,
    WRENCHBOARD_MOBILE_UPDATEPROFILE:15031,
    WRENCHBOARD_MOBILE_ACCOUNT: 15040,
    WRENCHBOARD_MOBILE_JOB_MESSAGE: 15044,
    WRENCHBOARD_MOBILE_MESSAGE:  15045,
    WRENCHBOARD_MOBILE_PAYMENTHX: 15046,
    WRENCHBOARD_MOBILE_TASKMESSAGE: 15047,
    WRENCHBOARD_MOBILE_SENDTASKMESSAGE: 15048,
    WRENCHBOARD_MOBILE_PURCHASEHX:   15049,
    WRENCHBOARD_FAMILY_LIST:           22010,
    WRENCHBOARD_FAMILY_TRANSFERHX:     22011,
    WRENCHBOARD_FAMILY_WALLET:         22012,
    WRENCHBOARD_FAMILY_TRANSFERSTART:  22013,
    WRENCHBOARD_FAMILY_TRANSFER:       22014,
    WRENCHBOARD_FAMILY_ADD:            22015,
    WRENCHBOARD_FAMILY_UPDATE:         22020,
    WRENCHBOARD_FAMILY_MANAGE:         22025,
    WRENCHBOARD_FAMILY_SGGESTSTATUS:   22026,
    WRENCHBOARD_FAMILY_SAMPLETASKS:    22027,
    WRENCHBOARD_FAMILY_SGGESTTASKS:    22028,
    WRENCHBOARD_FAMILY_SGGESTLIST:     22029,
    WRENCHBOARD_FAMILY_SGGESTWAITING:  22030,
    WRENCHBOARD_FAMILY_RELINVITE:      22031,
    WRENCHBOARD_SMONEY_ADDRECIPIENT: 33010,
    WRENCHBOARD_SMONEY_MEMBER: 33020,
    WRENCHBOARD_SMONEY_PROCFEE:  33025,
    WRENCHBOARD_SMONEY_BKOPROC:  33030 ,
    WRENCHBOARD_LOGIN_SHOP: 50501,
    WRENCHBOARD_LOGIN_ADMIN: 50502,
    WRENCHBOARD_LOGIN_MANAGER: 50503,
    WRENCHBOARD_SURVEY_DATA: 55000,
    WRENCHBOARD_ADD_SURVEY: 55050,
    WRENCHBOARD_LOAD_SURVEY: 55055,
    WRENCHBOARD_COMPLETE_SENDMONEY_INTERSW: 555,
    WRENCHBOARD_GETBILLER_INTERSW: 556,
    WRENCHBOARD_BILL_PAYMENT_ADVICE_INTERSW: 557,
    WRENCHBOARD_INTERSW_GETBILLER: 556,
    WRENCHBOARD_INTERSW_BILL_PAYMENT_ADVICE: 557,
    WRENCHBOARD_INTERSW_GETBILLERPAYMENTINTEMS: 558,
    WRENCHBOARD_INTERSW_GETBILLERCATEGORIES: 559,
    WRENCHBOARD_INTERSW_DO_TRANSFER: 560,
    WRENCHBOARD_INTERSW_QUERY_TRANSACTION: 561,
    WRENCHBOARD_ADD_MONEYRECIPIENT: 600,
    WRENCHBOARD_CREATE_USER_ACCOUNT: 700,
    WRENCHBOARD_USER_ACCOUNT_LOGIN: 710,
    WRENCHBOARD_START_PASSWORDRESET: 720,
    WRENCHBOARD_VERIFY_PASSWD_RESETLINK: 725,
    WRENCHBOARD_COMPLETE_PASSWORDRESET: 730,
    WRENCHBOARD_CARDS_GETCARDLIST: 40010,
    WRENCHBOARD_CARDS_UPDATECARD:  40015 ,
    WRENCHBOARD_COUPON_REDEEM:   85020,
    WRENCHBOARD_COUPON_MEMLIST:  85025,
    WRENCHBOARD_COUPON_PENDLIST: 85030,
    WRENCHBOARD_ADD_MOBILE_TOPUPNUM: 900,
    WRENCHBOARD_PROMO_CALL: 990,
    WRENCHBOARD_STOREFACE_GET_ACCOUNT_BALANCE: 891,
    WRENCHBOARD_LOG_ENTRY: 900000,
    WRENCHBOARD_CREDIT_TOPUP: 900010,
    WRENCHBOARD_TOPUP_ORDER: 900020,
    WRENCHBOARD_TOPUP_ORDER_PURCHASE: 900030,
    WRENCHBOARD_PAYPAL_IPNMSG: 900090,
    WRENCHBOARD_BULKTOPUP_ORDER: 700010,
    WRENCHBOARD_BULKTOPUP_ITEM: 700020,
    WRENCHBOARD_BULKTOPUP_ITEMUPDATE: 700030,
    WRENCHBOARD_BULKTOPUP_DELIVER: 700040,
    WRENCHBOARD_BALANCE_TOPUP_ORDER: 800020,
    WRENCHBOARD_BALANCE_TOPUP_PURCHASE: 800030,
    WRENCHBOARD_BALANCE_TOPUP_PAYMENT: 800040,
    VIRTUAL_AIRTOPUP: 70011,
    PAY_MODE_BALANCE: 0,
    PAY_MODE_CCARD: 1,
    PAY_MODE_BONUS: 9,
    APPROVED_BALANCE: 5,
    DISAPROVE_BALANCE: 3
};