import { useSelector } from "react-redux";
import Home from "../components/Home";

export default function HomePages() {
  const { commonHeadBanner } = useSelector((state) => state.commonHeadBanner);

  const bannerOptions = {
    bannerList: commonHeadBanner?.result_list,
    dashTypes: commonHeadBanner?.home_dash_type,
  };

  return (
    <>
      <Home {...bannerOptions} />
    </>
  );
}
