import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    recentActivitiesData: {}
};

export const recentActivitiesDataSlice = createSlice({
    name: "recentActivitiesData",
    initialState,
    reducers: {
        recentActivitiesData: (state,action) => {
            state.recentActivitiesData = {...action.payload}
        },
    },
});

// Action creators are generated for each case reducer function
export const { recentActivitiesData } = recentActivitiesDataSlice.actions;

export default recentActivitiesDataSlice.reducer;