const TimeDifference = ({ time }) => {
  const currentTime = new Date();
  const providedTime = new Date(time);

  const timeDifference = currentTime - providedTime; // Difference in milliseconds

  const minutes = Math.floor(timeDifference / (1000 * 60));
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));

  if (minutes < 1) {
    return "Just now";
  } else if (minutes < 60) {
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  } else if (hours < 12) {
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else if (hours < 24) {
    return "Today";
  } else if (days < 2) {
    return "Tomorrow";
  } else if (days < 7) {
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  } else if (months < 1) {
    const weeks = Math.floor(days / 7);
    return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
  } else if (months < 6) {
    return `${months} ${months === 1 ? "month" : "months"} ago`;
  }else if (months < 8) {
    return `"More than 6 months ago"`;
  } else {
    return time?.split(" ")[0];
  }
};

export default TimeDifference;
