import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobLists: {loading: true}
};

export const jobSlice = createSlice({
  name: "jobLists",
  initialState,
  reducers: {
    updateJobs: (state, action) => {
      state.jobLists = { ...action.payload };
    },
  },
});

export const { updateJobs } = jobSlice.actions;

export default jobSlice.reducer;
