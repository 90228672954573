import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { localImgLoad } from "../../lib";

import DarkModeContext from "../Contexts/DarkModeContext";

export default function LoginLayout({ slogan, children }) {
  const bgImg = localImgLoad("images/left-wrenchboard.jpg");
  const bgImgNig = localImgLoad("images/wrench-home-back-nigeria.jpg");
  const bgImgCom = localImgLoad("images/wrench-home-back-common.jpg");

  const { countryMode } = useContext(DarkModeContext);

  return (
    <div
      className={`min-h-screen overflow-y-auto bg-cover bg-center flex flex-col justify-between items-center`}
      style={{
        backgroundImage: `url(${countryMode == "NG" ? bgImgNig : bgImgCom})`,
      }}
    >
      
      <div className={`w-full grid grid-cols-1 xl:grid-cols-2`}>
        {/* <div 
            className={`auth-bg hidden xl:block bg-blue-50 relative bg-cover bg-no-repeat border-0 after:content-[''] after:absolute after:inset-0`} 
            style={{backgroundImage: `url(${bgImg})`}}
        >
        </div> */}
        <div className="p-5 sm:p-7 flex place-content-center xl:col-start-2">
          <div className="py-5 w-full sm:w-11/12 max-w-[550px] shadow-md bg-slate-50 dark:bg-dark-white rounded-[0.475rem]">
            <div className="w-full flex justify-center items-center">
              {children && children}
            </div>
          </div>
        </div>
      </div>

      <div className='w-full shadow-md bg-slate-50 dark:bg-dark-white'>
        <div className="w-full flex flex-col md:flex-row justify-center items-center px-10 py-2">
          <div className="flex justify-center items-center">
            <div className="flex items-center">
              <a
                href="https://www.wrenchboard.com/about-us"
                className="text-[#a1a5b7] text-[15px] px-2 font-medium hover:text-[#009ef7]"
                target="_blank"
                rel="noreferrer"
              >
                About
              </a>
              <a
                href="https://www.wrenchboard.com/service"
                className="text-[#a1a5b7] text-[15px] px-2 font-medium hover:text-[#009ef7]"
                target="_blank"
                rel="noreferrer"
              >
                Services
              </a>
              <a
                href="https://www.wrenchboard.com/contact"
                className="text-[#a1a5b7] text-[15px] px-2 font-medium hover:text-[#009ef7]"
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
            </div>
          </div>
          <p className="text-black text-[15px] px-2 font-medium flex items-center gap-1">
            <span className="dark:text-white">
              &copy; {new Date().getFullYear()} -
            </span>
            <Link to="/" className="text-[#009ef7] ml-1">
              WrenchBoard
            </Link>{" "}
          </p>
        </div>
      </div>

    </div>
  );
}
