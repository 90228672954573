import React from "react";

// export const PriceFormatter = (price, currency, currencyName) => {
//   const supportedCurrencies = ["USD", "EUR", "GBP"];
//   const symbolFormatter = supportedCurrencies.includes(currency)
//     ? currency
//     : undefined;

//   const formatter = new Intl.NumberFormat("en", {
//     style: symbolFormatter,
//     currencyDisplay: "symbol",
//     minimumFractionDigits: 2,
//   });

//   const displayCurrencyName = symbolFormatter ? "" : currencyName;

//   return `${formatter.format(price)} ${displayCurrencyName}`;
// };

export const PriceFormatter = (
  price = "00",
  currency = "",
  currencyName = "",
  priceClass
) => {
  // Convert the number to a string
  let numStr = String(price);

  // Split the string into integer and decimal parts
  let parts = numStr.split(".");
  let integerPart = parts[0] || "";
  let decimalPart = parts[1] || "";

  // Add thousands separators to the integer part
  // let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  let formattedInteger = integerPart;

  // Truncate or pad the decimal part to two decimal points
  let formattedDecimal = decimalPart.slice(0, 2).padEnd(2, "0");

  // Combine the formatted integer and decimal parts
  // let formattedNumber = formattedInteger + '.' + formattedDecimal;

  // return formattedNumber;
  return (
    <span className="text-sm flex items-center">
      <sup>{currency || currencyName || ""}</sup>
      <span className={`px-1 font-bold ${priceClass ? priceClass : "text-lg"}`}>{formattedInteger || ""}</span>
      <sup>{formattedDecimal || ""}</sup>
    </span>
  );
};


// FUNCTION TO RETURN AMOUNT TO TWO DECIMAL PLACES
export const AmountTo2DP = (
  amount = "00",
) => {
  // Convert the number to a string
  let numStr = String(amount);

  // Split the string into integer and decimal parts
  let parts = numStr.split(".");
  let integerPart = parts[0] || "";
  let decimalPart = parts[1] || "";

  // Add thousands separators to the integer part
  let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Truncate or pad the decimal part to two decimal points
  let formattedDecimal = decimalPart.slice(0, 2).padEnd(2, "0");

  // Combine the formatted integer and decimal parts
  let formattedNumber = formattedInteger + '.' + formattedDecimal;

  // return formattedNumber;
  return formattedNumber;
};