import React, { useEffect, useState } from "react";
import ModalCom from "../../../Helpers/ModalCom";
import InputCom from "../../../Helpers/Inputs/InputCom";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { AmountTo2DP } from "../../../Helpers/PriceFormatter";
import usersService from "../../../../services/UsersService";
import LoadingSpinner from "../../../Spinners/LoadingSpinner";
import { PriceFormatter } from "../../../Helpers/PriceFormatter";
import { tableReload } from "../../../../store/TableReloads";
import { useDispatch, useSelector } from "react-redux";
import { apiConst } from "../../../../lib/apiConst";
import { SocketValues } from "../../../Contexts/SocketIOContext";

const validationSchema = Yup.object().shape({
  // amount: Yup.string()
  //   .typeError("Invalid number")
  //   .min(1, "Price must be greater than 0")
  //   .test("no-e", "Invalid number", (value) => {
  //     if (value && /\d+e/.test(value)) {
  //       return false;
  //     }
  //     return true;
  //   })
  //   .required("Amount is required"),
  amount: Yup.number("Please enter a number")
    .min(1, "Price must be greater than 0")
    .required("Amount is required"),
  comment: Yup.string().required("Comment is required"),
});

function FamilyAddFundPopout({ action, situation, wallet, familyData }) {

  const {userDetails} = useSelector((state) => state?.userDetails); // Gets User Detail

  const { parentAssignJobToKid } = SocketValues() // socket emit event from FULL account

  const dispatch = useDispatch();

  const apiUrl = new usersService();

  const [startTransfer, setStartTransfer] = useState({
    loading: true,
    data: {},
  });

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    status: false,
    message: "",
  });

  // initial values for formik
  let initialValues = {
    amount: "",
    from: AmountTo2DP(startTransfer?.data?.origing_current_balance * 0.01),
    to: `${familyData.firstname} ${familyData.lastname}`,
    comment: "",
  };
  // FUNCTION TO PERFORM FAMILY TRANSFER
  const handleAddFund = (values) => {

    setRequestStatus({ loading: true, status: false, message: "" });

    let senderBal = startTransfer?.data?.origing_current_balance || ""; // SENDER'S ACCOUNT BALANCE
    let senderLimit = startTransfer?.data?.origing_transfer_limit || ""; // SENDER'S TRANSFER LIMIT

    let reqData = {
      // API REQUEST DATA
      family_uid: familyData.uid,
      wallet_uid: wallet.wallet_uid,
      origing_wallet_uid: startTransfer?.data?.origing_wallet_uid,
      currency: startTransfer?.data?.currency,
      amount: values.amount * 100,
      description: values.comment,
      family_transfer_mode: 100,
      action: apiConst.WRENCHBOARD_FAMILY_TRANSFER,
    };

    if (!senderBal || !senderLimit) {
      // RETURNS UNAUTHORIZED, IF SENDER BAL OR LIMIT IS NOT AVAILABLE
      setRequestStatus({
        loading: false,
        status: false,
        message: "Unauthorized, try again later",
      });
      return setTimeout(() => {
        setRequestStatus({ loading: false, status: false, message: "" });
      }, 5000);
    }

    if (values.amount > senderBal * 0.01) {
      // CHECKS TO SEE IF SENDER IS SENDING MORE THAN HIS BALANCE
      setRequestStatus({
        loading: false,
        status: false,
        message: "You cannot send more than your balance",
      });
      return setTimeout(() => {
        setRequestStatus({ loading: false, status: false, message: "" });
      }, 5000);
    }

    if (values.amount > senderLimit * 0.01) {
      // CHECKS TO SEE IF SENDER IS SENDING MORE THAN HIS LIMIT
      setRequestStatus({
        loading: false,
        status: false,
        message: `You cannot exceed ${senderLimit * 0.01} ${
          startTransfer?.data?.origing_currency.charAt(0).toUpperCase() +
          startTransfer?.data?.origing_currency.slice(1).toLowerCase()
        }`,
      });
      return setTimeout(() => {
        setRequestStatus({ loading: false, status: false, message: "" });
      }, 5000);
    }

    apiUrl
      .familyTransfer(reqData)
      .then(({ data }) => {
        if (
          data.internal_return < 0 ||
          data.credit_confirm == "" ||
          data.pay_confirm == ""
        ) {
          setRequestStatus({
            loading: false,
            status: false,
            message: "Transfer Failed",
          });
          return setTimeout(() => {
            setRequestStatus({ loading: false, status: false, message: "" });
          }, 5000);
        }
        setRequestStatus({
          loading: false,
          status: true,
          message: "Transfer Successful",
        });

        //SENDS MESSAGE TO SOCKET TO UPDATE CHILD ACCOUNT
        // message, room
        let socketMsg = {
          "audience": "MEMBER",
          "action": "REFRESH_WALLET",
          "family_uid": reqData.family_uid,
        }
        let socketRoom = `FAMILY-${userDetails.uid}`
        parentAssignJobToKid(socketMsg, socketRoom) //SENDS MESSAGE TO SOCKET TO UPDATE CHILD ACCOUNT

        setTimeout(() => {
          setRequestStatus({ loading: false, status: false, message: "" });
          dispatch(tableReload({ type: "WALLETTABLE" })); // UPDATES PARENT WALLET ACCOUNT
          action(); // TO CLOSE THE MODAL
        }, 5000);
      })
      .catch((error) => {
        setRequestStatus({
          loading: false,
          status: false,
          message: "Network Error, try again",
        });
        setTimeout(() => {
          setRequestStatus({ loading: false, status: false, message: "" });
        }, 5000);
      });
  };

  // LOAD FAMILY START TRANSFER
  useEffect(() => {
    let reqData = {
      family_uid: familyData.uid,
      wallet_uid: wallet.wallet_uid,
      action: apiConst.WRENCHBOARD_FAMILY_TRANSFERSTART,
    };
    apiUrl
      .familyTransferStart(reqData)
      .then((response) => {
        setStartTransfer({ loading: false, data: response?.data });
      })
      .catch((err) => {
        setStartTransfer({ loading: false, data: {} });
      });
  }, []);

  return (
    <ModalCom action={action} situation={situation}>
      <div className="relative logout-modal-wrapper lg:w-[500px] h-full lg:h-auto bg-white dark:bg-dark-white lg:rounded-2xl">
        <div className="modal-header-con">
          <h1 className="modal-title">
            Add Fund
          </h1>
          <button
            type="button"
            className="modal-close-btn"
            onClick={action}
          >
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36 16.16C36 17.4399 36 18.7199 36 20.0001C35.7911 20.0709 35.8636 20.2554 35.8385 20.4001C34.5321 27.9453 30.246 32.9248 22.9603 35.2822C21.9006 35.6251 20.7753 35.7657 19.6802 35.9997C18.4003 35.9997 17.1204 35.9997 15.8401 35.9997C15.5896 35.7086 15.2189 35.7732 14.9034 35.7093C7.77231 34.2621 3.08728 30.0725 0.769671 23.187C0.435002 22.1926 0.445997 21.1199 0 20.1599C0 18.7198 0 17.2798 0 15.8398C0.291376 15.6195 0.214408 15.2656 0.270759 14.9808C1.71321 7.69774 6.02611 2.99691 13.0428 0.700951C14.0118 0.383805 15.0509 0.386897 15.9999 0C17.2265 0 18.4532 0 19.6799 0C19.7156 0.124041 19.8125 0.136067 19.9225 0.146719C27.3 0.868973 33.5322 6.21922 35.3801 13.427C35.6121 14.3313 35.7945 15.2484 36 16.16ZM33.011 18.0787C33.0433 9.77105 26.3423 3.00309 18.077 2.9945C9.78479 2.98626 3.00344 9.658 2.98523 17.8426C2.96667 26.1633 9.58859 32.9601 17.7602 33.0079C26.197 33.0577 32.9787 26.4186 33.011 18.0787Z"
                fill=""
                fillOpacity="0.6"
              />
              <path
                d="M15.9309 18.023C13.9329 16.037 12.007 14.1207 10.0787 12.2072C9.60071 11.733 9.26398 11.2162 9.51996 10.506C9.945 9.32677 11.1954 9.0811 12.1437 10.0174C13.9067 11.7585 15.6766 13.494 17.385 15.2879C17.9108 15.8401 18.1633 15.7487 18.6375 15.258C20.3586 13.4761 22.1199 11.7327 23.8822 9.99096C24.8175 9.06632 26.1095 9.33639 26.4967 10.517C26.7286 11.2241 26.3919 11.7413 25.9133 12.2178C24.1757 13.9472 22.4477 15.6855 20.7104 17.4148C20.5228 17.6018 20.2964 17.7495 20.0466 17.9485C22.0831 19.974 24.0372 21.8992 25.9689 23.8468C26.9262 24.8119 26.6489 26.1101 25.4336 26.4987C24.712 26.7292 24.2131 26.3441 23.7455 25.8757C21.9945 24.1227 20.2232 22.3892 18.5045 20.6049C18.0698 20.1534 17.8716 20.2269 17.4802 20.6282C15.732 22.4215 13.9493 24.1807 12.1777 25.951C11.7022 26.4262 11.193 26.7471 10.4738 26.4537C9.31345 25.9798 9.06881 24.8398 9.98589 23.8952C11.285 22.5576 12.6138 21.2484 13.9387 19.9355C14.5792 19.3005 15.2399 18.6852 15.9309 18.023Z"
                fill="#"
                fillOpacity="0.6"
              />
            </svg>
          </button>
        </div>
        <div className="logout-modal-body w-full flex flex-col items-center px-10 py-8">
          {startTransfer.loading && (
            <LoadingSpinner size="16" color="sky-blue" height={"h-64"} />
          )}

          {!startTransfer.loading && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleAddFund}
            >
              {(props) => (
                <Form className="w-full">
                  <div className="flex flex-col-reverse sm:flex-row">
                    <div className="fields w-full">
                      {/* AMOUNT  */}
                      <div className="field w-full mb-[0.5rem]">
                        <InputCom
                          placeholder="0"
                          label={`Amount (${startTransfer?.data?.currency})`}
                          name="amount"
                          type="text"
                          parentClass="flex items-center gap-1 w-full"
                          labelClass="flex-[0.3] mb-0"
                          inputClass={`flex-[0.7] input-curve lg border border-[#dce4e9] ${
                            props.errors.amount && props.touched.amount
                              ? "border border-red-500"
                              : ""
                          }`}
                          fieldClass="px-2 text-right"
                          value={props.values.amount}
                          inputHandler={props.handleChange}
                        />
                      </div>

                      {/* FROM  */}
                      <div className="field w-full mb-[0.5rem]">
                        <InputCom
                          placeholder="From"
                          label={`From (${startTransfer?.data?.origing_currency})`}
                          name="from"
                          type="text"
                          parentClass="flex items-center gap-1 w-full"
                          labelClass="flex-[0.3] mb-0"
                          inputClass={`flex-[0.7] input-curve lg border border-[#dce4e9]`}
                          fieldClass="px-2 text-right"
                          value={props.values.from}
                          disable={true}
                        />
                      </div>

                      {/* TO  */}
                      <div className="field w-full mb-[0.5rem]">
                        <InputCom
                          placeholder="To"
                          label="To:"
                          name="to"
                          type="text"
                          parentClass="flex items-center gap-1 w-full"
                          labelClass="flex-[0.3] mb-0"
                          inputClass={`flex-[0.7] input-curve lg border border-[#dce4e9]`}
                          fieldClass="px-2 text-right"
                          value={props.values.to}
                          disable={true}
                        />
                      </div>

                      {/* COMMENT  */}
                      <div className="field w-full mb-[0.5rem]">
                        <div className="w-full">
                          <label
                            htmlFor="job-label"
                            className="input-label text-[#181c32] dark:text-white text-[13.975px] leading-[20.9625px] font-semibold flex items-center gap-1"
                          >
                            Comment
                            {/* {props.errors.comment && props.touched.comment && <span className='text-sm text-red-500'>{' '}{props.errors.comment}</span>} */}
                          </label>
                          <textarea
                            // id="Job Delivery Details"
                            rows="2"
                            className={`input-field px-3 py-2 placeholder:text-base text-dark-gray dark:text-white w-full bg-slate-100 dark:bg-[#11131F] focus:ring-0 focus:outline-[#dce4e9] rounded-[10px] border ${
                              props.errors.comment && props.touched.comment
                                ? "border border-red-500"
                                : ""
                            }`}
                            style={{ resize: "none" }}
                            name="comment"
                            value={props.values.comment}
                            onChange={props.handleChange}
                          />
                        </div>
                      </div>
                      {/* inputs ends here */}
                    </div>
                  </div>
                  {/* ERROR DISPLAY AND SUBMIT BUTTON */}
                  <div className="content-footer w-full">
                    {/* error or success display */}
                    {requestStatus.message != "" &&
                      (!requestStatus.status ? (
                        <div
                          className={`relative p-4 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
                          // className={`pb-1 absolute bottom-0 left-1/2 -translate-x-1/2 text-[#912741] rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
                        >
                          {requestStatus.message}
                        </div>
                      ) : (
                        requestStatus.status && (
                          <div
                            className={`relative p-4 text-green-700 bg-slate-200 border-slate-800 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
                            // className={`pb-1 absolute bottom-0 left-1/2 -translate-x-1/2 text-green-700 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
                          >
                            {requestStatus.message}
                          </div>
                        )
                      ))}
                    {/* End of error or success display */}

                    <div className="pt-2 w-full border-t border-light-purple dark:border-[#5356fb29]  flex justify-end items-center">
                      <div className="w-full flex justify-between gap-2 items-center">
                        <button
                          type="button"
                          onClick={action}
                          className="w-[150px] h-[48px] rounded-full text-base text-white bg-red-600 hover:bg-red-500"
                        >
                          Cancel
                        </button>
                        <>
                          {requestStatus.loading ? (
                            <LoadingSpinner size="6" color="sky-blue" />
                          ) : (
                            <button
                              type="submit"
                              className={`w-[150px] h-[48px] rounded-full text-base text-white bg-sky-500 hover:bg-sky-400 ${
                                requestStatus.status ? "opacity-50" : ""
                              }`}
                              disabled={requestStatus.status}
                            >
                              Send
                            </button>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </ModalCom>
  );
}

export default FamilyAddFundPopout;
