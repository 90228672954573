import React, { useEffect, useState } from 'react'

import usersService from '../../../services/UsersService'

import LoadingSpinner from '../../Spinners/LoadingSpinner'
import background from '../../../assets/images/bg-sky-blue.jpg'
import Wallet from './wallet/Wallet'

function FamilyWallet({familyData}) {
  const apiUrl = new usersService()

  let [familyWallet, setFamilyWallet] = useState({loading:true, data: []})

  let [familyWalletReload, setFamilyWalletReload] = useState(false) // STATE TO DETERMINE WHEN TO RELOAD FAMILY WALLET TAB/PAGE

  useEffect(()=>{
    setFamilyWallet({loading:true, data: []})
    apiUrl.getKidWallets({family_uid:familyData?.uid}).then(res => {
      setFamilyWallet({loading:false, data: res?.data?.result_list || []})
    }).catch(error => {
      setFamilyWallet({loading:false, data: []})
    })
  },[familyWalletReload])

  return (
    <div className='p-3 w-full h-full bg-white dark:bg-dark-white flex flex-col justify-start items-start'>
        {familyWallet.loading ?
        <div className='w-full h-20 flex justify-center items-center'>
          <LoadingSpinner size='8' color='sky-blue' />
        </div>
        :
        familyWallet?.data?.length > 0 ?
        <div className='w-full p-4 flex flex-col gap-2'>
        {familyWallet?.data?.map((wallet, index)=>(
            <Wallet key={index} wallet={wallet} familyData={familyData} setFamilyWalletReload={setFamilyWalletReload} />
          ))}
        </div>
        :
        <p className='text-lg text-gray-500 dark:text-gray-400'>No Wallet Found!</p>
        }
    </div>
  )
}

export default FamilyWallet