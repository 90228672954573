import React from "react";
// import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import * as animationData from "../../assets/images/Lotties/77618-website-404-error-animation.json";
import { localImgLoad } from "../../lib";
// import BgError from '../../assets/images/wrench-page-notfound.jpg'

export default function FourZeroFour() {
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className={`my-custom-bg-class flex justify-center items-center w-full min-h-screen before:content-[''] before:absolute before:inset-0 before:bg-[#cdcdcd]/[.8]`}>
      <div className="relative pt-32 max-w-3xl">
        {/* <Lottie options={defaultOptions} width={600} height={600} /> */}
        <div className="px-16 md:px-24 flex flex-col items-center justify-center gap-4">
          <img src={localImgLoad('images/404.png')} className="w-72" alt='404Image' />
          <p className="mt-8 text-red-600 font-black text-4xl md:text-5xl tracking-wider text-center">Sorry!</p>
          <h1 className="text-black text-4xl md:text-5xl font-black tracking-wide text-center leading-tight">The page can’t be found.</h1>
          <p className="px-2 md:px-8 text-slate-700 text-base md:text-lg text-center">The page you're looking for isn't available. Use the go back button below</p>
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="px-4 md:px-8 border-2 border-[#2b70fa] rounded-lg text-[#007bff] text-base md:text-lg h-[48px] flex justify-center items-center gap-1"
          >
            <span>Go Back</span>
            <span className="pb-1">&rarr;</span>
          </button>
        </div>
      </div>
    </div>
  );
}
