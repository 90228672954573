import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../Partials/Layout";
import MyJobTable from "./MyJobTable";
import CommonHead from "../UserHeader/CommonHead";
import SelectBox from "../Helpers/SelectBox";
import dataImage2 from "../../assets/images/data-table-user-2.png";

import LoadingSpinner from "../Spinners/LoadingSpinner";

import usersService from "../../services/UsersService";

export default function StartJob(props) {
    const apiCall = new usersService() // instantiating API CALL
    const navigate = useNavigate()

    let [request, setRequest] = useState({loading: false, status: false, message: ''})

    const handleOnclick = () => {
        setRequest({loading: true, status: false, message: ''})
        apiCall.jobManagerAgree().then((res)=>{
            if(res.status != 200 || res.data.internal_return < 1){
                setRequest({loading: false, status: false, message: 'Could not complete request, Try Again'})
                return   
            }
            setRequest({loading: false, status: true, message: 'Request completed successfully'})
            setTimeout(()=>{
                navigate('/', {replace: true})
                window.location.reload()
            }, 2000)
        }).catch(error => {
            setRequest({loading: false, status: false, message: 'Opps! An Error Occured. Try Again'})
            console.log(error)
        })
    }
    return (
        <Layout>
            {/*<CommonHead />*/}
            <div className="notification-page w-full mb-10">
                <div className="notification-wrapper w-full">
                    <div
                        className={`update-table w-full p-8 bg-white dark:bg-dark-white   overflow-hidden rounded-2xl section-shadow min-h-[520px]`}
                    >
                        <div className="header w-full flex justify-between items-center mb-5">
                            <div className="flex space-x-2 items-center">
                                <h3 className="text-lg font-bold text-dark-gray dark:text-white tracking-wide">
                                Additional Terms for Jobs Posting.
                                </h3>
                            </div>
                            {/*<SelectBox*/}
                            {/*    action={setCategory}*/}
                            {/*    datas={filterCategories}*/}
                            {/*    className="Update-table-dropdown"*/}
                            {/*    contentBodyClasses="w-auto min-w-max"*/}
                            {/*/>*/}
                        </div>
                        <div className="relative w-full overflow-x-auto sm:rounded-lg">
                            <p className="my-2 text-base">All terms of sale must follow WrenchBoard policies for buying and selling. Setting and meeting expectations in a listing's terms of sale ensure a smooth transaction and buyer satisfaction while helping WrenchBoard remain a reputable marketplace.
                            </p>

                            <h3 className="mb-2 mt-6 text-lg font-bold">What is the policy?</h3>

                            <p className="mt-2 text-base">Task or Job owners should provide clear and accurate delivery terms and conditions required to meet the expectations they have set in their listings. These should include:</p>

                            <ul className="mt-3 mb-10 border-2 rounded-md">
                                <li className="px-2 py-1 text-sm text-slate-900 border-b-2">Delivery mode, timelines, and reviews</li>
                                <li className="px-2 py-1 text-sm text-slate-900">The forms of payment available for the task</li>
                                <li className="px-2 py-1 text-sm text-slate-900">A returns policy:
                                    <ul>
                                        <li className="px-2 py-1 text-sm text-slate-900">Clearly stated criteria under which cancellation will be accepted.</li>
                                        <li className="px-2 py-1 text-sm text-slate-900">Clearly stated the time period in which the buyer must notify the seller about the delivery delay.</li>
                                        <li className="px-2 py-1 text-sm text-slate-900 border-2 rounded-md">How refunds/returns/cancellations will be issued</li>
                                    </ul>
                                </li>
                            </ul>

                            <p className="my-2 mt-6 text-base">Activity that doesn't follow WrenchBoard policy could result in a range of actions, including for example: administratively ending or canceling listings, hiding or demoting all listings from search results, lowering rating, buying or selling restrictions, and account suspension.
                            All fees paid or payable concerning listings or accounts on which we take any action will not be refunded or otherwise credited to your account.</p>

                            <h3 className="mb-2 mt-6 text-lg font-bold">Why does WrencBoard have this policy?</h3>

                            <p className="my-2 text-base">Buyers and sellers enter a contract when they complete transactions, so it's necessary to have precise details on what the transaction entails to guarantee satisfaction. This policy also helps sellers meet our performance standards.</p>
                            
                            {/* error or success display */}
                            {request.message && !request.status ?
                            <div className={`relative p-4 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}>
                                {request.message}
                            </div>
                            :
                            request.message && request.status &&
                            (<div className={`relative p-4 text-green-700 bg-slate-200 border-slate-800 mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}>
                                {request.message}
                            </div>
                            )
                            }
                            {/* End of error or success display */}
                            
                            <div className="my-2 p-2 flex justify-end items-center">
                                {request.loading ?
                                <LoadingSpinner size='6' color='sky-blue' />
                                :
                                <button onClick={handleOnclick} className="text-white btn-gradient text-lg tracking-wide px-4 py-3 rounded-full">Agree to Terms</button>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
}
