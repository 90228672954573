import React from 'react'

function ReviewTaskAction() {
  return (
    <div className='job-action  dark:bg-black task_action_panel'>
      <p className="my-3 py-1 text-base text-dark-gray dark:text-white">
        Waiting for the completion message from the client before you can approve. Worker True & Review Job
      </p>
    </div>
  )
}

export default ReviewTaskAction