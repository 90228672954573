import React, {Suspense, lazy} from 'react'
// import FamilyList from '../../FamilyList'
import LoadingSpinner from '../../../../components/Spinners/LoadingSpinner'

const AddFamily = () => {
  const FamilyListSettings = lazy(() => import('../../FamilyListSettings'));
  return (
    <>
      <Suspense fallback={<LoadingSpinner color="sky-blue" size="16" height='h-full min-h-[500px]' />}>
        <FamilyListSettings />
      </Suspense>
    </>
  )
}

export default AddFamily