import React from "react";
import HomeActivities from "../../Home/HomeActivities";

const RecentActivitiesTab = () => {
  return (
    <div className="recent-activity-tab w-full">
      <HomeActivities />
    </div>
  );
};

export default RecentActivitiesTab;
