import { configureStore } from "@reduxjs/toolkit";
import drawerReducer from "./drawer";

import commonHeadBannerReducer from "./CommonHeadBanner";
import tableReloadReducer from "./TableReloads";
import userDetailReducer from "./UserDetails";
import jobReducer from "./jobLists";
import notificationsReducer from "./notifications";
import userJobListReducer from "./userJobList";
import walletDetails from "./walletDetails";
import familyBannerListReducer from "./FamilyBannerList"
import familyResourcesReducer from './FamilyResources'
import familyWalletRedeemOptListReducer from './FamilyWalletRedeemOpt'

export default configureStore({
  reducer: {
    drawer: drawerReducer,
    userDetails: userDetailReducer,
    jobLists: jobReducer,
    tableReload: tableReloadReducer,
    userJobList: userJobListReducer,
    commonHeadBanner: commonHeadBannerReducer,
    notifications: notificationsReducer,
    walletDetails: walletDetails,
    familyBannersList: familyBannerListReducer,
    familyResources: familyResourcesReducer,
    familyWalletRedeemOptList: familyWalletRedeemOptListReducer
  },
});
