import React from 'react'
import FamWorkInProgress from '../components/familyResources/FamWorkInProgress'

export default function FamWorkInProgressPage() {
  return (
    <>
        <FamWorkInProgress />
    </>
  )
}
