import React from "react";
import FamilyActivities from "../components/FamilyAcc/FamilyActivities";

const FamilyActivitiesPage = () => {
  return (
    <>
      <FamilyActivities />
    </>
  );
};

export default FamilyActivitiesPage;
