import MyJobs from "../components/MyJobs"; 
import { useSelector } from "react-redux";

export default function MyJobsPage() {
  let { commonHeadBanner } = useSelector((state) => state.commonHeadBanner);
  const { userJobList } = useSelector((state) => state.userJobList);

  
  return (
    <>
      <MyJobs
        MyJobList={userJobList}
        commonHeadData={commonHeadBanner.result_list}
      />
    </>
  );
}