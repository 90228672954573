import React, { useState } from "react";

import AddGroup from "./AddGroup";
import DeleteGroup from "./DeleteGroup";
import { localImgLoad } from "../../lib";

export default function GroupList({
  groupList,
  selectedGroup,
  changeSelectedGroup,
  setUpdateList,
}) {
  const [deletePopout, setDeletePopout] = useState({
    status: false,
    data: {},
  });

  const [addGroupPopout, setAddGroupPopout] = useState(false);

  const handleAddGroup = () => {
    setAddGroupPopout(true);
  };

  const handleDeleteGroup = (item) => {
    setDeletePopout({
      status: true,
      data: { ...item },
    });
  };

  return (
    <>
      <div className="p-5 w-full lg:w-[400px] min-h-[300px] bg-sky-100 dark:bg-dark-gray rounded-2xl flex flex-col">
        {/* <h1 className='mb-5 text-lg lg:text-2xl tracking-wide font-bold text-slate-900 dark:text-slate-100'>Jobs Groups</h1> */}
        <div className="flex justify-end items-center">
          <button
            onClick={handleAddGroup}
            className="py-2 px-4 flex justify-center items-center bg-sky-blue hover:bg-sky-600 text-base rounded-full text-white font-bold"
          >
            Add Group
          </button>
        </div>

        {groupList && groupList.length < 1 ? (
          <h1 className="my-5 text-lg tracking-wide text-slate-900 dark:text-slate-100">
            No Group Found!
          </h1>
        ) : (
          <div className="my-4 max-h-[596px] bg-[#fffef6] rounded overflow-y-auto flex-1">
            <div className="flex flex-col">
              {groupList.map((item) => (
                <div
                  key={item.group_uid}
                  className="p-2 flex gap-2 items-center justify-between w-full"
                >
                  <div className="flex gap-2 items-center">
                    <input
                      type="radio"
                      name="grouplist"
                      value={item.group_id}
                      checked={selectedGroup?.id == item?.group_id}
                      onChange={changeSelectedGroup}
                      className="w-[20px] h-[20px] outline-none"
                    />
                    <p className="text-sm lg:text-base text-slate-900 dark:text-slate-100">
                      {item.group_name}
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      handleDeleteGroup(item);
                    }}
                    className="flex relative items-center justify-center border-0 w-6 h-6"
                  >
                    <img
                      src={localImgLoad("images/icons/remove_grp.svg")}
                      alt="remove-icon"
                    />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {deletePopout.status && (
        <DeleteGroup
          action={() => setDeletePopout({ status: false, data: {} })}
          situation={deletePopout.status}
          details={deletePopout.data}
        />
      )}

      {addGroupPopout && (
        <AddGroup
          action={() => setAddGroupPopout(false)}
          situation={addGroupPopout}
          setUpdateList={setUpdateList}
        />
      )}
    </>
  );
}
