import React, {memo, useCallback, useEffect, useState} from 'react'
import Image from '../../assets/images/taskbanners/default.jpg'

import usersService from '../../services/UsersService';
import { handlePagingFunc } from '../../components/Pagination/HandlePagination';
import PaginatedList from '../../components/Pagination/PaginatedList';

import LoadingSpinner from '../Spinners/LoadingSpinner';

import { AmountTo2DP } from '../Helpers/PriceFormatter';


export const RewardsTable = memo(() => {
    const apiCall = new usersService()

    let [familyRewardHistory, setFamilyRewardHistory] = useState({ // FOR PURCHASE HISTORY
        loading: true,
        data: [],
        error: false
    })

    const [currentPage, setCurrentPage] = useState(0);
    const indexOfFirstItem = Number(currentPage);
    const indexOfLastItem = Number(indexOfFirstItem)+Number(process.env.REACT_APP_ITEM_PER_PAGE);
    const currentReward = familyRewardHistory?.data?.slice(indexOfFirstItem, indexOfLastItem);
  
    const handlePagination = (e) => {
      handlePagingFunc(e,setCurrentPage)
    }


    //FUNCTION TO GET FAMILY REWARD HISTORY
    const getFamilyRewardHistory = useCallback(()=>{
        apiCall.getFamilyRewardHx().then((res)=>{
            if(res.data.internal_return < 0){ // success but no data
                setFamilyRewardHistory(prev => ({...prev, loading: false}))
                return
            }
            setFamilyRewardHistory(prev => ({...prev, loading: false, data: res.data.result_list}))
        }).catch((error)=>{
            setFamilyRewardHistory(prev => ({...prev, loading: false, error: true}))
        })
    },[])

    useEffect(()=>{
        getFamilyRewardHistory()
      }, [])

  return (
    <div className='p-4 flex flex-col justify-between min-h-[500px]'>
    {familyRewardHistory.loading ? 
    <LoadingSpinner size='16' color='sky-blue' height='h-[500px]' />
    : familyRewardHistory.data.length ?
    <table className="wallet-activity w-full table-auto border-collapse text-left">
        <thead className='w-full'>
            <tr className='text-slate-600 dark:text-white'>
            <th className="p-4"></th>
            <th className="p-4 text-right">Amount</th>
            <th className="p-4">Date</th>
            <th className="p-4">Confirmation</th>
            </tr>
        </thead>
        <tbody>
            {currentReward.map((item, index) => {
                let date = new Date(item.added).toLocaleDateString()
                return (
                    <tr key={index} className='dark:text-white dark:bg-dark-white border-y dark:border-[#5356fb29] hover:bg-gray-50 dark:hover:bg-gray-50 dark:hover:text-black transition-all duration-300'>
                        <td className="p-4">
                            <div className='flex items-center gap-2'>
                                <img src={item.icon} className='min-w-[60px] max-w-[60px] min-h-[60px] max-h-[60px] rounded-full bg-slate-500' alt='Reward Logo' />
                                <div className='flex flex-col'>
                                    <h1 className='text-lg font-bold'>Reward to {item.rec_firstname} {item.rec_lastname}</h1>
                                    <p className='text-sm'>{item.description}</p>
                                </div>
                            </div>
                        </td>
                        <td className="p-4 text-right">{AmountTo2DP(item.amount*0.01)} {item.currency}</td>
                        <td className="p-4">{date}</td>
                        <td className="p-4">{item.confirmation}</td>
                    </tr>
                )
            }
            )}
        </tbody>
    </table>
    :familyRewardHistory.error ?
    <div className="p-2 text-slate-500 flex flex-col grow justify-center items-center">
        <span>Opps! an error occurred. Please try again!</span>
    </div>
    :
    <div className="p-2 text-slate-500 flex flex-col grow justify-center items-center">
        <span>No Rewards History Found!</span>
    </div>
    }

    {/* PAGINATION BUTTON */}
    <PaginatedList onClick={handlePagination} prev={currentPage == 0 ? true : false} next={currentPage+Number(process.env.REACT_APP_ITEM_PER_PAGE) >= familyRewardHistory?.data?.length ? true : false} data={familyRewardHistory?.data} start={indexOfFirstItem} stop={indexOfLastItem} />
    {/* END OF PAGINATION BUTTON */}
    </div>
  )
}
)
export default RewardsTable