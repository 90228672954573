import React from 'react'
import ParentWaitingLayout from '../components/FamilyAcc/ParentWaiting'

export default function ParentWaitingPage() {
  return (
    <>
        <ParentWaitingLayout />
    </>
  )
}
