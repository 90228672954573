import React from "react";

export default function TermsConditionTab() {
  return (
    <div className="terms-conditon-tab w-full">
      <div className="terms-condition-wrappr w-full">
        <div className="mb-6">
          <h1 className="text-3xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            Terms of use
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px]">
            (updated August 10, 2023)
          </p>
          <p className="text-base text-thin-light-gray leading-[28px]">
            These Website Terms & Conditions (“T&Cs”) apply to your access and
            use of www.wrenchboard.com,users.wrenchboard.com (the “Site”),
            native apps, including all software, data, reports, text, images,
            sounds, video, and all contents made available through any portion
            of the Site (collectively, the “Content”). The range includes all
            such elements as whole, individual, and parts.
          </p>
        </div>
        <hr />
        <div className="my-8">
          <h1 className="text-2xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            Acceptance of Terms
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] ">
            WRENCHBOARD permits you (“User” or “you” or “your”) to access and
            use the Site and Content, subject to these T&Cs. By accessing or
            using any portion of the Site, you acknowledge that you have read,
            understood, and agree to be bound by these T&Cs. If you do not agree
            with these T&Cs, you must not accept these T&Cs or access or use the
            site or content.
          </p>
        </div>
        <hr />

        <div className="my-8">
          <h1 className="text-2xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            General Conditions of Use
          </h1>
          <ul className="ml-8">
            <li className="text-base text-thin-light-gray leading-[28px]">
              <h1 className="text-lg tracking-wide font-bold text-dark-gray dark:text-white my-4">
                Authorization to Access and Use Site and Content.
              </h1>
              <p className="text-base font-bold text-thin-light-gray leading-[28px]">
                Subject to your compliance with these T&Cs and the provisions
                hereof, you may access or use the Site and Content solely to
                evaluate WRENCHBOARD and WRENCHBOARD’s products and services.
                You may only link to the Site or Content, or any portion
                thereof, as expressly permitted by WRENCHBOARD.
              </p>
            </li>
            <li className="text-base font-bold text-thin-light-gray leading-[28px]">
              <h1 className="text-lg tracking-wide font-bold text-dark-gray dark:text-white my-4">
                Ownership and Restrictions
              </h1>
              <p className="text-base text-thin-light-gray leading-[28px]">
                All rights, title, and interest in and to the Site and Content
                will remain exclusive to WRENCHBOARD. You will not:
              </p>
              <ol className="ml-8 list-decimal">
                <li className="my-2 text-base font-bold text-thin-light-gray leading-[28px]">
                  Sublicense, resell, rent, lease, transfer, assign, timeshare,
                  or commercially exploit or make the Site and any Content
                  available to any third party.
                </li>
                <li className="my-2 text-base font-bold text-thin-light-gray leading-[28px]">
                  Use the Site and Content in any unlawful manner (including
                  without limitation in violation of any data, privacy, or
                  export control laws) or in any way that interferes with or
                  disrupts the integrity or performance of the Site and Content
                  or their related components.
                </li>
                <li className="my-2 text-base font-bold text-thin-light-gray leading-[28px]">
                  Modify, adapt, or hack the Site and Content to, or try to,
                  gain unauthorized access to the restricted portions of the
                  Site and Content or related systems or networks (i.e.,
                  circumvent any encryption or other security measures, gain
                  access to any source code or any other underlying form of
                  technology or information, and gain access to any part of the
                  Site and Content, or any other products or services of
                  WRENCHBOARD that are not readily made available to the general
                  public).
                </li>
              </ol>
              <p className="my-4 text-base font-bold text-thin-light-gray leading-[28px]">
                You are not permitted to copy, modify, frame, repost, publicly
                perform or display, sell, reproduce, distribute, or create
                derivative works of the Site and Content, except that you may
                download and print one copy of the publicly available materials
                (i.e., the Content that does not require an Account name or
                password to access) on any single computer solely for your
                personal, non-commercial use, provided that you do not modify
                the material in any way. You keep intact all copyright,
                trademark, and other proprietary notices.
              </p>
              <p className="my-4 text-base font-bold text-thin-light-gray leading-[28px]">
                You agree not to access the Site or Content by any means other
                than through the interface that WRENCHBOARD provides to access
                the same. You may not use any “page-scrape,” “deep-link,”
                “spider,” or “robot or other automatic program, device,
                algorithm or methodology, or any similar manual process, to
                access, copy, acquire, or monitor any portion of the Site or any
                Content, or in any way reproduce or circumvent the presentation
                or navigational structure of the Site or any Content, to obtain
                or attempt to obtain any Content or other information through
                any means not made generally available through the Site by
                WRENCHBOARD.
              </p>
              <p className="my-4 text-base font-bold text-thin-light-gray leading-[28px]">
                WRENCHBOARD reserves the right to take lawful measures to
                prevent such activity. You may not forge headers or otherwise
                manipulate identifiers to disguise the origin of any message or
                transmittal you send to WRENCHBOARD on or through the Site or
                any service offered on or through the Site. You may not pretend
                that you are, or that you represent, someone else or impersonate
                any other individual or entity.
              </p>
            </li>
            <li className="text-base text-thin-light-gray leading-[28px]">
              <h1 className="text-lg tracking-wide font-bold text-dark-gray dark:text-white my-4">
                Responsibility for Your Data
              </h1>
              <p className="text-base font-bold text-thin-light-gray leading-[28px]">
                You are solely responsible for all data, information, and other
                Content, that you upload, post, or otherwise provide or store
                (hereafter “post(ing)”) in connection with or relating to the
                Site. By posting your information and other Content (“User
                Content”) on or through the Site and Content, you grant
                WRENCHBOARD a worldwide, non-exclusive, perpetual, irrevocable,
                royalty-free, sublicensable, and transferable license to use,
                modify, reproduce, distribute, display, publish and perform User
                Content in connection with the Site and Content. WRENCHBOARD has
                the right, but not the obligation, to monitor the Site and
                Content and User Content. <br />
                WRENCHBOARD may remove or turn off any User Content at any time
                for any reason or no reason. WRENCHBOARD will have no liability
                to you for any unauthorized access or use of any of User Content
                or any corruption, deletion, destruction, or loss of any of User
                Content.
              </p>
            </li>
            <li className="text-base text-thin-light-gray leading-[28px]">
              <h1 className="text-lg tracking-wide font-bold text-dark-gray dark:text-white my-4">
                Feedback
              </h1>
              <p className="text-base font-bold text-thin-light-gray leading-[28px]">
                You may submit ideas, suggestions, or comments (“Feedback”)
                regarding the Site and Content or WRENCHBOARD’s business,
                products, or services. By submitting any Feedback, you
                acknowledge and agree that:{" "}
              </p>
              <ol className="ml-8 list-decimal">
                <li className="my-2 text-base font-bold text-thin-light-gray leading-[28px]">
                  Your Feedback is provided by you voluntarily, and WRENCHBOARD
                  may, without any obligations or limitations, use and exploit
                  such Feedback in any manner and for any purpose.
                </li>
                <li className="my-2 text-base font-bold text-thin-light-gray leading-[28px]">
                  You will not seek and are not entitled to any money or other
                  form of compensation, consideration, or attribution concerning
                  your Feedback, regardless of whether WRENCHBOARD considered or
                  used your Feedback in any manner.
                </li>
                <li className="my-2 text-base font-bold text-thin-light-gray leading-[28px]">
                  Your Feedback is not confidential or proprietary information
                  of you or any third party.
                </li>
              </ol>
            </li>
          </ul>
        </div>
        <hr />

        <div className="my-8">
          <h1 className="text-2xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            Termination of Access Due to Violations
          </h1>
          <p className="font-bold text-base text-thin-light-gray leading-[28px] ">
            WRENCHBOARD may, in its sole discretion and without prior notice,
            terminate your access to the Site and block your future access to
            the Site if we determine that you have violated these T&Cs or other
            agreements or guidelines which may be associated with your use of
            the Site. Further, WRENCHBOARD may, in its sole discretion and
            without prior notice, terminate your access to the Site for cause,
            which includes (but is not limited to):
          </p>
          <ol className="ml-8 list-decimal font-bold">
            <li className="my-2 text-base font-bold text-thin-light-gray leading-[28px]">
              Requests by law enforcement or other government agencies
            </li>
            <li className="my-2 text-base font-bold text-thin-light-gray leading-[28px]">
              Discontinuance or material modification of the Site or any service
              offered on or through the Site
            </li>
            <li className="my-2 text-base font-bold text-thin-light-gray leading-[28px]">
              Unexpected technical issues or problems.T&Cs Updates
            </li>
          </ol>
          <p className="text-base text-thin-light-gray leading-[28px] ">
            WRENCHBOARD reserves the right, at its sole discretion, to change or
            modify portions of these T&Cs at any time. WRENCHBOARD will post the
            changes to these T&Cs on the Site and indicate at the top of this
            page the date these terms were last revised. It is your
            responsibility to check the T&Cs periodically for changes. Your
            continued use of the Site and Content after the date any such
            changes become effective constitutes your acceptance of the new or
            revised T&Cs.
          </p>
        </div>
        <hr />

        <div className="my-8">
          <h1 className="text-2xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            NO WARRANTIES AND DISCLAIMER BY WRENCHBOARD
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] ">
            THE SITE AND CONTENT, AND ALL SERVER AND NETWORK COMPONENTS, ARE
            PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITH ALL ERRORS AND
            DEFECTS AND WITHOUT ANY WARRANTIES OF ANY KIND, AND WRENCHBOARD
            EXPRESSLY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, INCLUDING
            ANY IMPLIED WARRANTIES OF ACCURACY, COMPLETENESS, MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, AND ANY
            REPRESENTATIONS OR WARRANTIES ARISING FROM COURSE OF DEALING, COURSE
            OF PERFORMANCE OR USAGE OF TRADE. YOU ACKNOWLEDGE THAT WRENCHBOARD
            DOES NOT WARRANT THAT YOUR ACCESS OR USE OR BOTH OF THE SITE AND
            CONTENT WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE, OR
            VIRUS-FREE, AND WRENCHBOARD DOES NOT MAKE ANY WARRANTY AS TO THE
            RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SITE AND CONTENT.
            NO INFORMATION, ADVICE, OR SERVICES OBTAINED BY YOU FROM WRENCHBOARD
            OR THROUGH THE SITE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN
            THESE TERMS and CONDITIONS, AND YOU SHOULD NOT RELY ON THE SITE AND
            THE GENERAL CONTENT ALONE AS THE BASIS FOR YOUR BUSINESS DECISIONS.
          </p>
        </div>
        <hr />

        <div className="my-8">
          <p className="font-bold text-base text-thin-light-gray leading-[28px] ">
            WRENCHBOARD reserves the right to do any of the following, at any
            time, without notice: ( 1 ); to modify, suspend or terminate operation
            of or access to the Site, or any portion of the Site, for any
            reason; ( 2 ) to modify or change the Site, or any portion of the
            Site, for any reason; and ( 3 ) to interrupt the operation of the
            Site, or any portion of the Site, as necessary to perform routine or
            non-routine maintenance, error correction, or other changes. Changes
            to the Policy
          </p>

          <p className="my-4 text-base text-thin-light-gray leading-[28px] ">
            We reserve the right to update and change this Privacy policy at any
            time. Changes will become effective once posted. However, we will
            notify you by email or when you log on to the service or website
            about any changes that fundamentally affect how we manage your
            personal information. Contacting Us: You may contact us about this
            policy through our email address anytime: support@wrenchboard.com
          </p>
        </div>
      </div>
    </div>
  );
}
