import React, { useState } from "react";

import PaginatedList from "../../Pagination/PaginatedList";
import { handlePagingFunc } from "../../Pagination/HandlePagination";

function RecentActivityTable({ payment }) {
  const [currentPage, setCurrentPage] = useState(0);
  const indexOfFirstItem = Number(currentPage);
  const indexOfLastItem =
    Number(indexOfFirstItem) + Number(process.env.REACT_APP_ITEM_PER_PAGE);
  const currentActivity = payment?.data?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePagination = (e) => {
    handlePagingFunc(e, setCurrentPage);
  };

  return (
    <div className="p-4 flex flex-col justify-between min-h-[500px]">
      {payment?.data?.length > 0 ? 
        <table className="wallet-activity w-full table-auto border-collapse text-left">
          <thead className="w-full">
            <tr className="text-slate-600 dark:text-white">
              <th className="p-4">Date</th>
              <th className="p-4">Trx.</th>
              <th className="p-4 text-right">Amnt./Fee</th>
              <th className="p-4">Status</th>
            </tr>
          </thead>
          <tbody>
            {currentActivity.map((item, index) => (
              <tr key={index} className="dark:text-white dark:bg-dark-white border-y dark:border-[#5356fb29] hover:bg-gray-50 dark:hover:bg-gray-50 dark:hover:text-black transition-all duration-300">
                <td className="p-4">{item.trx_date}</td>
                <td
                  className="p-4"
                  dangerouslySetInnerHTML={{ __html: item.recipient }}
                ></td>
                <td className="p-4 text-right">
                  {item.amount}
                  <br />
                  {item.fee}
                </td>
                <td className="p-4">{item.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      :payment.error ?
        <div className="p-2 text-slate-500 flex flex-col grow justify-center items-center">
            <span>Opps! an error occurred. Please try again!</span>
        </div>
        :
        <div className="p-2 text-slate-500 flex flex-col grow justify-center items-center">
            <span>No Payment History Found!</span>
        </div> 
      }

      {/* PAGINATION BUTTON */}
      <PaginatedList
        onClick={handlePagination}
        prev={currentPage == 0 ? true : false}
        next={
          currentPage + Number(process.env.REACT_APP_ITEM_PER_PAGE) >=
          payment?.data?.length
            ? true
            : false
        }
        data={payment?.data}
        start={indexOfFirstItem}
        stop={indexOfLastItem}
      />
      {/* END OF PAGINATION BUTTON */}
    </div>
  );
}

export default RecentActivityTable;
