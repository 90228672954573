import React, { useEffect, useState, lazy, Suspense } from 'react'
import LoadingSpinner from '../../Spinners/LoadingSpinner'
import { NewTasks } from './forms'
import { PriceFormatter } from '../../Helpers/PriceFormatter'
import { useSelector } from 'react-redux';
import { InputCom } from '../../AddJob/settings';
import * as Yup from "yup";
import { Form, Formik } from "formik";


// To get the validation schema
const validationSchema = Yup.object().shape({
    currency: Yup.string()
      .min(1, "Minimum 3 characters")
      .max(25, "Maximum 25 characters")
      .required("required"),
    amount: Yup.number()
      .typeError("Invalid number")
      .min(1, "Must be greater than 0")
      .test("no-e", "Invalid number", (value) => {
        if (value && /\d+e/.test(value)) {
          return false;
        }
        return true;
      })
      .required("required"),
      job_description: Yup.string()
      .min(3, "Minimum 3 characters")
      .max(499, "Maximum 499 characters")
      .required("required"),
      timeline_days: Yup.number()
      .typeError("you must specify a number")
      .min(1, "Must be greater than 0")
      .required("required"),
  });



const VideoElement = lazy(() => import("../../VideoCom/VideoElement")); // LAZY IMPORTING VIDEO COMPONENT

export default function AssignMediaTask({
    commonMedia,
    requestStatus,
    setRequestStatus,
    assignMediaTask,
    activeMedia,
    handleActiveMedia,
    closeModal,
    family_uid
}) {

    const {userDetails} = useSelector((state) => state?.userDetails); // CHECKS IF USER Details are avaliable, to determine if user is active

    const { walletDetails } = useSelector((state) => state?.walletDetails); // WALLET STORE

  // For form initial values
    const initialValues = {
        // initial values for formik
        currency: walletDetails.data.length == 1 ? walletDetails.data[0].country : '',
        amount: "",
        job_description: "",
        timeline_days: "",
        media_uid: activeMedia?.uid,
        family_uid: family_uid,
        media_type: "COMMON"
    };




//   let imageSrc = (localStorage.getItem("session_token")
//   ? `${userDetails?.session_image_server}${localStorage.getItem("session_token")}/job/${activeMedia.uid}` : ""); // FOR GETTING JOB IMAGE

  return (
    <>
         {commonMedia?.loading ? (
              <div className="h-[30rem] w-full flex justify-center items-center">
                <LoadingSpinner color="sky-blue" size="16" />
              </div>
            ) : (
                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, helpers)=>{assignMediaTask(values, helpers)}}
              >
                {(props) => {
                  return (
                        <Form className='contents'>
                            <>
                                <div
                                className={`job-action-modal-body w-full h-full overflow-y-auto md:grid md:grid-cols-2`}
                                >
                                <div className="p-4 pt-0">
                                    <div className="p-4 w-full h-[450px] overflow-y-auto bg-slate-100 rounded-md dark:bg-[#11131f] dark:text-white">
                                        {commonMedia?.data?.length ? (
                                        commonMedia?.data?.map((item, index) => (
                                            <div
                                            key={item.uid}
                                            className="mb-2 flex justify-start items-center gap-2 text-sky-blue text-base cursor-pointer"
                                            onClick={() => handleActiveMedia(item)}
                                            >
                                            <input
                                                type="radio"
                                                name="media-list"
                                                checked={activeMedia?.uid == item?.uid}
                                                onChange={() =>
                                                handleActiveMedia(item)
                                                }
                                                className="w-[15px] h-[15px] cursor-pointer"
                                            />
                                            <p className="w-full text-dark-gray dark:text-white tracking-wide">
                                                {item?.title}
                                            </p>
                                            </div>
                                        ))
                                        ) : (
                                        <p className="p-8 text-lg text-dark-gray dark:text-white tracking-wide text-center cursor-default">
                                            No Media found!
                                        </p>
                                        )}
                                    </div>
                                </div>

                                {/*Right Hand Side for details && Task Type === select */}
                                    <>
                                    {commonMedia?.data?.length > 0 ? (
                                        <div className="p-4 py-0 h-auto">
                                        <div className="w-full">
                                            <div className="mb-3 w-full">
                                            <label className="job-label">
                                                Description
                                            </label>
                                            <p className="p-1 text-sm text-slate-900 dark:text-white">
                                                {activeMedia?.description}
                                            </p>
                                            </div>

                                            <div className="my-3 w-full flex items-center justify-center">
                                                <div className="w-full max-w-xs h-28 rounded-2xl flex items-center justify-center">
                                                    <Suspense fallback={<p>Loading...</p>}>
                                                        <VideoElement videoId={activeMedia?.uid} />
                                                    </Suspense>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 gap-3">
                                                {/* Price */}
                                                <div className="field w-full flex flex-col justify-between">
                                                    <label htmlFor="price" className="job-label flex gap-1">
                                                        Reward
                                                        <span className='text-red-500 text-base'>{props.errors.amount && props.touched.amount && '*'}</span>
                                                    </label>
                                                    <InputCom
                                                    fieldClass="px-6 text-right"
                                                    // label="Price"
                                                    // labelClass="tracking-wide"
                                                    inputBg="bg-slate-100"
                                                    type="number"
                                                    name="amount"
                                                    placeholder="0"
                                                    value={props.values.amount}
                                                    inputHandler={props.handleChange}
                                                    // error={props.errors.price && props.touched.price && props.errors.price}
                                                    />
                                                </div>

                                                {/* Currency */}
                                                <div className="field w-full flex flex-col justify-between">
                                                    <label
                                                    htmlFor="currency"
                                                    className="job-label flex gap-1 invisible"
                                                    >
                                                    Currency
                                                    {props.errors.currency && props.touched.currency && <span className="text-base text-red-500">*</span>}
                                                    </label>
                                                    <select
                                                    id="currency"
                                                    name="currency"
                                                    value={props.values.currency}
                                                    className={`input-field w-full h-[42px] flex items-center px-2 mt-2 rounded-full placeholder:text-base text-dark-gray dark:text-white bg-slate-100 dark:bg-[#11131F] focus:ring-0 focus:outline-none ${props.errors.currency && props.touched.currency && 'border border-red-500'}`}
                                                    onChange={props.handleChange}
                                                    disabled={walletDetails.data.length == 1}
                                                    >
                                                    {walletDetails?.loading ? (
                                                        <option className="text-slate-500 text-[13.975px]" value="">
                                                        Loading...
                                                        </option>
                                                    ) : walletDetails.data.length ? (
                                                        <>
                                                            {walletDetails.data.length == 1 ?
                                                            <>
                                                            {walletDetails.data?.map((item, index) => (
                                                                <option
                                                                key={index}
                                                                className="text-slate-500 text-lg"
                                                                value={item?.country}
                                                                >
                                                                {item?.code}
                                                                </option>
                                                            ))}
                                                            </>
                                                            :
                                                            <>
                                                                <option className="text-slate-500 text-[13.975px]" value="">
                                                                    Select
                                                                </option>
                                                                {walletDetails.data?.map((item, index) => (
                                                                    <option
                                                                    key={index}
                                                                    className="text-slate-500 text-lg"
                                                                    value={item?.country}
                                                                    >
                                                                    {item?.code}
                                                                    </option>
                                                                ))}
                                                            </>
                                                            }
                                                        </>
                                                    ) : (
                                                        <option className="text-slate-500 text-lg" value="">
                                                        No Options Found!
                                                        </option>
                                                    )}
                                                    </select>
                                                </div>
                                            
                                                {/* Duration */}
                                                <div className="field w-full flex flex-col justify-between">
                                                    <label
                                                    htmlFor="timeline_days"
                                                    className="job-label flex gap-1"
                                                    >
                                                    Timeline
                                                    {props.errors.timeline_days && props.touched.timeline_days && <span className="text-base text-red-500">*</span>}
                                                    </label>
                                                    <select
                                                    id="timeline_days"
                                                    name="timeline_days"
                                                    value={props.values.timeline_days}
                                                    className={`input-field w-full h-[42px] flex items-center px-2 mt-2 rounded-full placeholder:text-base text-dark-gray dark:text-white bg-slate-100 dark:bg-[#11131F] focus:ring-0 focus:outline-none`}
                                                    onChange={props.handleChange}
                                                    >
                                                    {publicArray.length && (
                                                        <>
                                                        <option className="text-slate-500 text-[13.975px]" value="">
                                                            Select
                                                        </option>
                                                        {publicArray.map(({ name, duration }, idx) => (
                                                            <option
                                                            key={idx}
                                                            className="text-slate-500 text-[13.975px]"
                                                            value={duration}
                                                            >
                                                            {name}
                                                            </option>
                                                        ))}
                                                        </>
                                                    )}
                                                    </select>
                                                </div>
                                            </div>
                                            
                                            {/* Delivery Detail */}
                                            <div className="my-3">
                                            <label className="w-full job-label flex gap-1">
                                                Delivery Detail
                                                {props.errors.job_description && props.touched.job_description && <span className="text-base text-red-500">*</span>}
                                            </label>
                                            <textarea
                                                className={`p-1 w-full text-sm text-slate-900 dark:text-white bg-transparent outline-none border border-slate-300 rounded-md`}
                                                rows="5"
                                                style={{ resize: "none" }}
                                                value={props.values.job_description}
                                                onChange={props.handleChange}
                                                name='job_description'
                                            />
                                            </div>
                                        </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    </>
                                </div>

                                {/* BTN */}
                                <div className="modal-footer-wrapper">
                                {/* error or success display */}
                                <div className="w-auto h-auto flex items-center">
                                    {requestStatus.message != "" &&
                                    (!requestStatus.status ? (
                                        <div
                                        className={`relative p-2 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px] self-start`}
                                        >
                                        {requestStatus.message}
                                        </div>
                                    ) : (
                                        requestStatus.status && (
                                        <div
                                            className={`relative p-2 text-green-700 bg-slate-200 border-slate-800 mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
                                        >
                                            {requestStatus.message}
                                        </div>
                                        )
                                    ))}
                                </div>

                                {/* End of error or success display */}
                                <div className="w-auto h-auto flex items-center gap-20">
                                    <button
                                    disabled={requestStatus.loading}
                                    onClick={()=>closeModal()}
                                    type="button"
                                    className="custom-btn border-gradient"
                                    >
                                    <span className="text-gradient">Close</span>
                                    </button>
                                    <div className="">
                                    {requestStatus.loading ? (
                                        <LoadingSpinner color="sky-blue" size="8" />
                                    ) : (
                                        <button
                                        type="submit"
                                        disabled={requestStatus.loading}
                                        // onClick={assignFamilyTask}
                                        className="custom-btn btn-gradient text-white"
                                        >
                                        Assign
                                        </button>
                                    ) 
                                    }
                                    </div>
                                </div>
                                </div>
                            </>
                        </Form>
                        )
                    }
                }
            </Formik>
            )  
        }     
    </>
  )
}



const publicArray = [
    { duration: 1, name: "1 day" },
    { duration: 2, name: "2 days" },
    { duration: 3, name: "3 days" },
    { duration: 4, name: "4 days" },
    { duration: 5, name: "5 days" },
    { duration: 6, name: "6 days" },
    { duration: 7, name: "1 week" },
    { duration: 14, name: "2 weeks" },
    { duration: 21, name: "3 weeks" },
    { duration: 28, name: "4 weeks" },
  ];