import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  walletDetails: {
    loading: true,
    data: []
  },
};

export const walletSlice = createSlice({
  name: "walletDetails",
  initialState,
  reducers: {
    updateWalletDetails: (state, action) => {
      state.walletDetails = { ...action.payload };
    },
  },
});

export const { updateWalletDetails } = walletSlice.actions;
export default walletSlice.reducer;
