import React, { useState } from "react";

import InputCom from "../../components/Helpers/Inputs/InputCom/index";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import GroupMemberTable from "./GroupMemberTable";

import EmailValidator from "../../lib/EmailValidator";

import { apiConst } from "../../lib/apiConst";
import usersService from "../../services/UsersService";

export default function MemberList({
  groupList,
  selectedGroup,
  setUpdateList,
}) {
  const api = new usersService();

  const [fields, setFields] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });

  const handleFieldsChange = ({ target: { name, value } }) => {
    setFields((prev) => ({ ...prev, [name]: value }));

    if (value == "") {
      setRequestState({
        loading: false,
        status: false,
        message: "",
        data: [],
        errors: [name],
      });
    } else {
      let error = requestState?.errors?.indexOf(name); //// checks if the input field was in error array and removes it when the input changes
      if (error >= 0) {
        let oldErrorArr = requestState.errors;
        let newErrorArr = oldErrorArr.splice(error, 1);
        setRequestState((prev) => ({ ...prev, errors: oldErrorArr }));
      }
    }

    if (name == "email") {
      //checks if email is a valid email address
      let regEx = /^[^0-9][a-zA-Z0-9._%+-]+@[a-zA-Z]+(\.[a-zA-Z]+)+$/;
      if (!EmailValidator(value)) {
        setRequestState({
          loading: false,
          status: false,
          message: "",
          data: [],
          errors: ["email"],
        });
      }
    }
  };

  const [requestState, setRequestState] = useState({
    loading: false,
    status: false,
    message: "",
    data: [],
    errors: ["email", "firstname", "lastname"],
  });

  const addMember = () => {
    // let errors = Object.keys(fields).filter((item) => { // CHECKS FOR EMPTY STRINGS
    //     if(typeof item == 'string' && fields[item] === ''){
    //         return item
    //     }
    // })

    // if(errors.length){
    // setRequestState({
    //     loading: false,
    //     status: false,
    //     message: '',
    //     data: [],
    //     errors: [...errors]
    // })
    // return
    // }

    if(fields.firstname.length > 25){ // checks if firstname length is more than 25
      setRequestState({
        loading: false,
        status: false,
        message: "Firstname must not be more than 25 characters",
        data: [],
        errors: [],
      });
      return setTimeout(() => {
        setRequestState({
          loading: false,
          status: false,
          message: "",
          data: [],
          errors: [],
        });
      }, 3000);
    }

    if(fields.lastname.length > 25){ // checks if lastname length is more than 25
      setRequestState({
        loading: false,
        status: false,
        message: "Lastname must not be more than 25 characters",
        data: [],
        errors: [],
      });
      return setTimeout(() => {
        setRequestState({
          loading: false,
          status: false,
          message: "",
          data: [],
          errors: [],
        });
      }, 3000);
    }

    if(fields.email.length > 45){ // checks if email length is more than 45
      setRequestState({
        loading: false,
        status: false,
        message: "Email must not be more than 45 characters",
        data: [],
        errors: [],
      });
      return setTimeout(() => {
        setRequestState({
          loading: false,
          status: false,
          message: "",
          data: [],
          errors: [],
        });
      }, 3000);
    }

    //checks if email is a valid email address
    let regEx = /^[^0-9][a-zA-Z0-9._%+-]+@[a-zA-Z]+(\.[a-zA-Z]+)+$/;
    if (!EmailValidator(fields.email)) {
      setRequestState({
        loading: false,
        status: false,
        message: "Email is invalid",
        data: [],
        errors: [],
      });
      return setTimeout(() => {
        setRequestState({
          loading: false,
          status: false,
          message: "",
          data: [],
          errors: [],
        });
      }, 3000);
    }

    setRequestState({
      loading: true,
      status: false,
      message: "",
      data: [],
      errors: [],
    });
    const requestData = {
      firstname: fields.firstname,
      lastname: fields.lastname,
      email: fields.email,
      group_id: selectedGroup?.id,
      action: apiConst.WRENCHBOARD_JOB_GROUP_MEMBER,
    };

    api
      .groupMemberAdd(requestData)
      .then((response) => {
        let { status, data } = response;
        if (status != 200 || data?.internal_return < 0) {
          setRequestState({
            loading: false,
            status: false,
            message: "Unable to add member",
            data: [],
            errors: [],
          });
          return;
        }
        setRequestState({
          loading: false,
          status: true,
          message: "Member added",
          data: [],
          errors: [],
        });

        setTimeout(() => {
          // trigger group page reload
          setUpdateList((prev) => !prev);
          setRequestState({
            loading: false,
            status: false,
            message: "",
            data: [],
            errors: [],
          });
        }, 3000);
      })
      .catch((error) => {
        setRequestState({
          loading: false,
          status: false,
          message: "Something went wrong, try again",
          data: [],
          errors: [],
        });
      })
      .finally(() => {
        setTimeout(() => {
          setRequestState({
            loading: false,
            status: false,
            message: "",
            data: [],
            errors: [],
          });
          setFields({
            firstname: "",
            lastname: "",
            email: "",
          });
        }, 3000);
      });
  };

  return (
    <>
      <div className="p-5 w-full min-h-[600px] overflow-y-auto">
        {groupList && groupList.length < 1 ? (
          <>
            <h1 className="my-5 text-lg lg:text-xl tracking-wide text-slate-900 dark:text-slate-100">
              You Currently Do not have any Group, Please Add Group
            </h1>
          </>
        ) : (
          <>
            <h1 className="mb-5 text-lg lg:text-2xl tracking-wide font-bold text-slate-900 dark:text-slate-100">
              {selectedGroup?.name}
            </h1>
            <div className="w-full flex flex-col-reverse lg:flex-col">
              <div className="relative py-3 w-full">
                <div className="relative grid grid-cols-1 sm:grid-cols-2 gap-2 place-content-center">
                  <div className="input-item">
                    <InputCom
                      labelClass="tracking-wider"
                      fieldClass="sm:px-6 px-2"
                      value={fields.firstname}
                      inputHandler={handleFieldsChange}
                      placeholder="First Name"
                      // label="Firstname"
                      name="firstname"
                      type="text"
                      // iconName="message"
                    />
                  </div>
                  <div className="input-item">
                    <InputCom
                      labelClass="tracking-wider"
                      fieldClass="sm:px-6 px-2"
                      value={fields.lastname}
                      inputHandler={handleFieldsChange}
                      placeholder="Last Name"
                      // label="Lastname"
                      name="lastname"
                      type="text"
                      // iconName="message"
                    />
                  </div>
                </div>
                <div className="sm:flex gap-2 items-center">
                  <div className="input-item my-2 w-full sm:w-9/12">
                    <InputCom
                      labelClass="tracking-wider"
                      fieldClass="sm:px-6 px-2"
                      value={fields.email}
                      inputHandler={handleFieldsChange}
                      inputClass=""
                      placeholder="Email"
                      // label="Email"
                      name="email"
                      type="email"
                      // iconName="message"
                    />
                  </div>
                  <div className="flex justify-end items-end w-full sm:w-3/12">
                    {requestState.loading ? (
                      <LoadingSpinner size="8" color="sky-blue" />
                    ) : (
                      <button
                        onClick={addMember}
                        disabled={
                          requestState.loading ||
                          requestState.status ||
                          requestState.errors.length
                        }
                        className={`py-2 px-4 h-[42px] flex justify-center items-center text-base rounded-full text-white font-bold transition-all duration-500 ${
                          requestState.loading ||
                          requestState.status ||
                          requestState.errors.length
                            ? "bg-sky-blue/50"
                            : "bg-sky-blue hover:bg-sky-600"
                        } `}
                      >
                        Add Member
                      </button>
                    )}
                  </div>
                </div>
                <div className="absolute top-full w-full my-1 text-center">
                  {!requestState.loading && requestState.message && (
                    <p
                      className={`text-base ${
                        requestState.status ? "text-green-500" : "text-red-500"
                      }`}
                    >
                      {requestState.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="my-2 flex flex-col min-h-[300px] h-full">
                {selectedGroup?.data?.length < 1 ? (
                  <h1 className="my-5 text-lg lg:text-xl tracking-wide text-slate-900 dark:text-slate-100">
                    No Member Found, Please Add
                  </h1>
                ) : (
                  <GroupMemberTable selectedList={selectedGroup?.data} />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
