import React from 'react'

import Layout from '../Partials/Layout'
import ParentWaiting from '../MyOffers/ParentWaiting'

export default function ParentWaitingLayout() {
  return (
    <Layout>
        <ParentWaiting />
    </Layout>
  )
}
