import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MyOffers from "../components/MyOffers";
import usersService from "../services/UsersService";

export default function MyOffersPage() {
  let { commonHeadBanner } = useSelector((state) => state.commonHeadBanner);
  let { pendingListTable } = useSelector((state) => state.tableReload);

  const [MyJobList, setMyJobList] = useState({ loading: true, data: [] });
  const api = new usersService();

  const getMyJobList = async () => {
    setMyJobList({ loading: true, data: [] });
    try {
      const res = await api.getMyPendingJobList();
      setMyJobList({ loading: false, data: res.data });
    } catch (error) {
      setMyJobList({ loading: false, data: [] });
      console.log("Error getting mode");
    }
  };

  useEffect(() => {
    getMyJobList();
  }, [pendingListTable]);

  // debugger;
  return (
    <>
      <MyOffers
        MyJobList={MyJobList}
        commonHeadData={commonHeadBanner.result_list}
      />
    </>
  );
}
