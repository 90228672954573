import React, { useMemo, useRef, useState } from "react";
import usersService from "../../../../services/UsersService";
import { apiConst } from "../../../../lib/apiConst";

const FamilyBanner = ({ imageServer }) => {
  const uploadedImage = `${imageServer}${localStorage.getItem(
    "session_token"
  )}/familybanner/${localStorage.getItem("uid")}`;

  const familyBannerRef = useRef(null);
  const jobApi = useMemo(() => new usersService(), []);

  const [familyBannerImage, setFamilyBannerImage] = useState(uploadedImage);
  const [uploadStatus, setUploadStatus] = useState({
    loading: false,
    status: false,
    message: "",
  });

  /**
   * Handles the change event of the family image input field.
   * Checks if the selected file exceeds the maximum file size limit and displays an alert if it does.
   * If the file is within the size limit, it reads the file using the FileReader API and sets the profile image state with the result.
   */

  const familyBannerHandler = (e) => {
    setUploadStatus({ loading: false, status: false, message: "" });
    let acceptedFormat = ["jpeg", "jpg", "png", "bmp"]; // ARRAY OF SUPPORTED FORMATS
    let uploadedFile = e.target.files[0]; //UPLOADED FILE

    const fileFormat = uploadedFile?.type?.split("/")[1]?.toLowerCase();
    if (!acceptedFormat.includes(fileFormat)) {
      //CHECKING FOR CORRECT UPLOAD FORMAT
      const msg = `Please select ${acceptedFormat
        .slice(0, -1)
        .join(", ")} or ${acceptedFormat.slice(-1)}`;
      setUploadStatus({ loading: false, status: false, message: msg });
      return setTimeout(() => {
        // profileImgInput.current.value = '' // clear the input
        setUploadStatus({ loading: false, status: false, message: "" });
      }, 5000);
    }

    if (uploadedFile.size > 5 * 1048576) {
      // CHECKING FOR CORRECT FILE SIZE
      setUploadStatus({
        loading: false,
        status: false,
        message: "File must not exceed 5MB",
      });
      return setTimeout(() => {
        // profileImgInput.current.value = '' // clear the input
        setUploadStatus({ loading: false, status: false, message: "" });
      }, 5000);
    }

    if (e.target.value !== "") {
      const imgReader = new FileReader();
      imgReader.onload = (event) => {
        let base64Img = imgReader.result.split(",")[1];
        let reqData = {
          // PAYLOAD FOR API CALL
          job_uid: localStorage.getItem("uid"),
          file_name: uploadedFile?.name.slice(0, 19),
          file_size: uploadedFile?.size,
          file_type: uploadedFile?.type?.split("/")[0]?.toLowerCase(),
          file_data: base64Img,
          msg_type: "FILE",
          action: apiConst.WRENCHBOARD_PICTURE_JOB,
        };
        setUploadStatus({
          loading: true,
          status: false,
          message: "Loading...",
        });
        jobApi
          .sendFiles(reqData)
          .then((res) => {
            if (res.status != 200 || res.data.internal_return < 0) {
              return setUploadStatus({
                loading: false,
                status: false,
                message: "Something went wrong, try again",
              });
            }
            setUploadStatus({
              loading: false,
              status: true,
              message: "Uploaded successfully",
            });
            setFamilyBannerImage(event.target.result);
          })
          .catch((error) => {
            setUploadStatus({
              loading: false,
              status: false,
              message: "Network error, try again",
            });
          })
          .finally(() => {
            setTimeout(() => {
              setUploadStatus({ loading: false, status: false, message: "" });
            }, 5000);
          });
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full lg:h-[400px] rounded-2xl">
        <img
          src={familyBannerImage}
          alt="familyBanner"
          className="w-full h-full object-cover rounded-2xl"
        />
      </div>
      <div className="flex justify-between w-full">
        <div>
          {uploadStatus.message && !uploadStatus.loading && (
            <p
              className={`text-center ${
                uploadStatus.status ? "text-green-500" : "text-red-500"
              }`}
            >
              {uploadStatus.message}
            </p>
          )}
          {uploadStatus.loading && (
            <p className="text-center">{uploadStatus.message}</p>
          )}
        </div>
        <div>
          <input
            ref={familyBannerRef}
            className="hidden"
            type="file"
            accept="image/*"
            onChange={familyBannerHandler}
          />
          <button
            className="btn-gradient w-[153px] h-[46px] rounded-full text-white lg:flex hidden justify-center items-center"
            onClick={() => familyBannerRef.current.click()}
          >
            Change Banner
          </button>
        </div>
      </div>
    </div>
  );
};

export default FamilyBanner;
