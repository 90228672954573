const PaginatedList = ({ onClick, prev, next, data, start, stop, borderTop=true }) => {
  if (data?.length > process.env.REACT_APP_ITEM_PER_PAGE) {
    return (
      <div className={`p-3 flex justify-center items-center min-h-[70px] space-x-2 w-full ${borderTop ? 'border-t-2' : 'border-t-0'}`}>
        {/* Render pagination buttons */}
        {!prev && (
          <button
            className={`p-2 border ${
              prev
                ? "border-black dark:border-white dark:text-white"
                : "border-transparent dark:text-white"
            } btn-shine rounded-full h-11 w-11`}
            name="prev"
            onClick={onClick}
          >
            <>&lt;</>
          </button>
        )}

        {data?.map((item, index) => {
          if (
            index % process.env.REACT_APP_ITEM_PER_PAGE == 0 &&
            index >= start &&
            index <= stop
          ) {
            return (
              <button
                key={index}
                value={index}
                className={`p-2 border ${
                  index === start
                    ? "border-black dark:border-white dark:text-white"
                    : "border-transparent dark:text-white"
                } btn-shine rounded-full h-11 w-11`}
                onClick={onClick}
                name="page_num"
                disabled={index === start}
              >
                {index <= 0
                  ? index + 1
                  : index / process.env.REACT_APP_ITEM_PER_PAGE + 1}
              </button>
            );
          }
        })}

        {!next && (
          <button
            className={`p-2 border ${
              next
                ? "border-black dark:border-white dark:text-white"
                : "border-transparent dark:text-white"
            } btn-shine rounded-full h-11 w-11`}
            name="next"
            onClick={onClick}
          >
            <>&gt;</>
          </button>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default PaginatedList;
