import React from 'react'
import FamBlog from '../components/familyResources/FamBlog'

export default function FamBlogPage() {
  return (
    <>
        <FamBlog />
    </>
  )
}
