import React, { useEffect, useState } from 'react'

export default function CustomTimer({className='text-emerald-500'}) {

    const [time, setTime] = useState(Number(process.env.REACT_APP_CUSTOMTIMER))

    useEffect(()=>{
        const timer = setInterval(()=>{
            if(time > 0 ){
                setTime(prev => prev - 1)
            }
        },1000)
        return ()=>{
            clearInterval(timer)
        }
    },[time])

    let minutes = time == 0 ? 0 : Math.floor(time/60)
    let seconds = time == 0 ? 0 :time - (minutes * 60)

  return (
    <p className={`w-full text-base text-emerald-500 ${className}`}>
        {`${minutes > 9 ? minutes : '0'+minutes} : ${seconds > 9 ? seconds : '0'+seconds}`}
    </p>
  )
}
