import React from "react";
import Referral from "../components/Referral/Index";

function ReferralPage() {
  return (
    <>
      <Referral />
    </>
  );
}

export default ReferralPage;
