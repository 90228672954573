import MarketPlace from "../components/MarketPlace";
import { useSelector } from "react-redux";

export default function MarketPlacePage() {
  let { commonHeadBanner } = useSelector((state) => state.commonHeadBanner);

  return (
    <>
      <MarketPlace commonHeadData={commonHeadBanner?.result_list} />
    </>
  );
}
