import { Form, Formik } from "formik";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import usersService from "../../services/UsersService";
import InputCom from "../Helpers/Inputs/InputCom";
import ModalCom from "../Helpers/ModalCom";

const DEFAULT_IMAGE = require("../../assets/images/family/default.jpg");
const SuggestTask = ({ details, onClose, situation, continuePopupData }) => {
  const { pathname, state } = useLocation();
  const [submitTask, setSubmitTask] = useState({
    loading: false,
    msg: "",
    state: "",
  });
  const [suggestedNextStep, setSuggestedNextStep] = useState("Send Task");

  const switchNextStep = ({ target: value }) => {
    setSuggestedNextStep(value);
  };

  const selectedImage = details?.selectedImage || DEFAULT_IMAGE;
  const initialValues = {
    title: details?.title || "",
    description: details?.description || "",
    banner: details?.banner,
  };

  const apiCall = new usersService();

  const handleSuggestedTask = async (values) => {
    if (!values.title && !values.description) return;

    try {
      setSubmitTask({ loading: true });
      const reqData = { ...values };
      const res = await apiCall.sendFamilySuggestedTasks(reqData);
      if (res.internal_return < 0) {
        setSubmitTask({ loading: false, msg: res.status, state: "bad" });
        return;
      }
      setSubmitTask({ loading: false, msg: res.status, state: "success" });
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      setSubmitTask({ loading: false, msg: error, state: "bad" });
      throw new Error("Error Occurred", error);
    }
  };

  const handleParentSuggestion = (values) => {
    if (suggestedNextStep === "Send Task") {
      let firstname = state?.firstname || details?.firstname;
      let family_uid = state?.family_uid || details?.family_uid;
      continuePopupData({
        ...details,
        firstname,
        family_uid,
      });
    }
    onClose();
  };

  const isActivitiesPage = pathname === "/acc-family/activities";
  const isManageFamilyPage = pathname === "/manage-family";

  const getButtonText = () => {
    if (isActivitiesPage) {
      return suggestedNextStep === "Send Task" ? "Continue" : "Complete";
    } else {
      if (!isManageFamilyPage) {
        if (submitTask.loading) return "Submitting Task";
        if (submitTask.state === "success") return "Task Submitted";
        if (submitTask.state === "bad") return "An Error Occurred";
        return "Send to Parents";
      } else {
        return suggestedNextStep === "Send Task" ? "Continue" : "Complete";
      }
    }
  };

  return (
    <ModalCom action={onClose} situation={situation}>
      <div className="logout-modal-wrapper lw-[90%] md:w-[48rem] min-h-[500px] bg-white dark:bg-dark-white lg:rounded-2xl">
        <div className="modal-header-con">
          <h1 className="modal-title">
            {isManageFamilyPage
              ? `${state?.firstname}'s Suggested Task`
              : isActivitiesPage
              ? `${details?.firstname}'s Suggested Task`
              : "Suggest to Parent"}
          </h1>
          <button
            type="button"
            className="modal-close-btn"
            onClick={onClose}
          >
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36 16.16C36 17.4399 36 18.7199 36 20.0001C35.7911 20.0709 35.8636 20.2554 35.8385 20.4001C34.5321 27.9453 30.246 32.9248 22.9603 35.2822C21.9006 35.6251 20.7753 35.7657 19.6802 35.9997C18.4003 35.9997 17.1204 35.9997 15.8401 35.9997C15.5896 35.7086 15.2189 35.7732 14.9034 35.7093C7.77231 34.2621 3.08728 30.0725 0.769671 23.187C0.435002 22.1926 0.445997 21.1199 0 20.1599C0 18.7198 0 17.2798 0 15.8398C0.291376 15.6195 0.214408 15.2656 0.270759 14.9808C1.71321 7.69774 6.02611 2.99691 13.0428 0.700951C14.0118 0.383805 15.0509 0.386897 15.9999 0C17.2265 0 18.4532 0 19.6799 0C19.7156 0.124041 19.8125 0.136067 19.9225 0.146719C27.3 0.868973 33.5322 6.21922 35.3801 13.427C35.6121 14.3313 35.7945 15.2484 36 16.16ZM33.011 18.0787C33.0433 9.77105 26.3423 3.00309 18.077 2.9945C9.78479 2.98626 3.00344 9.658 2.98523 17.8426C2.96667 26.1633 9.58859 32.9601 17.7602 33.0079C26.197 33.0577 32.9787 26.4186 33.011 18.0787Z"
                fill=""
                fillOpacity="0.6"
              />
              <path
                d="M15.9309 18.023C13.9329 16.037 12.007 14.1207 10.0787 12.2072C9.60071 11.733 9.26398 11.2162 9.51996 10.506C9.945 9.32677 11.1954 9.0811 12.1437 10.0174C13.9067 11.7585 15.6766 13.494 17.385 15.2879C17.9108 15.8401 18.1633 15.7487 18.6375 15.258C20.3586 13.4761 22.1199 11.7327 23.8822 9.99096C24.8175 9.06632 26.1095 9.33639 26.4967 10.517C26.7286 11.2241 26.3919 11.7413 25.9133 12.2178C24.1757 13.9472 22.4477 15.6855 20.7104 17.4148C20.5228 17.6018 20.2964 17.7495 20.0466 17.9485C22.0831 19.974 24.0372 21.8992 25.9689 23.8468C26.9262 24.8119 26.6489 26.1101 25.4336 26.4987C24.712 26.7292 24.2131 26.3441 23.7455 25.8757C21.9945 24.1227 20.2232 22.3892 18.5045 20.6049C18.0698 20.1534 17.8716 20.2269 17.4802 20.6282C15.732 22.4215 13.9493 24.1807 12.1777 25.951C11.7022 26.4262 11.193 26.7471 10.4738 26.4537C9.31345 25.9798 9.06881 24.8398 9.98589 23.8952C11.285 22.5576 12.6138 21.2484 13.9387 19.9355C14.5792 19.3005 15.2399 18.6852 15.9309 18.023Z"
                fill="#"
                fillOpacity="0.6"
              />
            </svg>
          </button>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={
            isActivitiesPage
              ? handleParentSuggestion
              : isManageFamilyPage
              ? handleParentSuggestion
              : handleSuggestedTask
          }
        >
          {(props) => {
            return (
              <Form className="h-[33.875rem] flex flex-col">
                <div className="px-5 w-full bg-white rounded-md flex justify-between items-center h-full">
                  {/* Image Section */}
                  <div className="p-4 w-full md:w-2/4 md:border-r-2 h-full flex items-center">
                    <div
                      className="w-full h-[14.75rem] p-6 bg-gray-400 rounded-xl overflow-hidden"
                      style={{
                        background: `url(${selectedImage}) center / contain no-repeat`,
                      }}
                    ></div>
                  </div>

                  {/* ACTION SECTION */}
                  <div className="p-4 w-full md:w-2/4 h-full flex flex-col justify-between">
                    {/* Title */}
                    <div className="field w-full mb-[.9375rem]">
                      <InputCom
                        fieldClass={
                          pathname === "/manage-family" ||
                          pathname === "/acc-family/activities"
                            ? "px-2"
                            : "px-6"
                        }
                        label="Title"
                        labalClass="text-[1.125rem]"
                        labelClass="tracking-wide"
                        inputBg={
                          pathname === "/manage-family" ||
                          pathname === "/acc-family/activities"
                            ? "bg-white"
                            : "bg-slate-100"
                        }
                        inputClass="disabled:cursor-default"
                        type="text"
                        name="title"
                        disable={details?.title}
                        value={props.values.title}
                        inputHandler={props.handleChange}
                        blurHandler={props.handleBlur}
                        error={
                          props.errors.title &&
                          props.touched.title &&
                          props.errors.title
                        }
                      />
                    </div>

                    {/* Description */}
                    <div className="w-full mb-[.3125rem]">
                      <label
                        htmlFor="description"
                        className='job-label'
                      >
                        Description
                        {props.errors.description &&
                          props.touched.description && (
                            <span className="text-[.75rem] text-red-500">
                              {props.errors.description}
                            </span>
                          )}
                      </label>
                      <textarea
                        id="description"
                        rows="5"
                        className={`input-field pt-2 placeholder:text-base text-dark-gray dark:text-white w-full ${
                          pathname === "/manage-family" ||
                          pathname === "/acc-family/activities"
                            ? "px-2 h-[6.875rem]"
                            : "bg-slate-100 px-3 dark:bg-[#11131F] focus:ring-0 focus:outline-[#dce4e9] rounded-[.625rem] h-[8.125rem]"
                        }`}
                        style={{ resize: "none" }}
                        name="description"
                        value={props.values.description}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                    </div>

                    {/* Radio buttons for family */}
                    {pathname === "/manage-family" ||
                    pathname === "/acc-family/activities" ? (
                      <div className="h-[3.75rem] w-full border-t dark:border-[#5356fb29] border-light-purple relative">
                        <div id="my-radio-group" className="sr-only">
                          Parent suggested next step
                        </div>
                        <div
                          role="group"
                          className="flex items-center justify-between py-2"
                          aria-labelledby="parent-suggested-radio-group"
                        >
                          {[
                            { title: "Send Task" },
                            { title: "Duplicate" },
                            { title: "Not Now" },
                          ].map(({ title }, idx) => (
                            <label
                              role="group"
                              key={idx}
                              htmlFor={`parent-suggested-${idx}`}
                              className={`transition duration-150 ease-in-out parent-suggest group cursor-pointer flex items-center`}
                              onClick={() => setSuggestedNextStep(title)}
                            >
                              <input
                                type="radio"
                                name="parent-suggested"
                                value={title}
                                checked={suggestedNextStep === title}
                                onChange={switchNextStep}
                                className={`transition duration-150 ease-in-out parent-suggest pointer-events-none w-[1.125rem] h-[1.125rem]`}
                              />
                              <span
                                onClick={() => setSuggestedNextStep(title)}
                                id={`parent-suggested-${idx}`}
                                name="parent-suggested"
                                className={`ml-1 ${
                                  title === "Not Now"
                                    ? "text-red-500"
                                    : title === "Duplicate"
                                    ? "text-purple"
                                    : "text-black"
                                } font-semibold text-[1.125rem]`}
                              >
                                {title}
                              </span>
                            </label>
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="w-full h-[4.375rem] border-t border-light-purple dark:border-[#5356fb29]  flex justify-end items-center">
                  <div className="flex items-center space-x-4 mr-9">
                    <button
                      type="button"
                      className=" border-gradient text-18 tracking-wide px-4 py-3 rounded-full"
                      onClick={onClose}
                    >
                      <span className="text-gradient"> Cancel</span>
                    </button>
                    <button
                      type="submit"
                      disabled={props.isSubmitting}
                      className="text-white primary-gradient text-18 tracking-wide px-4 py-3 rounded-full transition duration-150 ease-in-out flex items-center"
                    >
                      {/* {pathname === "/acc-family/activities" ? (
                        <>
                          {suggestedNextStep === "Send Task" ? (
                            <>
                              Continue <Icons name="chevron-right" />
                            </>
                          ) : (
                            "Complete"
                          )}
                        </>
                      ) : (
                        <>
                          {pathname !== "/manage-family" ? (
                            <>
                              {submitTask.loading
                                ? "Submitting Task"
                                : submitTask.state === "success"
                                ? "Task Submitted"
                                : submitTask.state === "bad"
                                ? "An Error Occurred"
                                : "Send to Parents"}
                            </>
                          ) : (
                            <>
                              {suggestedNextStep === "Send Task" ? (
                                <>
                                  Continue <Icons name="chevron-right" />
                                </>
                              ) : (
                                "Complete"
                              )}
                            </>
                          )}
                        </>
                      )} */}
                      {getButtonText()}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </ModalCom>
  );
};

export default SuggestTask;
