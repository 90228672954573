import React, {useState} from 'react'
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../../Spinners/LoadingSpinner";
import JobFieldInput from './JobFieldInput';



const validationSchema = Yup.object().shape({
    public: Yup.string().required("This is required "),
    depend_uid: Yup.string(),
    timeline: Yup.string().required("This is required "),
});


let initialValues = {
    public: "",
    depend_uid: "",
    timeline: "0"
};

export default function AssignToPublic({
    jobFieldHandler,
    myJobList,
    loader,
    details
}) {

    const [timeline, setTimeline] = useState('')
    console.log('timeline', timeline)
  return (
    <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={jobFieldHandler}
  >
    {(props) => {
      return (
        <Form className="w-full h-auto md:h-full flex flex-col justify-between">
            <div className='w-full'>
                <div className='mb-3 w-full flex flex-col xxs:flex-row items-center gap-1'>
                  <label className='w-full xxs:max-w-[120px] xxs:text-right'>Depends on:</label>
                  <div
                      className={`input-wrapper border border-[#f5f8fa] dark:border-[#5e6278] w-full rounded-[1rem] h-[42px] overflow-hidden relative font-medium leading-6 bg-clip-padding text-[#5e6278] dark:text-gray-100 bg-[#f5f8fa] dark:bg-[#5e6278] text-base`}
                  >
                      <select 
                      className='px-2 w-full h-full bg-white border-0 outline-0'
                      name="depend_uid"
                      value={props?.values.depend_uid}
                      onChange={props.handleChange}
                      >
                      <>
                          {myJobList.loading ? (
                          <option value={""}>Loading...</option>
                          ) : myJobList?.data?.result_list?.length > 0 ? (
                          <>
                              <option value="">None</option>
                              {myJobList?.data?.result_list?.filter(item => item.job_uid != details.job_uid)?.map((item, index) => (
                              <option value={item?.job_uid} key={item.job_uid}>
                                  {item?.title}
                              </option>
                              ))}
                          </>
                          ) : (
                          <option value="">No Job Found</option>
                          )}
                      </>
                      </select>
                  </div>
                </div>
                
                <div className='mb-3 w-full flex flex-col xxs:flex-row items-center gap-1'>
                    <label className='w-full xxs:max-w-[120px] xxs:text-right'>Duration:</label>
                    <div className='w-full'>
                        <JobFieldInput
                        select={true}
                        inputName="public"
                        value={props?.values.public}
                        data={publicArray}
                        optionText="Select Duration"
                        parentClass="w-full flex flex-col gap-4"
                        inputClass={(props.errors.public && props.touched.public) && 'border-red-500 dark:border-red-500'}
                        />
                    </div>
                </div>

                <div className='mb-3 w-full flex flex-col xxs:flex-row items-center gap-1'>
                    <label className='w-full xxs:max-w-[120px] xxs:text-right'>Timeline:</label>
                    <div className='w-full'>
                        <JobFieldInput
                        select={true}
                        inputName="timeline"
                        value={props?.values.timeline}
                        data={timelineArray}
                        optionText="Select Timeline"
                        parentClass="w-full flex flex-col gap-4"
                        inputClass={(props.errors.timeline && props.touched.timeline) && 'border-red-500 dark:border-red-500'}
                        // disabled={true}
                        />
                    </div>
                </div>
            </div>
          <div className="mt-3 mb-1 flex justify-end items-center">
            <button
              className={`uppercase px-4 h-11 flex justify-center items-center btn-gradient text-sm rounded-full text-white`}
              type="submit"
              name='public'
            >
              {loader?.jobFields ? 
                <LoadingSpinner size={5} /> 
                :
                'Place Task to the Market'
              }
            </button>
          </div>
        </Form>
      );
    }}
  </Formik>
  )
}


const publicArray = [
    { duration: 1, name: "1 day" },
    { duration: 2, name: "2 days" },
    { duration: 3, name: "3 days" },
    { duration: 4, name: "4 days" },
    { duration: 5, name: "5 days" },
    { duration: 6, name: "6 days" },
    { duration: 7, name: "1 week" },
    { duration: 14, name: "2 weeks" },
    { duration: 21, name: "3 weeks" },
    { duration: 28, name: "4 weeks" },
  ];

  const timelineArray = [
    { duration: "0", name: "Not Strict" },
    { duration: "1", name: "Strict Timeline" },
  ];