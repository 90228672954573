import React from 'react'
import { Field } from "formik";
import InputCom from '../../Helpers/Inputs/InputCom/index'

export default function JobFieldInput({
  value,
  inputHandler,
  inputName,
  inputClass,
  placeholder,
  input,
  select,
  label,
  labelClass,
  parentClass,
  optionText,
  data,
  disabled
}) {
  return (
    <div className="field w-full flex flex-col justify-between gap-4">
      {select && (
        <>
          <div className={`input-com ${parentClass}`}>
            <div
              className={`input-wrapper border border-[#f5f8fa] dark:border-[#5e6278] w-full rounded-[1rem] h-[42px] overflow-hidden relative font-medium leading-6 bg-clip-padding text-[#5e6278] dark:text-gray-100 bg-[#f5f8fa] dark:bg-[#5e6278] text-base ${inputClass}`}
            >
              <Field
                component="select"
                name={inputName}
                className={`options-default`}
                value={value}
                disabled={disabled}
              >
                {/* <option value="">{optionText}</option> */}
                {(inputName == "family" || inputName == "public" || inputName == "timeline") &&
                  Array.isArray(data) && (
                    <>
                    {inputName != "timeline" &&
                      <option value="">{optionText}</option>
                    }
                      {data?.map((item, idx) => (
                        <React.Fragment key={idx}>
                          {inputName === "family" &&
                            item?.last_login !== "" && (
                              <option value={item?.family_uid} key={idx}>
                                {`${item?.firstname} ${item?.lastname}`}
                              </option>
                            )}
                          {inputName === "public" && (
                            <option value={item?.duration} key={idx}>
                              {item?.name}
                            </option>
                          )}
                          {inputName == "timeline" && (
                            <option value={item?.duration} key={idx}>
                              {item?.name}
                            </option>
                          )}
                        </React.Fragment>
                      ))}
                    </>
                  )}
                {inputName == "group" && (
                  <>
                    {data.loading ? (
                      <option value={""}>Loading...</option>
                    ) : data?.groups?.length > 0 ? (
                      <>
                        <option value="">{optionText}</option>
                        {data?.groups?.map((item, index) => (
                          <option value={item?.group_id} key={index}>
                            {`${item?.group_name} (${
                              item?.member_count == null
                                ? "0"
                                : ' ' + item.member_count + ' '
                            })`}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option value="">No Group Found</option>
                    )}
                  </>
                )}
              </Field>
            </div>
          </div>
        </>
      )}

      {input && (
        <InputCom
          fieldClass="px-6"
          type="email"
          name={inputName}
          placeholder={placeholder}
          value={value}
          inputHandler={inputHandler}
          inputBg="bg-white"
          parentClass={`${parentClass}`}
          disable={disabled}
          labelClass={labelClass}
        />
      )}
    </div>
  );
}
