import React, { useEffect, useRef, useState } from 'react'
import LoadingSpinner from '../Spinners/LoadingSpinner'
import axios from 'axios'

import usersService from '../../services/UsersService'

export default function AttachFile({data='', fontSize='text-sm', showOnData=false}) {

    const apiCall = new usersService()
    
    let [uploadedList, setUploadedList] = useState({loading: true, data:{}})
    let [reloadList, setReloadList] = useState(false) // Triggers list of upload reload

    const [requestStatus, setRequestStatus] = useState({loading: false, status:false, message:''})

    const inputFile = useRef()

    const upload = (event) => {
        let inputs = {
            file: event.target.files[0],
            job_uid: data?.job_uid,
            // uid: localStorage.getItem("uid"),
            // member_id: localStorage.getItem("member_id"),
            // sessionid: localStorage.getItem("session_token"),
          };
        setRequestStatus({loading: true, status:false, message:''})
        if(!inputs.file){
            setRequestStatus({loading: false, status:false, message:'Please select a file'})
            setTimeout(()=>{
                setRequestStatus({loading: false, status:false, message:''})
            }, 3000)
            return 
        }
        // const formData = new FormData()
        // for (let input in inputs) {
        //     formData.append(input, inputs[input]);
        // }
        apiCall.uploadTaskFile(inputs).then(res => {
            if(res.data.internal_return < 0){
                inputFile.current.value = null
                setRequestStatus({loading: false, status:false, message:'upload failed'})
                setTimeout(()=>{
                    setRequestStatus({loading: false, status:false, message:''})
                }, 3000)
                return 
            }
            setRequestStatus({loading: false, status:true, message:'uploaded'})
            inputFile.current.value = null
            setReloadList(prev => !prev) // Triggers list of upload reload
            setTimeout(()=>{
                setRequestStatus({loading: false, status:false, message:''})
            }, 3000)
        }).catch(err => {
            inputFile.current.value = null
            setRequestStatus({loading: false, status:false, message:'upload failed'})
            setTimeout(()=>{
                setRequestStatus({loading: false, status:false, message:''})
            }, 3000)
            return 
        })
    }

    //FUNCTION TO OPEN NEW WINDOW
    const openNewWindow = (e, url, width=1000, height=600) => {
        e.preventDefault()
        var leftPosition, topPosition;
        //Allow for borders.
        leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
        //Allow for title and status bars.
        topPosition = (window.screen.height / 2) - ((height / 2) + 50);
        //Open the window.
        window.open(url, "",
        "status=no,height=" + height + ",width=" + width + ",resizable=yes,left="
        + leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY="
        + topPosition + ",toolbar=no,menubar=no,scrollbars=yes,location=no,directories=no");
    }

    useEffect(()=>{
        apiCall.jobManagerFiles({job_uid:data?.job_uid || data?.origin_job_uid}).then(res => {
            setUploadedList({loading: false, data:res.data})
        }).catch(err => {
            console.log(err)
            setUploadedList({loading: false, data:{}})
        })
    },[data, reloadList])

    return (
        <>
        {!showOnData ?
            <div className='w-full h-full flex flex-col justify-center items-center'>
                <div className='w-full flex flex-col items-start gap-1 overflow-hidden'>
                    <div className='w-full flex justify-between items-center gap-1'>
                        <div className='job-label job-label-flex'>
                            Files {
                            requestStatus.loading ? 
                                <LoadingSpinner size='6' /> 
                                : 
                                <span className={`text-[10px] ${requestStatus.status ? 'text-emerald-600' : 'text-red-500'}`}>{requestStatus.message}</span>
                            } 
                        </div>
                        <label htmlFor='file' className={`text-black bg-[#f8ffd4] cursor-pointer text-[12px] py-1 px-2 rounded-full border ${ requestStatus.loading && 'pointer-events-none'}`}>+ Add Files</label>
                    </div>
                    <div className='w-full p-2 rounded-2xl bg-slate-100'>
                        <div className='text-[12px] w-full h-20 overflow-y-auto flex flex-col gap-2'>
                            {uploadedList.loading ?
                            <div className='w-full h-full justify-center items-center'>
                                <LoadingSpinner size='6' height='h-full' />
                            </div>
                            :
                            Object.keys(uploadedList.data).length > 0 ?
                            uploadedList.data.result_list.length > 0 ? 
                                uploadedList.data.result_list.map((item, index) => {
                                    let fileNameExt = item.originalname.split('.')[item.originalname.split('.').length - 1]
                                    let downloadLink = fileNameExt == 'mp4' ? `${process.env.REACT_APP_MEDIA_LINK}/mytask/${item.file_uid}` : `${uploadedList?.data?.session_image_server}/${localStorage.getItem("session_token")}/jobfile/${item.file_uid}`
                                    return(
                                        <div key={item.file_uid} className={`mb-[6px] flex justify-start gap-2 ${fontSize}`}>
                                            <span>{index + 1}:</span>
                                            <a 
                                                target='_blank' 
                                                alt='download-link' 
                                                className='' 
                                                href={downloadLink}
                                                onClick={(e)=> openNewWindow(e, downloadLink)}
                                            >
                                                {(item.originalname).toString().length > 30 ? (item.originalname).toString().slice(0, 26) + '..._.' + fileNameExt : (item.originalname).toString()}
                                            </a>
                                        </div>
                                    )
                                })
                                : 
                                <p className='text-sm'>No Uploaded files yet</p>
                            :
                            <></>
                            }
                        </div>
                    </div>
                    <input 
                        type="file" 
                        id='file' 
                        ref={inputFile} 
                        className='hidden file:rounded-full job-label p-1' 
                        onChange={upload}
                    />
                </div>
            </div>
        :showOnData && uploadedList?.data?.result_list?.length > 0 ?
            <div className='w-full h-full flex flex-col justify-center items-center'>
                <div className='w-full flex flex-col items-start gap-1 overflow-hidden'>
                    <div className='w-full flex justify-between items-center gap-1'>
                        <div className='job-label job-label-flex'>
                            Files
                        </div>
                    </div>
                    <div className='w-full p-2 rounded-2xl bg-slate-100'>
                        <div className='text-[12px] w-full p-2 h-20 overflow-y-auto flex flex-col gap-2'>
                            {uploadedList.data.result_list.map((item, index) => {
                                let fileNameExt = item.originalname.split('.')[item.originalname.split('.').length - 1]
                                let downloadLink = fileNameExt == 'mp4' ? `${process.env.REACT_APP_MEDIA_LINK}/mytask/${item.file_uid}` : `${uploadedList?.data?.session_image_server}/${localStorage.getItem("session_token")}/jobfile/${item.file_uid}`
                                return(
                                    <div key={item.file_uid} className={`mb-[6px] flex justify-start gap-2 ${fontSize}`}>
                                        <span>{index + 1}:</span>
                                        <a 
                                            target='_blank' 
                                            alt='download-link'
                                            className='' 
                                            href={downloadLink}
                                            onClick={(e)=> openNewWindow(e, downloadLink)}
                                        >
                                            {(item.originalname).toString().length > 30 ? (item.originalname).toString().slice(0, 26) + '..._.' + fileNameExt : (item.originalname).toString()}
                                        </a>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    <input 
                        type="file" 
                        id='file' 
                        ref={inputFile} 
                        className='hidden file:rounded-full job-label p-1' 
                        onChange={upload}
                    />
                </div>
            </div>
        :
            <></>
        }
        </>
    )
}
