import React from "react";
import Layout from "../Partials/Layout";
import ReferralDisplay from "./ReferralDisplay";

function Referral() {
  return (
    <Layout>
      <ReferralDisplay />
    </Layout>
  );
}

export default Referral;
