import React from "react";
import RecomendedSliders from "./RecomendedSliders";

export default function CommonHead({ className = "", commonHeadData = [] }) {
  return (
    <>
      {process.env.REACT_APP_SHOW_SLIDER_BANNERS === "1" && (
        <div
          className={`create-nft w-full lg:h-[140px] shadow lg:flex rounded-lg justify-between items-center  md:p-2 p-2 bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29] -2 border-pink mb-10 ${className}`}
        >
          {commonHeadData?.length > 0 && (
            <RecomendedSliders bannerData={commonHeadData} />
          )}
        </div>
      )}
    </>
  );
}
