import React, { useState } from "react";
import dataImage1 from "../../assets/images/data-table-user-1.png";
import dataImage2 from "../../assets/images/data-table-user-2.png";
import dataImage3 from "../../assets/images/data-table-user-3.png";
import dataImage4 from "../../assets/images/data-table-user-4.png";
import SelectBox from "../Helpers/SelectBox";

export default function ReminderTable({ className }) {
  const filterCategories = ["All Categories", "Doctors Visit", "Medications"];
  const [selectedCategory, setCategory] = useState(filterCategories[0]);
  return (
    <div
      className={`update-table w-full p-8 bg-white dark:bg-dark-white   overflow-hidden rounded-2xl section-shadow min-h-[520px] ${
        className || ""
      }`}
    >
      <div className="header w-full flex justify-between items-center mb-5">
        <div className="flex space-x-2 items-center">
          <h1 className="text-xl font-bold text-dark-gray dark:text-white tracking-wide">
            Reminders List
          </h1>
        </div>
        <SelectBox
          action={setCategory}
          datas={filterCategories}
          className="Update-table-dropdown"
          contentBodyClasses="w-auto min-w-max"
        />
      </div>
      <div className="relative w-full overflow-x-auto sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            <tr className="text-base text-thin-light-gray whitespace-nowrap border-b dark:border-[#5356fb29]  default-border-b dark:border-[#5356fb29] ottom ">
              <td className="py-4">Item</td>
              <td className="py-4 text-center">Start</td>
              <td className="py-4 text-center">End</td>
              <td className="py-4 text-center">Reminder</td>
              <td className="py-4 text-right">.</td>
            </tr>

            {selectedCategory === "All Categories" ? (
              <>
                <tr className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
                  <td className=" py-4">
                    <div className="flex space-x-2 items-center">
                      <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex justify-center items-center">
                        <img
                          src={dataImage1}
                          alt="data"
                          className="w-full h-full"
                        />
                      </div>
                      <div className="flex flex-col">
                        <h1 className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                          Pizza Testing Appointament
                        </h1>
                        <span className="text-sm text-thin-light-gray">
                          Added <span className="text-purple">10-10-2025</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
           
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                        12-01-25 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                      12-01-28 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-right py-4 px-2">
                    <span className="text-base text-thin-light-gray whitespace-nowrap">
                     4
                    </span>
                  </td>
                  <td className="text-right py-4 px-2">
                    <button
                      type="button"
                      className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
                <tr className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
                  <td className=" py-4">
                    <div className="flex space-x-2 items-center">
                      <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex justify-center items-center">
                        <img
                          src={dataImage1}
                          alt="data"
                          className="w-full h-full"
                        />
                      </div>
                      <div className="flex flex-col">
                        <h1 className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                          Pizza Testing Appointament
                        </h1>
                        <span className="text-sm text-thin-light-gray">
                          Added <span className="text-purple">10-10-2025</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
           
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                        12-01-25 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                      12-01-28 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-right py-4 px-2">
                    <span className="text-base text-thin-light-gray whitespace-nowrap">
                     4
                    </span>
                  </td>
                  <td className="text-right py-4 px-2">
                    <button
                      type="button"
                      className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
                <tr className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
                  <td className=" py-4">
                    <div className="flex space-x-2 items-center">
                      <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex justify-center items-center">
                        <img
                          src={dataImage1}
                          alt="data"
                          className="w-full h-full"
                        />
                      </div>
                      <div className="flex flex-col">
                        <h1 className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                          Pizza Testing Appointament
                        </h1>
                        <span className="text-sm text-thin-light-gray">
                          Added <span className="text-purple">10-10-2025</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
           
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                        12-01-25 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                      12-01-28 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-right py-4 px-2">
                    <span className="text-base text-thin-light-gray whitespace-nowrap">
                     4
                    </span>
                  </td>
                  <td className="text-right py-4 px-2">
                    <button
                      type="button"
                      className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
                <tr className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
                  <td className=" py-4">
                    <div className="flex space-x-2 items-center">
                      <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex justify-center items-center">
                        <img
                          src={dataImage1}
                          alt="data"
                          className="w-full h-full"
                        />
                      </div>
                      <div className="flex flex-col">
                        <h1 className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                          Pizza Testing Appointament
                        </h1>
                        <span className="text-sm text-thin-light-gray">
                          Added <span className="text-purple">10-10-2025</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
           
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                        12-01-25 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                      12-01-28 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-right py-4 px-2">
                    <span className="text-base text-thin-light-gray whitespace-nowrap">
                     4
                    </span>
                  </td>
                  <td className="text-right py-4 px-2">
                    <button
                      type="button"
                      className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </>
            ) : selectedCategory === "Doctors Visit" ? (
              <>
                <tr className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
                  <td className=" py-4">
                    <div className="flex space-x-2 items-center">
                      <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex justify-center items-center">
                        <img
                          src={dataImage1}
                          alt="data"
                          className="w-full h-full"
                        />
                      </div>
                      <div className="flex flex-col">
                        <h1 className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                          Doctors Testing Appointament
                        </h1>
                        <span className="text-sm text-thin-light-gray">
                          Added <span className="text-purple">10-10-2025</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
           
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                        12-01-25 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                      12-01-28 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-right py-4 px-2">
                    <span className="text-base text-thin-light-gray whitespace-nowrap">
                     4
                    </span>
                  </td>
                  <td className="text-right py-4 px-2">
                    <button
                      type="button"
                      className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
                <tr className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
                  <td className=" py-4">
                    <div className="flex space-x-2 items-center">
                      <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex justify-center items-center">
                        <img
                          src={dataImage1}
                          alt="data"
                          className="w-full h-full"
                        />
                      </div>
                      <div className="flex flex-col">
                        <h1 className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                          Hospital Testing Appointament
                        </h1>
                        <span className="text-sm text-thin-light-gray">
                          Added <span className="text-purple">10-10-2025</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
           
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                        12-01-25 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                      12-01-28 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-right py-4 px-2">
                    <span className="text-base text-thin-light-gray whitespace-nowrap">
                     4
                    </span>
                  </td>
                  <td className="text-right py-4 px-2">
                    <button
                      type="button"
                      className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
                  <td className=" py-4">
                    <div className="flex space-x-2 items-center">
                      <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex justify-center items-center">
                        <img
                          src={dataImage1}
                          alt="data"
                          className="w-full h-full"
                        />
                      </div>
                      <div className="flex flex-col">
                        <h1 className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                          Pizza Testing Appointament
                        </h1>
                        <span className="text-sm text-thin-light-gray">
                          Added <span className="text-purple">10-10-2025</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
           
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                        12-01-25 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                      12-01-28 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-right py-4 px-2">
                    <span className="text-base text-thin-light-gray whitespace-nowrap">
                     4
                    </span>
                  </td>
                  <td className="text-right py-4 px-2">
                    <button
                      type="button"
                      className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
                <tr className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
                  <td className=" py-4">
                    <div className="flex space-x-2 items-center">
                      <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex justify-center items-center">
                        <img
                          src={dataImage1}
                          alt="data"
                          className="w-full h-full"
                        />
                      </div>
                      <div className="flex flex-col">
                        <h1 className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                          Pizza Testing Appointament
                        </h1>
                        <span className="text-sm text-thin-light-gray">
                          Added <span className="text-purple">10-10-2025</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
           
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                        12-01-25 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                      12-01-28 : 6:30 PM
                      </span>
                    </div>
                  </td>
                  <td className="text-right py-4 px-2">
                    <span className="text-base text-thin-light-gray whitespace-nowrap">
                     4
                    </span>
                  </td>
                  <td className="text-right py-4 px-2">
                    <button
                      type="button"
                      className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
