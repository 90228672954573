

export const handlePagingFunc = ({target:{name, value}},setCurrentPage) => {
    if(name == 'prev'){
      setCurrentPage((prev)=>{
        return prev-Number(process.env.REACT_APP_ITEM_PER_PAGE)
      })
    }
    if(name == 'next'){
      setCurrentPage((prev)=>{
        return prev+Number(process.env.REACT_APP_ITEM_PER_PAGE)
      }) 
    }
    if(name == 'page_num'){
      setCurrentPage(Number(value))
    }
  }