import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import usersService from "../../services/UsersService";
import Layout from "../Partials/Layout";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import CommonHead from "../UserHeader/CommonHead";
import { useSelector } from "react-redux";
import CustomBreadcrumb from "../Breadcrumb/CustomBreadcrumb";

export default function BlogItem(props) {

    const {
        userDetails: { account_type },
      } = useSelector((state) => state?.userDetails); // CHECKS IF USER Details account type

    const apiCall = new usersService()
    const navigate = useNavigate()

    const [blogdata, setBlogdata] = useState({loading: true, data:{}})

    const [selectTab, setValue] = useState("today");
    const filterHandler = (value) => {
        setValue(value);
    };
    // eslint-disable-next-line no-restricted-globals
    const queryParams = new URLSearchParams(location?.search);
    const blog_id = queryParams.get("blog_id");
    
    useEffect(()=>{
        if(!blog_id){
            navigate('/',{replace:true})
        }
        apiCall.getSingleBlogData({blog_id}).then(res => {
            setBlogdata({loading: false, data:res.data})
        }).catch(error => {
            setBlogdata({loading: false, data:{}})
            console.log('ERROR', error)
        })
    },[blog_id])
    return (
        <Layout>
            {account_type == 'FULL' &&
                <CommonHead
                    commonHeadData={props.commonHeadData}
                />
            }
            { account_type == 'FAMILY' &&
            <div className="mb-5">
                <CustomBreadcrumb 
                title = {blogdata?.data?.blogdata?.length > 0 ? blogdata?.data?.blogdata[0]?.post_title : 'Blog'}
                breadcrumb={
                    [
                    { link: "/", title: "Home" },
                    { link: "/fam-blog", title: "Blogs", active: true},
                    ]
                }
                />
            </div>
            }
            <div className="notification-page w-full mb-10">
                {/* <div className="mb-5">
                    <h1 className="text-26 font-bold text-dark-gray dark:text-white">
                        <span
                            className={`${selectTab === "today" ? "block" : "hidden"}`}
                        >
                        {blogdata.data?.blogdata?.[0]?.post_title}
                        </span>
                    </h1>
                </div> */}
                <div className="notification-wrapper w-full bg-white p-8 rounded-2xl">
                    {blogdata?.loading ? 
                        <LoadingSpinner size='8' color='sky-blue' height='h-[100px]' />
                    :
                        blogdata?.data?.blogdata && blogdata.data?.blogdata.length ?
                        <div className="w-full">
                            {/* heading */}
                            {/* <div className="sm:flex justify-between items-center mb-6">
                                <div className="mb-5">
                                    <h1 className="text-26 font-bold text-dark-gray dark:text-white">
                                        <span
                                            className={`${selectTab === "today" ? "block" : "hidden"}`}
                                        >
                                        {blogdata.data?.blogdata?.[0]?.post_title}
                                        </span>
                                    </h1>
                                </div>
                                <div className="slider-btns flex space-x-4">
                                </div>
                            </div> */}
                            <div dangerouslySetInnerHTML={{__html: blogdata.data?.blogdata?.[0]?.post_content}}>
                            </div>
                    </div>
                    :
                    <h1 className="text-26 font-bold text-dark-gray dark:text-white">No Blog Found!</h1>
                    }  
                </div>
            </div>
        </Layout>
    );
}
