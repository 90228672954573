import React from 'react'
import Promo from '../components/AuthPages/Promo/Promo'

export default function PromoPage() {
  return (
    <>
        <Promo />
    </>
  )
}
