import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import bank1 from "../../assets/images/bank-1.png";
import bank2 from "../../assets/images/bank-2.png";
import bank3 from "../../assets/images/bank-3.png";
import bank4 from "../../assets/images/bank-4.png";
import profileImg from "../../assets/images/profile.jpg";
import useToggle from "../../hooks/useToggle";
import DarkModeContext from "../Contexts/DarkModeContext";
import Icons from "../Helpers/Icons";
import ModalCom from "../Helpers/ModalCom";
import WalletHeader from "../MyWallet/WalletHeader";

import { useSelector } from "react-redux";
import Flag from "../../assets/images/united-states.svg";
import siteLogo from "../../assets/images/wrenchboard-logo-text.png";
// import { updateWalletDetails } from "../../store/walletDetails";
import TimeDifference from "../Helpers/TimeDifference";

const DEFAULT_PROFILE_IMAGE = require("../../assets/images/profile.jpg");

export default function Header({ logoutModalHandler, sidebarHandler }) {

  const {userDetails: { account_type }} = useSelector((state) => state?.userDetails);

  const [balanceDropdown, setbalanceValue] = useToggle(false);
  const [notificationDropdown, setNotificationValue] = useToggle(false);
  const [userProfileDropdown, setProfileDropdown] = useToggle(false);
  const [moneyPopup, setPopup] = useToggle(false);
  const darkMode = useContext(DarkModeContext);
  const { userDetails } = useSelector((state) => state?.userDetails);

  const { notifications } = useSelector((state) => state?.notifications); // NOTIFICATION STORE
  const { walletDetails } = useSelector((state) => state?.walletDetails); // WALLET STORE

  const image = localStorage.getItem("session_token")
    ? userDetails.account_type === "FAMILY"
      ? `${userDetails.session_image_server}${localStorage.getItem(
          "session_token"
        )}/family/${sessionStorage.getItem("family_uid")}`
      : `${userDetails.session_image_server}${localStorage.getItem(
          "session_token"
        )}/profile/${userDetails.uid}`
    : "";

  // 9308RDR122

  const handlerBalance = () => {
    setbalanceValue.toggle();
    if (notificationDropdown) {
      setNotificationValue.toggle();
    }
    if (userProfileDropdown) {
      setProfileDropdown.toggle();
    }
  };
  const handlerNotification = () => {
    setNotificationValue.toggle();
    if (balanceDropdown) {
      setbalanceValue.toggle();
    }
    if (userProfileDropdown) {
      setProfileDropdown.toggle();
    }
  };
  const handlerProfile = () => {
    setProfileDropdown.toggle();
    if (balanceDropdown) {
      setbalanceValue.toggle();
    }
    if (notificationDropdown) {
      setNotificationValue.toggle();
    }
  };
  const clickAwayhandler = () => {
    if (balanceDropdown) {
      setbalanceValue.toggle();
    }
    if (notificationDropdown) {
      setNotificationValue.toggle();
    }
    if (userProfileDropdown) {
      setProfileDropdown.toggle();
    }
  };
  const addMoneyHandler = () => {
    setPopup.toggle();
    setbalanceValue.set(false);
  };

  // getting the location of head
  let { pathname } = useLocation();

  const handleWalletBtn = () => {
    if (pathname === "/my-wallet") {
      setbalanceValue.set(false);
    } else return balanceDropdown;
  };

  // User Profile
  let { firstname, lastname, email, profile_pic_url } = userDetails;
  let userEmail = email?.split("@")[0];
  const userProfileImage = image || DEFAULT_PROFILE_IMAGE;

  return (
    <>
      <div className="header-wrapper backdrop-blur-sm bg-[#efedfe5e]/60 dark:bg-transparent w-full h-full flex items-center xl:px-0 md:px-10 px-5">
        <div className="flex justify-between items-center w-full">
          <button
            className="xl:hidden block mr-10"
            type="button"
            onClick={sidebarHandler}
          >
            <svg
              width="25"
              height="19"
              viewBox="0 0 25 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.3544 8.45953L16.9855 0.271831C16.8283 0.0982522 16.6089 0 16.3763 0H11.4637C11.1411 0 10.848 0.189955 10.7153 0.484712C10.5843 0.781107 10.6384 1.12663 10.8545 1.36571L17.7306 9.00647L10.8545 16.6456C10.6384 16.8863 10.5827 17.2318 10.7153 17.5266C10.848 17.823 11.1411 18.0129 11.4637 18.0129H16.3763C16.6089 18.0129 16.8283 17.913 16.9855 17.7427L24.3544 9.55505C24.6344 9.24391 24.6344 8.76903 24.3544 8.45953Z"
                fill="url(#paint0_linear_700_68145)"
              />
              <path
                d="M13.7104 8.45953L6.34147 0.271831C6.18427 0.0982522 5.96484 0 5.73231 0H0.819691C0.497095 0 0.203976 0.189955 0.071335 0.484712C-0.0596682 0.781107 -0.00562942 1.12663 0.210526 1.36571L7.08656 9.00647L0.210526 16.6456C-0.00562942 16.8863 -0.0613058 17.2318 0.071335 17.5266C0.203976 17.823 0.497095 18.0129 0.819691 18.0129H5.73231C5.96484 18.0129 6.18427 17.913 6.34147 17.7427L13.7104 9.55505C13.9904 9.24391 13.9904 8.76903 13.7104 8.45953Z"
                fill="url(#paint1_linear_700_68145)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_700_68145"
                  x1="10.644"
                  y1="0"
                  x2="28.9548"
                  y2="13.8495"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F539F8" />
                  <stop offset="0.416763" stopColor="#C342F9" />
                  <stop offset="1" stopColor="#5356FB" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_700_68145"
                  x1="0"
                  y1="0"
                  x2="18.3108"
                  y2="13.8495"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F539F8" />
                  <stop offset="0.416763" stopColor="#C342F9" />
                  <stop offset="1" stopColor="#5356FB" />
                </linearGradient>
              </defs>
            </svg>
          </button>

          <div className="search-bar xl:hidden justify-center items-center w-[376px]">
            {/* Home */}
            <HomeButton />
          </div>

          {/* user info */}
          <div className="user-info flex items-center justify-end w-full xl:space-x-7 space-x-2 z-10 ">
            {/* dark mode */}
            {/* <button
              onClick={darkMode.handleThemeSwitch}
              type="button"
              className="xl:w-[176px] w-[52px] h-[52px] dark:bg-white bg-dark-gray border border-pink rounded-full flex justify-center items-center"
            >
              <div className="flex space-x-2.5 items-center">
                <span className="dark:text-dark-gray text-white">
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-current"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.3065 16.3771C18.1572 16.6304 17.986 16.8743 17.7937 17.1062C17.7118 17.2044 17.7247 17.3515 17.8228 17.4339C17.9208 17.5163 18.0665 17.5028 18.1484 17.4046C18.3569 17.1532 18.5419 16.8897 18.7043 16.6155C18.7695 16.5051 18.7334 16.3622 18.6238 16.2966C18.5142 16.2304 18.3722 16.2668 18.3065 16.3771ZM18.9591 14.722C18.8948 15.0116 18.8078 15.2975 18.6982 15.5759C18.6511 15.6955 18.7093 15.831 18.8282 15.878C18.9466 15.9255 19.0812 15.8668 19.1283 15.7477C19.2472 15.446 19.3411 15.1368 19.4104 14.8231C19.4382 14.6978 19.3596 14.5735 19.2347 14.5456C19.1103 14.5176 18.9864 14.5968 18.9591 14.722ZM19.0673 12.944C19.0955 13.2411 19.1006 13.5395 19.0825 13.8375C19.0747 13.9655 19.1718 14.0763 19.2994 14.0837C19.4266 14.0917 19.5362 13.9939 19.5441 13.8659C19.564 13.5437 19.5584 13.2206 19.5274 12.8994C19.5154 12.7713 19.4021 12.6777 19.2754 12.6899C19.1482 12.702 19.0548 12.816 19.0673 12.944ZM18.6229 11.2201C18.7422 11.4948 18.8388 11.7769 18.9128 12.0641C18.9447 12.1884 19.071 12.2629 19.1949 12.2308C19.3184 12.1987 19.3924 12.0716 19.3605 11.9473C19.2805 11.6363 19.1755 11.3304 19.0465 11.0334C18.9951 10.9156 18.8587 10.8616 18.7417 10.9133C18.6247 10.9649 18.5715 11.1023 18.6229 11.2201ZM17.6651 9.72283C17.8653 9.94816 18.0448 10.1856 18.2034 10.4333C18.2723 10.5413 18.4157 10.5725 18.523 10.5026C18.6303 10.4333 18.6612 10.2889 18.5919 10.1809C18.4208 9.91324 18.2265 9.65578 18.0092 9.4123C17.9241 9.31639 17.7779 9.30848 17.6827 9.39414C17.5879 9.4798 17.58 9.62738 17.6651 9.72283Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.4409 5.61267C9.08757 5.61267 5.55359 9.14665 5.55359 13.5C5.55359 17.8533 9.08757 21.3873 13.4409 21.3873C17.7942 21.3873 21.3282 17.8533 21.3282 13.5C21.3282 9.14665 17.7942 5.61267 13.4409 5.61267ZM13.9049 6.5559C17.5298 6.79484 20.4003 9.81475 20.4003 13.5C20.4003 17.1852 17.5298 20.2051 13.9049 20.4441V6.5559Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.1116 1.32213V3.46343C12.1116 4.19318 12.7072 4.78556 13.4409 4.78556C14.1746 4.78556 14.7702 4.19318 14.7702 3.46343V1.32213C14.7702 0.592374 14.1746 0 13.4409 0C12.7072 0 12.1116 0.592374 12.1116 1.32213Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M21.0877 4.01472L19.5511 5.5513C19.0274 6.07499 19.0274 6.92528 19.5511 7.44898C20.0748 7.97267 20.9251 7.97267 21.4488 7.44898L22.9854 5.9124C23.509 5.38871 23.509 4.53841 22.9854 4.01472C22.4617 3.49102 21.6114 3.49102 21.0877 4.01472Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M25.6581 12.3649H23.4849C22.7443 12.3649 22.1431 12.9661 22.1431 13.7068C22.1431 14.4474 22.7443 15.0486 23.4849 15.0486H25.6581C26.3988 15.0486 27 14.4474 27 13.7068C27 12.9661 26.3988 12.3649 25.6581 12.3649Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22.9854 21.5012L21.4488 19.9646C20.9251 19.4409 20.0748 19.4409 19.5511 19.9646C19.0274 20.4883 19.0274 21.3386 19.5511 21.8623L21.0877 23.3989C21.6114 23.9226 22.4617 23.9226 22.9854 23.3989C23.509 22.8752 23.509 22.0249 22.9854 21.5012Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.7702 25.6778V23.5365C14.7702 22.8068 14.1746 22.2144 13.4409 22.2144C12.7072 22.2144 12.1116 22.8068 12.1116 23.5365V25.6778C12.1116 26.4076 12.7072 27 13.4409 27C14.1746 27 14.7702 26.4076 14.7702 25.6778Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.78958 23.0647L7.29553 21.5372C7.80878 21.0166 7.80878 20.1714 7.29553 19.6508C6.78228 19.1302 5.94893 19.1302 5.43568 19.6508L3.92974 21.1783C3.41649 21.6989 3.41649 22.5441 3.92974 23.0647C4.44299 23.5853 5.27633 23.5853 5.78958 23.0647Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.32213 14.8293H3.46343C4.19318 14.8293 4.78556 14.2337 4.78556 13.5C4.78556 12.7663 4.19318 12.1707 3.46343 12.1707H1.32213C0.592374 12.1707 0 12.7663 0 13.5C0 14.2337 0.592374 14.8293 1.32213 14.8293Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.92974 5.82165L5.43568 7.34911C5.94893 7.86969 6.78228 7.86969 7.29553 7.34911C7.80878 6.82853 7.80878 5.98328 7.29553 5.46269L5.78958 3.93524C5.27633 3.41465 4.44299 3.41465 3.92974 3.93524C3.41649 4.45582 3.41649 5.30107 3.92974 5.82165Z"
                    />
                  </svg>
                </span>
                <span className="dark:text-dark-gray font-medium text-white xl:block hidden">
                  {darkMode.theme === "light" ? "Dark" : "Light"} Mode
                </span>
              </div>
            </button> */}
            {/* balance */}

            {/* My Page Button */}
            {/* {userDetails.account_type === "FULL" ? <PageButton /> : null} */}

            {/*<div className="lg:hidden block"></div>*/}
            <WalletHeader
              myWalletList={walletDetails.data}
              handlerBalance={handlerBalance}
              balanceDropdown={balanceDropdown}
              addMoneyHandler={addMoneyHandler}
              setBalanceDropdown={setbalanceValue}
            />
            {/* notification */}
            <div className="user-notification lg:block hidden relative">
              <div
                onClick={() => handlerNotification()}
                className="lg:w-[48px] lg:h-[48px] w-[38px] h-[38px] bg-white   flex justify-center items-center rounded-full overflow-hidden relative"
              >
                <Icons name="notification" />
                <span className="absolute right-2 top-2 z-10 text-xs lg:w-5 lg:h-5 w-4 h-4 flex justify-center items-center rounded-full primary-gradient text-white cursor-default">
                  {notifications?.loading ? "●" : notifications?.data?.length}
                </span>
              </div>
              <div
                className={`notification-dropdown z-30 w-96 bg-white dark:bg-dark-white   absolute -right-12 rounded-lg cursor-pointer ${
                  notificationDropdown ? "active" : ""
                }`}
              >
                <div className="heading border-b dark:border-[#5356fb29]  border-light-purple px-7 py-6">
                  <h3 className="text-xl font-bold   text-dark-gray dark:text-white">
                    Recent Notifications
                  </h3>
                </div>

                <div className="content px-7 pb-7">
                  <ul>
                    {notifications?.data?.length &&
                      notifications?.data?.map((item, idx) => {
                        if (idx < 5) {
                          return (
                            <li
                              className={`content-item ${
                                idx == 4
                                  ? "py-5 "
                                  : "py-4 border-b dark:border-[#5356fb29]  border-light-purple hover:border-purple dark:hover:border-purple"
                              }`}
                              key={idx}
                            >
                              <div className="notifications flex space-x-4 items-center">
                                <div className="icon max-w-[52px] max-h-[52px] w-full h-full rounded-full object-cover">
                                  <img
                                    src={require(`../../assets/images/notifications/${item?.icon}`)}
                                    alt="icon"
                                    className="w-full h-full"
                                  />
                                </div>
                                <div className="name">
                                  <p className="text-base text-dark-gray dark:text-white font-medium mb-2">
                                    {item?.title}
                                    {/* <span className="ml-1 font-bold">
                                successfully done
                              </span> */}
                                  </p>
                                  <p className="text-sm text-thin-light-gray font-medium">
                                    <TimeDifference
                                      time={item?.date}
                                      key={item?.uid}
                                    />
                                  </p>
                                </div>
                              </div>
                            </li>
                          );
                        }
                      })}
                  </ul>

                  <div className="add-money-btn flex justify-center items-center">
                    <Link
                      to="/notification"
                      className="text-purple text-sm font-medium"
                      onClick={handlerNotification}
                    >
                      See all Notification
                    </Link>
                    {/* <button className="text-purple text-sm font-medium" onClick={()=>{
                      handlerNotification()
                      navigate('/notification', {replace: true})
                    }}>See all Notification</button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:hidden block">
              <Link
                to="/notification"
                className="lg:w-[48px] lg:h-[48px] w-[38px] h-[38px] bg-white   flex justify-center items-center rounded-full overflow-hidden relative"
              >
                <Icons name="notification" />
                <span className="absolute right-2 top-2 z-10 text-xs lg:w-5 lg:h-5 w-4 h-4 flex justify-center items-center rounded-full primary-gradient text-white">
                  10
                </span>
              </Link>
            </div>
            {/* profile */}
            <div className="user-profile relative">
              <div
                onClick={() => handlerProfile()}
                className="hidden lg:flex items-center space-x-3.5"
              >
                {/* profile-image */}
                <div className="lg:w-[62px] lg:h-[62px] w-[50px] h-[50px] rounded-full overflow-hidden">
                  <img
                    src={userProfileImage}
                    alt="profile"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="lg:block hidden">
                  <h1 className="text-xl font-bold   text-dark-gray dark:text-white">
                    {firstname}
                  </h1>
                  {userDetails && userDetails?.account_type !== "FAMILY" && (
                    <p className="text-sm text-thin-light-gray">@{userEmail}</p>
                  )}
                </div>
              </div>

              <div className="for-mobile-profile lg:hidden block">
                <div
                  // to="/profile"
                  onClick={() => handlerProfile()}
                  className="lg:w-[62px] lg:h-[62px] w-[50px] h-[50px] rounded-full overflow-hidden block"
                >
                  <img
                    src={profileImg}
                    alt="profile"
                    className="w-full h-full object-cover object-center"
                  />
                </div>
              </div>

              <div
                className={`profile-dropdown w-[293px] z-30 bg-white dark:bg-dark-white   absolute lg:right-16 -right-[16px]  rounded-lg ${
                  userProfileDropdown ? "active" : ""
                }`}
              >
                <div className="heading border-b dark:border-[#5356fb29]  border-light-purple px-7 py-2">
                  <img src={siteLogo} alt="Logo" className="w-[150px]" />
                </div>
                <div className="heading border-b dark:border-[#5356fb29]  border-light-purple px-7 py-2">
                  <h3 className="text-xl font-bold   text-dark-gray dark:text-white">
                    {`${firstname} ${lastname}`}
                  </h3>
                </div>
                <div className="content">
                  <ul className="px-7">
                    {userDetails && userDetails?.account_type !== "FAMILY" && (
                      <li className="content-item my-2 hover:bg-slate-100 transition duration-500 rounded-lg">
                        <Link to="/settings" className="notifications">
                          <div className="name">
                            <p className="text-sm py-2 px-4 text-dark-gray dark:text-white hover:text-sky-blue transition font-medium">
                              My Profile
                            </p>
                          </div>
                        </Link>
                      </li>
                    )}
                    <li className="content-item my-2 hover:bg-slate-100 transition duration-500 rounded-lg">
                      <Link to={ account_type == "FULL" ? "/my-wallet" : "/family-wallet"} className="notifications">
                        <div className="name">
                          <p className="text-sm py-2 px-4 text-dark-gray dark:text-white hover:text-sky-blue transition font-medium">
                            My Wallet
                          </p>
                        </div>
                      </Link>
                    </li>
                    {userDetails && userDetails?.account_type !== "FAMILY" && (
                      <li className="content-item my-2 hover:bg-slate-100 transition duration-500 rounded-lg">
                        <Link to="/yourpage" className="notifications">
                          <div className="name">
                            <p className="text-sm py-2 px-4 text-dark-gray dark:text-white hover:text-sky-blue transition font-medium">
                              My Page
                            </p>
                          </div>
                        </Link>
                      </li>
                    )}
                    {userDetails && userDetails?.account_type !== "FAMILY" && (
                      <>
                        <li className="content-item my-2 hover:bg-slate-100 transition duration-500 rounded-lg">
                          <Link to="#" className="notifications">
                            <div className="name">
                              <p className="text-sm py-2 px-4 text-dark-gray dark:text-white font-medium">
                                My Statements
                              </p>
                            </div>
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                  <hr />
                  <ul className="px-7">
                    {userDetails && userDetails?.account_type !== "FAMILY" && (
                      <>
                        <li className="content-item relative my-2 hover:bg-slate-100 transition duration-500 rounded-lg">
                          <Link to="#" className="notifications">
                            <div className="name">
                              <div className="flex items-center justify-between text-sm py-2 px-4 text-dark-gray dark:text-white hover:text-sky-blue transition font-medium">
                                <p>Language</p>
                                <div className="px-1 flex items-center space-x-1 bg-slate-100 rounded-sm">
                                  <p className="text-[10px]">English </p>
                                  <img
                                    className="w-[10px] h-[10px]"
                                    src={Flag}
                                    alt="U.S.A Flag"
                                  />
                                </div>
                              </div>
                            </div>
                          </Link>
                          {/* langauge list items*/}
                          <div className="inner-list-items absolute rounded-lg">
                            <ul className="p-3">
                              <li className="content-item my-1 hover:bg-slate-100 transition duration-500 rounded-lg">
                                <Link to="#" className="notifications">
                                  <div className="name">
                                    <div className="px-1 flex items-center space-x-1 bg-slate-100 rounded-sm text-sm py-1 text-dark-gray dark:text-white hover:text-sky-blue transition font-medium">
                                      <img
                                        className="w-[15px] h-[15px]"
                                        src={Flag}
                                        alt="U.S.A Flag"
                                      />
                                      <p className="text-sm">English </p>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          {/* end Language list items*/}
                        </li>
                        <li className="content-item my-2 hover:bg-slate-100 transition duration-500 rounded-lg">
                          <Link to="#" className="notifications">
                            <div className="name">
                              <p className="text-sm py-2 px-4 text-dark-gray dark:text-white hover:text-sky-blue transition font-medium">
                                Account Settings
                              </p>
                            </div>
                          </Link>
                        </li>
                      </>
                    )}

                    <li className="content-item my-2 hover:bg-slate-100 transition duration-500 rounded-lg cursor-pointer">
                      <div className="name" onClick={logoutModalHandler}>
                        <p className="text-sm py-2 px-4 text-dark-gray dark:text-white hover:text-sky-blue transition font-medium">
                          {process.env.REACT_APP_LOGOUT_TEXT}
                        </p>
                      </div>
                    </li>
                  </ul>
                  <hr className="mb-3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {balanceDropdown || notificationDropdown || userProfileDropdown ? (
        <div
          onClick={clickAwayhandler}
          className="w-full h-screen fixed left-0"
          style={{ zIndex: "-1" }}
        ></div>
      ) : (
        ""
      )}
      {moneyPopup && (
        <ModalCom action={addMoneyHandler} situation={moneyPopup}>
          <div className="lg:w-[580px] sm:w-[450px] w-full rounded-2xl h-auto bg-white dark:bg-dark-white  ">
            <div className="heading border-b dark:border-[#5356fb29]  border-light-purple lg:px-7 sm:px-5 px-3 py-6 flex items-center justify-between">
              <h3 className="text-xl font-bold   text-dark-gray dark:text-white">
                Connect to a wallet
              </h3>
              <span onClick={addMoneyHandler}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36 16.16C36 17.4399 36 18.7199 36 20.0001C35.7911 20.0709 35.8636 20.2554 35.8385 20.4001C34.5321 27.9453 30.246 32.9248 22.9603 35.2822C21.9006 35.6251 20.7753 35.7657 19.6802 35.9997C18.4003 35.9997 17.1204 35.9997 15.8401 35.9997C15.5896 35.7086 15.2189 35.7732 14.9034 35.7093C7.77231 34.2621 3.08728 30.0725 0.769671 23.187C0.435002 22.1926 0.445997 21.1199 0 20.1599C0 18.7198 0 17.2798 0 15.8398C0.291376 15.6195 0.214408 15.2656 0.270759 14.9808C1.71321 7.69774 6.02611 2.99691 13.0428 0.700951C14.0118 0.383805 15.0509 0.386897 15.9999 0C17.2265 0 18.4532 0 19.6799 0C19.7156 0.124041 19.8125 0.136067 19.9225 0.146719C27.3 0.868973 33.5322 6.21922 35.3801 13.427C35.6121 14.3313 35.7945 15.2484 36 16.16ZM33.011 18.0787C33.0433 9.77105 26.3423 3.00309 18.077 2.9945C9.78479 2.98626 3.00344 9.658 2.98523 17.8426C2.96667 26.1633 9.58859 32.9601 17.7602 33.0079C26.197 33.0577 32.9787 26.4186 33.011 18.0787Z"
                    fillOpacity="0.6"
                  />
                  <path
                    d="M15.9309 18.023C13.9329 16.037 12.007 14.1207 10.0787 12.2072C9.60071 11.733 9.26398 11.2162 9.51996 10.506C9.945 9.32677 11.1954 9.0811 12.1437 10.0174C13.9067 11.7585 15.6766 13.494 17.385 15.2879C17.9108 15.8401 18.1633 15.7487 18.6375 15.258C20.3586 13.4761 22.1199 11.7327 23.8822 9.99096C24.8175 9.06632 26.1095 9.33639 26.4967 10.517C26.7286 11.2241 26.3919 11.7413 25.9133 12.2178C24.1757 13.9472 22.4477 15.6855 20.7104 17.4148C20.5228 17.6018 20.2964 17.7495 20.0466 17.9485C22.0831 19.974 24.0372 21.8992 25.9689 23.8468C26.9262 24.8119 26.6489 26.1101 25.4336 26.4987C24.712 26.7292 24.2131 26.3441 23.7455 25.8757C21.9945 24.1227 20.2232 22.3892 18.5045 20.6049C18.0698 20.1534 17.8716 20.2269 17.4802 20.6282C15.732 22.4215 13.9493 24.1807 12.1777 25.951C11.7022 26.4262 11.193 26.7471 10.4738 26.4537C9.31345 25.9798 9.06881 24.8398 9.98589 23.8952C11.285 22.5576 12.6138 21.2484 13.9387 19.9355C14.5792 19.3005 15.2399 18.6852 15.9309 18.023Z"
                    fillOpacity="0.6"
                  />
                </svg>
              </span>
            </div>
            <div className="content lg:px-7 px-3 sm:px-5 py-8">
              <ul>
                <li
                  className="content-item px-5 py-2.5 lg:mb-8 mb-3 border rounded-lg b border-light-purple dark:border-[#5356fb29]  hover:border-purple dark:hover:border-purple"
                  style={{ boxShadow: "0px 16px 93px 0px #55526329" }}
                >
                  <div className="sm:flex justify-between items-center">
                    <div className="account-name flex space-x-4 items-center mb-2 sm:mb-0">
                      <div className="icon w-14 h-14 transition duration-300 ease-in-out rounded-full flex justify-center items-center bg-light-purple dark:bg-dark-light-purple ">
                        <img src={bank1} alt="" />
                      </div>
                      <div className="name">
                        <p className="text-xl font-bold   text-dark-gray dark:text-white">
                          MetaMask
                        </p>
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="w-[122px] h-11 flex justify-center items-center border-gradient  text-base rounded-full text-back"
                      >
                        <span className="text-gradient"> Cannect</span>
                      </button>
                    </div>
                  </div>
                </li>
                <li
                  className="content-item px-5 py-2.5 lg:mb-8 mb-3 border rounded-lg b border-light-purple dark:border-[#5356fb29]  hover:border-purple dark:hover:border-purple"
                  style={{ boxShadow: "0px 16px 93px 0px #55526329" }}
                >
                  <div className="sm:flex justify-between items-center">
                    <div className="account-name flex space-x-4 items-center mb-2 sm:mb-0">
                      <div className="icon w-14 h-14 transition duration-300 ease-in-out rounded-full flex justify-center items-center bg-light-purple dark:bg-dark-light-purple ">
                        <img src={bank2} alt="" />
                      </div>
                      <div className="name">
                        <p className="text-xl font-bold   text-dark-gray dark:text-white">
                          Coinbase Wallet
                        </p>
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="w-[122px] h-11 flex justify-center items-center border-gradient  text-base rounded-full text-back"
                      >
                        <span className="text-gradient"> Cannect</span>
                      </button>
                    </div>
                  </div>
                </li>
                <li
                  className="content-item px-5 py-2.5 mb-8 border b rounded-lg border-light-purple dark:border-[#5356fb29]  hover:border-purple dark:hover:border-purple"
                  style={{ boxShadow: "0px 16px 93px 0px #55526329" }}
                >
                  <div className="sm:flex justify-between items-center">
                    <div className="account-name flex space-x-4 items-center mb-2 sm:mb-0">
                      <div className="icon w-14 h-14 transition duration-300 ease-in-out rounded-full flex justify-center items-center bg-light-purple dark:bg-dark-light-purple ">
                        <img src={bank3} alt="" />
                      </div>
                      <div className="name">
                        <p className="text-xl font-bold   text-dark-gray dark:text-white">
                          Bitski
                        </p>
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="w-[122px] h-11 flex justify-center items-center border-gradient  text-base rounded-full text-back"
                      >
                        <span className="text-gradient"> Cannect</span>
                      </button>
                    </div>
                  </div>
                </li>
                <li
                  className="content-item px-5 py-2.5 mb-8 border b rounded-lg border-light-purple dark:border-[#5356fb29]  hover:border-purple dark:hover:border-purple"
                  style={{ boxShadow: "0px 16px 93px 0px #55526329" }}
                >
                  <div className="sm:flex justify-between items-center">
                    <div className="account-name flex space-x-4 items-center mb-2 sm:mb-0">
                      <div className="icon w-14 h-14 transition duration-300 ease-in-out rounded-full flex justify-center items-center bg-light-purple dark:bg-dark-light-purple ">
                        <img src={bank4} alt="" />
                      </div>
                      <div className="name">
                        <p className="text-xl font-bold   text-dark-gray dark:text-white">
                          WalletConnect
                        </p>
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="w-[122px] h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white"
                      >
                        Connected
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="add-money-btn flex justify-center items-center">
                <Link
                  to="#"
                  className="text-18 rounded-full text-thin-light-gray"
                >
                  View Wallet
                </Link>
              </div>
            </div>
          </div>
        </ModalCom>
      )}
    </>
  );
}

const PageButton = () => {
  return (
    <Link
      to="/yourpage"
      className="lg:flex hidden user-balance cursor-pointer lg:w-[152px] w-[150px] h-[48px]  items-center rounded-full relative  bg-sky-blue pr-1.5 pl-4"
    >
      <div className="flex items-center lg:justify-between justify-center w-full h-full">
        <span className="lg:block hidden w-[25px]">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 50" id="Page">
            <path
              fillRule="evenodd"
              d="M0 13V2a2 2 0 0 1 2-2h64a2 2 0 0 1 2 2v11H0Zm0 4v31a2 2 0 0 0 2 2h20V17H0Zm26 33h40a2 2 0 0 0 2-2V17H26v33Z"
              fill="#ffffff"
              className="color000000 svgShape"
            ></path>
          </svg>
        </span>
        <p className="lg:text-xl text-lg font-bold text-white">My Page</p>
        <span className="lg:block hidden">
          {/* <Icons name="deep-plus" /> */}
        </span>
      </div>
    </Link>
  );
};

const HomeButton = () => {
  return (
    <Link
      to="/"
      className="flex user-balance cursor-pointer w-[110px] h-[48px] items-center rounded-full relative bg-sky-blue pr-1.5 pl-4"
    >
      <div className="flex items-center lg:justify-between justify-center w-full h-full">
        <span className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-7 h-7 stroke-white fill-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
            />
          </svg>
        </span>
        <p className="lg:text-xl text-lg font-bold text-white">Home</p>
        <span className=""></span>
      </div>
    </Link>
  );
};
