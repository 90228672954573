import React from "react";
import GetMyPageLoad from "./getMyPageLoad";


const LoadedPage = ({reloader}) => {
  const { loading, data, error } = GetMyPageLoad(reloader);

  return (
    <div className="w-full border border-gray-400 rounded-md p-4 flex flex-col h-72 gap-2 overflow-y-auto dark:text-white">
      {loading ? (
        <>
          <h1 className="text-xl font-bold tracking-wide">...</h1>
          <p>...</p>
        </>
      ) : error ? (
        <>
          <h1 className="text-xl font-bold tracking-wide">Unable to load</h1>
        </>
      ) : (
        <>
          <h1 className="text-xl font-bold tracking-wide">
            {!data.intro ? "Introduction" : data.intro}
          </h1>
          <p>{!data.description ? "Brief Details" : data.description}</p>
        </>
      )}
    </div>
  );
};

export default LoadedPage;
