import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import usersService from "../../services/UsersService";
import InputCom from "../Helpers/Inputs/InputCom";
import LoadingSpinner from "../Spinners/LoadingSpinner";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import ReferralTable from "../MyWallet/WalletComponent/ReferralTable";
import TabButton from "../customTabs/TabButton";

const validationSchema = Yup.object().shape({
  ref_email: Yup.string()
    .email("Wrong email format")
    .matches(
      // /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      /^[^0-9][a-zA-Z0-9._%+-]+@[a-zA-Z]+(\.[a-zA-Z]+)+$/,
      "Invalid email format"
    )
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("Email is required"),
  ref_firstname: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(25, "Maximum 25 characters")
    .required("Firstname is required"),
  ref_lastname: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(25, "Maximum 25 characters")
    .required("Lastname is required"),
});

const initialValues = {
  ref_firstname: "",
  ref_lastname: "",
  ref_email: "",
};

function ReferralDisplay() {
  const apiCall = new usersService(); // GET API CALL

  let [refHistoryReload, setRefHistoryReload] = useState(false); // Determines when referral history reloads

  // STATE TO HOLD REFERRAL HISTORY
  let [referralList, setReferralList] = useState({
    loading: true,
    error: false,
    data: [],
  });

  let [error, setError] = useState({
    message: "",
    loading: false,
    status: false,
  }); // for displaying error message on the page

  //function to call referral history API
  const allReferrals = () => {
    setReferralList({
      loading: true,
      error: false,
      data: [],
    });
    apiCall
      .getReferralHx()
      .then((res) => {
        setReferralList((prev) => {
          return { ...prev, loading: false, data: [...res.data.result_list] };
        });
      })
      .catch((error) => {
        setReferralList((prev) => ({ ...prev, loading: false, error: true }));
      });
  };

  //FUNCTION TO SEND REFERRAL MESSAGE
  const sendReferralMsg = (postData) => {
    apiCall
      .sendReferralMsg(postData)
      .then((res) => {
        if (res.data.internal_return < 0) {
          setError({
            message: res.data.status,
            loading: false,
            status: false,
          });
          return;
        } else {
          toast.success(res.data.status | "Message Sent!");
          setError({ message: "", loading: false, status: true });
          setRefHistoryReload((prev) => !prev);
        }
      })
      .catch((error) => {
        setError({
          message: "Opps! an error occured, try again later",
          loading: false,
          status: false,
        });
      });
  };

  //FUNCTION TO HANDLE SUBMIT
  const handleSubmit = (values, helpers) => {
    setError({ message: "", loading: true, status: false });
    sendReferralMsg({...values}); // FUNCTION TO SEND REFERRAL MESSAGE
  };

  const [selectedTab, setSelectedTab] = useState("Send Referral");
  const tabs = [  //STATE FOR SWITCHING BETWEEN TABS
      {
        id: 1,
        title: "Send Referral",
        iconName: "history",
      },
      {
        id: 2,
        title: "Referral List",
        iconName: "history",
      },
  ]

  useEffect(() => {
    allReferrals();
  }, [refHistoryReload]);

  return (
    <>
      <div className='w-full'>
        <div className="sm:flex justify-between items-center mb-6">
          <div className="mb-5 sm:mb-0">
            <h1 className="text-26 font-bold inline-flex gap-3 text-dark-gray dark:text-white items-center">
              <span>Refer a Friend</span>
            </h1>
          </div>
        </div>
      </div>
      <div className="w-full h-full p-4 bg-white dark:bg-dark-white rounded-2xl section-shadow lg:flex lg:px-10 px-4 justify-between">
        <div className="content-tab-items lg:w-[230px] w-full mr-2">
            <div className='overflow-hidden mb-5 lg:mb-0 py-2 lg:py-8'>
                {tabs.map((item) => (
                    <div key={item.id} className='w-full'>
                        <TabButton 
                            key={item.id}
                            item={item.title}
                            iconName={item.iconName}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    </div>
                ))}
            </div>
        </div>
        <div className="w-[1px] bg-[#E3E4FE] dark:bg-[#a7a9b533]  mr-10"></div>
        <div className="flex-1 overflow-y-auto min-h-[520px]">
          <>
            {selectedTab == 'Send Referral' &&
              <div className="referral w-full p-4">
                <h2 className="mb-4 text-slate-900 dark:text-white text-xl lg:text-2xl font-medium">
                  Send Referral
                </h2>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {(props) => (
                    <Form className="referral-info">
                      <div className="block md:mb-6 md:flex gap-10">
                        {/* Firstname */}
                        <div className="field w-full mb-6 md:mb-0">
                          <InputCom
                            fieldClass="px-6"
                            label="Firstname"
                            type="text"
                            name="ref_firstname"
                            placeholder="Firstname"
                            value={props.values.ref_firstname}
                            inputHandler={props.handleChange}
                            blurHandler={props.handleBlur}
                          />
                          {props.errors.ref_firstname &&
                            props.touched.ref_firstname && (
                              <p className="text-sm text-red-500">
                                {props.errors.ref_firstname}
                              </p>
                            )}
                        </div>

                        {/* Lastname */}
                        <div className="field w-full mb-6 md:mb-0">
                          <InputCom
                            fieldClass="px-6"
                            label="Lastname"
                            type="text"
                            name="ref_lastname"
                            placeholder="Lastname"
                            value={props.values.ref_lastname}
                            inputHandler={props.handleChange}
                            blurHandler={props.handleBlur}
                          />
                          {props.errors.ref_lastname &&
                            props.touched.ref_lastname && (
                              <p className="text-sm text-red-500">
                                {props.errors.ref_lastname}
                              </p>
                            )}
                        </div>
                      </div>

                      <div className="field w-full mb-6">
                        <InputCom
                          fieldClass="px-6"
                          label="Email"
                          type="text"
                          name="ref_email"
                          placeholder="Email"
                          value={props.values.ref_email}
                          inputHandler={props.handleChange}
                          blurHandler={props.handleBlur}
                        />
                        {props.errors.ref_email && props.touched.ref_email && (
                          <p className="text-sm text-red-500">
                            {props.errors.ref_email}
                          </p>
                        )}
                      </div>

                      <hr />
                      {error.message != "" && (
                        <p className="text-base text-red-500 py-2">{error.message}</p>
                      )}
                      <div className="referral-btn flex justify-end items-center py-4">
                        {error.loading ? (
                          <LoadingSpinner size="6" color="sky-blue" />
                        ) : (
                          <button
                            type="submit"
                            className="px-2 h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white"
                          >
                            Send Message
                          </button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            }

            {selectedTab == 'Referral List' &&
              <>
                <div className="w-full p-4">
                  <h2 className="mb-2 text-slate-900 dark:text-white text-xl lg:text-2xl font-medium">
                    Referral List
                  </h2>
                  {referralList.loading ? (
                    <LoadingSpinner size="22" color="sky-blue" />
                  ) : (
                    <ReferralTable history={referralList} />
                  )}
                </div>
              </>
            }
          </>
        </div>
      </div>
    </>
  );
}

export default ReferralDisplay;
