import React, { useEffect, useState } from 'react'
import Layout from '../Partials/Layout'
import SearchCom from '../Helpers/SearchCom'
import DataIteration from '../Helpers/DataIteration'
import FamBlogItem from './FamBlogItem'
import CustomBreadcrumb from '../Breadcrumb/CustomBreadcrumb'
import usersService from '../../services/UsersService'
import LoadingSpinner from '../Spinners/LoadingSpinner'

export default function FamBlog() {

  const [blogData, setBlogData] = useState({loading: true, data: []});

  const [filteredBlog, setFilteredBlog] = useState({value: '', data:[]}) // State to hold filter blog

  const handleFilterBlog = ({target}) => {
    let filterWord = target.value
    let filteredData = []
    if(!filterWord){
      filteredData = blogData?.data?.blogdata
    }else{
      filteredData = blogData?.data?.blogdata?.filter(item => item.post_title.toLowerCase().startsWith(filterWord.toLowerCase()))
    }
    setFilteredBlog({value:target.value, data: filteredData})
  }

  const api = new usersService();
  const getFamilyBlog = async () => {
    setBlogData({loading: true, data: []})
    try {
      const res = await api.getFamilyBlogData();
      setBlogData({loading: false, data:res.data});
      setFilteredBlog(prev => ({...prev, data:res.data?.blogdata}))
    } catch (error) {
      setBlogData({loading: false, data: []})
      throw new Error("Error getting mode");
    }
  };

  useEffect(() => {
    getFamilyBlog();
  }, []);

  return (
    <Layout>
      <>
        <div className='mb-4'>
          <CustomBreadcrumb 
            title='Blog' 
            breadcrumb = {
              [
                { link: "/", title: "Home" },
                { link: "/fam-blog", title: "Blogs", active: true},
              ]
            }
          />
        </div>
        <div className={`w-full`}>
          <div className="main-container w-full">
            <div className="filter-section w-full items-center sm:flex justify-between mb-6">
              {/* filter-search */}
              {blogData?.data?.blogdata?.length > 0 &&
              <div className="sm:w-1/2 w-full sm:pr-20 pr-0 mb-5 sm:mb-0">
                <SearchCom 
                  placeholder='Search Blog Items...' 
                  value={filteredBlog.value}
                  handleSearch={handleFilterBlog}
                />
              </div>
              }
            </div>

            <div className="content-section w-full-width">
              {blogData.loading ?
                <div className='flex justify-center items-center bg-white rounded-2xl'>
                  <LoadingSpinner size='10' color='sky-blue' height='h-[20rem]' />
                </div>
                : blogData?.data?.blogdata?.length > 0 && filteredBlog?.data?.length > 0?
                <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-[30px]">
                  <DataIteration
                    datas={filteredBlog?.data}
                    startLength={process.env.REACT_APP_ZERO_STATE}
                    endLength={filteredBlog?.data?.length}
                  >
                    {({ datas }) => (
                      <div key={Math.random()}>
                        <FamBlogItem
                          datas={datas}
                          bg={blogData?.data?.image_url && blogData?.data?.image_url}
                          className=''
                          hidden={false}
                        />
                      </div>
                    )}
                  </DataIteration>
                </div>
                :
                <div className='h-[30rem] flex justify-center items-center bg-white rounded-2xl'>
                  <p>No Blog Found</p>
                </div>
              }
            </div>
          </div>
        </div>
      </>
    </Layout>
  )
}
