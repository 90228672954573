import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userJobList: {loading: true, data: []}
};

export const userSlice = createSlice({
  name: "userJobList",
  initialState,
  reducers: {
    updateUserJobList: (state,action) => {
      state.userJobList = {...action.payload}
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUserJobList } = userSlice.actions;

export default userSlice.reducer;