import { useState } from "react";
import { PriceFormatter } from "../Helpers/PriceFormatter";
import MarketPopUp from "../MarketPlace/PopUp/MarketPopUp";
import { useSelector } from "react-redux";
import PendingJobsPopout from "../jobPopout/PendingJobsPopout";

export default function AvailableJobsCard({
  className,
  datas,
  hidden = false,
  contentDisplay,
  image_server,
  marketPlaceProduct
}) {
  //debugger;
  const [marketPopUp, setMarketPopUp] = useState({ show: false, data: {} });
  const [jobPopout, setJobPopout] = useState({ show: false, data: {} });
  
  const [imageUrl, setImageUrl] = useState("");

  const {
    userDetails: { uid },
  } = useSelector((state) => state?.userDetails); // GETS USER DETAILS


  let thePrice = PriceFormatter(
    datas?.price * 0.01,
    datas?.currency_code,
    datas?.currency
  );

  // useEffect(() => {
  //   const imagePath = require(`../../assets/images/${datas.thumbnil}`); // Replace with your directory path for local images
  //   setImageUrl(imagePath);
  // }, []);

  const image = localStorage.getItem("session_token")
    ? `${image_server}${localStorage.getItem("session_token")}/job/${
        datas.job_uid
      }`
    : "";

  return (
    <>
      {contentDisplay == "grid" ? (
        <div
          className={`card-style-two w-full p-[10px] bg-white dark:bg-dark-white   rounded-2xl section-shadow ${
            className || ""
          }`}
        >
          <div
            onClick={() => {
              datas.market_uid != uid ? setMarketPopUp({ show: true, data: datas }) :setJobPopout({ show: true, data: datas });
            }}
            className="flex flex-col gap-2 justify-between w-full h-full"
          >
            <div className='w-full flex items-center gap-4'>
              <div className='min-w-[60px] min-h-[60px] max-w-[60px] max-h-[60px] rounded-full overflow-hidden'>
                <img className='w-full h-full object-cover' src={image} alt='Job Image' />
              </div>
              <div className='w-full'>
                <h1 className="font-bold text-xl tracking-wide line-clamp-1 text-dark-gray dark:text-white capitalize">
                  {datas.title}
                </h1>

                <div className="card-two-info flex justify-between items-center">
                  <div className="owned-by flex space-x-2 items-center">
                    <div>
                      <p className="text-thin-light-gray text-sm leading-3">
                        Added
                      </p>
                      <p className="text-base   text-dark-gray dark:text-white">
                        {new Date(datas.offer_added).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                  <div className="w-[1px] bg-light-purple dark:bg-dark-light-purple h-7"></div>
                  <div className="created-by flex space-x-2 items-center flex-row-reverse">
                    <div>
                      <p className="text-thin-light-gray text-sm leading-3 text-right">
                        Expires
                      </p>
                      <p className="text-base text-dark-gray dark:text-white text-right">
                        {new Date(datas.expire).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="thumbnail-area w-full">
              <div
                className="w-full p-1 h-[150px] rounded-xl overflow-y-auto bg-center bg-cover bg-no-repeat"
                // style={{
                //   backgroundImage: `url('${image}')`,
                // }}
              >
                <div className="flex flex-col min-h-full bg-slate-100 p-2 rounded-md">
                  <p>{datas.description}</p>
                </div>
              </div>
            </div>
            <div className="details-area">
              {/* <div className="product-two-options flex justify-between mb-5 relative"></div> */}
              <div className="flex justify-between">
                <div className="flex items-center space-x-2">
                  <div className='w-full'>
                    <p className="w-full flex gap-1 items-center font-bold text-xl tracking-wide text-dark-gray dark:text-white">
                      {/* {thePrice} | {datas.timeline_days} day(s) */}
                      {datas?.offer_depend_uid && <i className="fa-solid fa-lock p-1 text-red-500 text-[12px]"></i>}
                      {thePrice}
                    </p>
                    <p className="text-sm text-lighter-gray">
                      ( {datas.offer_code}) |
                      <span className="italic ml-1">
                        {datas.timeline_days} day(s)
                      </span>
                    </p>
                  </div>
                </div>
                <div>
                  {datas.market_uid != uid ?
                    <button
                    type="button"
                      className="px-4 py-2.5 text-white text-sm bg-pink rounded-full tracking-wide"
                      onClick={() => {
                        setMarketPopUp({ show: true, data: datas });
                      }}
                    >
                      View
                    </button>
                    :
                    <button
                    type="button"
                    className="px-4 py-2.5 text-white text-sm bg-yellow-500 rounded-full tracking-wide"
                    onClick={() => {
                      setJobPopout({ show: true, data: datas });
                    }}
                    >
                      Manage
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card-style-two w-full px-4 py-[0.4rem] my-2 flex items-center gap-4 bg-white dark:bg-dark-white rounded-2xl section-shadow">
          <div className="flex gap-5 items-center w-full">
            <div className="w-full h-[60px] rounded-full overflow-hidden flex justify-center items-center flex-[0.1] min-w-[60px] max-w-[60px]">
              <img src={image} alt="data" className="w-full h-full" />
            </div>
            <div className="flex flex-col flex-[0.9]">
              <h1
                onClick={() => {
                  datas.market_uid != uid ? setMarketPopUp({ show: true, data: datas }) :setJobPopout({ show: true, data: datas })
                }}
                className="font-bold text-xl tracking-wide line-clamp-1 text-dark-gray dark:text-white capitalize"
              >
                {datas?.title}
              </h1>

              <div
                onClick={() => {
                  datas.market_uid != uid ? setMarketPopUp({ show: true, data: datas }) :setJobPopout({ show: true, data: datas })
                }}
                className="my-2"
              >
                <p className="text-dark-gray dark:text-white">
                  {datas?.description}
                </p>
              </div>

              <div className="block sm:flex flex-wrap gap-4">
                <p className="text-sm text-thin-light-gray flex flext-start gap-1 items-center">
                  {datas?.offer_depend_uid && <i className="fa-solid fa-lock p-1 text-red-500 text-[12px]"></i>}
                  Price: <span className="text-purple">{thePrice}</span>
                </p>
                <p className="text-sm text-thin-light-gray">
                  Duration:{" "}
                  <span className="text-purple italic">
                    {" "}
                    {datas?.timeline_days} day(s)
                  </span>
                </p>
                <p className="text-sm text-thin-light-gray">
                  Code:{" "}
                  <span className="text-purple"> {datas?.offer_code}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="">
            {datas.market_uid != uid ?
            <button
            type="button"
              className="px-4 py-2.5 text-white text-sm bg-pink rounded-full tracking-wide"
              onClick={() => {
                setMarketPopUp({ show: true, data: datas });
              }}
            >
              View
            </button>
            :
            <button
            type="button"
            className="px-4 py-2.5 text-white text-sm bg-yellow-500 rounded-full tracking-wide"
            onClick={() => {
              setJobPopout({ show: true, data: datas });
            }}
            >
              Manage
            </button>
            }
          </div>
        </div>
      )}
      {marketPopUp.show && (
        <MarketPopUp
          details={datas}
          onClose={() => {
            setMarketPopUp({ show: false, data: {} });
          }}
          situation={marketPopUp.show}
          marketPlaceProduct={marketPlaceProduct}
        />
      )}

      {/* Active Job Popout */}
      {jobPopout.show && (
        <PendingJobsPopout
          details={datas}
          onClose={() => {
            setJobPopout({ show: false, data: {} });
          }}
          situation={jobPopout.show}
        />
      )}
      {/* End of Active Job Popout */}
    </>
  );
}
