import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  commonHeadBanner: {}
};

export const commonHeadBannerSlice = createSlice({
  name: "commonHeadBanner",
  initialState,
  reducers: {
    commonHeadBanner: (state,action) => {
      state.commonHeadBanner = {...action.payload}
    },
  },
});

// Action creators are generated for each case reducer function
export const { commonHeadBanner } = commonHeadBannerSlice.actions;

export default commonHeadBannerSlice.reducer;