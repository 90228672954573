import React from 'react'

function CurrentJobAction() {
  return (
    <div className='job-action  dark:bg-black'>
      <table className="w-full my-3 py-1  text-sm text-left text-gray-500">
          <tbody>
          <tr>
              <td>
                  <div className="flex space-x-2 items-center w-full task_action_panel">

                          <div>
                              Waiting for the completion message from the client before you can approve.
                          </div>
                  </div>
              </td>
          </tr>
          </tbody>
      </table>
    </div>
  )
}

export default CurrentJobAction