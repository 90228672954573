import React, { useContext,useState, useEffect } from "react";
import usersService from "../services/UsersService";

import MyPastDueTasks from "../components/MyActiveJobs/MyPastDueTasks";
import { useSelector } from "react-redux";

export default function MyPastDueTasksPage() {
    let {commonHeadBanner} = useSelector(state => state.commonHeadBanner)
    const [MyJobList, setMyJobList] = useState({loading:true, data:[]});
    const api = new usersService();

    const getMyJobList = async () => {
        try {
            const res = await api.getMyPastDueJobList();
            console.log("DATA", res.data);
            setMyJobList({loading:false, data:res.data})
        } catch (error) {
            setMyJobList({loading:false, data:[]})
            console.log("Error getting mode");
        }
    };
    useEffect(() => {
        getMyJobList();
    }, []);

    // debugger;
    return (
        <>
            <MyPastDueTasks
                MyJobList={MyJobList?.data}
                loading={MyJobList?.loading}
                commonHeadData={commonHeadBanner?.result_list}
            />
        </>
    );
}
