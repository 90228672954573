import InputCom from "../Helpers/Inputs/InputCom/index";
import UpdateButton from "./UpdateButton";

const YourPageForm = ({ values, onChange, onSubmit, loading, msg }) => (
  <div className="ml-16 my-2 flex flex-col gap-3">
    <div className="field w-full">
      <InputCom
        fieldClass="px-4"
        parentClass="flex items-center gap-1 justify-between"
        labelClass="flex-[0.2] mb-0 font-semibold"
        labalClass="dark:text-white"
        inputClass="flex-[0.8]"
        inputBg="bg-slate-100"
        label="Introduction: "
        type="text"
        name="intro"
        value={values.intro}
        inputHandler={onChange}
      />
    </div>

    <div className="field w-full mb-6 flex gap-1 justify-between">
      <label className="text-[#181c32] dark:text-white text-[13.975px] font-semibold flex flex-[0.2] mt-2.5">
        Brief Details:
      </label>
      <textarea
        style={{ resize: "none" }}
        className="text-base px-4 py-2 rounded-[36px] min-h-[100px] text-dark-gray dark:text-white w-full bg-slate-100 focus:ring-0 focus:outline-none flex-[0.8]"
        name="description"
        cols="30"
        rows="2"
        value={values.description}
        onChange={onChange}
      />
    </div>
    <div className="w-full flex justify-end mb-2">
      <UpdateButton onClick={onSubmit} loading={loading} msg={msg} />
    </div>
    <hr />
  </div>
);

export default YourPageForm;
