import React, { useEffect, useState } from "react";
import SwitchCom from "../../Helpers/SwitchCom";

import LoadingSpinner from "../../Spinners/LoadingSpinner";
import usersService from "../../../services/UsersService";
import localImgLoad from "../../../lib/localImgLoad";
import { toast } from "react-toastify";

import defaultImage from '../../../assets/images/banner-job-due.jpg'


export default function NotificationSettingTab() {
  const api = new usersService();

  const [accSettings, setAccSettings] = useState({loading: true, data: []}) // STATE TO HOLD ACCOUNT SETTINGS

  let [notificationChange, setNotificationChange] = useState({loading: false, uid: ''})

  // const [updateNotification, setUpdateNotification] = useState(false);
  // const [uploadProduct, setUploadProduct] = useState(true);
  // const [saleProduct, setSaleProduct] = useState(true);
  // const [getProduct, setGetProduct] = useState(false);
  // const [authLevel, setAuthLevel] = useState(true);

  const NotificationInterfaceChange = (item) => {
    setAccSettings(prev => {
      let newAccSettings = prev.data.map(data => {
        if(data.uid == item.uid){
          let newPrefValue = data.pref_value == null || data.pref_value == '0' ? '100' : '0'
          return {...data, pref_value: newPrefValue}
        }else{
          return data
        }
      })
      return {...prev, data: newAccSettings}
    })
  }

  let handleNotificationChange = (item) => { // FUNCTION TO SET ACCOUNT SETTING
    setNotificationChange({loading: true, uid: item.uid})
    let reqData = { // API PAYLOADS
      pref_id: item.pref_id,
      status: item.pref_value == null || item.pref_value == 0 ? 100 : 0
    }
    api.setAccSettings(reqData).then(res => {
      if(res.status != 200 || res.data.internal_return < 0){
        toast.error("unable to complete");
      }
      toast.success("successful");
      NotificationInterfaceChange(item) // CHANGES NOTIFICATION UI INTERFACE
    }).catch(errer => {
      toast.error("unable to complete");
    }).finally(()=>{
      setNotificationChange({loading: false, uid: {}})
      // setTimeout(()=>{setNotificationChange({loading: false, uid: {}})},2000)
    })
  }

  useEffect(()=>{
    api.getAccSettings().then(res => {
      setAccSettings({loading: false, data: res.data?.result_list || []})
    }).catch(errer => {
      setAccSettings({loading: false, data: []})
    })
  },[])

  return (
    <div className="notification-setting-tab w-full">
      <div className="notification-settings-wrapper w-full">
        <ul className="notification-settings-items w-full min-h-[300px]">
        {accSettings.loading ?
            <LoadingSpinner size='8' color='sky-blue' height='h-full'/>
          :
            accSettings.data.length ?
            accSettings.data.map(item =>{
            let image = item.banner && localImgLoad(`images/settings/${item.banner.toLowerCase()}`)
            return(
            <li key={item.uid} className="notification-settings-item sm:flex justify-between items-center mb-3 pb-3 border-b border-light-purple dark:border-[#5356fb29] ">
              <div className="sm:flex sm:space-x-4 items-center">
                <div className="w-10 h-10">
                  <img className="w-full h-full" src={image ? image: defaultImage} alt='account-settings' />
                </div>
                <div>
                  <p className="text-xl tracking-wide text-dark-gray dark:text-white font-bold">
                    {item.title}
                  </p>
                  <p className="text-base tracking-wide text-thin-light-gray">
                    {item.description}
                  </p>
                </div>
              </div>
              <div className="mt-5 sm:mt-0">
              {notificationChange.loading && notificationChange.uid == item.uid ?
                <LoadingSpinner size='8' color='sky-blue' />
                :
                <SwitchCom
                  value={item.pref_value == null || item.pref_value == '0' ? 0 : 100}
                  handler={ notificationChange.loading ? ()=>{} : () => handleNotificationChange(item)}
                  // value={updateNotification}
                  // handler={() => setUpdateNotification(!updateNotification)}
                />
              }
              </div>
            </li>
            )}
            )
          :
            <h1 className='font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap'>No Cards Found</h1>
            }
        </ul>
      </div>
    </div>
  );
}
