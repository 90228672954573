import React, { useEffect, useMemo, useState } from "react";
import GridView from "../../assets/images/grid-view.svg";
import ListView from "../../assets/images/list-view.png";
import AvailableJobsCard from "../Cards/AvailableJobsCard";
import DataIteration from "../Helpers/DataIteration";
import SelectBox from "../Helpers/SelectBox";
import NewPaginatedList from "../Pagination/NewPaginatedList";
import InfiniteScroll from "../infiniteScroll/InfiniteScroll";

export default function MainSection({
  className,
  marketPlaceProduct,
  image_server,
  categories,
}) {
  // Creating All cart..
  let marketCategories = useMemo(
    () => ({ All: "All Categories", ...categories }),
    [categories]
  );
  const [tab, setTab] = useState(Object.keys(marketCategories)[0]);

  let [contentDisplay, setContentDisplay] = useState("grid"); // STATE TO HOLD LIST VIEW STYLE

  // Convert to array in order to map
  const mappedArray = Object.entries(marketCategories).map(([key, value]) => {
    return { key, value };
  });

  const [products, setProducts] = useState([]);
  const tabHandler = (value) => {
    setTab(value);
  };

  // Handles the category selection on mobile view
  const handleSetCategory = (value) => {
    for (let i in marketCategories) {
      if (marketCategories[i] == value) {
        setTab(i);
      }
    }
  };

  useEffect(() => {
    if (tab === "All") {
      setProducts(marketPlaceProduct);
    } else {
      const filteredProducts = marketPlaceProduct.filter((product) =>
        product.category.includes(tab)
      );
      setProducts(filteredProducts);
    }
  }, [tab, marketPlaceProduct, categories, marketCategories]);

  return (
    <div className={`market-place-section w-full ${className || ""}`}>
      <div className="market-place-wrapper w-full">
        <div className="filter-navigate-area flex justify-between items-center mb-8">
          <div className="tab-item w-full flex items-center">
            <div className="hidden lg:flex md:space-x-8 space-x-2">
              {mappedArray.map(({ key, value }) => (
                <span
                  key={key}
                  onClick={() => tabHandler(key)}
                  className={`md:text-[18px] text-md  text-dark-gray dark:text-white hover:text-pink border-b hover:border-pink font-medium cursor-pointer ${
                    tab === key
                      ? "text-pink border-pink"
                      : " border-transparent"
                  }`}
                >
                  {value}
                </span>
              ))}
            </div>
            {/* market categories on screen smaller than large screen */}
            <div className="w-[80%] lg:hidden">
              <SelectBox
                action={handleSetCategory}
                datas={Object.values(marketCategories)}
                className="Update-table-dropdown"
                contentBodyClasses="w-auto min-w-max"
                position="left"
              />
            </div>
          </div>
          {/* contentDisplay toggler */}
          <div className="p-2 w-[35px] h-[35px] bg-white dark:bg-slate-200 rounded-lg">
            <img
              title={contentDisplay == "grid" ? "list view" : "grid view"}
              onClick={() =>
                setContentDisplay((prev) => (prev == "grid" ? "list" : "grid"))
              }
              src={contentDisplay == "grid" ? ListView : GridView}
              className="w-full h-full cursor-pointer"
              alt="view"
            />
          </div>
          {/* end of contentDisplay toggler */}
        </div>

        {/* OLD MARKET JOB LISTING */}
        {/* <div className="filter-navigate-content w-full min-h-screen">
          <div
            className={
              contentDisplay == "grid"
                ? "grid lg:grid-cols-3 sm:grid-cols-2 gap-[30px]"
                : "w-full"
            }
          >
            <DataIteration
              datas={products}
              startLength={process.env.REACT_APP_ZERO_STATE}
              endLength={products?.length}
            >
              {({ datas, index }) => (
                <div key={datas.job_uid+index}>
                  <AvailableJobsCard
                    contentDisplay={contentDisplay}
                    image_server={image_server}
                    datas={datas}
                  />
                </div>
              )}
            </DataIteration>
          </div>
        </div> */}
        {/* END OF OLD MARKET JOB LISTING */}

        {products?.length ?
        <NewPaginatedList
            data={products}
            itemsPerPage={9}
            filterItem=''
            tableTitle='' 
        >
          {
            ({data})=>(
              <div className="filter-navigate-content w-full min-h-[600px]">
                <div
                  className={
                    contentDisplay == "grid"
                      ? "grid lg:grid-cols-3 sm:grid-cols-2 gap-[30px]"
                      : "w-full"
                  }
                >
                {
                  data.map((datum, index) => (
                    <div key={datum.job_uid+index}>
                      <AvailableJobsCard
                        contentDisplay={contentDisplay}
                        image_server={image_server}
                        datas={datum}
                        marketPlaceProduct={marketPlaceProduct}
                      />
                    </div>
                  ))
                }
                </div>
              </div>
            )
          }
        </NewPaginatedList>
        // <InfiniteScroll
        // allData={products}
        // addItemBy={3}
        // intialItemsToShow={9}
        // >
        //   {
        //     ({dataToShow})=>(
        //       <div className="filter-navigate-content w-full min-h-[600px]">
        //         <div
        //           className={
        //             contentDisplay == "grid"
        //               ? "grid lg:grid-cols-3 sm:grid-cols-2 gap-[30px]"
        //               : "w-full"
        //           }
        //         >
        //         {
        //           dataToShow.map((datum, index) => (
        //             <div key={datum.job_uid+index}>
        //               <AvailableJobsCard
        //                 contentDisplay={contentDisplay}
        //                 image_server={image_server}
        //                 datas={datum}
        //               />
        //             </div>
        //           ))
        //         }
        //         </div>
        //       </div>
        //     )
        //   }
        // </InfiniteScroll>
        :
        <div className="w-full h-[40rem] bg-white dark:bg-dark-white flex justify-center items-center">
          No Jobs Found!
        </div>
        }
      </div>
    </div>
  );
}
