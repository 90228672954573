import React from 'react'
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../../Spinners/LoadingSpinner";
import JobFieldInput from './JobFieldInput';



const validationSchema = Yup.object().shape({
    family: Yup.string().required("This is required "),
});


let initialValues = {
    family: "",
};

export default function AssignToFamily({
    jobFieldHandler,
    familyList,
    loader
}) {
  return (
    <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={jobFieldHandler}
  >
    {(props) => {
      return (
        <Form className="w-full h-auto md:h-full flex flex-col justify-between">
          <div className='w-full'>
              <div className='hidden w-full'>
                <JobFieldInput
                  select={true}
                  inputName="family"
                  value={props?.values.family}
                  data={familyList}
                  optionText="Select Family"
                  parentClass="w-full flex flex-col gap-4"
                />
                <p className="h-4 text-[13px] font-light italic text-red-600 tracking-wide">
                  {(props.errors.family && props.touched.family) && (
                    <span>{props.errors.family}</span>
                  )}
                </p>
              </div>
          </div>
          <div className="mt-3 mb-1 flex justify-end items-center">
            <button
              className={`uppercase px-4 h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white`}
              type="submit"
              name='family'
            >
              {loader?.jobFields ? 
                <LoadingSpinner size={5} /> 
                :
                'Assign to family'
              }
            </button>
          </div>
        </Form>
      );
    }}
  </Formik>
  )
}
