import React, { lazy, Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ActiveJobs from "../components/MyActiveJobs/ActiveJobs";
// import ActiveJobsMedia from "../components/MyActiveJobs/ActiveJobsMedia";
import usersService from "../services/UsersService";
import { useSelector } from "react-redux";
import LoadingSpinner from "../components/Spinners/LoadingSpinner";

const ActiveJobsMedia = lazy(() => import("../components/MyActiveJobs/ActiveJobsMedia"));

/**
 * This code defines a React functional component called `ManageActiveJobs`.
 * It fetches a list of active job messages and renders the `ActiveJobs` component with the necessary props.
 */
function ManageActiveJobs() {
  const { chatMessageList } = useSelector(
    (state) => state.tableReload
  );

  const ApiCall = new usersService();

  const navigate = useNavigate();
  const { state } = useLocation();

  const [details, setDetails] = useState({});

  const [activeJobMesList, setActiveJobMesList] = useState({
    loading: true,
    error: false,
    data: [],
  });

  const [activeJobMesListReload, setActiveJobMesListReload] = useState(false);

  /**
   * Fetches the active job message list.
   */
  const getActiveJobMesList = () => {
    setActiveJobMesList({ loading: true, error: false, data: [] });
    const contract = { contract: state.contract };
    ApiCall.activeJobMesList(contract)
      .then((res) => {
        if (res.status !== 200 || res.data.internal_return < 0) {
          setActiveJobMesList({ loading: false, error: false, data: [] });
          return;
        }
        setActiveJobMesList({
          loading: false,
          error: false,
          data: res.data.result_list,
          image: res.data.session_image_server,
        });
      })
      .catch((error) => {
        setActiveJobMesList({ loading: false, error: true, data: [] });
      });
  };

  useEffect(() => {
    if (!state) {
      navigate("/", { replace: true });
      return;
    }
    setDetails(state);
    getActiveJobMesList();
  }, [activeJobMesListReload, chatMessageList]);

  return (
    <>
    {details.job_type == 'MEDIA' ?
    <Suspense fallback={<Fallback />}>
      <ActiveJobsMedia
        details={state}
        activeJobMesList={activeJobMesList}
        reloadActiveJobList={setActiveJobMesListReload}
      />
    </Suspense>
    : details.job_type == 'TASK' ?
    <ActiveJobs 
      details={state}
      activeJobMesList={activeJobMesList}
      reloadActiveJobList={setActiveJobMesListReload}
    />
    :
    null
    }
    </>
  );
}

export default ManageActiveJobs;

let Fallback = () => {
  return (
    <div className="w-full flex justify-center items-center">
      <LoadingSpinner size='20' color='skyblue' height='h-screen' />
    </div>
  )
}
