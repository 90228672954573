import { useNavigate, Link } from "react-router-dom";
import AuthLayout from "../AuthLayout";
import WrenchBoard from "../../../assets/images/wrenchboard-logo-text.png";

export default function VerifyYou() {
  const navigate = useNavigate();
  return (
    <>
      <AuthLayout slogan="Welcome to WrenchBoard">
        <div className="w-full">
          <div className="mb-12">
            <Link to="#">
              <img
                src={WrenchBoard}
                alt="wrenchboard"
                className="h-10 mx-auto"
              />
            </Link>
          </div>
          <div className="content-wrapper login shadow-md w-full lg:max-w-[500px] mx-auto flex justify-center items-center dark:bg-dark-white 2xl:w-[828px] rounded-[0.475rem] sm:p-7 p-5">
            <div className="w-full">
              <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                <h1 className="text-[#181c32] font-semibold dark:text-white mb-3 leading-[27.3px] text-[22.75px]">
                  Let's verify your email now
                </h1>
                <span className="text-gray-400 font-medium text-[16.25px] leading-[24.375px]">
                  Check your email.
                </span>
              </div>
              <div className="input-area">
                <div className="mb-5">
                  <p className="text-[14px] leading-[19px] text-center text-[#181c32]">
                    <b>Verify Email.</b> Help us secure your WrenchBoard account
                    by verifying your email registration address. Verification
                    will let you access all of WrenchBoard's features.
                  </p>
                </div>
                <div className="mb-5">
                  <p className="text-[14px] leading-[19px] text-center text-[#181c32]">
                    If you do not receive the confirmation message within a few
                    minutes of signing up, please check your Junk E-mail folder
                    just in case the confirmation email got delivered there
                    instead of your inbox. If so, select the confirmation
                    message and click Not Junk, which will allow future messages
                    to get through.
                  </p>
                </div>
              </div>
              <div className="signin-area flex justify-center mb-3.5">
                <button
                  onClick={() => navigate("/")}
                  type="button"
                  className={`rounded-[0.475rem] mb-6 text-[15px] font-semibold text-[#009ef7] hover:text-white flex justify-center bg-[#f1faff] hover:bg-[#009ef7] transition-all duration-300 items-center py-[0.8875rem] px-[1.81rem]`}
                >
                  <span>Return Home</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
