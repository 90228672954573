import React, { useState, useEffect } from "react";
import Resources from "../components/Resources";
import usersService from "../services/UsersService";
import { useLocation } from "react-router-dom";
export default function ResourcePage() {
  const {state, pathname} = useLocation() // CHECKS IF THERE IS AN ACTIVE TAB WITH LINK BACK TO RESOURCES
  const [MyResourceData, setMyResourceData] = useState([]);
  const api = new usersService();
  const getMyResourceData = async () => {
    try {
      const res = await api.getResourceList();
      setMyResourceData(res.data);
    } catch (error) {
      throw new Error("Error getting mode");
    }
  };
  useEffect(() => {
    getMyResourceData();
  }, []);

  return <Resources MyResourceData={MyResourceData} activeTab={state ? state.tab : ''} />;
}