import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import usersService from "../../../services/UsersService";
import Icons from "../../Helpers/Icons";
import InputCom from "../../Helpers/Inputs/InputCom";
import LoadingSpinner from "../../Spinners/LoadingSpinner";

import cover from "../../../assets/images/profile-info-cover.png";
import profileImage from "../../../assets/images/profile.jpg";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import { apiConst } from "../../../lib/apiConst";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .matches(
      // /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      /^[^0-9][a-zA-Z0-9._%+-]+@[a-zA-Z]+(\.[a-zA-Z]+)+$/,
      "Invalid email format"
    )
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("Email is required"),
  firstname: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(25, "Maximum 25 characters")
    .required("Firstname is required"),
  lastname: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(25, "Maximum 25 characters")
    .required("Lastname is required"),
  city: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(25, "Maximum 25 characters")
    .required("City is required"),
  state: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(25, "Maximum 25 characters")
    .required("State is required"),
});

export default function PersonalInfoTab({
  datas,
  frstNmeHndlr,
  lstNmeHndlr,
  dscrphn,
}) {
  let { userDetails } = useSelector((state) => state.userDetails);

  const image = localStorage.getItem("session_token")
    ? `${userDetails.session_image_server}${localStorage.getItem(
        "session_token"
      )}/profile/${userDetails.uid}`
    : "";

  const apiCall = new usersService();

  let navigate = useNavigate();

  const initialValues = {
    firstname: userDetails?.firstname,
    lastname: userDetails?.lastname,
    state: userDetails?.state,
    city: userDetails?.city,
    email: userDetails?.email,
    profile: userDetails?.profile_pic,
    pref_email: 0,
    pref_phone: 0,
    accept_promo: false,
    online_name: userDetails?.online_name,
  };

  let [profile, setProfile] = useState({
    // state for requesting from load profile API
    loading: true,
    status: false,
  });

  let [requestStatus, setRequestState] = useState({
    // state for requesting from update api
    message: "",
    loading: false,
    status: false,
  });

  const [profileImg, setProfileImg] = useState(
    userDetails?.session_image_server ? image : profileImage
  );

  let [uploadStatus, setUploadStatus] = useState({
    loading: false,
    status: false,
    message: "",
  }); // HOLDS STATE FOR UPLOAD PROFILE PICTURE STATUS

  const [coverImg, setCoverImg] = useState(cover);

  // profile img
  const profileImgInput = useRef(null);
  const browseProfileImg = () => {
    profileImgInput.current.click();
  };

  const profileImgChangHandler = (e) => {
    setUploadStatus({ loading: false, status: false, message: "" });
    const acceptedFormat = ["jpeg", "jpg", "png", "bmp", "gif"]; // ARRAY OF SUPPORTED FORMATS
    const uploadedFile = e.target.files[0]; //UPLOADED FILE

    const fileFormat = uploadedFile?.type?.split("/")[1]?.toLowerCase();
    const MAX_FILE_SIZE = 5 * 1048576; // 5MB

    if (!acceptedFormat.includes(fileFormat)) {
      //CHECKING FOR CORRECT UPLOAD FORMAT
      const msg = `Please select ${acceptedFormat
        .slice(0, -1)
        .join(", ")} or ${acceptedFormat.slice(-1)}`;
      setUploadStatus({ loading: false, status: false, message: msg });
      return setTimeout(() => {
        profileImgInput.current.value = ""; // clear the input
        setUploadStatus({ loading: false, status: false, message: "" });
      }, 5000);
    }

    if (uploadedFile.size > MAX_FILE_SIZE) {
      // CHECKING FOR CORRECT FILE SIZE
      setUploadStatus({
        loading: false,
        status: false,
        message: "File must not exceed 5MB",
      });
      return setTimeout(() => {
        profileImgInput.current.value = ""; // clear the input
        setUploadStatus({ loading: false, status: false, message: "" });
      }, 5000);
    }

    if (e.target.value !== "") {
      const imgReader = new FileReader();
      imgReader.onload = (event) => {
        let base64Img = imgReader.result.split(",")[1];
        let reqData = {
          // PAYLOAD FOR API CALL
          file_name: uploadedFile?.name,
          file_size: uploadedFile?.size,
          file_type: uploadedFile?.type?.split("/")[0]?.toLowerCase(),
          file_data: base64Img,
          msg_type: "FILE",
          action: apiConst.WRENCHBOARD_PICTURE_PROFILE,
        };

        setUploadStatus({
          loading: true,
          status: false,
          message: "Loading...",
        });
        apiCall
          .sendFiles(reqData)
          .then((res) => {
            if (res.status != 200 || res.data.internal_return < 0) {
              return setUploadStatus({
                loading: false,
                status: false,
                message: "Something went wrong, try again",
              });
            }
            setUploadStatus({
              loading: false,
              status: true,
              message: "Uploaded successfully",
            });
            setProfileImg(event.target.result);
          })
          .catch((error) => {
            setUploadStatus({
              loading: false,
              status: false,
              message: "Network error, try again",
            });
          })
          .finally(() => {
            setTimeout(() => {
              setUploadStatus({ loading: false, status: false, message: "" });
            }, 5000);
          });
      };
      imgReader.readAsDataURL(uploadedFile);
    }
  };

  // cover img
  const coverImgInput = useRef(null);
  const browseCoverImg = () => {
    coverImgInput.current.click();
  };
  const coverImgChangHandler = (e) => {
    if (e.target.value !== "") {
      const imgReader = new FileReader();
      imgReader.onload = (event) => {
        setCoverImg(event.target.result);
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleUpdateUser = (values, helpers) => {
    setRequestState({ message: "", loading: true, status: false });

    // there is no profile accommodation on the payload
    delete values?.profile;

    const reqData = {
      ...values,
      pref_email: Number(values.pref_email?.toString()),
      pref_phone: Number(values.pref_phone?.toString()),
    };

    apiCall
      .updateProfile(reqData)
      .then((res) => {
        // API CALL TO UPDATE USER DETAILS
        if (res.data.internal_return < 0) {
          setRequestState({
            message: "Profile Was unable to update",
            loading: false,
            status: false,
          });
          return;
        }
        setRequestState({
          message: "Profile update successfully",
          loading: false,
          status: true,
        });
        // toast.success("Update Successful");
        setTimeout(() => {
          navigate("/", { replace: true });
          window.location.reload(true);
        }, 1000);
      })
      .catch((error) => {
        setRequestState({
          message: "Opps! an error occurred. Try Agian",
          loading: false,
          status: false,
        });
      });
  };

  useEffect(() => {
    setProfile({ loading: false, status: true });
  }, []);

  return profile.loading ? (
    <div className="personal-info-tab w-full flex flex-col justify-between">
      <div className="p-3">
        <LoadingSpinner size="32" color="sky-blue" />
      </div>
    </div>
  ) : (
    <div className="personal-info-tab w-full flex flex-col justify-between">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdateUser}
      >
        {(props) => {
          return (
            <Form>
              <div className="flex flex-col-reverse sm:flex-row">
                <div className="flex-1 sm:mr-10">
                  <div className="fields w-full">
                    {/* inputs starts here */}
                    {/* username */}
                    <div className="field w-full mb-6">
                      <InputCom
                        fieldClass="px-6"
                        label="User Name"
                        type="text"
                        name="username"
                        placeholder="Username"
                        value={userDetails?.username}
                        disable={true}
                      />
                    </div>

                    {/* Email */}
                    <div className="field w-full mb-6">
                      <InputCom
                        fieldClass="px-6"
                        label="Email"
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={props.values.email}
                        inputHandler={props.handleChange}
                        blurHandler={props.handleBlur}
                      />
                      {props.errors.email && props.touched.email && (
                        <p className="text-sm text-red-500">
                          {props.errors.email}
                        </p>
                      )}
                    </div>
                    {/* first name and last name */}
                    <div className="xl:flex xl:space-x-7 mb-6">
                      <div className="field w-full mb-6 xl:mb-0">
                        <InputCom
                          fieldClass="px-6"
                          label="First Name"
                          type="text"
                          name="firstname"
                          placeholder="First Name"
                          value={props.values.firstname}
                          inputHandler={props.handleChange}
                          blurHandler={props.handleBlur}
                        />
                        {props.errors.firstname && props.touched.firstname && (
                          <p className="text-sm text-red-500">
                            {props.errors.firstname}
                          </p>
                        )}
                      </div>
                      <div className="field w-full">
                        <InputCom
                          fieldClass="px-6"
                          label="Last Name"
                          type="text"
                          name="lastname"
                          placeholder="Last Name"
                          value={props.values.lastname}
                          inputHandler={props.handleChange}
                          blurHandler={props.handleBlur}
                        />
                        {props.errors.lastname && props.touched.lastname && (
                          <p className="text-sm text-red-500">
                            {props.errors.lastname}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* Online Name */}
                    <div className="field w-full mb-6">
                      <InputCom
                        fieldClass="px-6"
                        label="Online Name (optional)"
                        type="text"
                        name="online_name"
                        placeholder=""
                        value={props.values.online_name}
                        inputHandler={props.handleChange}
                        blurHandler={props.handleBlur}
                      />
                      {props.errors.online_name &&
                        props.touched.online_name && (
                          <p className="text-sm text-red-500">
                            {props.errors.online_name}
                          </p>
                        )}
                    </div>

                    {/* Country */}
                    <div className="field w-full mb-6">
                      <InputCom
                        fieldClass="px-6"
                        label="Country"
                        type="text"
                        name="country"
                        value={userDetails.country}
                        disable={true}
                      />
                    </div>

                    {/* State/Province */}
                    <div className="field w-full mb-6">
                      <InputCom
                        fieldClass="px-6"
                        label="State/Province"
                        type="text"
                        name="state"
                        placeholder="State"
                        value={props.values.state}
                        inputHandler={props.handleChange}
                        blurHandler={props.handleBlur}
                      />
                      {props.errors.state && props.touched.state && (
                        <p className="text-sm text-red-500">
                          {props.errors.state}
                        </p>
                      )}
                    </div>

                    {/* City */}
                    <div className="field w-full mb-6">
                      <InputCom
                        fieldClass="px-6"
                        label="City"
                        type="text"
                        name="city"
                        placeholder="City"
                        value={props.values.city}
                        inputHandler={props.handleChange}
                        blurHandler={props.handleBlur}
                      />
                      {props.errors.city && props.touched.city && (
                        <p className="text-sm text-red-500">
                          {props.errors.city}
                        </p>
                      )}
                    </div>

                    {/* Preferred Communication*/}
                    <div className="field w-full mb-6 md:flex items-center space-x-4">
                      <label className="input-label text-[#181c32] dark:text-white text-base font-semibold">
                        Pref. Communication
                      </label>
                      <div className="check-box">
                        <div
                          role="group"
                          className="flex items-center justify-start"
                        >
                          <div className="check-input flex items-center mr-1">
                            <input
                              className="w-4 h-4 cursor-pointer"
                              type="checkbox"
                              name="pref_email"
                              value={1}
                              onChange={props.handleChange}
                            />
                            <span className="mx-2 text-base text-dark-gray dark:text-white">
                              Email
                            </span>
                          </div>
                          <div className="check-input flex items-center">
                            <input
                              className="w-4 h-4 cursor-pointer"
                              type="checkbox"
                              name="pref_phone"
                              value={1}
                              onChange={props.handleChange}
                            />
                            <span className="mx-2 text-base text-dark-gray dark:text-white">
                              Phone
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Allow Promotions */}
                    <div className="field w-full mb-6 flex items-center space-x-4">
                      <label
                        htmlFor="accept_promo"
                        className="input-label text-[#181c32] dark:text-white text-base font-semibold"
                      >
                        Allow Promotions
                      </label>
                      <div>
                        <input
                          type="checkbox"
                          id="accept_promo"
                          name="accept_promo"
                          value={props.values.accept_promo}
                          checked={props.values.accept_promo}
                          onChange={props.handleChange}
                          className="hidden"
                        />
                        <label
                          htmlFor="accept_promo"
                          className={`cursor-pointer flex items-center h-6 w-8 mr-1 p-1 ${
                            props.values.accept_promo
                              ? "bg-sky-blue flex justify-end items-center"
                              : "bg-slate-200"
                          } rounded-full transition duration-200`}
                        >
                          <div className="w-4 h-full bg-white rounded-full"></div>
                        </label>
                      </div>
                    </div>
                    {/* inputs ends here */}
                  </div>
                </div>
                {/* {process.env.REACT_APP_SHOW_UPLOAD_PROFILE_PICTURE != 0 && */}
                <div className="w-[232px] mb-10">
                  <div className="update-profile w-full mb-9">
                    <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white flex items-center mb-2">
                      Update Profile
                      <span className="ml-1">
                        <Icons name="block-question" />
                      </span>
                    </h1>
                    <p className="text-base text-thin-light-gray mb-5">
                      Profile of at least Size
                      <span className="ml-1   text-dark-gray dark:text-white">
                        200x200
                      </span>
                      . Gifs work too.
                      <span className="ml-1   text-dark-gray dark:text-white">
                        Max 5mb
                      </span>
                      .
                    </p>
                    <div className="flex justify-center">
                      <div className="w-[200px] h-[200px] relative">
                        <img
                          src={profileImg}
                          alt="profile"
                          className="sm:w-[198px] sm:h-[198px] w-full h-full rounded-full overflow-hidden object-cover object-center"
                        />
                        <input
                          ref={profileImgInput}
                          onChange={(e) => profileImgChangHandler(e)}
                          type="file"
                          className="hidden"
                        />
                        <div
                          onClick={browseProfileImg}
                          className="w-[32px] h-[32px] absolute bottom-4 right-4  hover:bg-pink bg-dark-gray rounded-full cursor-pointer"
                        >
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                              fill="white"
                            />
                            <path
                              d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    {uploadStatus.message && !uploadStatus.loading && (
                      <p
                        className={`text-center ${
                          uploadStatus.status
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {uploadStatus.message}
                      </p>
                    )}
                    {uploadStatus.loading && (
                      <p className="text-center">{uploadStatus.message}</p>
                    )}
                  </div>
                </div>
                {/* } */}
              </div>

              <div className="content-footer w-full">
                {requestStatus.message != "" && (
                  <p
                    className={`text-center text-base ${
                      requestStatus.status ? "text-green-800" : "text-red-600"
                    }`}
                  >
                    {requestStatus.message}
                  </p>
                )}
                <div className="w-full h-[120px] border-t border-light-purple dark:border-[#5356fb29]  flex justify-end items-center">
                  <div className="flex items-center space-x-4 mr-9">
                    {/* <Link
                      to="/"
                      className="text-18 text-light-red tracking-wide "
                    >
                      <span className="border-b dark:border-[#5356fb29]  border-light-red">
                        {" "}
                        Cancel
                      </span>
                    </Link> */}

                    {requestStatus.loading ? (
                      <LoadingSpinner size="8" color="sky-blue" />
                    ) : (
                      <button
                        type="submit"
                        className="w-[152px] h-[46px] flex justify-center items-center btn-gradient text-base rounded-full text-white"
                      >
                        Update Profile
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
