import React from 'react'

import AppleRedirect from '../components/AuthPages/AuthRedirect/AppleRedirect'

function AppleRedirectPage() {
  return (
    <>
      <AppleRedirect />
    </>
  )
}

export default AppleRedirectPage