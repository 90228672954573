import React, {useState, useEffect} from "react";
import { Outlet } from "react-router-dom";
import Layout from "../Partials/Layout";
import usersService from "../../services/UsersService";


export default function FamilyRoutes() {

  const apiCall = new usersService();

  const [loader, setLoader] = useState(false);

  const [familyList, setFamilyList] = useState({});

  const [listReload, setListReload] = useState(false);

  const memberList = async () => {
    setLoader(true);
    try {
      const res = await apiCall.familyListings();
      const { data } = res;
      if (data?.internal_return >= 0 && data?.status === "OK") {
        const { result_list, session_image_server } = data;
        setFamilyList({ result_list, session_image_server });
        sessionStorage.setItem("family_list", JSON.stringify(result_list))
        setLoader(false);
      } else {
        return;
      }
    } catch (error) {
      setLoader(false);
      throw new Error(error);
    }
  }

  useEffect(() => {
    memberList();
  }, [listReload]);

  return (
    <>
      <Outlet context={{loader, setLoader, familyList, setListReload}} />
    </>
  );
}
