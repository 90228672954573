import React from 'react'
import FamilyPending from '../components/FamilyAcc/FamilyPendingOffer'

export default function FamilyPendingOfferPage() {
  return (
    <>
        <FamilyPending />
    </>
  )
}
