import LoadingSpinner from "../Spinners/LoadingSpinner";

const UpdateButton = ({ onClick, loading, msg }) => (
  <button
    type="submit"
    onClick={onClick}
    disabled={loading}
    className="text-lg text-white bg-sky-blue px-4 py-2 hover:opacity-90 rounded-full"
  >
    {loading ? (
      <LoadingSpinner size="6" color="sky-blue" />
    ) : msg !== "" ? (
      msg
    ) : (
      "Update"
    )}
  </button>
);

export default UpdateButton;
