import { Link, useLocation, useNavigate } from "react-router-dom";
import Icons from "../Helpers/Icons";
// import bank1 from "../../assets/images/bank-1.png";
// import bank2 from "../../assets/images/bank-2.png";
// import bank3 from "../../assets/images/bank-3.png";
// import bank4 from "../../assets/images/bank-4.png";
import Accordion from "../Helpers/Accordion";
import { PriceFormatter } from "../Helpers/PriceFormatter";
import localImgLoad from "../../lib/localImgLoad";
import { useSelector } from "react-redux";

export default function WalletHeader(props) {

  const {userDetails: { account_type, wallet_available_status }} = useSelector((state) => state?.userDetails);
  //  debugger;
  //props.myWalletList.result_list
  let { pathname } = useLocation();
  let navigate = useNavigate();

  const onWalletClick = () => {
    if (pathname == "/my-wallet")
      props.setBalanceDropdown.toggle();
    else navigate("/my-wallet", { replace: true });
  }
  // console.log('props.myWalletList', wallet_available_status)
  return (
    <>
      {account_type == 'FULL' ?
        <div className="lg:flex hidden user-balance cursor-pointer lg:w-[152px] w-[150px] h-[48px]  items-center rounded-full relative  bg-sky-blue pr-1.5 pl-4">
          <div
            onClick={() => props.handlerBalance()}
            className="flex items-center lg:justify-between justify-center w-full h-full"
          >
            <span className="lg:block hidden">
              <Icons name="wallet" />
            </span>
            <p className="lg:text-xl text-lg font-bold text-white">Wallet</p>
            <span className="lg:block hidden">
              <Icons name="deep-plus" />
            </span>
          </div>
          <div
            className={`balance-dropdown w-96 z-30 bg-white dark:bg-dark-white   absolute -left-24 rounded-lg cursor-pointer ${
              props.balanceDropdown ? "active" : ""
            }`}
          >
            <div className="heading border-b dark:border-[#5356fb29]  border-light-purple px-7 py-6">
              <h3 className="text-xl font-bold   text-dark-gray dark:text-white">
                Wallet
              </h3>
            </div>
            <div className="content px-7 pb-7">
            {/* wallet_available_status == 'WALLET_AVAILABLE' */}
            {(wallet_available_status == 'WALLET_AVAILABLE' || localStorage.getItem('wallet_available_status')== 'WALLET_AVAILABLE') ?
              <ul>
                {props.myWalletList &&
                  props.myWalletList?.length > 0 &&
                  props.myWalletList.map((value, index) => 
                  {
                    let image = value.code ? `${value.code.toLocaleLowerCase()}.svg` : 'default.png'
                    return(
                      <li
                        key={index}
                        className="content-item py-4 border-b dark:border-[#5356fb29]  border-light-purple hover:border-purple dark:hover:border-purple"
                        onClick={onWalletClick}
                      >
                        <div className="sm:flex justify-between items-center">
                          <div className="account-name flex space-x-4 items-center mb-2 sm:mb-0">
                            <div className="icon w-14 h-14 transition duration-300 ease-in-out rounded-full flex justify-center items-center bg-light-purple dark:bg-dark-light-purple ">
                              <img src={localImgLoad(`images/currency/${image}`)} className="w-14 h-14" alt="" />
                            </div>
                            <div className="name">
                              <p className="text-2xl font-bold text-dark-gray dark:text-white">
                                {value.description}
                              </p>
                            </div>
                          </div>
                          <div>
                            <p className="eth text-xl font-bold text-purple">
                              {PriceFormatter(value.amount * 0.01, value.code)}
                            </p>
                          </div>
                        </div>
                      </li>
                    )
                  }
                  )}

                {/*<li className="content-item py-4 border-b dark:border-[#5356fb29]  border-light-purple hover:border-purple dark:hover:border-purple">*/}
                {/*    <div className="sm:flex justify-between items-center">*/}
                {/*        <div className="account-name flex space-x-4 items-center mb-2 sm:mb-0">*/}
                {/*            <div*/}
                {/*                className="icon w-14 h-14 transition duration-300 ease-in-out rounded-full flex justify-center items-center bg-light-purple dark:bg-dark-light-purple ">*/}
                {/*                <img src={bank1} alt=""/>*/}
                {/*            </div>*/}
                {/*            <div className="name">*/}
                {/*                <p className="text-base text-dark-gray dark:text-white font-medium">*/}
                {/*                    MetaMask*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <p className="eth text-xl font-bold text-purple">*/}
                {/*                75,320 ETH*/}
                {/*            </p>*/}
                {/*            <p className="usd text-base text-thin-light-gray text-right">*/}
                {/*                (773.69 USD)*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</li>*/}
                {/*<li className="content-item py-4 border-b dark:border-[#5356fb29]  border-light-purple hover:border-purple dark:hover:border-purple">*/}
                {/*    <div className="sm:flex justify-between items-center">*/}
                {/*        <div className="account-name flex space-x-4 items-center mb-2 sm:mb-0">*/}
                {/*            <div*/}
                {/*                className="icon w-14 h-14 transition duration-300 ease-in-out rounded-full flex justify-center items-center bg-light-purple dark:bg-dark-light-purple ">*/}
                {/*                <img src={bank2} alt=""/>*/}
                {/*            </div>*/}
                {/*            <div className="name">*/}
                {/*                <p className="text-base text-dark-gray dark:text-white font-medium">*/}
                {/*                    Coinbase Wallet*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <p className="eth text-xl font-bold text-purple">*/}
                {/*                56,124 ETH*/}
                {/*            </p>*/}
                {/*            <p className="usd text-base text-thin-light-gray text-right">*/}
                {/*                (773.69 USD)*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</li>*/}
                {/*<li className="content-item py-4 border-b dark:border-[#5356fb29]  border-light-purple hover:border-purple dark:hover:border-purple">*/}
                {/*    <div className="sm:flex justify-between items-center">*/}
                {/*        <div className="account-name flex space-x-4 items-center mb-2 sm:mb-0">*/}
                {/*            <div*/}
                {/*                className="icon w-14 h-14 transition duration-300 ease-in-out rounded-full flex justify-center items-center bg-light-purple dark:bg-dark-light-purple ">*/}
                {/*                <img src={bank3} alt=""/>*/}
                {/*            </div>*/}
                {/*            <div className="name">*/}
                {/*                <p className="text-base text-dark-gray dark:text-white font-medium">*/}
                {/*                    Bitski*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <p className="eth text-xl font-bold text-purple">*/}
                {/*                99,123 ETH*/}
                {/*            </p>*/}
                {/*            <p className="usd text-base text-thin-light-gray text-right">*/}
                {/*                (773.69 USD)*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</li>*/}
                {/*<li className="content-item py-5">*/}
                {/*    <div className="sm:flex justify-between items-center">*/}
                {/*        <div className="account-name flex space-x-4 items-center mb-2 sm:mb-0">*/}
                {/*            <div*/}
                {/*                className="icon w-14 h-14 transition duration-300 ease-in-out rounded-full flex justify-center items-center bg-light-purple dark:bg-dark-light-purple ">*/}
                {/*                <img src={bank4} alt=""/>*/}
                {/*            </div>*/}
                {/*            <div className="name">*/}
                {/*                <p className="text-base text-dark-gray dark:text-white font-medium">*/}
                {/*                    WalletConnect*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <p className="eth text-xl font-bold text-purple">*/}
                {/*                43,728 ETH*/}
                {/*            </p>*/}
                {/*            <p className="usd text-base text-thin-light-gray text-right">*/}
                {/*                (773.69 USD)*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</li>*/}
              </ul>
            :
              <div className='p-2 w-full flex gap-4 items-center'>
                <img className='w-[100px!important] h-auto' src={localImgLoad('images/icons/wallet.svg')} alt='Wallet Icon' />
                <p className='text-lg text-red-500'>We do not currently support wallets in your area. We are diligently working on it. </p>
              </div>
            }

            {(wallet_available_status == 'WALLET_AVAILABLE' || localStorage.getItem('wallet_available_status')== 'WALLET_AVAILABLE') &&
              <div className="add-money-btn flex justify-center items-center mt-3">
                {/* <button
                    onClick={() => {
                    if(pathname == '/my-wallet') props.setBalanceDropdown.toggle()
                    else navigate('/my-wallet', {replace: true})
                    }}
                    type="button"
                    className="w-[122px] h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white"
                >
                    Manage
                </button> */}
                <Link
                  to="/my-wallet"
                  onClick={onWalletClick}
                  className="w-[122px] h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white"
                >
                  Manage
                </Link>
              </div>
            }
            </div>
          </div>
        </div>
        :
        <div className="lg:flex hidden user-balance cursor-pointer lg:w-[152px] w-[150px] h-[48px]  items-center rounded-full relative  bg-sky-blue pr-1.5 pl-4">
          <div
            onClick={() => navigate("/family-wallet", { replace: true })}
            className="flex items-center lg:justify-between justify-center w-full h-full"
          >
            <span className="lg:block hidden">
              <Icons name="wallet" />
            </span>
            <p className="lg:text-xl text-lg font-bold text-white">Wallet</p>
            <span className="lg:block hidden">
              <Icons name="deep-plus" />
            </span>
          </div>
        </div>
      }
      <div className="lg:hidden block"></div>
    </>
  );
}
