import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../Partials/Layout";
import CommonHead from "../UserHeader/CommonHead";

import usersService from "../../services/UsersService";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import OthersInterestedTable from "./OthersInterestedTable";
import { useDispatch, useSelector } from "react-redux";
import { tableReload } from "../../store/TableReloads";
import { PriceFormatter } from "../Helpers/PriceFormatter";

export default function ManageInterestOffer(props) {

    const { walletDetails } = useSelector((state) => state?.walletDetails); // WALLET STORE

    let { othersInterestedTable } = useSelector((state) => state.tableReload); // FOR OTHERS INTERESTED TABLE RELOAD
    
    let walletBal = walletDetails?.data?.filter(wallet => wallet.code == props?.offerDetails?.currency_code) // USER WALLET BALANCE FOR CORRESPONDING TASK CURRENCY

    let clientAdded = new Date(props.offerDetails?.client_added)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const apiCall = new usersService()

    let [redirectTime, setRedirectTime] = useState(5)

    let [messageToSend, setMessageToSend] = useState('')

    let [tab, setTab] = useState("info"); //message STATE FOR SWITCHING BETWEEN TABS

    let [requestStatus, setRequestStatus] = useState({loading: false, status: false, message: '', processType: ''})

    let [messageListReload, setMessageListReload] = useState(false) // STATE TO DETERMINE WHEN MESSAGE LIST WILL RELOAD

    const [messageList, setMessageList] = useState({loading: true, data: []}) // TO BE REMOVED AND REPLACE WITH REAL MESSAGE FROM API CALL

    const [interestStats, setInterestStats] = useState({loading: true, data: []}) // STATE TO HOLD INTEREST STATS

    const [selectTab, setValue] = useState("today");
    const filterHandler = (value) => {
        setValue(value);
    };

    //FUNCTION TO HANDLE ON CHANGE MESSAGE IN TEXTAREA
    const onMessageChange = ({target:{value}}) => {
        if(messageToSend.length > 149){
            setRequestStatus({loading: false, status: false, message: 'max of 150 characters', processType: 'sendmessage'})
        }else{
            setRequestStatus({loading: false, status: false, message: '', processType: 'sendmessage'})
        }
        setMessageToSend(value)
    }

    //FUNCTION TO SEND MESSAGE TO CLIENT
    const sendMessage = () => {
        let reqData = { // API PAYLOADS
            msg_type: 'MRKTINT',
            yourmessage: messageToSend,
            offer_uid: props.offerDetails.offer_uid,
            interest_uid: props.offerDetails.interest_uid
        }
        setRequestStatus(prev => ({...prev, loading: true, processType: 'sendmessage'}))
        if(!messageToSend){
            setRequestStatus({loading: false, status: false, message: 'Please enter message to send', processType: 'sendmessage'})
            return
        }
        if(messageToSend.length > 149){
            return
        }
        apiCall.offerInterestMsg(reqData).then(res=>{
            if(res.status != 200 || res.data.internal_return < 0){
                setRequestStatus({loading: false, status: false, message: 'message not sent, try again', processType: 'sendmessage'})
                return
            }
            setRequestStatus({loading: false, status: true, message: 'message sent', processType: 'sendmessage'})
            setMessageToSend('') // sets message to empty strings
            setMessageListReload(prev => !prev) //A FUNCTION TO MAKE MESSAGE LIST RELOAD
        }).catch(error => {
            setRequestStatus({loading: false, status: false, message: 'Opps, an error occured', processType: 'sendmessage'})
        }).finally(()=>{
            setTimeout(() => {
                setRequestStatus({loading: false, status: false, message: '', processType: ''})
            }, 5000);
        })
    }

    //FUNCTION TO ACCEPT/REJECT OFFER INTEREST
    const interestOfferProcess = ({target:{name}}) => {
        setRequestStatus(prev => ({...prev, loading: true, processType: name}))
        let reqData = { // API PAYLOADS
            proc: name.toUpperCase(),
            client_uid : props.offerDetails?.client_uid,
            offer_code : props.offerDetails?.offer_code,
            offer_uid: props.offerDetails?.offer_uid,
        }
        apiCall.offersInterestProc(reqData).then(res => {
            if(res.status != 200 || res.data.internal_return < 0){
                setRequestStatus({loading: false, status: false, message: 'Unable to complete request', processType: ''})
                return
            }
            let intervalTime = setInterval(() => {  // SETS REDIRECT COUNT DOWN
                setRedirectTime(prev => prev - 1)
            }, 1000);
            setRequestStatus({loading: false, status: true, message: `Offer ${name}ed`, processType: ''})
            dispatch(tableReload({ type: "WALLETTABLE" }));
            setTimeout(()=>{
                navigate('/offer-interest', {replace: true})
                clearInterval(intervalTime)
            },5000)
        }).catch(err => {
            setRequestStatus({loading: false, status: false, message: 'Opps! something went wrong. Try again', processType: ''})
        }).finally(()=>{
            setTimeout(()=>{
                setRequestStatus({loading: false, status: false, message: '', processType: ''})
            },5000)
        })
    }

    useEffect(()=>{ //API to get Offer Interest message list
        let reqData = { // API PAYLOADS
            msg_type: 'MRKTINT',
            offer_uid: props.offerDetails?.offer_uid,
            interest_uid: props.offerDetails?.interest_uid
        }
        setMessageList(prev => ({...prev, loading: true}))
        apiCall.offerInterestListMsg(reqData).then(res=>{
            setMessageList({loading: false, data:res?.data?.result_list})
        }).catch(err => {
            setMessageList(prev => ({...prev, loading: false}))
            console.log('Failed', err)
        })
    },[messageListReload])

    useEffect(()=>{ //API to get Interest stats
        let reqData = { // API PAYLOADS
            interest_uid: props?.offerDetails?.interest_uid,
            client_uid: props?.offerDetails?.client_uid
        }
        setInterestStats(prev => ({...prev, loading: true}))
        apiCall.interestStatistics(reqData).then(res=>{
            setInterestStats({loading: false, data:res?.data})
        }).catch(err => {
            setInterestStats(prev => ({...prev, loading: false}))
            console.log('Failed', err)
        })
    },[othersInterestedTable])
    return (
        <Layout>
            <CommonHead
                commonHeadData={props.commonHeadData}
            />
            <div className="notification-page w-full mb-10">
                <div className="notification-wrapper w-full mb-4">
                    {/* heading */}
                    <div className="sm:flex justify-between items-center mb-3">
                        <div className="mb-3 sm:mb-0">
                            <h1 className="text-26 font-bold text-dark-gray dark:text-white">
                                <span className={`${selectTab === "today" ? "block" : "hidden"}`}>Manage Offer Interest</span>
                            </h1>
                        </div>

                    </div>

                    {/* manage offer section */}
                    <div className="w-full p-4 bg-white dark:bg-dark-white rounded-2xl section-shadow">
                        <div className="my-2 w-full md:grid gap-5 grid-cols-4">
                            {/* Detail section */}
                            <div className="w-full mb-5 lg:mb-0 col-span-3">
                                <div className="w-full flex justify-start space-x-3 items-center">
                                    <button
                                    type="button"
                                    className="min-w-[45px] h-auto text-[#374557] border border-sky-blue p-1 rounded-full"
                                    onClick={() =>
                                        navigate('/offer-interest', { replace: true })
                                    }
                                    >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="35"
                                        height="35"
                                        viewBox="0 0 24 24"
                                        fill="skyblue"
                                    >
                                        <path d="M19 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H19v-2z" />
                                    </svg>
                                    </button>
                                    <h1 className="text-sm lg:text-xl font-bold text-sky-blue dark:text-white tracking-wide">
                                    {props.offerDetails?.offer_code && props.offerDetails.offer_code}
                                    </h1>
                                </div>
                                <h1 className="my-3 text-xl lg:text-2xl font-bold text-dark-gray dark:text-white tracking-wide border">
                                {props.offerDetails?.title}
                                </h1>

                                <div className="w-full">
                                    {/* switch button */}
                                    <div className="pl-7 my-2 flex items-center border-b border-slate-300 gap-3">
                                        <button
                                        name="info"
                                        onClick={(e) => setTab(e.target.name)}
                                        className={`px-4 py-1 rounded-t-2xl ${
                                            tab == "info" ? "bg-[#4687ba] border-[2px] border-[#4687ba] text-white" : "bg-white text-[#000] border-t-[2px]"
                                          }`}
                                        >
                                        Info
                                        </button>
                                        <button
                                        name="message"
                                        onClick={(e) => setTab(e.target.name)}
                                        className={`px-4 py-1 rounded-t-2xl flex gap-2 ${
                                            tab == "message" ? "bg-[#4687ba] border-[2px] border-[#4687ba] text-white" : "bg-white text-[#000] border-t-[2px]"
                                          }`}
                                        >
                                        Messages ({messageList.data.length})
                                        </button>
                                        <button
                                        name="details"
                                        onClick={(e) => setTab(e.target.name)}
                                        className={`px-4 py-1 rounded-t-2xl ${
                                            tab == "details" ? "bg-[#4687ba] border-[2px] border-[#4687ba] text-white" : "bg-white text-[#000] border-t-[2px]"
                                          }`}
                                        >
                                        Delivery Details
                                        </button>
                                    </div>
                                    {/* END OF switch button */}

                                    {/* info tab */}
                                    {tab == 'info' ?
                                    <div className="info-details w-full border-t">
                                        <div className="my-0 md:my-3 block md:flex items-center gap-10">
                                            <div className="my-3 md:my-0 flex items-center gap-1">
                                                <p className="text-xl font-extrabold text-black dark:text-white tracking-wide">
                                                    Name : {' '}
                                                    <span className="text-lg font-normal text-dark-gray dark:text-white tracking-wide">{props.offerDetails?.client_name}</span>
                                                </p>
                                            </div>
                                            <div className="my-3 md:my-0 flex items-center gap-1">
                                                <p className="text-xl font-extrabold text-black dark:text-white tracking-wide">
                                                    Member Since : {' '}
                                                    <span className="text-lg font-normal text-dark-gray dark:text-white tracking-wide">
                                                        {clientAdded.getFullYear()}{" - "}
                                                        {clientAdded.getMonth() < 9 ? '0'+ (clientAdded.getMonth() + 1) : clientAdded.getMonth() + 1}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <>
                                        {interestStats.loading ?
                                            <LoadingSpinner color='sky-blue' size='10' height='min-h-[40px]' />
                                            :
                                            <>
                                                <div className='w-full my-3 md:my-0'>
                                                    <h1 className="text-xl font-extrabold text-black dark:text-white tracking-wide">Previous Job Statistics</h1>
                                                </div>
                                                <div className='p-2 w-full md:max-w-xl flex flex-col md:flex-row gap-4 justify-center items-center rounded-2xl bg-sky-100'>
                                                    <div className='contents md:flex flex-col gap-3'>
                                                        <div className='flex items-center gap-1'>  
                                                            <p className="min-w-[150px] text-lg font-bold text-black dark:text-white tracking-wide text-right">Completed :</p>
                                                            <p className="min-w-[100px] text-lg font-normal text-dark-gray dark:text-white tracking-wide">{interestStats.data?.job_completed && interestStats.data?.job_completed}</p>
                                                        </div>
                                                        <div className="flex items-center gap-1">
                                                            <p className="min-w-[150px] text-lg font-bold text-black dark:text-white tracking-wide text-right">Active :</p>
                                                            <p className="min-w-[100px] text-lg font-normal text-dark-gray dark:text-white tracking-wide">{interestStats.data?.job_active && interestStats.data?.job_active}</p>
                                                        </div>
                                                        <div className="flex items-center gap-1">
                                                            <p className="min-w-[150px] text-lg font-bold text-black dark:text-white tracking-wide text-right">% Completion :</p>
                                                            <p className="min-w-[100px] text-lg font-normal text-dark-gray dark:text-white tracking-wide">{interestStats.data?.job_percent_complete && interestStats.data?.job_percent_complete}</p>
                                                        </div>
                                                    </div>
                                                    <div className='contents md:flex flex-col gap-3'>
                                                        <div className="flex items-center gap-1">
                                                            <p className="min-w-[150px] text-lg font-bold text-black dark:text-white tracking-wide text-right">Last Completed :</p>
                                                            <p className="min-w-[100px] text-lg font-normal text-dark-gray dark:text-white tracking-wide">{interestStats.data?.job_last_date && interestStats.data?.job_last_date}</p>
                                                        </div>
                                                        <div className="flex items-center gap-1">
                                                            <p className="min-w-[150px] text-lg font-bold text-black dark:text-white tracking-wide text-right">Uncompleted :</p>
                                                            <p className="min-w-[100px] text-lg font-normal text-dark-gray dark:text-white tracking-wide">{interestStats.data?.job_uncompleted && interestStats.data?.job_uncompleted}</p>
                                                        </div>
                                                        <div className="flex items-center gap-1">
                                                            <p className="min-w-[150px] text-lg font-bold text-black dark:text-white tracking-wide text-right">Pending Offers :</p>
                                                            <p className="min-w-[100px] text-lg font-normal text-dark-gray dark:text-white tracking-wide">{interestStats.data?.job_pending && interestStats.data?.job_pending}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="my-0 md:my-3 block md:flex items-center gap-5">
                                                    <div className='my-3 md:my-0 flex items-center gap-4'>  
                                                        <p className="min-w-[200px] text-lg font-bold text-dark-gray dark:text-white tracking-wide text-right">Completed:</p>
                                                        <p className="min-w-[100px] text-lg font-normal text-dark-gray dark:text-white tracking-wide">{interestStats.data?.job_completed && interestStats.data?.job_completed}</p>
                                                    </div>
                                                    <div className="my-3 md:my-0 flex items-center gap-4">
                                                        <p className="min-w-[200px] text-lg font-bold text-dark-gray dark:text-white tracking-wide text-right">Completed:</p>
                                                        <p className="min-w-[150px] text-lg font-normal text-dark-gray dark:text-white tracking-wide">{interestStats.data?.job_last_date && interestStats.data?.job_last_date}</p>
                                                    </div>
                                                </div> */}
                                                {/* <div className="my-0 md:my-3 block md:flex items-center gap-10">
                                                    <div className="my-3 md:my-0 flex items-center gap-4">
                                                        <p className="min-w-[200px] text-lg font-bold text-dark-gray dark:text-white tracking-wide text-right">Active:</p>
                                                        <p className="min-w-[100px] text-lg font-normal text-dark-gray dark:text-white tracking-wide">{interestStats.data?.job_active && interestStats.data?.job_active}</p>
                                                    </div>
                                                    <div className="my-3 md:my-0 flex items-center gap-4">
                                                        <p className="min-w-[200px] text-lg font-bold text-dark-gray dark:text-white tracking-wide text-right">Uncompleted:</p>
                                                        <p className="min-w-[100px] text-lg font-normal text-dark-gray dark:text-white tracking-wide">{interestStats.data?.job_uncompleted && interestStats.data?.job_uncompleted}</p>
                                                    </div>
                                                </div> */}
                                                {/* <div className="block md:flex items-center gap-10">
                                                    <div className="my-3 md:my-0 flex items-center gap-4">
                                                        <p className="min-w-[200px] text-lg font-bold text-dark-gray dark:text-white tracking-wide text-right">% Completion:</p>
                                                        <p className="min-w-[100px] text-lg font-normal text-dark-gray dark:text-white tracking-wide">{interestStats.data?.job_percent_complete && interestStats.data?.job_percent_complete}</p>
                                                    </div>
                                                    <div className="flex items-center gap-4">
                                                        <p className="min-w-[200px] text-lg font-bold text-dark-gray dark:text-white tracking-wide text-right">Pending Offers:</p>
                                                        <p className="min-w-[100px] text-lg font-normal text-dark-gray dark:text-white tracking-wide">{interestStats.data?.job_pending && interestStats.data?.job_pending}</p>
                                                    </div>
                                                </div> */}
                                            </>
                                        }
                                        </>
                                    </div>
                                    : tab == 'message' ?
                                    <div className="message-details w-full border-t">
                                        <p className="my-1 text-base text-dark-gray dark:text-white tracking-wide">To: <span className="font-bold">{props.offerDetails?.client_name}</span></p>
                                        <div className="w-full flex items-center gap-5">
                                            <div className="w-full">
                                                <textarea 
                                                    value={messageToSend}
                                                    onChange={onMessageChange} 
                                                    rows={2} autoFocus={true} 
                                                    className="p-2 text-base font-bold text-dark-gray dark:text-white dark:bg-dark-gray border tracking-wide w-full resize-none rounded-md outline-none" 
                                                />
                                            </div>
                                            <div className="flex justify-end items-center">
                                                {requestStatus.loading && requestStatus.processType == 'sendmessage' ?
                                                    <LoadingSpinner color='sky-blue' size='10' />
                                                :
                                                    <button
                                                        type="button"
                                                        onClick={sendMessage}
                                                        disabled={requestStatus.loading}
                                                        // className="px-2 py-1 h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white"
                                                        className="w-[100px] h-[50px] bg-sky-blue text-center text-lg font-semibold text-white rounded-md shadow-md flex justify-center items-center"
                                                    >
                                                    Send
                                                    </button>
                                                }
                                            </div>
                                        </div>

                                        {/* ERROR DISPLAY FOR MESSAGE SENDING */}
                                        <div className="w-full">
                                        {/* error or success display */}
                                        {requestStatus.message != "" && requestStatus.processType == 'sendmessage' &&
                                            (!requestStatus.status ? (
                                            <div
                                                className={`relative p-4 my-4 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
                                            >
                                                {requestStatus.message}
                                            </div>
                                            ) : (
                                            requestStatus.status && (
                                                <div
                                                className={`relative p-4 my-4 text-green-700 bg-slate-200 border-slate-800 mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
                                                >
                                                {requestStatus.message}
                                                </div>
                                            )
                                            ))}
                                        </div>
                                        {/* END OF ERROR DISPLAY FOR MESSAGE SENDING */}

                                        {/* message list */}
                                        <div className="my-1 min-h-[100px] max-h-[200px] border-t overflow-y-scroll">
                                        { messageList.loading ?
                                        <LoadingSpinner color='sky-blue' size='16' />
                                        : messageList?.data?.map((item, index)=>(
                                            <div key={index} className="my-2 w-full flex items-center gap-1">
                                                <p className="text-base font-bold text-dark-gray dark:text-white tracking-wide">{item.added.split(' ')[0]}-from { }<span className="font-normal">{item.sender || 'Dummy name'}</span></p>
                                                <p className="text-base font-bold text-dark-gray dark:text-white tracking-wide">{item.msg}</p>
                                            </div>
                                            ))
                                        }
                                        </div>
                                    </div>
                                    : tab == 'details' ?
                                    <div className="info-details w-full border-t">
                                        <h1 className="my-3 text-base font-medium text-dark-gray dark:text-white tracking-wide">{props?.offerDetails?.description}</h1>
                                    </div>
                                    :
                                    null
                                    }
                                </div>
                            </div>
                            {/* END OF Detail section */}

                            <div className="p-4 w-full min-h-full bg-sky-100 rounded-2xl dark:bg-dark-gray col-span-1">
                                {/* Wallet balance and reward */}
                                <div className='mb-4 border-b-2 flex flex-col xxs:flex-row md:flex-col justify-center items-center gap-4'>
                                    <div className='w-full flex flex-col lg:flex-row justify-center items-center gap-2'>
                                        <p className="text-lg font-bold text-dark-gray dark:text-white tracking-wide">Wallet:</p>
                                        <span className="font-medium text-dark-gray dark:text-white">{ walletDetails?.loading ? 'loading...' : PriceFormatter(walletBal[0]?.amount * 0.01,props?.offerDetails?.currency_code,props?.offerDetails?.currency)}</span>
                                    </div>
                                    <div className='w-full flex flex-col lg:flex-row justify-center items-center gap-2'>
                                        <p className="text-lg font-bold text-dark-gray dark:text-white tracking-wide">Reward:</p>
                                        <span className="font-medium text-dark-gray dark:text-white">{PriceFormatter(props?.offerDetails?.price * 0.01,props?.offerDetails?.currency_code,props?.offerDetails?.currency)}</span>
                                    </div>
                                </div>
                                {/* BUTTON section */}
                                <div className="w-full">
                                    <div className="h-full flex flex-col xxs:flex-row md:flex-col justify-center items-center gap-10">
                                        {requestStatus.loading && requestStatus.processType == 'accept' ?
                                            <LoadingSpinner color='sky-blue' size='10' />
                                        :    
                                            <button
                                                type="button"
                                                name='accept'
                                                disabled={requestStatus.loading}
                                                onClick={interestOfferProcess}
                                                // className="px-2 py-1 h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white"
                                                className='max-w-[150px] h-[100px] bg-[#57cd89] text-center text-lg font-semibold text-white py-2 px-4 rounded-md shadow-md'
                                            >
                                            Accept this Interest
                                            </button>
                                        }

                                        {requestStatus.loading && requestStatus.processType == 'reject' ?
                                            <LoadingSpinner color='sky-blue' size='10' />
                                        :
                                            <button
                                                type="button"
                                                name='reject'
                                                disabled={requestStatus.loading}
                                                onClick={interestOfferProcess}
                                                // className="px-2 py-1 h-11 flex justify-center items-center border-gradient text-base rounded-full text-black"
                                                className='max-w-[150px] h-[100px] bg-red-300 text-center text-lg font-semibold text-white py-2 px-4 rounded-md shadow-md'
                                            >
                                            Reject this Interest
                                            </button>
                                        }
                                    </div>
                                    {/* END of BUTTON section */}

                                    {/* ERROR DISPLAY */}
                                    <div className="w-full">
                                    {/* error or success display */}
                                    {requestStatus.message != "" && requestStatus.processType != 'sendmessage' &&
                                        (!requestStatus.status ? (
                                        <div
                                            className={`relative p-4 my-4 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
                                        >
                                            {requestStatus.message}
                                        </div>
                                        ) : (
                                        requestStatus.status && (
                                            <div
                                            className={`relative p-4 my-4 text-green-700 bg-slate-200 border-slate-800 mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
                                            >
                                            {`${requestStatus.message} redirecting.... ${redirectTime}sec`}
                                            </div>
                                        )
                                        ))}
                                    </div>
                                    {/* End of error or success display */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END OF manage offer section */}
                </div>

                {props.othersInterestedList?.data?.length ?
                <div className="w-full overflow-x-auto">
                    {/* heading */}
                    <div className="sm:flex justify-between items-center mb-3">
                        <div className="mb-3 sm:mb-0">
                            <h1 className="text-26 font-bold text-dark-gray dark:text-white">
                                <span className={`${selectTab === "today" ? "block" : "hidden"}`}>Others interested in this Task</span>
                            </h1>
                        </div>
                    </div>
                    <OthersInterestedTable othersInterestedList={props.othersInterestedList} />
                </div>
                :
                null
                }
            </div>
        </Layout>
    );
}
