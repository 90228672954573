import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import activeAidsBanner from "../../assets/images/kids-waiting.jpg";
import ParentWaitingTable from "./ParentWaitingTable";
import CustomBreadcrumb from "../Breadcrumb/CustomBreadcrumb";

export default function ParentWaiting({ className }) {
  const [addFavorite, setValue] = useState(false);
  const favoriteHandler = () => {
    if (!addFavorite) {
      setValue(true);
      toast.success("Added to Favorite List");
    } else {
      setValue(false);
      toast.warn("Remove to Favorite List");
    }
  };
  return (
    <>
      <div className={`overview-section w-full ${className || ""}`}>
        <div className="w-full mb-3 flex justify-between items-center gap-1">
          {/* <h1 className="text-26 font-bold text-dark-gray dark:text-white">
            Waiting for Parent to Get Started...
          </h1> */}

          <CustomBreadcrumb 
          title = {'Waiting for Parent to Get Started...'}
          breadcrumb={
              [
              { link: "/", title: "Home" },
              { link: "/suggested", title: "Suggested", active: true},
              ]
          }
          />

          <Link
            to='/familymarket'
            className="px-4 h-10 flex justify-center items-center btn-gradient text-base rounded-full text-white"
          >
            More Task
          </Link>
        </div>
        {/* <div className="overview-section-wrapper py-2 min-h-[450px] lg:flex lg:space-x-2 flex-1 lg:gap-8"> */}
        <div className="overview-section-wrapper py-2 min-h-[400px] lg:grid grid-cols-2 gap-4">
          <div className="overview-countdown mb-10 lg:mb-0 h-full w-full flex flex-col justify-between p-4 rounded-2xl bg-white dark:bg-dark-white">
            {<ParentWaitingTable />}
          </div>

          <div className="h-full w-full bg-white dark:bg-dark-white rounded-2xl overflow-hidden">
            <img
              src={activeAidsBanner}
              alt="banner"
              className="w-full h-full"
            />
          </div>
        </div>
      </div>
    </>
  );
}