import React from 'react'
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../../Spinners/LoadingSpinner";
import JobFieldInput from './JobFieldInput';



const validationSchema = Yup.object().shape({
    individual: Yup.string().required("This is required ")
        .email("Invalid email format")
        .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        "Invalid email format"
        ),
});


let initialValues = {
    individual: "",
};

export default function AssignToIndividual({
    jobFieldHandler,
    loader
}) {
  return (
    <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={jobFieldHandler}
  >
    {(props) => {
      return (
        <Form className="w-full h-auto md:h-full flex flex-col justify-between">
          <div className='w-full'>
              <div className='w-full'>
                <JobFieldInput
                    input={true}
                    inputName="individual"
                    value={props?.values.individual}
                    placeholder="Enter email of individual"
                    inputHandler={props?.handleChange}
                    parentClass="w-full"
                    labelClass="mb-0"
                />
                <p className="h-4 text-[13px] font-light italic text-red-600 tracking-wide">
                  {(props.errors.individual && props.touched.individual) && (
                    <span>{props.errors.individual}</span>
                  )}
                </p>
              </div>
          </div>
          <div className="mt-3 mb-1 flex justify-end items-center">
            <button
              className={`uppercase px-4 h-11 flex justify-center items-center btn-gradient text-sm rounded-full text-white`}
              type="submit"
              name='individual'
            >
              {loader?.jobFields ? 
                <LoadingSpinner size={5} /> 
                :
                'Send Offer to Individual'
              }
            </button>
          </div>
        </Form>
      );
    }}
  </Formik>
  )
}
