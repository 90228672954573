const checkAndSetError = (field, value, setErrorFunc, errorMessage) => {
  const isNotEmpty = () => {
    if (!value) {
      setErrorFunc({ [field]: errorMessage });
      setTimeout(() => setErrorFunc({ [field]: "" }), 1000);
      return false;
    }
  };

  const textCount = () => {
    if (!value) {
      setErrorFunc({ [field]: errorMessage });
      setTimeout(() => setErrorFunc({ [field]: "" }), 1000);
      return false;
    }
  };

  return { isNotEmpty, textCount };
};

export default checkAndSetError;
