import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
//import SideStatistics from "./SideStatistics";
import { localImgLoad } from "../../lib";
import DarkModeContext from "../Contexts/DarkModeContext";
import Icons from "../Helpers/Icons";

export default function RightSideBar({ myJobList }) {
  const filterDatas = ["Last 15 days", "Last Month", "Last 6 month"];
  const [filterDataSet, setFilterDataSet] = useState([10, 30, 20, 40]);
  const dataSetHandler = (value) => {
    if (value === "Last 15 days") {
      setFilterDataSet([10, 30, 20, 40]);
    } else if (value === "Last Month") {
      setFilterDataSet([15, 35, 10, 20]);
    } else {
      setFilterDataSet([8, 15, 40, 30]);
    }
  };
  const darkMode = useContext(DarkModeContext);

  const [selectedRate, setSelectedRate] = useState("ETH");
  const [rateStaticsDropdown, setRateStaticsDropdown] = useState(false);
  const [filterRateStatics, setFilterRateStatics] = useState([50, 30, 90, 20]);
  const rateDataSetHandler = (value) => {
    setSelectedRate(value);
    if (value === "USD") {
      setFilterRateStatics([50, 30, 90, 20]);
    } else if (value === "BTC") {
      setFilterRateStatics([15, 35, 10, 20]);
    } else {
      setFilterRateStatics([8, 15, 20, 30]);
    }
    setRateStaticsDropdown(!filterRateStatics);
  };

  const { userDetails } = useSelector((state) => state?.userDetails);
  let walletExist = userDetails?.wallet_available_status == 'WALLET_AVAILABLE' || localStorage.getItem('wallet_available_status')== 'WALLET_AVAILABLE'

  return (
    <>
      <div className="right-sidebar-wrapper overflow-y-scroll overflow-style-none 2xl:fixed h-full 2xl:pb-96">
        <div className="top-platform bg-white dark:bg-dark-white rounded-2xl p-8 w-full 2xl:mb-6 2xl:border-none border ">
          {/* heading */}
          <div className="heading flex justify-between items-center mb-3.5">
            <h3 className="text-xl font-bold   text-dark-gray dark:text-white">
              Quick Links
            </h3>
            <div></div>
          </div>

          <div className="platform-list flex flex-col gap-y-2.5">
            {userDetails && userDetails?.account_type !== "FAMILY" && (
              <>
                <div className="item flex space-x-3 items-center">
                  {/* image */}
                  <div className="w-8 h-8 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      id="history"
                    >
                      <g data-name="14">
                        <circle cx="9" cy="14" r="7" fill="#ffd54f"></circle>
                        <path
                          fill="#ef6c00"
                          d="M21 9H17a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2zM21 5H3A1 1 0 0 1 3 3H21a1 1 0 0 1 0 2zM21 13H19a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2zM21 17H19a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2zM21 21H17a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z"
                        ></path>
                        <path
                          fill="#ff8f00"
                          d="M10,10a1,1,0,0,0-2,0v3.59L6.29,15.29a1,1,0,1,0,1.41,1.41l2-2A1,1,0,0,0,10,14Z"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  {/* name */}
                  <div>
                    <p className="text-thin-light-gray text-base font-medium">
                      <NavLink to="/history">History</NavLink>
                    </p>
                  </div>
                  {/* action */}
                </div>

                <div className="item flex space-x-3 items-center">
                  {/* image */}
                  <div className="w-8 h-8 rounded-full flex items-center justify-center">
                    <Icons name="pending-job" />
                  </div>
                  {/* name */}
                  <div>
                    <p className="text-thin-light-gray text-base font-medium">
                      <NavLink to="/pend-interest">Waiting</NavLink>
                    </p>
                  </div>
                  {/* action */}
                </div>
                <div className="item flex space-x-3 items-center">
                  {/* image */}
                  <div className="w-8 h-8 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      id="add-user"
                    >
                      <path
                        fill="#9bcb5b"
                        d="M12 13.4c-2 0-3.7-1.6-3.7-3.6s1.6-3.7 3.6-3.7 3.7 1.6 3.7 3.6-1.6 3.6-3.6 3.7zm0-6.2c-1.4 0-2.6 1.1-2.6 2.6 0 1.4 1.1 2.6 2.6 2.6s2.6-1.1 2.6-2.6c-.1-1.5-1.2-2.6-2.6-2.6z"
                      ></path>
                      <path
                        fill="#9bcb5b"
                        d="M16.6 17.9c-.3 0-.5-.2-.6-.5 0-2.2-1.8-4-4-4s-4 1.8-4 4c0 .3-.3.5-.6.5-.2 0-.4-.2-.5-.5 0-2.8 2.3-5.1 5.1-5.1s5.1 2.3 5.1 5.1c0 .3-.2.5-.5.5z"
                      ></path>
                      <path
                        fill="#0376bc"
                        d="M12 23.7C5.5 23.7.3 18.4.3 12 .3 5.5 5.6.3 12 .3c2.6 0 5.1.9 7.2 2.5.2.2.2.6 0 .8-.2.2-.4.2-.7.1-1.9-1.4-4.1-2.2-6.5-2.2C6.2 1.4 1.4 6.2 1.4 12S6.2 22.6 12 22.6 22.6 17.8 22.6 12c0-2.4-.8-4.6-2.2-6.5-.2-.3-.1-.6.2-.8.2-.1.5-.1.7.1 1.6 2 2.5 4.6 2.4 7.2 0 6.4-5.3 11.7-11.7 11.7z"
                      ></path>
                      <circle cx="20.2" cy="20.3" r="2.4" fill="#fff"></circle>
                      <path
                        fill="#9bcb5b"
                        d="M18 18.1c.6-.6 1.4-.9 2.2-.9.8 0 1.6.3 2.2.9s1 1.4.9 2.2c0 .8-.3 1.6-.9 2.2s-1.4 1-2.2.9c-.8 0-1.6-.3-2.2-.9s-1-1.4-.9-2.2c-.1-.8.3-1.7.9-2.2zm3.8 2.5V20h-1.3v-1.3h-.6V20h-1.3v.6h1.3v1.3h.6v-1.3h1.3z"
                      ></path>
                    </svg>
                  </div>
                  {/* name */}
                  <div>
                    <p className="text-thin-light-gray text-base font-medium">
                      <NavLink to="/referral">Refer a Friend</NavLink>
                    </p>
                  </div>
                </div>
                { Number(process.env.REACT_APP_SHOW_USER_PAGE) == 1 &&
                  <div className="item flex space-x-3 items-center">
                    {/* image */}
                    <div className="w-8 h-8 rounded-full bg-sky-500/50 p-1 flex justify-center items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 50" id="Page">
                        <path
                          fillRule="evenodd"
                          d="M0 13V2a2 2 0 0 1 2-2h64a2 2 0 0 1 2 2v11H0Zm0 4v31a2 2 0 0 0 2 2h20V17H0Zm26 33h40a2 2 0 0 0 2-2V17H26v33Z"
                          fill="#ffffff"
                          className="color000000 svgShape"
                        ></path>
                      </svg>
                    </div>
                    {/* name */}
                    <div>
                      <p className="text-thin-light-gray text-base font-medium">
                        <NavLink to="/yourpage">My Page</NavLink>
                      </p>
                    </div>
                  </div>
                }
              </>
            )}

            <div className="item flex space-x-3 items-center">
              {/* image */}
              <div className="w-8 h-8 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  id="InternationalUser"
                >
                  <path
                    fill="#e6e7f9"
                    d="M38.5 31.1c2.1-1.1 3.9-2.5 5.4-4.4h-3.2c-.6 1.6-1.3 3.1-2.2 4.4zm-5.8 1.4c2-.7 3.7-2.8 4.9-5.8h-4.9v5.8zm8.8-8.6h4.2c.9-1.8 1.5-3.7 1.7-5.8h-5.1c-.1 2-.4 4-.8 5.8zm2.4-17.3c-1.5-1.8-3.3-3.3-5.4-4.4.8 1.2 1.6 2.7 2.2 4.4h3.2zm-5.3 2.9h-5.9v5.8h6.7c-.1-2.1-.4-4.1-.8-5.8zm3.7 5.8h5.1c-.2-2.1-.8-4-1.7-5.8h-4.2c.4 1.8.7 3.7.8 5.8zM32.7.8v5.8h4.9c-1.2-2.9-2.9-5.1-4.9-5.8zm6.7 17.3h-6.7v5.8h5.9c.4-1.8.7-3.8.8-5.8zM25 6.6h4.9V.8c-2 .7-3.7 2.9-4.9 5.8zm-1.8 8.7h6.7V9.5H24c-.4 1.7-.7 3.7-.8 5.8zm6.7 17.2v-5.8H25c1.2 3 2.9 5.1 4.9 5.8zM24.1 2.3c-2.1 1.1-3.9 2.5-5.4 4.4H22c.5-1.7 1.3-3.2 2.1-4.4zM24 23.9h5.9v-5.8h-6.7c.1 2 .4 4 .8 5.8z"
                    className="colorc1e5ff svgShape"
                  ></path>
                  <path
                    fill="#ff6699"
                    d="M3.7 44.7c0 1.6 1.2 2.8 2.8 2.8 1.3 0 2.4-.9 2.7-2.2.3 1.3 1.4 2.2 2.7 2.2 1.5 0 2.8-1.3 2.8-2.8V30.1h3.1V18.8c0-4.2-3.3-7.5-7.4-7.5H8c-4.1 0-7.4 3.4-7.4 7.5v11.3h3.1v14.6z"
                    className="colorff99b0 svgShape"
                  ></path>
                  <path
                    fill="#998da0"
                    d="M9.2 10.3c2.4 0 4.4-2.2 4.4-4.9S11.6.5 9.2.5C6.8.5 4.8 2.7 4.8 5.4s2 4.9 4.4 4.9z"
                    className="colorffd499 svgShape"
                  ></path>
                  <path
                    fill="#e6e7f9"
                    d="M16.9 9.5c-.3.6-.5 1.2-.8 1.8 1.3 1 2.3 2.4 2.9 4h1.3c.1-2 .3-4 .8-5.8h-4.2zm2.9 9.3v5.1h1.4c-.4-1.8-.7-3.8-.8-5.8h-.6c-.1.2 0 .4 0 .7zm2.2 7.9h-2.2v1.2c1.3 1.3 2.7 2.4 4.4 3.2-.9-1.3-1.7-2.8-2.2-4.4z"
                    className="colorc1e5ff svgShape"
                  ></path>
                </svg>
              </div>
              {/* name */}
              <div>
                <p className="text-thin-light-gray text-base font-medium">
                  <NavLink to="/resources">Resources</NavLink>
                </p>
              </div>
            </div>
            {/* dark mode */}
            <div
              onClick={darkMode.handleThemeSwitch}
              className="item flex space-x-5 items-center cursor-pointer"
            >
              <span className="dark:text-dark-gray text-white">
                {darkMode.theme === "light" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 fill-black"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
                    />
                  </svg>
                ) : (
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-current w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.3065 16.3771C18.1572 16.6304 17.986 16.8743 17.7937 17.1062C17.7118 17.2044 17.7247 17.3515 17.8228 17.4339C17.9208 17.5163 18.0665 17.5028 18.1484 17.4046C18.3569 17.1532 18.5419 16.8897 18.7043 16.6155C18.7695 16.5051 18.7334 16.3622 18.6238 16.2966C18.5142 16.2304 18.3722 16.2668 18.3065 16.3771ZM18.9591 14.722C18.8948 15.0116 18.8078 15.2975 18.6982 15.5759C18.6511 15.6955 18.7093 15.831 18.8282 15.878C18.9466 15.9255 19.0812 15.8668 19.1283 15.7477C19.2472 15.446 19.3411 15.1368 19.4104 14.8231C19.4382 14.6978 19.3596 14.5735 19.2347 14.5456C19.1103 14.5176 18.9864 14.5968 18.9591 14.722ZM19.0673 12.944C19.0955 13.2411 19.1006 13.5395 19.0825 13.8375C19.0747 13.9655 19.1718 14.0763 19.2994 14.0837C19.4266 14.0917 19.5362 13.9939 19.5441 13.8659C19.564 13.5437 19.5584 13.2206 19.5274 12.8994C19.5154 12.7713 19.4021 12.6777 19.2754 12.6899C19.1482 12.702 19.0548 12.816 19.0673 12.944ZM18.6229 11.2201C18.7422 11.4948 18.8388 11.7769 18.9128 12.0641C18.9447 12.1884 19.071 12.2629 19.1949 12.2308C19.3184 12.1987 19.3924 12.0716 19.3605 11.9473C19.2805 11.6363 19.1755 11.3304 19.0465 11.0334C18.9951 10.9156 18.8587 10.8616 18.7417 10.9133C18.6247 10.9649 18.5715 11.1023 18.6229 11.2201ZM17.6651 9.72283C17.8653 9.94816 18.0448 10.1856 18.2034 10.4333C18.2723 10.5413 18.4157 10.5725 18.523 10.5026C18.6303 10.4333 18.6612 10.2889 18.5919 10.1809C18.4208 9.91324 18.2265 9.65578 18.0092 9.4123C17.9241 9.31639 17.7779 9.30848 17.6827 9.39414C17.5879 9.4798 17.58 9.62738 17.6651 9.72283Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.4409 5.61267C9.08757 5.61267 5.55359 9.14665 5.55359 13.5C5.55359 17.8533 9.08757 21.3873 13.4409 21.3873C17.7942 21.3873 21.3282 17.8533 21.3282 13.5C21.3282 9.14665 17.7942 5.61267 13.4409 5.61267ZM13.9049 6.5559C17.5298 6.79484 20.4003 9.81475 20.4003 13.5C20.4003 17.1852 17.5298 20.2051 13.9049 20.4441V6.5559Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.1116 1.32213V3.46343C12.1116 4.19318 12.7072 4.78556 13.4409 4.78556C14.1746 4.78556 14.7702 4.19318 14.7702 3.46343V1.32213C14.7702 0.592374 14.1746 0 13.4409 0C12.7072 0 12.1116 0.592374 12.1116 1.32213Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M21.0877 4.01472L19.5511 5.5513C19.0274 6.07499 19.0274 6.92528 19.5511 7.44898C20.0748 7.97267 20.9251 7.97267 21.4488 7.44898L22.9854 5.9124C23.509 5.38871 23.509 4.53841 22.9854 4.01472C22.4617 3.49102 21.6114 3.49102 21.0877 4.01472Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M25.6581 12.3649H23.4849C22.7443 12.3649 22.1431 12.9661 22.1431 13.7068C22.1431 14.4474 22.7443 15.0486 23.4849 15.0486H25.6581C26.3988 15.0486 27 14.4474 27 13.7068C27 12.9661 26.3988 12.3649 25.6581 12.3649Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22.9854 21.5012L21.4488 19.9646C20.9251 19.4409 20.0748 19.4409 19.5511 19.9646C19.0274 20.4883 19.0274 21.3386 19.5511 21.8623L21.0877 23.3989C21.6114 23.9226 22.4617 23.9226 22.9854 23.3989C23.509 22.8752 23.509 22.0249 22.9854 21.5012Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.7702 25.6778V23.5365C14.7702 22.8068 14.1746 22.2144 13.4409 22.2144C12.7072 22.2144 12.1116 22.8068 12.1116 23.5365V25.6778C12.1116 26.4076 12.7072 27 13.4409 27C14.1746 27 14.7702 26.4076 14.7702 25.6778Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.78958 23.0647L7.29553 21.5372C7.80878 21.0166 7.80878 20.1714 7.29553 19.6508C6.78228 19.1302 5.94893 19.1302 5.43568 19.6508L3.92974 21.1783C3.41649 21.6989 3.41649 22.5441 3.92974 23.0647C4.44299 23.5853 5.27633 23.5853 5.78958 23.0647Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.32213 14.8293H3.46343C4.19318 14.8293 4.78556 14.2337 4.78556 13.5C4.78556 12.7663 4.19318 12.1707 3.46343 12.1707H1.32213C0.592374 12.1707 0 12.7663 0 13.5C0 14.2337 0.592374 14.8293 1.32213 14.8293Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.92974 5.82165L5.43568 7.34911C5.94893 7.86969 6.78228 7.86969 7.29553 7.34911C7.80878 6.82853 7.80878 5.98328 7.29553 5.46269L5.78958 3.93524C5.27633 3.41465 4.44299 3.41465 3.92974 3.93524C3.41649 4.45582 3.41649 5.30107 3.92974 5.82165Z"
                    />
                  </svg>
                )}

              </span>
              <p className="text-thin-light-gray text-base font-medium">
                {darkMode.theme === "light" ? "Dark" : "Light"} Mode
              </p>
            </div>
          </div>
        </div>

        {/*JOB LINKS*/}
        {(userDetails?.account_type !== "FAMILY" && walletExist) &&
          myJobList?.data?.result_list?.length > 0 && (
            <div className="top-platform mt-6 bg-white dark:bg-dark-white rounded-2xl py-8 w-full 2xl:mb-10 2xl:border-none border ">
              {/* heading */}
              <div className="px-8 heading flex justify-between items-center mb-3.5">
                <h3 className="text-xl font-bold   text-dark-gray dark:text-white">
                  Job Links
                </h3>
                <div></div>
              </div>

              <div className="platform-list flex flex-col gap-y-2.5">
                <div className="px-8 item flex space-x-3 items-center">
                  {/* image */}
                  <div className="w-8 h-8 p-[4px] rounded-full">
                    <img
                      src={localImgLoad("images/icons/job_active.svg")}
                      className="w-full h-full"
                      alt="Active Task"
                    />
                  </div>
                  {/* name */}
                  <div>
                    <p className="text-thin-light-gray text-base font-medium">
                      <NavLink to="/my-active-jobs">Active Task</NavLink>
                    </p>
                  </div>
                  {/* action */}
                </div>

                <div className="px-8 item flex space-x-3 items-center">
                  {/* image */}
                  <div className="w-8 h-8 p-[4px] rounded-full">
                    <img
                      src={localImgLoad("images/icons/job_active.svg")}
                      className="w-full h-full"
                      alt="Active Task"
                    />
                  </div>
                  {/* name */}
                  <div>
                    <p className="text-thin-light-gray text-base font-medium">
                      <NavLink to="/offer-interest">Interest</NavLink>
                    </p>
                  </div>
                  {/* action */}
                </div>

                <div className="px-8 item flex space-x-3 items-center">
                  {/* image */}
                  <div className="w-8 h-8 p-[4px] rounded-full">
                    <img
                      src={localImgLoad("images/icons/job_pending.svg")}
                      className="w-full h-full"
                      alt="Review Task"
                    />
                  </div>
                  {/* name */}
                  <div>
                    <p className="text-thin-light-gray text-base font-medium">
                      <NavLink to="/my-review-jobs">Review</NavLink>
                    </p>
                  </div>
                </div>

                <div className="px-8 item flex space-x-3 items-center">
                  {/* image */}
                  <div className="w-8 h-8 p-[4px] rounded-full">
                    <img
                      src={localImgLoad("images/icons/job_past_due.svg")}
                      className="w-full h-full"
                      alt="Past Due Task"
                    />
                  </div>
                  {/* name */}
                  <div>
                    <p className="text-thin-light-gray text-base font-medium">
                      <NavLink to="/my-pastdue-jobs">Past Due</NavLink>
                    </p>
                  </div>
                </div>

                {/* Line */}
                <div className="my-4 mx-auto w-10/12 h-[2px] bg-slate-500 dark:bg-white rounded-full"></div>

                <div className="px-8 item flex space-x-3 items-center">
                  {/* image */}
                  <div className="w-8 h-8 p-[4px] rounded-full">
                    <img
                      src={localImgLoad("images/icons/job_group.svg")}
                      className="w-full h-full"
                      alt="Job Groups"
                    />
                  </div>
                  {/* name */}
                  <div>
                    <p className="text-thin-light-gray text-base font-medium">
                      <NavLink to="/job-groups">Job Groups</NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
}
