import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../Partials/Layout";
import CommonHead from "../UserHeader/CommonHead";
import OffersInterestTable from './OffersInterestTable'

export default function OffersInterest(props) {
    const [selectTab, setValue] = useState("today");
    const filterHandler = (value) => {
        setValue(value);
    };
    
    return (
        <Layout>
            <CommonHead
                commonHeadData={props.commonHeadData}
            />
            <div className="notification-page w-full mb-10">
                <div className="notification-wrapper w-full">
                    {/* heading */}
                    <div className="sm:flex justify-between items-center mb-6">
                        <div className="mb-5 sm:mb-0">
                            <h1 className="text-26 font-bold   text-dark-gray dark:text-white">
                                <span className={`${selectTab === "today" ? "block" : "hidden"}`}>Offer Interest</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <OffersInterestTable offerInterestList={props.offerInterestList} />
        </Layout>
    );
}
