import React from "react";
import AppDownload from "../components/AppDownload/AppDownload";
import { useLocation } from "react-router-dom";

function AppDownloadPage() {
  return (
    <>
      <AppDownload />
    </>
  );
}

export default AppDownloadPage;
