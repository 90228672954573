import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    familyBannersList: {loading:true}
};

export const familyBannersListSlice = createSlice({
  name: "familyBannersList",
  initialState,
  reducers: {
    familyBannersList: (state,action) => {
      state.familyBannersList = {...action.payload}
    },
  },
});

// Action creators are generated for each case reducer function
export const { familyBannersList } = familyBannersListSlice.actions;

export default familyBannersListSlice.reducer;