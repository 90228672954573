import React from "react";
import Login from "../components/AuthPages/Login/index2";

function LoginPageTwo() {
  return (
    <>
      <Login />
    </>
  );
}

export default LoginPageTwo;
