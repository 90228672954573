import { useEffect, useState } from "react";

function CountDown({ lastDate = "" }) {
  // State to store the countdown values
  const [countdownValues, setCountdownValues] = useState({
    showHour: 0,
    showMinute: 0,
    showSecond: 0,
  });

  useEffect(() => {
    if (lastDate) {
      // Interval function to update countdown values
      const intervalId = setInterval(() => {
        const now = new Date().getTime();
        const targetDate = new Date(lastDate).getTime();
        const distance = targetDate - now;

        if (distance < 0) {
          // If the countdown has reached zero or gone past the target date, clear the interval
          clearInterval(intervalId);
          setCountdownValues({
            showHour: 0,
            showMinute: 0,
            showSecond: 0,
          });
          return;
        }

        // Calculate the countdown values (days, hours, minutes, seconds)
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // since we don't have a slot for days...
        const totalHours = days * 24 + hours;

        // Update the countdown values in the state
        setCountdownValues({
          showHour: totalHours,
          showMinute: minutes,
          showSecond: seconds,
        });
      }, 1000);

      // Clean up the interval on component unmount or when the lastDate prop changes
      return () => clearInterval(intervalId);
    }
  }, [lastDate]);

  // Destructure the countdown values from the state
  const { showHour, showMinute, showSecond } = countdownValues;

  return (
    <span>
      {showHour < 10 ? "0" + showHour : showHour} :{" "}
      {showMinute < 10 ? "0" + showMinute : showMinute} :{" "}
      {showSecond < 10 ? "0" + showSecond : showSecond}
    </span>
  );
}

export default CountDown;
