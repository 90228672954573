import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../Partials/Layout";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import CouponTable from "./CouponTable";
import usersService from "../../services/UsersService";
import { useSelector } from "react-redux";

export default function MyCoupons() {
  const apiCall = useMemo(() => new usersService(), []);
  const {couponTable, walletTable} = useSelector(state => state.tableReload)
  let [couponHistory, setCouponHistory] = useState({
    // FOR COUPON HISTORY
    loading: true,
    data: [],
    error: false,
  });

  //FUNCTION TO GET COUPON HISTORY
  const getCouponHistory = useCallback(() => {
    apiCall
      .getCouponPending()
      .then((res) => {
        if (res.data.internal_return < 0) {
          // success but no data
          setCouponHistory((prev) => ({ ...prev, loading: false }));
          return;
        }
        setCouponHistory((prev) => ({
          ...prev,
          loading: false,
          data: res.data.result_list,
        }));
      })
      .catch((error) => {
        setCouponHistory((prev) => ({ ...prev, loading: false, error: true }));
      });
  }, [apiCall]);

  useEffect(() => {
    getCouponHistory();
  }, [couponTable, walletTable]);

  return (
    <>
      <Layout>
        <div className="my-wallet-wrapper w-full mb-10">
          {/* heading */}
          <div className="sm:flex justify-start items-center mb-6">
            <div className="mb-5 sm:mb-0">
              <h1 className="text-26 font-bold   text-dark-gray dark:text-white">
                Coupons
              </h1>
            </div>
          </div>
          <div className="w-full lg:flex xl:space-x-8 lg:space-x-4 bottomMargin">
            {/* COUPON SECTION */}
            <div className="lg:w-4/4 w-full mb-10 lg:mb-0">
              <div className="wallet w-full md:p-8 p-4 h-full max-h-[500px] min-h-[500px] bg-white dark:bg-dark-white overflow-y-auto rounded-2xl shadow coupon">
                {couponHistory.loading ? (
                  <div className="w-full h-full flex justify-center items-center">
                    <LoadingSpinner size="16" color="sky-blue" />
                  </div>
                ) : (
                  <CouponTable coupon={couponHistory} />
                )}
              </div>
            </div>
            {/* END OF COUPON SECTION */}
          </div>
        </div>
      </Layout>
    </>
  );
}
