import React from 'react'

function Detail({label, value, bg,}) {
  return (
    <>
        <label className='job-label w-full md:w-[150px]'>{label}</label>
        <p className={`p-1 w-full md:w-3/4 text-sm text-slate-900 dark:text-white ${bg ? bg : null}`}>{value}</p>
    </>
  )
}

export default Detail