import React from 'react'

import CurrentJobAction from './CurrentJobAction'
import CurrentTaskAction from './CurrentTaskAction'

import PastDueJobAction from './PastDueJobAction'
import PastDueTaskAction from './PastDueTaskAction'

import ReviewJobAction from './ReviewJobAction'
import ReviewTaskAction from './ReviewTaskAction'

function IndexJobActions({details}) { // FUNCTION TO RENDER SPECIFIC JOB ACTION DEPENDING ON OWNER STATUS & STATUS DESCRIPTION
    let owner = details?.owner_status
    let description = details?.status_description
    switch(owner) {
        case 'OWNER':
            return (()=>{
                if(description == 'ACTIVE'){
                    return <CurrentJobAction jobDetails={details} />
                }else if(description == 'PASTDUE'){
                    return <PastDueJobAction jobDetails={details} />
                }else if(description == 'REVIEW'){
                    return <ReviewJobAction jobDetails={details} />
                } else {
                    return null
                }
            })()
        case 'WORKER':
            return (()=>{
                if(description == 'ACTIVE'){
                    return <CurrentTaskAction jobDetails={details} />
                }else if(description == 'PASTDUE'){
                    return <PastDueTaskAction jobDetails={details} />
                }else if(description == 'REVIEW'){
                    return <ReviewTaskAction jobDetails={details} />
                } else {
                    return null
                }
            })()
        default:
            return null
    }
}

export default IndexJobActions