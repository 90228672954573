import React from 'react'

function PastDueTaskAction() {
  return (
    <div className='job-action dark:bg-black'>

        <table className="w-full text-sm text-left text-gray-500 worker-pastdue">
            <tbody>
            <tr>
                <td>
                    <div className="flex space-x-2 items-center w-full task_action_panel">

                            <div>
                                You have missed the allocated time
                            </div>
                            {/*<div className="flex flex-col flex-[0.9]"> </div>*/}
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div className="flex justify-center items-center">
                        <button type="button" className="w-[150px] h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white">
                            Request Extension
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
  )
}

export default PastDueTaskAction