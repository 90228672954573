import React, { useState } from 'react'
import { handlePagingFunc } from '../../../Pagination/HandlePagination';
import PaginatedList  from '../../../Pagination/PaginatedList';

import RelativePopout from './RelativePopout';
import RelativeReminderPopout from './RelativeReminderPopout';

export default function RelativeTable({relativeList, familyList}) {
  
    // Handle Pagination
    const [currentPage, setCurrentPage] = useState(0);
    const indexOfFirstItem = Number(currentPage);
    const indexOfLastItem =Number(indexOfFirstItem) + Number(process.env.REACT_APP_ITEM_PER_PAGE);

    const currentRelativeList = relativeList?.slice(indexOfFirstItem, indexOfLastItem);

    const [relativePopout, setRelativePopout] = useState({show:false, data:null})

    const [reminderPopout, setReminderPopout] = useState({show:false, data:null})

    const closePopout = () => {
      setRelativePopout({show:false, data:null})
    }

    const closeReminderPopout = () => {
      setReminderPopout({show:false, data:null})
    }
  
    const handlePagination = (e) => {
      handlePagingFunc(e, setCurrentPage);
    };
   
    return (
      <>
        <div className={`w-full overflow-hidden rounded-2xl`}>
            <div className="relative w-full overflow-x-auto sm:rounded-lg flex flex-col justify-between min-h-[400px]">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead>
                  <tr>
                    <th className='py-4 px-2'></th>
                    <th className='py-4 px-2'></th>
                    <th className='py-4 px-2 text-center'>Status</th>
                    <th className='py-4 px-2 text-center'>Kids</th>
                    <th className='py-4 px-2'></th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {relativeList && relativeList?.length > 0 ? (
                        currentRelativeList.map((value, index) => (
                        <tr key={value.uid || index} className="border-b dark:border-[#5356fb29] hover:bg-gray-50">
                          <td className='py-4 px-2'>
                            <div className="flex flex-col">
                              <h1 className="font-bold text-xl text-dark-gray dark:text-white">
                                {value.firstname && value.firstname} {value.lastname && value.lastname}
                              </h1>
                              <span className="text-sm text-thin-light-gray">
                                {value.email && value.email}
                              </span>
                            </div>
                          </td>
                          <td className='py-4 px-2'>
                            {/* <span>Family Type</span> */}
                            <span>{value.family_type && value.family_type.toUpperCase()}</span>
                          </td>
                          {value.status_action ?
                          <td className='py-4 px-2 text-center'>
                            <button
                              type='button'
                              className='p-1 cursor-pointer text-center bg-sky-50'
                              onClick={() => setReminderPopout({show:true, data:{relativeSelected:value}})}
                            >
                              {value.status && value.status}
                            </button>
                          </td>
                            :
                          <td className='py-4 px-2 text-center'>
                            {value.status && value.status}
                          </td>
                          }
                          <td className='py-4 px-2 text-center'>
                            {value.kid_count && value.kid_count}
                          </td>
                          <td className='py-4 px-2 flex items-center justify-end'>
                            <button
                              onClick={() => setRelativePopout({show:true, data:{relativeSelected:value}})}
                              type="button"
                              className="w-12 h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 11 20"
                                id="Arrow"
                                className="w-[0.7rem]"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M.366 19.708c.405.39 1.06.39 1.464 0l8.563-8.264a1.95 1.95 0 0 0 0-2.827L1.768.292A1.063 1.063 0 0 0 .314.282a.976.976 0 0 0-.011 1.425l7.894 7.617a.975.975 0 0 1 0 1.414L.366 18.295a.974.974 0 0 0 0 1.413"
                                  // fill=""
                                  className="color000000 svgShape fill-[#fff]"
                                ></path>
                              </svg>
                            </button>
                          </td>
                        </tr>
                        ))
                    ) : (
                      <tr className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                        <td className="p-2">No Members Found</td>
                      </tr>
                    )}
                  </>
                </tbody>
              </table>
    
              {/* PAGINATION BUTTON */}
              <PaginatedList
                onClick={handlePagination}
                prev={currentPage == 0 ? true : false}
                next={
                  currentPage + Number(process.env.REACT_APP_ITEM_PER_PAGE) >=
                  relativeList?.length
                    ? true
                    : false
                }
                data={relativeList}
                start={indexOfFirstItem}
                stop={indexOfLastItem}
              />
              {/* END OF PAGINATION BUTTON */}
            </div>
        </div>

        {relativePopout.show &&
          <RelativePopout
            situation={relativePopout.show}
            action={closePopout}
            relativeSelected={relativePopout.data.relativeSelected}
            familyList={familyList}
          />
        }

        {reminderPopout.show &&
          <RelativeReminderPopout
            situation={reminderPopout.show}
            action={closeReminderPopout}
            relativeSelected={reminderPopout.data.relativeSelected}
            familyList={familyList}
          />
        }

      </>
      );
    };
