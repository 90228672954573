import React from "react";

export default function PrivacyPolicyTab() {
  return (
    <div className="terms-conditon-tab w-full">
      <div className="terms-condition-wrappr w-full">
        <div className="mb-6">
          <h1 className="text-3xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            Privacy Policy
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] ">
            Wrenchboard issues this policy to inform users about the information
            we collect, the use and disclosures in the course of interactions
            with the WrenchBoard services by customers. We value the privacy of
            our customers and such strive to employ best industry standards and
            practices to protect their privacy. By signing up for this services,
            using our products or technologies offered through the WrenchBoard
            website, you expressly agree to the terms of this Privacy Policy.
          </p>
        </div>
        <hr />
        <div className="my-8">
          <h1 className="text-2xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            Collection of Information
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] ">
            When you sign up for WrenchBoard platform as a user, freelancer,
            worker or client, you inadvertently provide certain personal
            information such as name and email address. This information may be
            used to identify or contact you as part of the service we provide.
            Below is information we may collect from registered users of the
            service.
          </p>
          <ul className="flex flex-col rounded-[0.25rem]">
            {[
              "We collect financial information which include bank account numbers, debit/credit account.",
              "We collect financial information which include bank account numbers, debit/credit account.",
              "We collect personal information (names email addresses) from you and your friends when you use our referral program. We may also receive information about you from third party sites during promotions and campaigns.",
            ].map((item, idx) => (
              <li
                className="border-opacity-10 block relative px-[1.25rem] py-[0.75rem] first:rounded-tl first:rounded-tr text-[#505056]"
                key={idx}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
        <hr />

        <div className="my-8">
          <h1 className="text-2xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            Use of Personal Information
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] ">
            The information we collect about you is mainly utilized to provide a
            secure, efficient and smooth user experience as you interact with
            the site. We may also utilize the information to contact you to
            resolve issues with your account or resolve disputes between
            freelancers and clients. We also use your personal information in
            the following ways.
          </p>
          <ul className="flex flex-col rounded-[0.25rem]">
            {[
              "To comply with the regulatory requirement, whenever the need arises.",
              "To improve business relationship between Wrenchboard and users.",
              "To manage and protect our IT infrastructure.",
              "To contact you as the need arises as part of the services we offer to users.",
              "To provide some indication on the promotional offers and advertisement we direct to you.",
              "To protect you and your information. We regularly send you notification based on activities as you use the service.",
            ].map((item, idx) => (
              <li
                className="border-opacity-10 block relative px-[1.25rem] py-[0.75rem] first:rounded-tl first:rounded-tr text-[#505056]"
                key={idx}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
        <hr />

        <div className="my-8">
          <h1 className="text-2xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            How We Share Your Personal Information:
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] ">
            When you sign up to the Service as a freelancer and create a
            profile, we will share your limited personal information with
            clients or project owners for whom you may perform a task or do work
            for based on expression of interest. Such information will include
            name, profile information with experience and your ranking based on
            previous performance. This information will be available to the
            public.
            <br />
            When you register as a client or project owner and post a job in the
            market place or offer jobs to preselected freelancers or workers on
            this service, information about you, your business will be shared.
            This information will be based on the information provided in your
            profile. <br />
            In order to deliver a quality service on WrenchBoard, we require the
            services of third parties and agents such as PayPal, Quickteller
            Service or banks to facilitate and process transactions. <br />
            We may also share your personal information when and if compelled or
            mandated by government agencies, or court injunctions or regulatory
            requirement. In order to settle disputes or claims, we may also be
            compelled to share your information in order to deliver services to
            you. <br />
            Finally, we may share your information with companies that we plan
            to to merge with or to be acquired by. We will take necessary
            measure to ensure that all such new affiliations and corporate
            entities comply with the Privacy Policies hereby stated failing
            which you will be notified duly.
          </p>
        </div>
        <hr />

        <div className="my-8">
          <h1 className="text-2xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            How We Use Cookies and Related Technologies:
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] ">
            When you access the service or website, www.wrenchboard.com , we
            store small files called Cookies in your browser. Cookies and
            related technologies like beacons and tags are used to identify
            customers uniquely. With Cookies, we can track and trend the pages
            of interest within the website. Hence, these technologies help us
            focus only relevant promotions or advertisement to each customer.
            Through this technologies, we are able to improve the service based
            on the feedback from the interaction with users. We also rely on
            these technologies to mitigate fraudulent attempts on your
            WrenchBoard account. You may elect to disable cookies to prevent
            installation of cookies from the website to your browser. However,
            your functional experience with the service or website may be
            impacted. Note that you automatically disconnect from cookies when
            you close your browser session or log out of the service.
          </p>
        </div>
        <hr />

        <div className="my-8">
          <h1 className="text-2xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            Measures We Take to Secure Your Information
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] ">
            Your data and privacy are important to us as such we take extreme
            care on how we protect your information. We use a combination of
            industry standard encryption technologies to protect your
            information. Your password is not visible to our technical support
            team or any one in WrenchBoard. Our IT infrastructures are located
            in hosting facilities with strict access controls, and security
            protocols with only pre checked and authorized persons have access.
          </p>
        </div>
        <hr />

        <div className="my-8">
          <h1 className="text-2xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            Updating Your Information or Opting Out
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] ">
            Users of this service may update the information posted in profiles
            or their accounts at any time. The accuracy of information posted on
            the profiles and accounts is entirely the responsibility of users.
            You may also decide to opt out the service or close your account at
            any time. We may also deactivate your account if you violate the
            terms of use of the website. Whilst we will endeavor to delete your
            core information in such instances, we will retain minimal records
            for reasons including disputes, claims or administrative purposes.
            Also, note that it may not be possible to delete any information you
            had shared with other users on the website previously.
          </p>
        </div>
        <hr />

        <div className="my-8">
          <h1 className="text-2xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            Deactivate/Delete your account
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] ">
            If you no longer wish to use our Services, you can deactivate your
            Services account. If you want to deactivate your account, that
            setting is available to you in your account settings. Otherwise,
            please get in touch with our support. While we close any associated
            process, you will not be able to access your account. Note that this
            process cannot be reversed since we will remove your data. If you
            want to use our services in the future, it will be a new account
            altogether.
          </p>
        </div>
        <hr />

        <div className="mt-8">
          <h1 className="text-2xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            Changes To the Policy
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] ">
            We reserve the rights to update and make changes to this Privacy
            policy at anytime. Changes will become effective once posted.
            However, we will notify you by email or when you log on to the
            service or website about any changes that fundamentally affect how
            we manage your personal information. Contacting Us: You may contact
            us about this policy through our email address anytime :
            support@wrenchboard.com
          </p>
        </div>
      </div>
    </div>
  );
}
