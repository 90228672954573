import React, { useContext,useState, useEffect } from "react";
import usersService from "../services/UsersService";
import ManageInterestOffer from "../components/OffersInterest/ManageInterestOffer";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export default function MyReviewDueJobsPage() {
    const {state} = useLocation()
    let navigate = useNavigate()

    let {commonHeadBanner} = useSelector(state => state.commonHeadBanner)
    let { othersInterestedTable } = useSelector((state) => state.tableReload); // FOR OTHERS INTERESTED TABLE RELOAD

    const apiCall = new usersService();
    const [othersInterestedList, setOthersInterestedList] = useState({loading: true, data: [], imageServer:''})
   
    useEffect(() => {
        if(!state){
            navigate('/', {replace: true})
            return
        }
        apiCall.offersInterestList().then(res => {
            let newData
            if(res.data.result_list.length){
                newData = res.data.result_list.filter(item => item.offer_code == state.offer_code && item.client_uid != state.client_uid)
            }else{
                newData = []
            }
            setOthersInterestedList({loading: false, data: newData, imageServer:res.data.session_image_server})
        }).catch(err => {
            setOthersInterestedList({loading: false, data: [], imageServer:''})
            console.log('Error: ', err)
        })
    }, [othersInterestedTable]);

    // debugger;
    return (
        <>
            <ManageInterestOffer
                othersInterestedList={othersInterestedList}
                commonHeadData={commonHeadBanner.result_list}
                offerDetails={state}
            />
        </>
    );
}
