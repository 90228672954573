import React, { useContext,useState, useEffect } from "react";
import usersService from "../services/UsersService";

import MyPastDueJobs from "../components/MyActiveJobs/MyPastDueJobs";
import { useSelector } from "react-redux";

export default function MyPastDueJobsPage() {
    let {commonHeadBanner} = useSelector(state => state.commonHeadBanner)
    const [MyJobList, setMyJobList] = useState([]);
    const api = new usersService();

    const getMyJobList = async () => {
        try {
            const res = await api.getMyPastDueJobList();
            setMyJobList(res.data);
        } catch (error) {
            console.log("Error getting mode");
        }
    };
    useEffect(() => {
        getMyJobList();
    }, []);

    // debugger;
    return (
        <>
            <MyPastDueJobs
                MyJobList={MyJobList}
                commonHeadData={commonHeadBanner?.result_list}
            />
        </>
    );
}
