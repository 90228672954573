import React,{useState, useEffect} from "react";
import Settings from "../components/Settings";
import usersService from "../services/UsersService";

export default function SettingsPage() {

  const apiCall = new usersService()

  let [faq, setFaq] = useState({loading:true, data:null}) // STATE TO HOLD FAQ DATA

  //FUNCTION TO GET FAQ
  const getFaq = () => {
    apiCall.getFaq().then(res => {
      setFaq({loading:false, data:res.data.result_list})
    }).catch(err => {
      setFaq({loading:false, data:null})
      console.log('Error', err)
    })
  }

  useEffect(()=>{
    getFaq()
  },[])
  
  return (
    <>
      <Settings faq={faq} />
    </>
  );
}
