import React from 'react'
import { Link } from 'react-router-dom';

export default function FamBlogItem({datas, className, bg, hidden=false}) {
    return (
        <div
          className={`card-style-two w-full h-[336px] p-[20px] bg-white dark:bg-dark-white   rounded-2xl section-shadow ${
            className || ""
          }`}
        >
          <div className="flex flex-col justify-between w-full h-full">
            <div className="thumbnail-area w-full">
              <div
                className="w-full h-[236px] p-6  rounded-xl overflow-hidden bg-sky-200"
                style={{
                background: `url(${`${bg}${datas.meta_value}`}) 0% 0% / cover no-repeat`,
                }}
              >
                <div className="product-two-options flex justify-between mb-5 relative">
                  <div className="status">
                    {datas?.isActive && (
                      <span className="text-xs px-3 py-1.5 tracking-wide rounded-full bg-gold text-white">
                        Active
                      </span>
                    )}
                  </div>
                </div>
                {hidden && <div className="flex justify-center"></div>}
              </div>
            </div>
            <div className="details-area">
              {/* title */}
              <Link to={`/blog-page?blog_id=${datas?.ID ? datas.ID : '1'}`} className="mb-2.5" rel="noreferrer">
                <h1 className="font-bold text-xl tracking-wide line-clamp-1 text-dark-gray dark:text-white capitalize">
                  {datas?.post_title ? datas?.post_title : "dummy title..."}
                </h1>
              </Link>
              <div className="flex justify-between">
                <div className="flex items-center space-x-2"></div>
                <div className="my-1">
                  <Link
                    to={`/blog-page?blog_id=${datas?.ID ? datas.ID : '1'}`}
                    className="px-4 py-2.5 text-white text-sm bg-pink rounded-full tracking-wide"
                    rel="noreferrer"
                  >
                    View
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}
