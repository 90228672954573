import React, { useEffect, useState } from "react";
import PaginatedList from "../Pagination/PaginatedList";
import { handlePagingFunc } from "../Pagination/HandlePagination";
import ModalCom from "../Helpers/ModalCom";
import CouponPopup from "./CouponPopup";
import { useNavigate } from "react-router-dom";
import { PriceFormatter } from "../Helpers/PriceFormatter";

function CouponTable({ coupon }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [couponPopup, setCouponPopup] = useState({ state: false, data: null });
  const indexOfFirstItem = Number(currentPage);
  const indexOfLastItem =
    Number(indexOfFirstItem) + Number(process.env.REACT_APP_ITEM_PER_PAGE);
  const currentCoupon = coupon?.data?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePagination = (e) => {
    handlePagingFunc(e, setCurrentPage);
  };

  const navigate = useNavigate();

  const handleCouponPopUp = () =>
    setCouponPopup((prev) => ({ state: !prev.state }));

  useEffect(() => {
    if (!coupon) navigate("/my-coupon", { replace: true });
  }, []);

  return (
    <div className="flex flex-col justify-between h-full">
      <table className="wallet-activity w-full table-auto border-collapse text-left">
        <thead className="border-b-2">
          <tr className="text-slate-600">
            <th className="p-2">Coupon</th>
            <th className="p-2 text-center">Amount</th>
            <th className="p-2"></th>
          </tr>
        </thead>
        {coupon.data.length ? (
          <tbody>
            {currentCoupon.map((item, index) => {
              let thePrice = PriceFormatter(
                item?.amount * 0.01,
                item?.currency_code,
                item?.currency
              );
              return(
              <tr key={index} className="text-slate-500">
                <td className="p-2 cursor-default">
                  {item.added} <br /> {item.code}
                </td>
                <td className="p-2 text-center cursor-default">{thePrice}</td>
                <td className="p-2 h-20 flex items-center justify-end">
                  <button
                    type="button"
                    className="btn-gradient w-[116px] h-[46px] text-white rounded-full text-base bg-pink flex justify-center items-center"
                    onClick={() => {
                      setCouponPopup((prev) => ({
                        state: !prev.state,
                        data: {...item, thePrice},
                      }));
                    }}
                  >
                    Redeem
                  </button>
                </td>
              </tr>
            )})}
          </tbody>
        ) : coupon.error ? (
          <tbody>
            <tr className="text-slate-500">
              <td className="p-2" colSpan={4}>
                Opps! an error occurred. Please try again!
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            <tr className="text-slate-500">
              <td className="p-2" colSpan={4}>
                No Purchase History Found!
              </td>
            </tr>
          </tbody>
        )}
      </table>

      {/* PAGINATION BUTTON */}
      <PaginatedList
        onClick={handlePagination}
        prev={currentPage === 0 ? true : false}
        next={
          currentPage + Number(process.env.REACT_APP_ITEM_PER_PAGE) >=
          coupon?.data?.length
            ? true
            : false
        }
        data={coupon?.data}
        start={indexOfFirstItem}
        stop={indexOfLastItem}
      />
      {/* END OF PAGINATION BUTTON */}

      {couponPopup.state && (
        <ModalCom action={handleCouponPopUp} situation={couponPopup.state}>
          <CouponPopup
            data={couponPopup.data}
            popUpHandler={handleCouponPopUp}
          />
        </ModalCom>
      )}
    </div>
  );
}
export default CouponTable;
