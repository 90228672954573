import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    familyResources: {}
};

export const familyResourcesSlice = createSlice({
  name: "familyResources",
  initialState,
  reducers: {
    familyResources: (state,action) => {
      state.familyResources = {...action.payload}
    },
  },
});

// Action creators are generated for each case reducer function
export const { familyResources } = familyResourcesSlice.actions;

export default familyResourcesSlice.reducer;