import React from "react";
import Tracking from "../components/Tracking";

export default function TrackingPage() {
    return (
        <>
            <Tracking />
        </>
    );
}
