import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import localImgLoad from "../../lib/localImgLoad";
import CountDown from "../Helpers/CountDown";
import Icons from "../Helpers/Icons";

export default function FamilyActiveJobsCard({ datas, hidden = false, image_server }) {

    let { pathname } = useLocation();

    const [imageUrl, setImageUrl] = useState("");
    const [addFavorite, setValue] = useState(false);
    const [options, setOption] = useState(false);
    const favoriteHandler = () => {
        if (!addFavorite) {
            setValue(true);
            toast.success("Added to Favorite List");
        } else {
            setValue(false);
            toast.warn("Remove to Favorite List");
        }
    };
//debugger;
    // const bannerName  =  datas.banner == null ?'default.jpg':datas.banner;
    let image = `${image_server}${localStorage.getItem('session_token')}/job/${datas.origin_job_uid}`
    return (
        <div className="card-style-one flex flex-col justify-between w-full h-[387px] bg-white dark:bg-dark-white   p-3 pb rounded-2xl">
            <div className="content">
                {/* thumbnail */}
                <div className="w-full h-40">
                    {/* thumbnail image */}
                    <div
                        className="thumbnail w-full h-full rounded-xl overflow-hidden px-4 pt-4"
                        // style={{
                        //     background: `url(${localImgLoad(
                        //         `images/taskbanners/${bannerName}`
                        //     )}) center / contain no-repeat`,
                        // }}
                        style={{
                            background: `url(${image}) center / contain no-repeat`,
                        }}
                    >
              {/*          <div className="product-options flex justify-between relative">*/}
              {/*<span*/}
              {/*    onClick={favoriteHandler}*/}
              {/*    className={`w-7 h-7 bg-white   rounded-full flex justify-center items-center  cursor-pointer ${*/}
              {/*        addFavorite ? "text-pink" : "  text-dark-gray"*/}
              {/*    }`}*/}
              {/*>*/}
              {/*  <Icons name="love" />*/}
              {/*</span>*/}
              {/*              <span*/}
              {/*                  onClick={() => setOption(!options)}*/}
              {/*                  className="w-7 h-7 flex justify-center items-center bg-white  rounded-full cursor-pointer"*/}
              {/*              >*/}
              {/*  <Icons name="dots" />*/}
              {/*</span>*/}
              {/*              {options && (*/}
              {/*                  <div*/}
              {/*                      onClick={() => setOption(!options)}*/}
              {/*                      className="w-full h-screen fixed top-0 left-0 z-10"*/}
              {/*                  ></div>*/}
              {/*              )}*/}
              {/*              <div*/}
              {/*                  style={{ boxShadow: "0px 4px 87px 0px #0000002B" }}*/}
              {/*                  className={`drop-down-content w-[80px] bg-white dark:bg-dark-white   rounded-[4px] p-2.5 absolute right-0 top-[100%] z-20 ${*/}
              {/*                      options ? "active" : ""*/}
              {/*                  }`}*/}
              {/*              >*/}

              {/*              </div>*/}
              {/*          </div>*/}
                        {hidden && (
                            <div className="flex justify-center">

                            </div>
                        )}
                    </div>
                </div>
                {/* details */}
                <div className="details">
                    {/* product title */}
                    <Link
                        to="/manage-active-job"
                        state={{ ...datas, pathname }}
                        className="text-xl font-bold text-dark-gray dark:text-white mb-2 capitalize line-clamp-1"
                    >
                        {datas.title}
                    </Link>
                    {/* countdown */}
                    <div className="w-full h-[54px] flex justify-evenly items-center p-2 rounded-lg border border-[#E3E4FE] dark:border-[#a7a9b533] ">
                        <div className="flex flex-col justify-between">
                            <p className="text-sm text-thin-light-gray dark:text-white tracking-wide">
                                Task Code
                            </p>
                            <p className="text-base font-bold tracking-wide   text-dark-gray dark:text-white">
                                {datas.contract}
                            </p>
                        </div>
                        <div className="w-[1px] h-full bg-[#E3E4FE] dark:bg-[#a7a9b533] "></div>
                        <div className="flex flex-col justify-between">
                            <p className="text-sm text-thin-light-gray dark:text-white tracking-wide">
                                Remaining Time
                            </p>
                            <p className="text-base font-bold tracking-wide   text-dark-gray dark:text-white">
                                <CountDown lastDate={datas.delivery_date} />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-buttons flex justify-end items-center space-x-2">
                <Link
                    to="/manage-active-job"
                    state={{ ...datas, pathname }}
                    className="btn-shine w-[98px] h-[33px] text-white rounded-full text-sm bg-pink flex justify-center items-center"
                >
                    View
                </Link>
            </div>
        </div>
    );
}
