import React, { useEffect, useState } from 'react'
import Layout from '../Partials/Layout'
import MyOffersFamilyTable from '../MyTasks/MyOffersFamilyTable'
import LoadingSpinner from '../Spinners/LoadingSpinner';
import usersService from '../../services/UsersService';
import CustomBreadcrumb from '../Breadcrumb/CustomBreadcrumb';
import { useSelector } from 'react-redux';

export default function FamilyPendingOffer() {
    const userApi = new usersService();

    const {familyOfferList} = useSelector((state) => state.tableReload)

    const [myOffersList, setMyOffersList] = useState({loading: true, data: []});

    const getMyOffersList = async () => {
        try {
            const res = await userApi.getOffersList();
            setMyOffersList({loading:false, data:res.data});
        } catch (error) {
            setMyOffersList({loading:false, data:[]});
        }
    };

    useEffect(()=>{
        getMyOffersList()
    },[familyOfferList])
    return (
        <Layout>
            {myOffersList.loading ?
            <div className='w-full flex justify-center items-center rounded-2xl bg-white'>
                <LoadingSpinner size='10' color='sky-blue' height='h-[20rem]'  />
            </div>
            :
            myOffersList?.data?.result_list && myOffersList?.data?.result_list.length > 0 ?
            <MyOffersFamilyTable
                familyOffers={myOffersList?.data?.result_list}
                image_server={myOffersList?.data?.session_image_server}
                className="mb-10" 
            />
            :
            <>
                <div className="mb-6">
                    <CustomBreadcrumb 
                    title = {'Ready to Start'}
                    breadcrumb={
                        [
                        { link: "/", title: "Home" },
                        { link: "/pending", title: "Pending", active: true},
                        ]
                    }
                    />
                </div>
                <div className='w-full h-[30rem] bg-white dark:bg-dark-white flex justify-center items-center rounded-2xl'>
                    <p className='text-black dark:text-white'>No Record Found!</p>
                </div>
            </>
            }
        </Layout>
    )
}
