import React, { useEffect, useState } from "react";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import CustomBreadcrumb from "../../Breadcrumb/CustomBreadcrumb";
import Icons from "../../Helpers/Icons";
import Layout from "../../Partials/Layout";
import { AddFamily, FamilyBanner, Relatives } from "./Tabs";

const FamilySettings = () => {

  const {loader, setLoader, familyList, setListReload} = useOutletContext() // CONTEXT VALUES FROM OUTLET TO CHILD

  let { state } = useLocation();

  const imageServer = state?.imageServer

  let relativeFamilyList = {loader, familyList, imageServer}

  const tabs = [
    {
      id: 1,
      name: "add_family",
      title: "Add Family",
      iconName: "new-family",
    },
    {
      id: 2,
      name: "relatives",
      title: "Relatives",
      iconName: "people-hover",
    },
    {
      id: 3,
      name: "family_banner",
      title: "Family Banner",
      iconName: "people-hover",
    },
  ];

  const [tab, setTab] = useState(() => {
    // Retrieve the active tab from local storage or use the default tab
    return localStorage.getItem("activeTab") || tabs[0].name;
  });

  const tabHandler = (value) => {
    setTab(value);
  };

  // Update local storage when the tab changes
  useEffect(() => {
    localStorage.setItem("activeTab", tab);
  }, [tab]);

  const tabComponents = {
    add_family: <AddFamily />,
    relatives: <Relatives familyList={relativeFamilyList} />,
    family_banner: <FamilyBanner imageServer={state.imageServer} />,
  };

  const defaultTabComponent = Array(tabComponents)[0].add_family;

  const selectedComponent = tabComponents[tab] || defaultTabComponent;

  return (
    <Layout>
      <div className="notification-page w-full mb-10">
        <div className="notification-wrapper w-full">
          {/* heading */}
          <div className="sm:flex justify-between items-center mb-6">
            <div className="mb-5 sm:mb-0">
              <CustomBreadcrumb
                title={"Family Settings"}
                breadcrumb={[
                  { link: "/", title: "Home" },
                  {
                    link: "/acc-family",
                    title: "Family Account",
                  },
                  {
                    link: "/familysettings",
                    title: "Family Settings",
                    active: true,
                  },
                ]}
              />
            </div>
            <Link
              className="item-content relative text-[18px] transition-all duration-300 ease-in-out bg-[#76a5df] text-white font-medium dark:text-white h-12 px-2 flex items-center gap-2 rounded-md shadow-sm justify-center cursor-pointer dark:bg-[linear-gradient(134.38deg,#f539f8_0%,#c342f9_43.55%,#5356fb_104.51%)]"
              to="/acc-family"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>

              <span>Family</span>
            </Link>
          </div>

          <div className="w-full bg-white dark:bg-dark-white overflow-y-auto rounded-2xl section-shadow h-full ">
            <div className="update-table w-full h-full p-4 bg-white dark:bg-dark-white overflow-y-auto rounded-2xl section-shadow min-h-[520px] lg:flex lg:px-10 px-4 justify-between">
              <div className="content-tab-items lg:w-[230px] w-full mr-2">
                <ul className="overflow-hidden mb-10 lg:mb-0 py-8">
                  {tabs.map(({ name, id, title, iconName }) => (
                    <li
                      onClick={() => tabHandler(name)}
                      key={id}
                      className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                        tab === name ? "text-purple" : " text-thin-light-gray"
                      }`}
                    >
                      <div>
                        <Icons name={iconName} />
                      </div>
                      <div>
                        <p className="text-18 tracking-wide">{title}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="w-[1px] bg-[#E3E4FE] dark:bg-[#a7a9b533]  mr-10"></div>
              <div className="flex-1">
                <div className="tab-item">{selectedComponent}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FamilySettings;
