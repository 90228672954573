import React, { useState } from 'react'
import { localImgLoad } from '../../../../lib'
import { PriceFormatter } from '../../../Helpers/PriceFormatter'
import FamilyAddFundPopout from './FamilyAddFundPopout'

function Wallet({wallet, familyData, setFamilyWalletReload}) {
    const [addFundPopout, setAddFundPopout] = useState({ show: false, data: {} })
  return (
    <div className='w-full p-4 bg-[aliceblue] rounded-lg'
    // style={{
    //   background: `url(${background}) 0% 0% / cover no-repeat`,
    // }}
  >
    <div className="w-full flex justify-start items-center gap-3">
      <div className="min-w-[50px] min-h-[50px] max-w-min md:max-w-[80px] max-h-min md:max-h-[80px] rounded-full bg-[#e3e3e3] flex justify-center items-center">
        <img
          src={localImgLoad(`images/currency/${(wallet.code).toLowerCase()}.svg`)}
          className="w-full h-full"
          alt="currency-icon"
        />
      </div>
      <div className="w-full flex flex-col">
        <div className="w-full flex gap-2">
          <p className="text-base md:text-lg text-thin-light-gray tracking-wide">
            Balance:
          </p>
          <p 
            className="text-base md:text-lg font-bold text-purple tracking-wide leading-10"
            // className="text-[44px] lg:text-[62px] font-bold text-white tracking-wide leading-10 xxs:scale-100 lg:scale-100 xl:scale-125"
            >
            {PriceFormatter(
              Number(wallet.amount) * 0.01,
              wallet.code,
              wallet.country,
              ""
            )}
          </p>
        </div>
        {/* BTN */}
        <div className='mt-1 flex justify-end items-center gap-2'>
            <button
                className="w-[150px] h-[48px] rounded-full text-base text-white bg-sky-500 hover:bg-sky-400"
                name="add"
                onClick={()=>{
                    setAddFundPopout({ show: true, data: {} });
                }}
            >
                Add Money
            </button>
            <button
                className="w-[150px] h-[48px] rounded-full text-base text-white bg-[#4687ba] hover:bg-[#009ef7]"
                name="plan"
                // onClick={onClose}
            >
                Plan Wallet
            </button>
        </div>
      </div>
    </div>

    {/* MODAL TO ADD FUND */}
    {addFundPopout.show &&
        <FamilyAddFundPopout 
            action={() => {
                setAddFundPopout({ show: false, data: {} });
                setFamilyWalletReload(prev => !prev) // TO RELOAD FAMILY WALLET // DETERMINES WHEN TO RELOAD FAMILY WALLET TAB/PAGE
            }}
            situation={addFundPopout.show}
            wallet={wallet}
            familyData={familyData}
        />
    }
</div>
  )
}

export default Wallet