import React from 'react'
import { Route, Routes } from "react-router-dom";

import FamilyRoutes from '../components/FamilyAcc/FamilyRoutes'
import FamilyAcc from '../components/FamilyAcc';
import FamilyActivities from '../components/FamilyAcc/FamilyActivities';
import FamilySettings from '../components/FamilyAcc/FamilySettings';
import FourZeroFour from '../components/FourZeroFour';

export default function FamilyRoutesPage() {
  return (
    <Routes>
        <Route element={<FamilyRoutes />}>
            <Route exact path='/' element={<FamilyAcc />} />
            <Route exact path="/activities" element={<FamilyActivities />} />
            <Route exact path="/familysettings" element={<FamilySettings />} />
            <Route exact path="/*" element={<FourZeroFour />} />
        </Route>
    </Routes>
  )
}
