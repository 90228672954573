import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useOutletContext } from "react-router-dom";
import usersService from "../../../services/UsersService";
import { tableReload } from "../../../store/TableReloads";
import ModalCom from "../../Helpers/ModalCom";
import { PriceFormatter } from "../../Helpers/PriceFormatter";
import LoadingSpinner from "../../Spinners/LoadingSpinner";
import Detail from "../../jobPopout/popoutcomponent/Detail";
import { NewTasks } from "./forms";
import { SocketValues } from "../../Contexts/SocketIOContext";
import { errorMsg } from "../../../lib/errorMsg";

import AssignPrevNewTask from "./AssignPrevNewTask";
import AssignMediaTask from "./AssignMediaTask";

const AssignTaskPopout = ({
  action,
  details,
  situation,
  familyDetailsData,
  jobList,
  activeTask,
  setActiveTask,
  setUpdatePage,
  familyList
}) => {

  const newJobList = {...jobList, data:jobList?.data?.filter(item => item?.job_mode == 'FAMILY')}
  
  const {parentAssignJobToKid} = SocketValues()

  const { walletDetails } = useSelector((state) => state?.walletDetails); // WALLET STORE


  const apiCall = new usersService();

  let { pathname, state } = useLocation();

  const {userDetails} = useSelector((state) => state?.userDetails); // CHECKS IF USER Details are avaliable, to determine if user is active

  const [selectedFamilyUid, setSelectedFamilyUid] = useState('');

  const handleFamChange = (event) => {
    setSelectedFamilyUid(event.target.value);
  };


  const dispatch = useDispatch();

  // const getFamilySession = JSON.parse(sessionStorage.getItem("family_list"));

  const famList = familyList?.map((member) => (
    <option key={member?.family_uid} value={member?.family_uid}>
      {member?.firstname} {member?.lastname}
    </option>
  ));

  let [requestStatus, setRequestStatus] = useState({
    loading: false,
    status: false,
    message: "",
  }); // HOLDS RESPONSE FOR SENDING API REQUEST

  let [commonMedia, setCommonMedia] = useState({loading: true, data: [], image: ''})  // HOLDS COMMON MEDIA DATA
  let [activeMedia, setActiveMedia] = useState({})  // HOLDS ACTIVE COMMON MEDIA DATA
  const handleActiveMedia = (data = {}) => {
    // FUNCTION TO CHANGE SELECTED ACTIVE MEDIA
    setActiveMedia({...data});
  };


  let [assignType, setAssignType] = useState("task"); // SWITCHES BTW TASK AND MEDIA ASSIGNMENT

  const switchAssignType = ({ target: { name } }) => {
    // FUNCTION TO CHANGE ASSIGN TASK TYPE
    setAssignType(name);
  };

  let [taskType, setTaskType] = useState(details ? "new" : "select"); // SWITCHES BTW SELECT TASK AND NEW TASK

  const switchTaskType = ({ target: { value } }) => {
    // FUNCTION TO CHANGE SELECTED ACTIVE TASK
    setTaskType(value);
  };

  const handleActiveTask = (id = 0, data = {}) => {
    // FUNCTION TO CHANGE SELECTED ACTIVE TASK
    setActiveTask({ id, data });
  };

  // New Task
  const [formState, setFormState] = useState({
    // Initialize form state with desired fields
    banner: details?.banner || "default.jpg",
    country: details?.country ? details?.country : walletDetails.data.length == 1 ? walletDetails.data[0].country : '',
    price: details?.price || "",
    title: details?.title || "",
    description: details?.description || "",
    job_detail: details?.job_detail || "",
    timeline_days: details?.timeline_days || "",
    category: details?.category || "",
  });

  const assignFamilyTask = () => {
    setRequestStatus({ loading: true, status: false, message: "" });

    if(!selectedFamilyUid){ // If no family found, throw error
      setRequestStatus({ loading: false, status: false, message: "Please Select a Kid" });
      return setTimeout(() => {
        setRequestStatus({ loading: false, status: false, message: "" });
      }, 3000);
    }

    let reqData = {};
    if (taskType == "select" && assignType == 'task') {
      // RUNS HERE IF TASK TYPE IS SELECT
      if (!Object.keys(activeTask.data).length) {
        setRequestStatus({
          loading: false,
          status: false,
          message: "No Task is selected",
        });
        return setTimeout(() => {
          setRequestStatus({ loading: false, status: false, message: "" });
        }, 3000);
      }
      reqData = {
        // API PAYLOADS
        job_id: activeTask.data?.job_id,
        job_uid: activeTask.data?.job_uid,
        family_uid: selectedFamilyUid
          ? selectedFamilyUid
          : familyDetailsData?.uid || details?.family_uid,
        job_description: activeTask.data?.description,
        assign_mode: 110011,
      };
    }

    if (taskType === "new" && assignType == 'task') {
      const {
        banner,
        category,
        country,
        description,
        job_detail,
        price,
        timeline_days,
        title,
      } = formState;

      const requiredFields = {
        banner,
        // category,
        currency: country,
        description,
        "job detail": job_detail,
        price,
        timeline: timeline_days,
        title,
      };

      for (let field in requiredFields) {
        if (requiredFields[field] == "") {
          // let currencyErrMsg = field == "country" && "currency"
          setRequestStatus({
            loading: false,
            status: false,
            message: `${
              field[0].toUpperCase() + field.slice(1).toLowerCase()
            } is empty`,
          });
          return setTimeout(() => {
            setRequestStatus({ loading: false, status: false, message: "" });
          }, 3000);
        }
      }

      reqData = {
        banner,
        category,
        country,
        description,
        job_detail,
        price: price * 100,
        timeline_days,
        title,
        assign_mode: 110055,
        family_uid: selectedFamilyUid
        ? selectedFamilyUid
        : familyDetailsData?.uid || details?.family_uid,
      };
    }

    apiCall
      .assignFamilyTask(reqData)
      .then((res) => {
        if (res.status != 200 || res.data.internal_return < 0) {

          let error = errorMsg[res?.data?.error_msg] || ''

          setRequestStatus({
            loading: false,
            status: false,
            message: error? error : "failed to assign task",
          });

          return setTimeout(() => {
            setRequestStatus({ loading: false, status: false, message: "" });
          }, 5000);
        }
        if (res.status === 200) {

          setRequestStatus({
            loading: false,
            status: true,
            message: "Task Assigned successfully",
          });

          setUpdatePage(prev => !prev); // Updates family task page by calling the useeffect hook

          dispatch(tableReload({ type: "WALLETTABLE" })); // RELOADS USER WALLET

          //SENDS MESSAGE TO SOCKET TO UPDATE CHILD ACCOUNT
          // message, room
          let socketMsg = {
            "audience": "MEMBER",
            "action": "REFRESH_OFFER",
            "family_uid": reqData.family_uid,
          }
          let socketRoom = `FAMILY-${userDetails.uid}`
          parentAssignJobToKid(socketMsg, socketRoom) //SENDS MESSAGE TO SOCKET TO UPDATE CHILD ACCOUNT

          setTimeout(() => {
            setRequestStatus({ loading: false, status: false, message: "" });
            action(); // FUNCTION THAT CLOSES THE MODAL BOX
          }, 5000);
        }
      })
      .catch((err) => {
        setRequestStatus({
          loading: false,
          status: false,
          message: "An Error occured, try again",
        });

        setTimeout(() => {
          setRequestStatus({ loading: false, status: false, message: "" });
        }, 5000);
      });
  };

  const closeModal = () => {  // FOR CLOSING ASSIGN TASK MODAL
    action()
  }

  const assignMediaTask = (values, helpers) => {  // FUNCTION TO HANDLE ASSIGNING MEDIA TASK
    setRequestStatus({ loading: true, status: false, message: "" });

    if(!selectedFamilyUid){ // If no family found, throw error
      setRequestStatus({ loading: false, status: false, message: "Please Select a Kid" });
      return setTimeout(() => {
        setRequestStatus({ loading: false, status: false, message: "" });
      }, 3000);
    }

    let reqData = {...values, assign_mode:'110012', family_uid:selectedFamilyUid, media_uid:activeMedia.uid, amount:values.amount * 100}

    apiCall.parentAssignMediaTask(reqData).then(res => {  // API CALL TO ASSIGN MEDIA TASK
      if(res.status != 200 || res.data.internal_return < 0){
        let error = errorMsg[res?.data?.error_msg] || ''

        setRequestStatus({
          loading: false,
          status: false,
          message: error? error : "failed to assign task",
        });
        return setTimeout(()=>{
          setRequestStatus({ loading: false, status: false, message: "" });
        }, 3000)
      }
      setRequestStatus({ loading: false, status: true, message: "Task Assigned Successfully" });
      return setTimeout(()=>{
        setRequestStatus({ loading: false, status: false, message: "" });
        closeModal() // FOR CLOSING ASSIGN MODAL
      }, 3000)
    }).catch(err => {
      setRequestStatus({ loading: false, status: false, message: "Failed, something went wrong. Try Again" });
      return setTimeout(()=>{
        setRequestStatus({ loading: false, status: false, message: "" });
      }, 3000)
    })
  }

  useEffect(()=>{ // effect to update family UID when components mounts
    if(familyDetailsData?.uid){
      setSelectedFamilyUid(familyDetailsData?.uid)
    }else if(details?.family_uid){
      setSelectedFamilyUid(details?.family_uid)
    }else{
      setSelectedFamilyUid('')
    }
  },[])

  useEffect(()=>{
    apiCall.getParentCommonMedia().then((res)=>{
        // console.log('RESPONSE', res)
        setCommonMedia({loading: false, data: res?.data?.result, image: ''})
        setActiveMedia(res?.data?.result[0])
    }).catch(err => {
        setCommonMedia({loading: false, data: [], image: ''})
    })
  },[])

  return (
    <>
      <ModalCom action={action} situation={situation}>
        <div className="modal-container">
          <div className="modal-header-con">
            <h1 className="modal-title">
              {details ? (
                ` Assign ${details?.firstname}'s Task`
              ) : familyDetailsData ? (
                ` Assign ${familyDetailsData.firstname}'s Task`
              ) : (
                <div className="flex items-center gap-2">
                  <span className="text-black">Assign task to{" "}</span>
                  <div className="w-[270px] h-[40px] flex items-center">
                    <select
                      name=""
                      id=""
                      className="text-lg text-black/80 px-2 tracking-wide font-semibold transition-all cursor-pointer bg-white focus:outline-none border border-gray-200 rounded-full w-full h-full"
                      onChange={handleFamChange}
                      value={selectedFamilyUid}
                    >
                      <option value="" className="">
                        Select a kid
                      </option>
                      {famList}
                    </select>
                  </div>
                </div>
              )}
            </h1>
            <button
              type="button"
              className="modal-close-btn"
              onClick={action}
            >
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                className="fill-current"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36 16.16C36 17.4399 36 18.7199 36 20.0001C35.7911 20.0709 35.8636 20.2554 35.8385 20.4001C34.5321 27.9453 30.246 32.9248 22.9603 35.2822C21.9006 35.6251 20.7753 35.7657 19.6802 35.9997C18.4003 35.9997 17.1204 35.9997 15.8401 35.9997C15.5896 35.7086 15.2189 35.7732 14.9034 35.7093C7.77231 34.2621 3.08728 30.0725 0.769671 23.187C0.435002 22.1926 0.445997 21.1199 0 20.1599C0 18.7198 0 17.2798 0 15.8398C0.291376 15.6195 0.214408 15.2656 0.270759 14.9808C1.71321 7.69774 6.02611 2.99691 13.0428 0.700951C14.0118 0.383805 15.0509 0.386897 15.9999 0C17.2265 0 18.4532 0 19.6799 0C19.7156 0.124041 19.8125 0.136067 19.9225 0.146719C27.3 0.868973 33.5322 6.21922 35.3801 13.427C35.6121 14.3313 35.7945 15.2484 36 16.16ZM33.011 18.0787C33.0433 9.77105 26.3423 3.00309 18.077 2.9945C9.78479 2.98626 3.00344 9.658 2.98523 17.8426C2.96667 26.1633 9.58859 32.9601 17.7602 33.0079C26.197 33.0577 32.9787 26.4186 33.011 18.0787Z"
                  fill=""
                  fillOpacity="0.6"
                />
                <path
                  d="M15.9309 18.023C13.9329 16.037 12.007 14.1207 10.0787 12.2072C9.60071 11.733 9.26398 11.2162 9.51996 10.506C9.945 9.32677 11.1954 9.0811 12.1437 10.0174C13.9067 11.7585 15.6766 13.494 17.385 15.2879C17.9108 15.8401 18.1633 15.7487 18.6375 15.258C20.3586 13.4761 22.1199 11.7327 23.8822 9.99096C24.8175 9.06632 26.1095 9.33639 26.4967 10.517C26.7286 11.2241 26.3919 11.7413 25.9133 12.2178C24.1757 13.9472 22.4477 15.6855 20.7104 17.4148C20.5228 17.6018 20.2964 17.7495 20.0466 17.9485C22.0831 19.974 24.0372 21.8992 25.9689 23.8468C26.9262 24.8119 26.6489 26.1101 25.4336 26.4987C24.712 26.7292 24.2131 26.3441 23.7455 25.8757C21.9945 24.1227 20.2232 22.3892 18.5045 20.6049C18.0698 20.1534 17.8716 20.2269 17.4802 20.6282C15.732 22.4215 13.9493 24.1807 12.1777 25.951C11.7022 26.4262 11.193 26.7471 10.4738 26.4537C9.31345 25.9798 9.06881 24.8398 9.98589 23.8952C11.285 22.5576 12.6138 21.2484 13.9387 19.9355C14.5792 19.3005 15.2399 18.6852 15.9309 18.023Z"
                  fill="#"
                  fillOpacity="0.6"
                />
              </svg>
            </button>
          </div>
          <div className="modal-body-wrapper p-[0!important]">
            <div className="px-4 py-2 w-full flex items-center gap-4">
              <button
                name='task'
                className={`py-1 px-2 font-medium bg-transparent border border-purple text-purple transition-all rounded-md duration-300 ${assignType=='task' && 'bg-yellow-500'}`}
                onClick={switchAssignType}
                disabled={requestStatus.loading}
              >
              Task
              </button>
              <button
                name='media'
                className={`py-1 px-2 font-medium bg-transparent border border-purple text-purple transition-all rounded-md duration-300 ${assignType=='media' && 'bg-yellow-500'}`}
                onClick={switchAssignType}
                disabled={requestStatus.loading}
              >
              Media
              </button>
            </div>
            <div className="contents">
              {assignType == 'task' ?
                <AssignPrevNewTask 
                  jobList={newJobList}
                  requestStatus={requestStatus}
                  assignFamilyTask={assignFamilyTask}
                  taskType={taskType}
                  switchTaskType={switchTaskType}
                  formState={formState}
                  setFormState={setFormState}
                  activeTask={activeTask}
                  handleActiveTask={handleActiveTask}
                  closeModal={closeModal}
                />
                :
                <AssignMediaTask 
                  commonMedia={commonMedia}
                  requestStatus={requestStatus}
                  assignMediaTask={assignMediaTask}
                  activeMedia={activeMedia}
                  handleActiveMedia={handleActiveMedia}
                  closeModal={closeModal}
                  family_uid = {selectedFamilyUid}
                />
              }
            </div>
          </div>
        </div>
      </ModalCom>
    </>
  );
};

export default AssignTaskPopout;
