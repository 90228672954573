import React, { useEffect, useState } from 'react'
import Layout from '../Partials/Layout'

import LoadingSpinner from '../Spinners/LoadingSpinner'

function Lnd() {

  const [reqStatus, setReqStatus] = useState({loading:true, data: []})

  useEffect(()=>{
    const timer = setTimeout(()=>{
      setReqStatus({loading:false, data: []})
    },2000)
    return () => clearTimeout(timer)
  }, [])
  return (
    <>
      {reqStatus.loading ?
        <LoadingSpinner color='sky-blue' size='32' height='min-h-screen' />
      :
        <div className='min-h-screen flex flex-col justify-center items-center'>Empty Dummy Page</div>
      }
    </>
  )
}

export default Lnd