export default function getTimeAgo(dateString) {
  const date = new Date(dateString);
  const now = new Date();

  const timeDifference = now.getTime() - date.getTime();
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);

  if (secondsDifference < 60) {
    return "Just now";
  } else if (minutesDifference < 60) {
    return `${minutesDifference} ${
      minutesDifference === 1 ? "minute" : "minutes"
    } ago`;
  } else if (hoursDifference < 24) {
    return `${hoursDifference} ${hoursDifference === 1 ? "hour" : "hours"} ago`;
  } else if (hoursDifference < 48) {
    return "Yesterday";
  } else if (daysDifference < 7) {
    return `${daysDifference} ${daysDifference === 1 ? "day" : "days"} ago`;
  } else if (daysDifference < 30) {
    const weeksDifference = Math.floor(daysDifference / 7);
    return `${weeksDifference} ${weeksDifference === 1 ? "week" : "weeks"} ago`;
  } else if (daysDifference < 365) {
    const monthsDifference = Math.floor(daysDifference / 30);
    return `${monthsDifference} ${
      monthsDifference === 1 ? "month" : "months"
    } ago`;
  } else {
    const yearsDifference = Math.floor(daysDifference / 365);
    return `${yearsDifference} ${yearsDifference === 1 ? "year" : "years"} ago`;
  }
}
