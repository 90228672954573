import React, { useRef } from "react";
//import ProductCardStyleOne from "../Cards/ProductCardStyleOne";
import Icons from "../Helpers/Icons";
import SliderCom from "../Helpers/SliderCom";
import FamilyActiveJobsCard from "../Cards/FamilyActiveJobsCard";

export default function FamilyActiveLSlde({ className, trending, image_server }) {
    const settings = {
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: trending?.length > 3,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: trending?.length > 3,
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: trending?.length > 2,
                },
            },
            {
                breakpoint: 619,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: trending?.length > 1,
                },
            },
        ],
    };
    const trendingSlider = useRef(null);
    const prevHandler = () => {
        trendingSlider.current.slickPrev();
    };
    const nextHandler = () => {
        trendingSlider.current.slickNext();
    };
    return (
        <div className={`trending-section w-full px-2 sm:px-0 ${className || ""}`}>
            {/* heading */}
            <div className="flex justify-between items-center mb-6">
                <div>
                    <h1 className="text-26 font-bold   text-dark-gray dark:text-white">Current Tasks</h1>
                </div>
                <div className="slider-btns flex space-x-3">
                    <button onClick={nextHandler} type="button">
                        <div className="trending-slider-left-btn relative text-white w-10 h-10 flex justify-center items-center rounded-full overflow-hidden">
                            <svg
                                width="11"
                                height="19"
                                viewBox="0 0 11 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.09766 1.1499L1.13307 9.11449L9.09766 17.0791"
                                    stroke="url(#paint0_linear_220_23410)"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <defs>
                                    <linearGradient
                                        id="paint0_linear_220_23410"
                                        x1="9.09766"
                                        y1="1.1499"
                                        x2="-4.2474"
                                        y2="7.96749"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stopColor="#F539F8" />
                                        <stop offset="0.416763" stopColor="#C342F9" />
                                        <stop offset="1" stopColor="#5356FB" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    </button>
                    <button onClick={prevHandler} type="button">
                        <div className="trending-slider-right-btn  primary-gradient  text-white w-10 h-10 relative flex justify-center items-center rounded-full ">
                            <Icons name="arrows" />
                        </div>
                    </button>
                </div>
            </div>
            {/* trending products */}
            <div className="trending-products slider-left relative w-full rounded-2xl p-[10px] bg-alice-blue">
                <SliderCom selector={trendingSlider} settings={settings}>
                    {trending &&
                        trending.length > 0 &&
                        trending.map((item) => (
                            <FamilyActiveJobsCard key={item.id} datas={item} image_server={image_server} />
                        ))}
                </SliderCom>
            </div>
        </div>
    );
}
