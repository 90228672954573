import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: {
    loading: false,
    data: null,
  },
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    updateNotifications: (state, action) => {
      state.notifications = { ...action.payload };
    },
  },
});

export const { updateNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;
