import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../Partials/Layout";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import FamilyManageTabs from "./FamilyManageTabs";
import CustomBreadcrumb from "../Breadcrumb/CustomBreadcrumb";

export default function FamilyManage() {
  const [selectTab, setValue] = useState("today");
  let [pageLoading, setPageLoading] = useState(true);

  let location = useLocation();
  let navigate = useNavigate();
  let accountDetails = location?.state;

  //   tab handler
  const filterHandler = (value) => {
    setValue(value);
  };

  useEffect(() => {
    if (!accountDetails) {
      navigate("/acc-family", { replace: true });
    } else {
      setPageLoading(false);
    }
  }, []);

  return (
    <Layout>
      {/*<CommonHead />*/}
      {pageLoading ? (
        <LoadingSpinner size={8} color="sky-blue" />
      ) : (
        <div className="notification-page w-full mb-10">
          <div className="notification-wrapper w-full">
            {/* heading */}
            {/* <div className="sm:flex justify-between items-center mb-6">
              <div className="mb-5 sm:mb-0">
                <h1 className="text-26 font-bold inline-flex gap-3 text-dark-gray dark:text-white items-center">
                  <span
                    className={`${selectTab === "today" ? "block" : "hidden"}`}
                  >
                    Manage Family
                  </span>
                </h1>
              </div>
              <div className="slider-btns flex space-x-4">
                <div
                  onClick={() => filterHandler("today")}
                  className="relative"
                ></div>
              </div>
            </div> */}
            <div className="w-full mb-5 flex justify-between items-center">
              <div className="">
                  <CustomBreadcrumb 
                  title = {'Manage Family'}
                  breadcrumb={
                      [
                      { link: "/", title: "Home" },
                      { link: "/manage-family", title: "Manage Family", active: true},
                      ]
                  }
                  />
              </div>
              <Link
                className="item-content relative text-[18px] transition-all duration-300 ease-in-out bg-[#76a5df] text-white font-medium dark:text-white h-12 px-2 flex items-center gap-2 rounded-md shadow-sm justify-center cursor-pointer dark:bg-[linear-gradient(134.38deg,#f539f8_0%,#c342f9_43.55%,#5356fb_104.51%)]"
                to="/acc-family"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                  />
                </svg>

              <span>Family</span>
            </Link>
            </div>
            <FamilyManageTabs accountDetails={accountDetails} />
          </div>
        </div>
      )}
    </Layout>
  );
}
