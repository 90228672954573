import React, { useState } from "react";
import usersService from "../../services/UsersService";
import Layout from "../Partials/Layout";
import LoadedPage from "./LoadedPage";
import YourPageForm from "./YourPageForm";
// import { updateYourPage } from "./updateYourPage";

const YourPage = () => {
  const [pageValues, setPageValues] = useState(pageInitialValues);
  const [response, setResponse] = useState(responseInitialValues);
  const [reloader, setReloader] = useState(false);

  const handleChange = ({ target: { name, value } }) =>
    setPageValues((prev) => ({ ...prev, [name]: value }));

  const updateYourPageDetails = async () => {
    if (!pageValues.intro || !pageValues.description) return;

    try {
      setResponse({ loading: true, error: "", msg: "" });

      let api = new usersService();
      const res = await api.MyPageIntro(pageValues);

      setTimeout(() => {
        setResponse({
          loading: false,
          data: res.data,
          msg: "Update Complete",
        });

        setReloader((prev) => !prev);
      }, 1000);

      setTimeout(() => {
        setResponse({ msg: "" });
        // Clear form after successful update
        setPageValues({ intro: "", description: "" });
      }, 3000);
    } catch (error) {
      return setResponse({
        loading: false,
        data: {},
        error: "Error updating page",
        msg: "",
      });
    }
  };

  return (
    <Layout>
      <div className="notification-page w-full mb-10">
        <div className="notification-wrapper w-full">
          <div className="update-table w-full h-full p-4 bg-white dark:bg-dark-white overflow-y-auto rounded-2xl section-shadow min-h-[650px]">
            <h1 className="text-26 font-bold text-dark-gray dark:text-white tracking-wide mb-2">
              My page
            </h1>
            <hr />
            <YourPageForm
              values={pageValues}
              onChange={handleChange}
              onSubmit={updateYourPageDetails}
              loading={response.loading}
              msg={response.msg}
            />
            <LoadedPage reloader={reloader} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default YourPage;

const pageInitialValues = {
  intro: "",
  description: "",
};

const responseInitialValues = {
  loading: false,
  data: {},
  error: "",
  msg: "",
};
