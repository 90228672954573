import React from 'react'

import Lnd from '../components/Lnd/Lnd'

function LndPage() {
  return (
    <>
        <Lnd />
    </>
  )
}

export default LndPage