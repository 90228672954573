import React, { useEffect } from "react";

export default function ModalCom({ action, children, situation, isOpen, className }) {
  useEffect(() => {
    if (situation) {
      document.body.style.overflowY = "hidden";
    }
    return () => {
      document.body.style.overflowY = "unset";
    };
  });

  return (
    <div className="modal-com">
      <div
        onClick={action || isOpen}
        className="fixed top-0 left-0 bottom-0 w-full bg-black bg-opacity-40 backdrop-filter backdrop-blur-sm z-50"
      ></div>
      <div className={`fixed h-full z-[99999999999999] w-full lg:w-auto inset-0 flex flex-col justify-center items-center ${className}`}>
        {children && children}
      </div>
    </div>
  );
}
