import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../Partials/Layout";
import SearchCom from "../Helpers/SearchCom";
import FamilyMarketCard from "../Cards/FamilyMarketCard";
import usersService from "../../services/UsersService";
import SuggestTask from "../FamilyPopup/SuggestTask";
import CustomBreadcrumb from "../Breadcrumb/CustomBreadcrumb";

export default function FamilyMarket() {
  const [popUp, setPopUp] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [myFamilySampleTasks, setMyFamilySampleTasks] = useState({
    loading: true,
    status: false,
    data: [],
  });

  const apiCall = useMemo(() => new usersService(), []);

  const getMyFamilySampleTasks = useCallback(async () => {
    try {
      setMyFamilySampleTasks({ loading: true, status: false, data: [] });
      const res = await apiCall.getFamilySampleTasks();
      setMyFamilySampleTasks({
        loading: false,
        status: true,
        data: res.data.result_list,
      });
    } catch (error) {
      setMyFamilySampleTasks({ loading: false, status: false, data: [] });
      console.log("Error getting tasks:", error);
    }
  }, [apiCall]);

  useEffect(() => {
    getMyFamilySampleTasks();
  }, [getMyFamilySampleTasks]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredTasks =
    myFamilySampleTasks.data?.filter((task) =>
      task.title.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  const popUpHandler = () => {
    setPopUp((prev) => !prev);
  };

  return (
    <Layout>
      <div className="notification-page w-full mb-10">
        <div className="notification-wrapper w-full">
          {/* heading */}
          {/* <div className="sm:flex justify-between items-center mb-6">
            <div className="mb-5 sm:mb-0">
              <h1 className="text-26 font-bold inline-flex gap-3 text-dark-gray dark:text-white items-center">
                <span>Suggest Task to the Parents</span>
              </h1>
            </div>
          </div> */}
          <div className="mb-5">
            <CustomBreadcrumb 
            title = {'Suggest Task to the Parents'}
            breadcrumb={
                [
                { link: "/", title: "Home" },
                { link: "/familymarket", title: "Family Market", active: true},
                ]
            }
            />
          </div>
          {/* Body */}
          <div className="filter-section w-full items-center sm:flex justify-between mb-6">
            {/* filter-search */}
            <div className="sm:w-1/2 w-full sm:pr-20 pr-0 mb-5 sm:mb-0">
              <SearchCom
                placeholder="Search for tasks..."
                handleSearch={handleSearch}
                value={searchQuery}
              />
            </div>
            {/* filer-dropdown */}
            <div className="flex-1 flex sm:justify-end">
              <button
                onClick={popUpHandler}
                className="btn-gradient lg:flex hidden w-[153px] h-[46px] rounded-full text-white justify-center items-center"
              >
                Any Other Task
              </button>
            </div>
          </div>
          <div className="content-section w-full-width">
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-[30px]">
              {filteredTasks.map((task) => (
                <FamilyMarketCard key={task.id} datas={task} />
              ))}
            </div>
          </div>
        </div>
      </div>
      {popUp && <SuggestTask onClose={popUpHandler} situation={popUp} />}
    </Layout>
  );
}
