import {useEffect} from 'react'
import WalletRoutes from "../components/MyWallet/Wallet";
import { useSelector } from "react-redux";
import {useNavigate} from 'react-router-dom'

export default function MyWalletPage() {
  const {userDetails: { account_type, wallet_available_status }} = useSelector((state) => state?.userDetails);
  const navigate = useNavigate()

  useEffect(()=>{
    let walletExist = wallet_available_status || localStorage.getItem('wallet_available_status')
    console.log(walletExist)
    if(account_type == 'FULL' && walletExist != 'WALLET_AVAILABLE'){
      navigate('/', {replace: true})
    }
  },[])

  return <WalletRoutes />;
}
