import React from "react";
import UpdatePassword from "../components/AuthPages/UpdatePassword/index2";

export default function UpdatePasswordPagesTwo() {
  return (
    <>
      <UpdatePassword />
    </>
  );
}
