import React from "react";
import SignUp from "../components/AuthPages/SignUp/index2";

function SignupPageTwo() {
  return (
    <>
      <SignUp />
    </>
  );
}

export default SignupPageTwo;
