import React, { useRef, useState } from "react";
import Icons from "../Helpers/Icons";
import SliderCom from "../Helpers/SliderCom";

import OfferCard from "../Cards/OfferCard";
import OfferJobPopout from "../jobPopout/OfferJobPopout";

export default function MyOffersTable({ className, MyActiveOffersList }) {
  let [offerPopout, setOfferPopout] = useState({ show: false, data: {} }); // STATE TO HOLD THE VALUE OF THE ALERT DETAILS AND DETERMINE WHEN TO SHOW

  const settings = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: MyActiveOffersList?.result_list?.length > 3,
    responsive: [
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: MyActiveOffersList?.result_list?.length > 3,
            },
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: MyActiveOffersList?.result_list?.length > 2,
            },
        },
        {
            breakpoint: 619,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: MyActiveOffersList?.result_list?.length > 1,
            },
        },
    ],
};

  console.log("YES WE SEE OFFERS", MyActiveOffersList);
  const sellSlider = useRef(null);
  //const buySlider = useRef(null);
  const prevHandler = (value) => {
    if (value === "sell") {
      sellSlider.current.slickPrev();
    }
    // if (value === "buy") {
    //     buySlider.current.slickPrev();
    // }
  };
  const nextHandler = (value) => {
    if (value === "sell") {
      sellSlider.current.slickNext();
    }
    // if (value === "buy") {
    //     buySlider.current.slickNext();
    // }
  };

  if (!MyActiveOffersList || MyActiveOffersList?.result_list?.length == 0) {
    return ""; // want blank or no appear when no items
  }

  return (
    <>
      <div className={`top-seller-top-buyer-wrapper ${className || ""}`}>
        <div className={`trending-section w-full px-2 sm:px-0 ${className || ""}`}>
          {/* heading */}
          <div className="flex justify-between items-center mb-6">
              <div>
                  <h1 className="text-26 font-bold   text-dark-gray dark:text-white">Ready to Start?</h1>
              </div>
              <div className="slider-btns flex space-x-3">
                  <button onClick={() => nextHandler("sell")} type="button">
                      <div className="trending-slider-left-btn relative text-white w-10 h-10 flex justify-center items-center rounded-full overflow-hidden">
                          <svg
                              width="11"
                              height="19"
                              viewBox="0 0 11 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                              <path
                                  d="M9.09766 1.1499L1.13307 9.11449L9.09766 17.0791"
                                  stroke="url(#paint0_linear_220_23410)"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                              />
                              <defs>
                                  <linearGradient
                                      id="paint0_linear_220_23410"
                                      x1="9.09766"
                                      y1="1.1499"
                                      x2="-4.2474"
                                      y2="7.96749"
                                      gradientUnits="userSpaceOnUse"
                                  >
                                      <stop stopColor="#F539F8" />
                                      <stop offset="0.416763" stopColor="#C342F9" />
                                      <stop offset="1" stopColor="#5356FB" />
                                  </linearGradient>
                              </defs>
                          </svg>
                      </div>
                  </button>
                  <button onClick={() => prevHandler("sell")} type="button">
                      <div className="trending-slider-right-btn  primary-gradient  text-white w-10 h-10 relative flex justify-center items-center rounded-full ">
                          <Icons name="arrows" />
                      </div>
                  </button>
              </div>
          </div>
          {/* trending products */}
          <div className="trending-products slider-left relative w-full rounded-2xl p-[10px] bg-alice-blue">
              <SliderCom selector={sellSlider} settings={settings}>
                  {MyActiveOffersList &&
                      MyActiveOffersList?.result_list?.length > 0 &&
                      MyActiveOffersList.result_list.map((item) => {
                        return (
                          <OfferCard key={item.id} datas={item} setOfferPopout={setOfferPopout} image_server={MyActiveOffersList.session_image_server} />
                        )
                      })}
              </SliderCom>
          </div>
        </div>

        {/* Offer Job Popout */}
        {offerPopout.show && (
          <OfferJobPopout
            details={offerPopout.data}
            onClose={() => {
              setOfferPopout({ show: false, data: {} });
            }}
            situation={offerPopout.show}
          />
        )}
        {/* End of Offer Job Popout */}
      </div>
    </>
  );
}
