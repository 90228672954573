import React, { useContext, useState, useEffect } from "react";
// import BlogItem from "../components/Blogs";
import { useSelector } from "react-redux";
import OffersInterest from "../components/OffersInterest";

import usersService from "../services/UsersService";

import { SocketValues } from "../components/Contexts/SocketIOContext"; // for reading socket context values

export default function OffersInterestPage() {

    const { offerInterestListReload } = useSelector((state) => state.tableReload); // table/list reload variable

    const {userDetails} = useSelector((state) => state?.userDetails); // Gets USER Details

    let {joinRoom} = SocketValues() // function to join room for socket

    const apiCall = new usersService()

    let {commonHeadBanner} = useSelector(state => state.commonHeadBanner)

    let [offerInterestList, setOfferInterestList] = useState({loading: true, data: [], imgServer:''})

    useEffect(()=>{
        apiCall.offersInterestList().then(res => {
            setOfferInterestList({loading: false, data: res.data.result_list, imgServer:res.data.session_image_server})
        }).catch(err => {
            setOfferInterestList({loading: false, data: [], imgServer:''})
            console.log('Error: ', err)
        })
    },[offerInterestListReload])

    useEffect(()=>{
        joinRoom(`INTEREST-${userDetails?.uid}`)
    },[])

    return (
        <>
            <OffersInterest  commonHeadData={commonHeadBanner.result_list} offerInterestList={offerInterestList} />
        </>
    );
}