import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    familyWalletRedeemOptList: {loading: true, image: '', data: {}}
};

export const familyWalletRedeemOptListSlice = createSlice({
  name: "familyWalletRedeemOptList",
  initialState,
  reducers: {
    familyWalletRedeemOptList: (state,action) => {
      state.familyWalletRedeemOptList = {...action.payload}
    },
  },
});

// Action creators are generated for each case reducer function
export const { familyWalletRedeemOptList } = familyWalletRedeemOptListSlice.actions;

export default familyWalletRedeemOptListSlice.reducer;