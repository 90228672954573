import React, { useEffect, useState } from 'react'
import Layout from '../Partials/Layout'

import usersService from '../../services/UsersService'
import SearchCom from '../Helpers/SearchCom'
import LoadingSpinner from '../Spinners/LoadingSpinner'
import { useLocation } from 'react-router-dom'
import CustomBreadcrumb from '../Breadcrumb/CustomBreadcrumb'
import { localImgLoad } from '../../lib'

export default function FamAIQuestion() {
  const apiCall = new usersService()

  const {pathname} = useLocation()

  const [requestStatus, setRequestStatus] = useState({loading: false, status: false, message: ''})

  const [error, setError] = useState({question: '', searchPhrase: ''})

  const [questions, setQuestions] = useState({loading: true, data: []})

  const [askQuestion, setAskQuestion] = useState({question: '', searchPhrase: ''})

  const changeAskQuestion = ({target: {name, value}}) => {
      setAskQuestion(prev => ({...prev, [name]: value}))
      setRequestStatus({loading: false, status: false, message: ''})
  }

  const onSearch = () => {
      setError({question: '', searchPhrase: ''}) // sets error to false
      if(!askQuestion.question){
          return setError(prev => ({...prev, question: 'Select a question'}))
      }
      if(!askQuestion.searchPhrase){
          return setError(prev => ({...prev, searchPhrase: 'Enter search parameter'}))
      }
      if(askQuestion.searchPhrase.length > 60){
          return setError(prev => ({...prev, searchPhrase: 'Max of 60 characters'}))
      }
      
      setRequestStatus({loading: true, status: false, message: ''})
      let reqData = {
          question_key: '',
          question: ''
      }
      apiCall.askResourcesResult().then(res => {
          console.log(res.data.choices[0].text)
          if(!res.data || res.data?.choices?.length < 1){
          setRequestStatus({loading: false, status: false, message: 'No result found!'})
          }
          setRequestStatus({loading: false, status: false, message: res.data?.choices[0].text})
      }).catch(error => {
          setRequestStatus({loading: false, status: false, message: 'No result found!'})
      })
  }
 
  useEffect(()=>{
      apiCall.getResourceList().then(res => {
          setQuestions({loading: false, data: res.data?.ask_categories?.data})
      }).catch(error => {
          setQuestions({loading: false, data: []})
          console.log('ERROR', error)
      })
  }, [])
  return (
    <Layout>
        <>
          <div className='mb-4'>
            <CustomBreadcrumb 
              title={pathname == '/ai-question' ? 'Questions' : 'AI Lab'} 
              breadcrumb = {
                [
                  { link: "/", title: "Home" },
                  { link: pathname == '/ai-question' ? '/ai-question' : '/ai-lab', title: pathname == '/ai-question' ? 'Questions' : 'AI Lab', active: true},
                ]
              }
            />
          </div>
          <div className={`w-full`}>
              <div className="main-container w-full">
                  <div className="filter-section w-fullmb-6">
                      {/* <h1 className="text-xl lg:text-2xl font-bold text-dark-gray dark:text-white tracking-wide">Ask our A.I</h1> */}
                      <div className="mt-2 lg:grid grid-cols-2 gap-2 h-full lg:h-[500px]">
                          <div className="h-full mb-5 lg:mb-0">
                              <img className="w-full h-full rounded-2xl" src={localImgLoad(`images/resources-ask.jpg`)} alt='AI' />
                          </div>
                          <div className="p-8 bg-white rounded-2xl h-full">
                              <div className="input-wrapper border border-[#f5f8fa] dark:border-[#5e6278] w-full rounded-full h-[42px] overflow-hidden relative font-medium leading-6 bg-clip-padding text-[#5e6278] dark:text-gray-100 bg-[#f5f8fa] dark:bg-[#5e6278] text-base">
                                  <select value={askQuestion.question} name='question' onChange={changeAskQuestion} className="input-field px-2 placeholder:text-base text-dark-gray w-full h-full tracking-wide dark:bg-[#11131F] bg-[#fafafa] focus:ring-0 focus:outline-none">
                                      {questions.loading ? 
                                          <option value='' className="">Loading...</option>
                                          :
                                          <>
                                          <option value='' className="">Find answer on:</option>
                                          {questions.data.length > 0 && questions.data.map((item, index)=>(
                                              <option key={index} value={item.question_key} className="">{item.name}</option>
                                              ))}
                                          </>
                                      }
                                      
                                  </select>
                              </div>
                              {error.question && <p className="text-red-500 text-[12px]">{error.question}</p>} 
                              {/* filter-search */}
                              <div className="w-full my-5 border-2 rounded-full">
                                  <SearchCom 
                                      name={'searchPhrase'}
                                      value={askQuestion.searchPhrase}
                                      handleSearch={changeAskQuestion}
                                      />
                              </div>
                              {error.searchPhrase && <p className="text-red-500 text-[12px]">{error.searchPhrase}</p>}
                              <div className="w-full flex justify-end items-center border-b-2 pb-4">
                                  <button
                                      onClick={onSearch}
                                      disabled={requestStatus.loading}
                                      className="btn-gradient text-base tracking-wide px-4 py-2 rounded-full text-white cursor-pointer"
                                      >
                                      Search
                                  </button>
                              </div>
                              <div className="search_result my-2 max-h-[400px] overflow-auto">
                              {requestStatus.loading ?
                                  <LoadingSpinner size='8' color='sky-blue' height='h-[100px]' />
                                  :
                                  <p className="py-2 text-sm font-bold text-dark-gray dark:text-white tracking-wide">{requestStatus.message}</p>
                                  }
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </>
    </Layout>
  )
}
