import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import WrenchBoard from "../../../assets/images/wrenchboard-logo-text.png";
import usersService from "../../../services/UsersService";
import InputCom from "../../Helpers/Inputs/InputCom";
import AuthLayout from "../AuthLayout2";
import ForgetPwdResponse from "../ForgetPwdResponse";
import PasswordValidator from "../../../lib/PasswordValidator";
import LoadingSpinner from "../../Spinners/LoadingSpinner";

const VerifyPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [requestStatus, setRequestStatus] = useState({
    loading: true,
    status: false,
    data: [],
  });
  const [msgError, setMsgError] = useState("");
  const [linkLoader, setLinkLoader] = useState(false);
  const [linkSuccess, setLinkSuccess] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const token = queryParams.get("passlink");
  const userApi = new usersService();

  // To Show and Hide Password
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Password
  const handlePassword = (e) => {
    let { name, value } = e?.target;
    if (name == "password") setPassword(value);
    if (name == "confirm_password") setConfirmPassword(value);
  };

  const completeReset = async () => {
    if (!password || !confirmPassword) {
      // CHECKS IF PASSWORD IS EMPTY
      setMsgError("Please fill in fields");
      return setTimeout(() => {
        setMsgError(null);
      }, process.env.REACT_APP_RESET_START_ERROR_TIMEOUT);
    }
    if (password != confirmPassword) {
      // CHECKS IF PASSWORD EQUALS CONFIRM PASSWORD
      setMsgError("Passwords does not match");
      return setTimeout(() => {
        setMsgError(null);
      }, process.env.REACT_APP_RESET_START_ERROR_TIMEOUT);
    }
    if (password.length < 6) {
      // CHECKS IF PASSWORD LENGTH IS UPTO 6 CHARACTERS
      setMsgError("Password must be upto six characters");
      return setTimeout(() => {
        setMsgError(null);
      }, process.env.REACT_APP_RESET_START_ERROR_TIMEOUT);
    }
    if (!PasswordValidator(password)) {
      // CHECKS IF PASSWORD IS VALID
      setMsgError(
        "Password must contain alphanumeric, uppercase and special character: eg: Password1@"
      );
      return setTimeout(() => {
        setMsgError(null);
      }, process.env.REACT_APP_RESET_START_ERROR_TIMEOUT);
    }
    try {
      setLinkLoader(true);
      var reqData = {
        reset_link: token,
        newpass: password,
        m_uid: requestStatus.data?.m_uid || "",
        reset_uid: requestStatus.data?.reset_uid || "",
        step: 300,
      };
      const res = await userApi?.CompleteResetPassword(reqData);

      if (res.status == 200) {
        const { data } = res;
        if (data?.internal_return >= 0) {
          // setTimeout(() => {
          //   navigate("/login", { replace: true });
          //   setLinkLoader(false);
          // }, 2000);
          setLinkSuccess(true);
        } else {
          setLinkLoader(false);
          setMsgError("An error occurred");
          setLinkSuccess(false);
        }
      } else {
        setLinkLoader(false);
        setLinkSuccess(false);
      }
    } catch (error) {
      setLinkLoader(false);
      setLinkSuccess(false);
      throw new Error(error);
    } finally {
      setTimeout(() => {
        setMsgError(null);
      }, process.env.REACT_APP_SIGNUP_ERROR_TIMEOUT);
    }
  };

  const verifyResetPwd = () => {
    // FUNCTION TO VERIFY RESET PASSWORD LINK
    setRequestStatus({ loading: true, status: false, data: [] });
    var reqData = {
      reset_link: token,
      step: 200,
    };
    userApi
      .CompleteResetPassword(reqData)
      .then((res) => {
        if (res.status != 200 || res.data.internal_return < 0) {
          return setRequestStatus({ loading: false, status: false, data: [] });
        }
        setRequestStatus({ loading: false, status: true, data: res.data });
      })
      .catch((error) => {
        setRequestStatus({ loading: false, status: false, data: [] });
      });
  };

  useEffect(() => {
    // little checker for the validity of the token
    if (token == null || token?.length != 64) {
      return setRequestStatus({ loading: false, status: false, data: [] });
    }
    verifyResetPwd();
  }, []);
  return (
    <>
      <AuthLayout slogan="Welcome to WrenchBoard">
        <div className="w-full">
          <div className="mb-12">
            <Link to="#">
              <img
                src={WrenchBoard}
                alt="wrenchboard"
                className="h-10 mx-auto"
              />
            </Link>
          </div>
          <div className="flex place-content-center">
            {requestStatus.loading ? (
              <LoadingSpinner color="sky-blue" size="16" height="h-300px" />
            ) : !requestStatus.loading && requestStatus.status ? (
              <div className="w-10/12">
                {linkSuccess == null ? (
                  <>
                    <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                      <h1 className="text-[#181c32] font-semibold dark:text-white mb-3 leading-[27.3px] text-[22.75px]">
                        Password Reset
                      </h1>
                      <span className="text-gray-400 font-medium text-[16.25px] leading-[24.375px]">
                        Enter a new password to reset
                      </span>
                      <span className="text-gray-400 font-medium text-[16.25px] leading-[24.375px]">
                        We'll send an email to confirm reset
                      </span>
                    </div>
                    <div>
                      <p className="text-red-500 font-semibold mb-3 leading-[27.3px] text-[13px]">
                        Must include a special, numeric, uppercase and lowercase
                        character
                      </p>
                    </div>
                    <SuccessfulComponent
                      password={password}
                      confirmPassword={confirmPassword}
                      handlePassword={handlePassword}
                      onSubmit={completeReset}
                      msgErr={msgError}
                      loader={linkLoader}
                      showPassword={showPassword}
                      onClick={togglePasswordVisibility}
                      navigateHandler={() => navigate("/login")}
                    />
                  </>
                ) : (
                  <ForgetPwdResponse
                    title={
                      linkSuccess
                        ? "Password Reset Complete"
                        : "Password Reset Error"
                    }
                    message={
                      linkSuccess
                        ? "Password Reset Complete. You can login now with your new credentials"
                        : "Password Reset Error. Please get in touch with support for further support"
                    }
                    type={linkSuccess}
                  />
                )}
              </div>
            ) : (
              <div className="w-11/12 sm:max-w-[500px] title-area flex flex-col justify-center items-center relative text-center mb-7">
                <ForgetPwdResponse
                  title={"Forget Password"}
                  message={
                    "We are unable to continue to reset process. This error is usually due to expired links. Please start all over or contact us"
                  }
                  type={requestStatus.status}
                />
              </div>
            )}
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default VerifyPassword;

const SuccessfulComponent = ({
  onSubmit,
  navigateHandler,
  showPassword,
  onClick,
  password,
  confirmPassword,
  handlePassword,
  msgErr,
  loader,
}) => (
  <div className="input-area">
    {/* INPUT */}
    <div className="mb-5">
      <InputCom
        fieldClass="sm:px-6 px-2 tracking-[0.25em] text-2xl"
        value={password}
        inputHandler={handlePassword}
        placeholder="● ● ● ● ● ●"
        label="Password"
        name="password"
        type={showPassword ? "text" : "password"}
        onClick={onClick}
        passIcon={showPassword ? "show-password" : "hide-password"}
      />
    </div>
    <div className="mb-5">
      <InputCom
        fieldClass="sm:px-6 px-2 tracking-[0.25em] text-2xl"
        value={confirmPassword}
        inputHandler={handlePassword}
        placeholder="● ● ● ● ● ●"
        label="Confirm Password"
        name="confirm_password"
        type="password"
      />
    </div>
    {msgErr && (
      <div className="relative p-4 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]">
        {msgErr}
      </div>
    )}
    <div className="signin-area mb-3.5">
      <button
        onClick={onSubmit}
        type="button"
        className={`btn-login  rounded-[0.475rem] mb-6 text-xl text-white flex justify-center bg-[#4687ba] hover:bg-[#009ef7] transition-all duration-300 items-center text-[15px]`}
      >
        {loader ? (
          <div className="signup btn-loader"></div>
        ) : (
          <span>Continue</span>
        )}
      </button>
    </div>
    <div className="signin-area mb-3.5">
      <button
        onClick={navigateHandler}
        type="button"
        className={`rounded-[0.475rem] mb-6 text-[15px] font-semibold text-[#009ef7] hover:text-white flex justify-center bg-[#f1faff] hover:bg-[#009ef7] transition-all duration-300 items-center py-[0.8875rem] px-[1.81rem] btn-login`}
      >
        <span>Return Home</span>
      </button>
    </div>
  </div>
);
