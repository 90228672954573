import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../Partials/Layout";
import CommonHead from "../UserHeader/CommonHead";
import MyWaitingJobTable from "./MyWaitingJobTable";

export default function MyWaitingJobs(props) {
  const [selectTab, setValue] = useState("today");
  const filterHandler = (value) => {
    setValue(value);
  };
  console.log("AMEYE LOC1", props.MyJobList);
  return (
    <Layout>
      <CommonHead
          commonHeadData={props.commonHeadData}
      />
      <div className="notification-page w-full mb-10">
        <div className="notification-wrapper w-full">
          {/* heading */}
          <div className="sm:flex justify-between items-center mb-6">
            <div className="mb-5 sm:mb-0">
              <h1 className="text-26 font-bold   text-dark-gray dark:text-white">
                <span
                  className={`${selectTab === "today" ? "block" : "hidden"}`}
                >
                  Waiting Job(s)
                </span>
              </h1>
            </div>
            <div className="slider-btns flex space-x-4">
              <div
                onClick={() => filterHandler("today")}
                className="relative"
              ></div>
            </div>
          </div>
          <MyWaitingJobTable MyJobList={props.MyJobList} />
        </div>
      </div>
    </Layout>
  );
}
