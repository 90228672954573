import React from 'react'
import LoadingSpinner from '../../Spinners/LoadingSpinner'
import { NewTasks } from './forms'
import { PriceFormatter } from '../../Helpers/PriceFormatter'
import { useSelector } from 'react-redux';
import AttachFile from '../../attachmentCom/AttachFile';

export default function AssignPrevNewTask({
    jobList,
    requestStatus,
    assignFamilyTask,
    taskType,
    switchTaskType,
    formState,
    setFormState,
    activeTask,
    handleActiveTask,
    closeModal
}) {

  const {userDetails} = useSelector((state) => state?.userDetails); // CHECKS IF USER Details are avaliable, to determine if user is active


  let imageSrc = (localStorage.getItem("session_token")
  ? `${userDetails?.session_image_server}${localStorage.getItem("session_token")}/job/${activeTask?.data?.job_uid}` : ""); // FOR GETTING JOB IMAGE

  return (
    <>
         {jobList?.loading ? (
              <div className="h-[30rem] w-full flex justify-center items-center">
                <LoadingSpinner color="sky-blue" size="16" />
              </div>
            ) : (
              <>
                <div
                  className={`job-action-modal-body w-full h-full overflow-y-auto md:grid ${
                    taskType !== "new" ? "md:grid-cols-2" : "md:grid-cols-1"
                  }`}
                >
                  <div className="px-4">
                    <div className="mb-2 w-full flex items-center gap-4">
                      <div className="flex items-center gap-2 text-sky-blue text-base">
                        <input
                          type="radio"
                          name="task-type"
                          value="select"
                          className="w-[20px] h-[20px] cursor-pointer"
                          checked={taskType == "select"}
                          onChange={switchTaskType}
                        />
                        <span className="text-lg tracking-wide font-semibold">Previous Task</span>
                      </div>
                      <div className="flex items-center gap-2 text-sky-blue text-base">
                        <input
                          type="radio"
                          name="task-type"
                          value="new"
                          className="w-[20px] h-[20px] cursor-pointer"
                          checked={taskType == "new"}
                          onChange={switchTaskType}
                        />
                        <span className="text-lg tracking-wide font-semibold">New Task</span>
                      </div>
                    </div>
                    {/* Task Type === select */}
                    {taskType == "select" && (
                      <div className="p-4 w-full h-[450px] overflow-y-auto bg-slate-100 rounded-md dark:bg-[#11131f] dark:text-white">
                        {jobList?.data?.length ? (
                          jobList?.data?.map((item, index) => (
                            <div
                              key={item.job_uid}
                              className="mb-2 flex justify-start items-center gap-2 text-sky-blue text-base cursor-pointer"
                              onClick={() => handleActiveTask(item.job_uid, item)}
                            >
                              <input
                                type="radio"
                                name="task-list"
                                checked={
                                  activeTask.id == item.job_uid ||
                                  (activeTask.id == index && true)
                                }
                                onChange={() =>
                                  handleActiveTask(item.job_uid, item)
                                }
                                className="w-[15px] h-[15px] cursor-pointer"
                              />
                              <p className="w-full text-dark-gray dark:text-white tracking-wide">
                                {item?.title}
                              </p>
                            </div>
                          ))
                        ) : (
                          <p className="p-8 text-lg text-dark-gray dark:text-white tracking-wide text-center cursor-default">
                            No Task found!
                          </p>
                        )}
                      </div>
                    )}
                    {taskType == "new" && (
                      <div className="px-4 w-full">
                        <NewTasks
                          formState={formState}
                          setFormState={setFormState}
                        />
                      </div>
                    )}
                  </div>

                  {/*Right Hand Side for details && Task Type === select */}
                  {taskType == "select" && (
                    <>
                      {jobList?.data?.length > 0 ? (
                        <div className="px-4">
                          <div className="w-full">
                            <p className="text-lg font-bold text-dark-gray dark:text-white tracking-wide border-b-2 max-h-[50px] overflow-y-auto">
                              {activeTask?.data?.title}
                            </p>
                            {/* <div className="my-3">
                              <Detail
                                label="Description"
                                value={activeTask?.data?.description}
                              />
                            </div> */}
                            <div className='flex flex-col gap-2'>
                              <div className="my-1 w-full">
                                <label className="job-label">
                                  Description
                                </label>
                                <p className="p-1 text-sm text-slate-900 dark:text-white max-h-[100px] overflow-y-auto">
                                  {activeTask?.data?.description}
                                </p>
                              </div>
                              <div className="w-full grid grid-cols-2">
                                <div className="w-full">
                                  <div className="w-full flex items-center gap-1">
                                    <label className="job-label">
                                      Reward
                                    </label>
                                    <p className="p-1 text-sm text-slate-900 dark:text-white">
                                      {PriceFormatter(
                                        activeTask?.data?.price * 0.01,
                                        activeTask?.data?.currency,
                                        activeTask?.data?.curreny_code
                                      )}
                                    </p>
                                  </div>

                                  <div className="w-full flex items-center gap-1">
                                    <label className="job-label">
                                      Timeline
                                    </label>
                                    <p className="p-1 text-sm text-slate-900 dark:text-white">{`${activeTask?.data?.timeline_days} day(s)`}</p>
                                  </div>
                                </div>
                                <div className="w-full flex items-center justify-center">
                                  <div className="mb-1 w-24 max-h-24 rounded-2xl flex items-center justify-center">
                                      <img 
                                        className="w-full h-auto"
                                        loading="lazy"
                                        src={imageSrc} 
                                        alt='job image' 
                                      />
                                  </div>
                                </div>
                              </div>
                              
                              {/* ATTACHMENT SECTION*/}
                              <div className="w-full max-h-28">
                                  <AttachFile data={activeTask.data} />
                              </div>

                              <div className="my-3">
                                <label className="w-full job-label">
                                  Delivery Detail
                                </label>
                                <textarea
                                  className={`p-1 w-full text-sm text-slate-900 dark:text-white bg-transparent outline-none border border-slate-300 rounded-md`}
                                  rows="5"
                                  style={{ resize: "none" }}
                                  value={activeTask?.data?.job_detail}
                                  readOnly
                                  // onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>

                {/* BTN */}
                <div className="modal-footer-wrapper">
                  {/* error or success display */}
                  <div className="w-auto h-auto flex items-center">
                    {requestStatus.message != "" &&
                      (!requestStatus.status ? (
                        <div
                          className={`relative p-2 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px] self-start`}
                        >
                          {requestStatus.message}
                        </div>
                      ) : (
                        requestStatus.status && (
                          <div
                            className={`relative p-2 text-green-700 bg-slate-200 border-slate-800 mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
                          >
                            {requestStatus.message}
                          </div>
                        )
                      ))}
                  </div>

                  {/* End of error or success display */}
                  <div className="w-auto h-auto flex items-center gap-20">
                    <button
                      disabled={requestStatus.loading}
                      onClick={()=>closeModal()}
                      type="button"
                      className="custom-btn border-gradient"
                    >
                      <span className="text-gradient">Close</span>
                    </button>
                    <div className="">
                      {requestStatus.loading ? (
                        <LoadingSpinner color="sky-blue" size="8" />
                      ) : (
                        <button
                          type="button"
                          disabled={requestStatus.loading || requestStatus.status}
                          onClick={assignFamilyTask}
                          className={`custom-btn btn-gradient text-white ${requestStatus.status && 'opacity-50'}`}
                        >
                          Assign
                        </button>
                      ) 
                      // : (
                      //   <button
                      //     type="button"
                      //     disabled={requestStatus.loading}
                      //     onClick={assignFamilyTask}
                      //     className="px-1 w-40 h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white cursor-pointer"
                      //   >
                      //     {details
                      //       ? `Assign task to ${details?.firstname}`
                      //       : familyDetailsData
                      //       ? `Assign task to ${familyDetailsData.firstname}`
                      //       : "Assign"}
                      //   </button>
                      // )
                      }
                    </div>
                  </div>
                </div>
              </>
            )  
        }     
    </>
  )
}
