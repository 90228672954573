import React from 'react'

import FbookRedirect from '../components/AuthPages/AuthRedirect/FbookRedirect'

function FacebookRedirect() {
  return (
    <>
      <FbookRedirect />
    </>
  )
}

export default FacebookRedirect