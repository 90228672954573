import React from 'react'
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../../Spinners/LoadingSpinner";
import JobFieldInput from './JobFieldInput';



const validationSchema = Yup.object().shape({
    group: Yup.string().required("This is required "),
});


let initialValues = {
    group: "",
};

export default function AssignToGroup({
    jobFieldHandler,
    groupList,
    loader
}) {
  return (
    <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={jobFieldHandler}
  >
    {(props) => {
      return (
        <Form className="w-full h-auto md:h-full flex flex-col justify-between">
          <div className='w-full'>
              <div className='w-full'>
                <JobFieldInput
                    select={true}
                    inputName="group"
                    value={props?.values.group}
                    optionText="Select Group"
                    data={groupList}
                    parentClass="w-full flex flex-col gap-4"
                />
                <p className="h-4 text-[13px] font-light italic text-red-600 tracking-wide">
                  {(props.errors.group && props.touched.group) && (
                    <span>{props.errors.group}</span>
                  )}
                </p>
              </div>
          </div>
          <div className="mt-3 mb-1 flex justify-end items-center">
            <button
              className={`uppercase px-4 h-11 flex justify-center items-center btn-gradient text-sm rounded-full text-white`}
              type="submit"
              name='group'
            >
              {loader?.jobFields ? 
                <LoadingSpinner size={5} /> 
                :
                'Send Task to Group'
              }
            </button>
          </div>
        </Form>
      );
    }}
  </Formik>
  )
}
