import React, { useState } from "react";
import Icons from "./Icons";

export default function SearchCom({
  className,
  inputClasses,
  placeholder,
  handleSearch,
  value,
  name,
}) {
  return (
    <div
      className={`w-full h-[48px]  pl-8 flex rounded-full overflow-hidden bg-white dark:bg-dark-white   ${
        className || ""
      }`}
    >
      <div className="w-6 h-full flex justify-center items-center mr-2.5   text-dark-gray dark:text-light-gray">
        <Icons name="deep-search" />
      </div>
      <div className="flex-1 h-full">
        <input
          className={`w-full h-full focus:outline-0 focus:ring-0 dark:bg-dark-white dark:text-white ${
            inputClasses || ""
          }`}
          name={name}
          type="text"
          onInput={handleSearch}
          value={value}
          placeholder={placeholder || "Search items, collections..."}
        />
      </div>
    </div>
  );
}
