import React from "react";
import { Link } from "react-router-dom";

export default function CustomBreadcrumb({ title, breadcrumb }) {
  return (
    <div className="">
      <h3 className="text-26 font-bold text-dark-gray dark:text-white">
        {title}
      </h3>
      <nav
        aria-label="breadcrumb"
        className="text-base text-dark-gray dark:text-white flex items-center"
      >
        {breadcrumb.map((value, index) => (
          <React.Fragment key={index}>
            <Link className={value.active ? "opacity-60" : ""} to={value.link}>
              {value.title}
            </Link>
            {index !== breadcrumb.length - 1 && <span className="px-1">\</span>}
          </React.Fragment>
        ))}
      </nav>
    </div>
  );
}
