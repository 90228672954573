import React, { useEffect, useState } from "react";
import Layout from "../Partials/Layout";

import GroupList from "./GroupList";
import MemberList from "./MemberList";
import LoadingSpinner from "../Spinners/LoadingSpinner";

import usersService from "../../services/UsersService";

export default function JobGroups() {
  const userApi = new usersService();

  const [updateList, setUpdateList] = useState(false);

  const [groupList, setGroupList] = useState({
    loading: true,
    groups: [],
    members: [],
  });

  const [selectedGroup, setSelectedGroup] = useState({
    id: "",
    name: "",
    data: [],
  });

  const changeSelectedGroup = (e) => {
    let groupID = e.target.value;
    const activeMembers = groupList?.members?.filter(
      (item) => item.group_id == groupID
    );
    const activeGroup = groupList?.groups?.filter(
      (item) => item.group_id == groupID
    );
    setSelectedGroup({
      id: groupID,
      name: activeGroup[0]?.group_name,
      data: activeMembers,
    });
  };

  useEffect(() => {
    setGroupList({ loading: true, groups: [], members: [] });
    userApi
      .jobGroupList({})
      .then((res) => {
        const { status, data } = res;
        if (status != 200 || data?.internal_return < 0) {
          setGroupList({ loading: false, groups: [], members: [] });
          return;
        }
        if (data.result_list.length < 0) {
          setGroupList({ loading: false, groups: [], members: [] });
          return;
        }
        setGroupList({
          loading: false,
          groups: data.result_list,
          members: data.result_list_member,
        });
        if (selectedGroup.id == "") {
          let activeGroupId = data.result_list[0].group_id;
          let activeGroup = data.result_list[0].group_name;
          let activeMembers = data.result_list_member?.filter(
            (item) => item.group_id == activeGroupId
          );
          setSelectedGroup({
            id: activeGroupId,
            name: activeGroup,
            data: activeMembers,
          });
        } else {
          let activeMembers = data.result_list_member?.filter(
            (item) => item.group_id == selectedGroup?.id
          );
          setSelectedGroup({
            id: selectedGroup?.id,
            name: selectedGroup?.name,
            data: activeMembers,
          });
        }
      })
      .catch((error) => {
        setGroupList({ loading: false, groups: [], members: [] });
        console.log(error);
      });
  }, [updateList]);

  return (
    <Layout>
      <div>
        <h1 className="mb-5 text-lg lg:text-2xl tracking-wide font-bold text-slate-900 dark:text-slate-100">
          Job Groups
        </h1>
      </div>
      <div className="p-5 w-full min-h-[400px] flex flex-col lg:flex-row gap-3 lg:gap-6 rounded-lg shadow-md bg-white dark:bg-dark-white">
        {groupList.loading ? (
          <div className="w-full h-[400px] flex justify-center items-center">
            <LoadingSpinner size="16" />
          </div>
        ) : (
          <>
            <GroupList
              groupList={groupList?.groups}
              selectedGroup={selectedGroup}
              changeSelectedGroup={changeSelectedGroup}
              setUpdateList={setUpdateList}
            />
            <MemberList
              groupList={groupList?.groups}
              selectedGroup={selectedGroup}
              setUpdateList={setUpdateList}
            />
          </>
        )}
      </div>
    </Layout>
  );
}
