import { Field, Form, Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import usersService from "../../services/UsersService";
import { tableReload } from "../../store/TableReloads";
import InputCom from "../Helpers/Inputs/InputCom";
import ModalCom from "../Helpers/ModalCom";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import { apiConst } from "../../lib/apiConst";
import AttachFile from "../attachmentCom/AttachFile";

const validationSchema = Yup.object().shape({
  country: Yup.string()
    .min(1, "Minimum 3 characters")
    .max(25, "Maximum 25 characters")
    .required("Country is required"),
  price: Yup.string()
    .typeError("Invalid number")
    .min(1, "Price must be greater than 0")
    .test("no-e", "Invalid number", (value) => {
      if (value && /\d+e/.test(value)) {
        return false;
      }
      return true;
    })
    .required("Price is required"),
  title: Yup.string()
    .min(5, "Minimum 5 characters")
    .max(149, "Maximum 149 characters")
    .required("Title is required"),
  description: Yup.string()
    .min(5, "Minimum 5 characters")
    .max(299, "Maximum 299 characters")
    .required("Description is required"),
  job_detail: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(1440, "Maximum 1440 characters")
    .required("Details is required"),
  timeline_days: Yup.number()
    .typeError("you must specify a number")
    .min(1, "Price must be greater than 0")
    .required("Timeline is required"),
  category: Yup.array().min(1, "Select at least one checkbox"),
});

const EditJobPopoutNew = ({
  details,
  onClose,
  situation,
  country,
  categories,
}) => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.userDetails);
  const { walletDetails } = useSelector((state) => state.walletDetails);

  const uploadedImage = `${
    userDetails.session_image_server
  }${localStorage.getItem("session_token")}/job/${details?.job_uid}`;

  const [taskImage, setTaskImage] = useState(uploadedImage);

  let [uploadStatus, setUploadStatus] = useState({
    loading: false,
    status: false,
    message: "",
  }); // HOLDS STATE FOR UPLOAD PROFILE PICTURE STATUS

  let [requestStatus, setRequestStatus] = useState({
    loading: false,
    status: false,
    message: "",
  }); // Holds state when submit button is pressed

  let initialValues = {
    // initial values for formik
    country: details.currency,
    price: details?.price * 0.01,
    title: details?.title,
    description: details?.description,
    job_detail: details?.job_detail,
    timeline_days: details?.timeline_days,
    category: details?.category,
  };

  const jobApi = useMemo(() => new usersService(), []);
  const navigate = useNavigate();

  const handleEditJob = useCallback(
    async (values) => {
      let reqData = {
        country: values?.country,
        price: Number(values.price) * 100,
        title: values?.title,
        description: values?.description,
        job_detail: values?.job_detail,
        timeline_days: values?.timeline_days,
        category: values.category?.join("@"),
        job_id: details.job_id,
        job_uid: details.job_uid,
      };
      setRequestStatus({ loading: true, message: "" });

      try {
        let res = await jobApi.jobManagerUpdateJob(reqData);
        let { data } = await res;
        if (data?.internal_return < 0) return;
        setRequestStatus({ loading: false, message: null });
        setTimeout(() => {
          dispatch(tableReload({ type: "JOBTABLE" }));
          navigate("/myjobs", { replace: true });
          onClose();
        }, 1000);
      } catch (error) {
        setRequestStatus({ loading: false, message: error });
        throw new Error(error);
      }
    },
    [jobApi, navigate, onClose, details]
  );

  const taskImgChangeHandler = (e) => {
    setUploadStatus({ loading: false, status: false, message: "" });
    let acceptedFormat = ["jpeg", "jpg", "png", "bmp", "gif"]; // ARRAY OF SUPPORTED FORMATS
    let uploadedFile = e.target.files[0]; //UPLOADED FILE

    const fileFormat = uploadedFile?.type?.split("/")[1]?.toLowerCase();
    if (!acceptedFormat.includes(fileFormat)) {
      //CHECKING FOR CORRECT UPLOAD FORMAT
      const msg = `Please select ${acceptedFormat
        .slice(0, -1)
        .join(", ")} or ${acceptedFormat.slice(-1)}`;
      setUploadStatus({ loading: false, status: false, message: msg });
      return setTimeout(() => {
        // profileImgInput.current.value = '' // clear the input
        setUploadStatus({ loading: false, status: false, message: "" });
      }, 5000);
    }

    if (uploadedFile.size > 5 * 1048576) {
      // CHECKING FOR CORRECT FILE SIZE
      setUploadStatus({
        loading: false,
        status: false,
        message: "File must not exceed 5MB",
      });
      return setTimeout(() => {
        // profileImgInput.current.value = '' // clear the input
        setUploadStatus({ loading: false, status: false, message: "" });
      }, 5000);
    }

    if (e.target.value !== "") {
      const imgReader = new FileReader();
      imgReader.onload = (event) => {
        let base64Img = imgReader.result.split(",")[1];
        let reqData = {
          // PAYLOAD FOR API CALL
          job_uid: details?.job_uid,
          file_name: uploadedFile?.name.slice(0, 19),
          file_size: uploadedFile?.size,
          file_type: uploadedFile?.type?.split("/")[0]?.toLowerCase(),
          file_data: base64Img,
          msg_type: "FILE",
          action: apiConst.WRENCHBOARD_PICTURE_JOB,
        };
        setUploadStatus({
          loading: true,
          status: false,
          message: "Loading...",
        });
        jobApi
          .sendFiles(reqData)
          .then((res) => {
            if (res.status != 200 || res.data.internal_return < 0) {
              return setUploadStatus({
                loading: false,
                status: false,
                message: "Something went wrong, try again",
              });
            }
            setUploadStatus({
              loading: false,
              status: true,
              message: "Uploaded successfully",
            });
            setTaskImage(event.target.result);
            setTimeout(() => {
              dispatch(tableReload({ type: "JOBTABLE" }));
              navigate("/myjobs", { replace: true });
              onClose();
            }, 1000);
          })
          .catch((error) => {
            setUploadStatus({
              loading: false,
              status: false,
              message: "Network error, try again",
            });
          })
          .finally(() => {
            setTimeout(() => {
              setUploadStatus({ loading: false, status: false, message: "" });
            }, 5000);
          });
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <ModalCom action={onClose} situation={situation}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleEditJob}
          >
            {(props) => (
              <Form className="">
                <div className="modal-container">
                    <div className="modal-header-con">
                        <h1 className="modal-title">
                            Edit Job
                        </h1>
                        <button
                            type="button"
                            className="modal-close-btn"
                            onClick={onClose}
                        >
                            <svg
                            width="36"
                            height="36"
                            viewBox="0 0 36 36"
                            fill="none"
                            className="fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="M36 16.16C36 17.4399 36 18.7199 36 20.0001C35.7911 20.0709 35.8636 20.2554 35.8385 20.4001C34.5321 27.9453 30.246 32.9248 22.9603 35.2822C21.9006 35.6251 20.7753 35.7657 19.6802 35.9997C18.4003 35.9997 17.1204 35.9997 15.8401 35.9997C15.5896 35.7086 15.2189 35.7732 14.9034 35.7093C7.77231 34.2621 3.08728 30.0725 0.769671 23.187C0.435002 22.1926 0.445997 21.1199 0 20.1599C0 18.7198 0 17.2798 0 15.8398C0.291376 15.6195 0.214408 15.2656 0.270759 14.9808C1.71321 7.69774 6.02611 2.99691 13.0428 0.700951C14.0118 0.383805 15.0509 0.386897 15.9999 0C17.2265 0 18.4532 0 19.6799 0C19.7156 0.124041 19.8125 0.136067 19.9225 0.146719C27.3 0.868973 33.5322 6.21922 35.3801 13.427C35.6121 14.3313 35.7945 15.2484 36 16.16ZM33.011 18.0787C33.0433 9.77105 26.3423 3.00309 18.077 2.9945C9.78479 2.98626 3.00344 9.658 2.98523 17.8426C2.96667 26.1633 9.58859 32.9601 17.7602 33.0079C26.197 33.0577 32.9787 26.4186 33.011 18.0787Z"
                                fill=""
                                fillOpacity="0.6"
                            />
                            <path
                                d="M15.9309 18.023C13.9329 16.037 12.007 14.1207 10.0787 12.2072C9.60071 11.733 9.26398 11.2162 9.51996 10.506C9.945 9.32677 11.1954 9.0811 12.1437 10.0174C13.9067 11.7585 15.6766 13.494 17.385 15.2879C17.9108 15.8401 18.1633 15.7487 18.6375 15.258C20.3586 13.4761 22.1199 11.7327 23.8822 9.99096C24.8175 9.06632 26.1095 9.33639 26.4967 10.517C26.7286 11.2241 26.3919 11.7413 25.9133 12.2178C24.1757 13.9472 22.4477 15.6855 20.7104 17.4148C20.5228 17.6018 20.2964 17.7495 20.0466 17.9485C22.0831 19.974 24.0372 21.8992 25.9689 23.8468C26.9262 24.8119 26.6489 26.1101 25.4336 26.4987C24.712 26.7292 24.2131 26.3441 23.7455 25.8757C21.9945 24.1227 20.2232 22.3892 18.5045 20.6049C18.0698 20.1534 17.8716 20.2269 17.4802 20.6282C15.732 22.4215 13.9493 24.1807 12.1777 25.951C11.7022 26.4262 11.193 26.7471 10.4738 26.4537C9.31345 25.9798 9.06881 24.8398 9.98589 23.8952C11.285 22.5576 12.6138 21.2484 13.9387 19.9355C14.5792 19.3005 15.2399 18.6852 15.9309 18.023Z"
                                fill="#"
                                fillOpacity="0.6"
                            />
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body-wrapper">
                        <div className="flex flex-col-reverse sm:flex-row">
                            <div className="fields w-full">
                            <div className="sm:flex sm:space-x-7 mb-[0.5rem]">
                                <div className="field w-full mb-[0.5rem] sm:mb-0">
                                <InputCom
                                    fieldClass="px-6 cursor-default"
                                    label="Currency"
                                    labelClass="tracking-wide"
                                    inputBg="bg-slate-100"
                                    inputClass="input-curve lg border border-light-purple"
                                    type="text"
                                    name="country"
                                    value={props.values.country}
                                    inputHandler={props.handleChange}
                                    blurHandler={props.handleBlur}
                                    disable={true}
                                />
                                </div>

                                {/* Price */}
                                <div className="field w-full mb-[0.5rem] sm:mb-0">
                                <InputCom
                                    fieldClass="px-6 text-right"
                                    label="Price"
                                    labelClass="tracking-wide"
                                    inputBg="bg-slate-100"
                                    inputClass="input-curve lg border border-light-purple"
                                    type="number"
                                    name="price"
                                    value={props.values.price}
                                    inputHandler={props.handleChange}
                                    blurHandler={props.handleBlur}
                                    errorBorder={
                                    props.errors.price && props.touched.price
                                    }
                                />
                                </div>

                                <div className="field w-full mb-[0.5rem] sm:mb-0">
                                <label
                                    className="job-label"
                                    htmlFor="timeline_days"
                                >
                                    Timeline -
                                    {/* <span className="w-full text-center text-green-700 text-sm tracking-wide">
                                    Expected duration of this task
                                    </span> */}
                                </label>
                                <Field
                                    component="select"
                                    name="timeline_days"
                                    className={`input-field p-2 mt-3 rounded-full placeholder:text-base text-dark-gray w-full h-[42px] bg-slate-100 focus:ring-0 focus:outline-none border ${
                                    props.errors.timeline_days &&
                                    props.touched.timeline_days
                                        ? "border-[#ff0a0a63] shadow-red-500 border-[0.5px] animate-shake"
                                        : "dark:border-[#5e6278]"
                                    }`}
                                    value={props.values.timeline_days}
                                >
                                    <option value="" className='text-slate-500 text-lg'>Select Duration</option>
                                    {publicArray.map(({ name, duration }, idx) => (
                                    <option
                                        key={duration}
                                        className="text-slate-500 text-lg"
                                        value={duration}
                                    >
                                        {name}
                                    </option>
                                    ))}
                                </Field>
                                </div>
                            </div>

                            {/* Title  */}
                            <div className="field w-full mb-[0.5rem]">
                                <InputCom
                                fieldClass="px-6"
                                label="Title"
                                labelClass="tracking-wide"
                                inputBg="bg-slate-100"
                                inputClass=" input-curve lg border border-light-purple"
                                type="text"
                                name="title"
                                value={props.values.title}
                                inputHandler={props.handleChange}
                                blurHandler={props.handleBlur}
                                errorBorder={props.errors.title && props.touched.title}
                                />
                            </div>

                            {/* Description  */}
                            <div className="field w-full mb-[0.5rem]">
                                <InputCom
                                fieldClass="px-6"
                                label="Description"
                                labelClass="tracking-wide"
                                inputBg="bg-slate-100"
                                inputClass=" input-curve lg border border-light-purple"
                                type="text"
                                name="description"
                                value={props.values.description}
                                inputHandler={props.handleChange}
                                blurHandler={props.handleBlur}
                                errorBorder={
                                    props.errors.description && props.touched.description
                                }
                                />
                            </div>

                            {/* Details */}
                            <div className="field flex flex-col sm:flex-row w-full mb-[2px] gap-2">
                                <div className="sm:w-[60%] w-full">
                                <label
                                    htmlFor="job-label"
                                    className='job-label'
                                >
                                    Job Delivery Details
                                </label>
                                <textarea
                                    id="Job Delivery Details"
                                    rows="5"
                                    className={`input-field px-6 py-2 placeholder:text-base text-dark-gray dark:text-white w-full h-[100px] bg-slate-100 dark:bg-[#11131F] focus:ring-0 focus:outline-[#dce4e9] ${
                                    props.errors.job_detail && props.touched.job_detail
                                        ? "border-[#ff0a0a63] shadow-red-500 border-[0.5px] animate-shake"
                                        : "border border-[#f5f8fa] dark:border-[#5e6278]"
                                    } rounded-[10px]`}
                                    style={{ resize: "none" }}
                                    name="job_detail"
                                    value={props.values.job_detail}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                />
                                </div>

                                <div className="sm:w-[35%] w-full">
                                <div
                                    htmlFor="Job Categories"
                                    className='job-label'
                                    id="checked-group"
                                >
                                    Categories
                                </div>
                                <div
                                    className="sm:flex-col flex flex-wrap px-3 mt-3"
                                    role="group"
                                    aria-labelledby="checked-group"
                                >
                                    {categories &&
                                    Object.entries(categories)?.map(([key, value]) => (
                                        <label
                                        key={key}
                                        className="flex gap-1 w-full items-center dark:text-white"
                                        >
                                        <Field
                                            type="checkbox"
                                            name="category"
                                            value={key}
                                        />
                                        <span className="text-[13.975px]">{value}</span>
                                        </label>
                                    ))}
                                    <span className="h-5 text-sm italic text-[#cf3917]">
                                    {props.errors.category &&
                                        props.touched.category &&
                                        "please select a category"}
                                    </span>
                                </div>
                                </div>
                            </div>
                            <div className="w-full flex items-center justify-center gap-2 mb-2">
                                {/* FOR TASK IMAGE */}
                                <div className="w-1/3 relative flex flex-col max-h-[130px] min-h-[130px]">
                                <div className="w-full flex gap-2">
                                    <label
                                        className={`w-full job-label cursor-pointer ${uploadStatus.loading && 'pointer-events-none'}`}
                                        htmlFor="task_image"
                                        >
                                        Banner
                                    </label>
                                    {uploadStatus.loading ? 
                                        <LoadingSpinner size='6' /> 
                                        : 
                                        <span className={`text-[10px] ${uploadStatus.status ? 'text-emerald-600' : 'text-red-500'}`}>{uploadStatus.message}</span>
                                    } 
                                </div>
                                <input
                                    id="task_image"
                                    className="hidden"
                                    type="file"
                                    accept="image/*"
                                    onChange={taskImgChangeHandler}
                                />
                                {taskImage ? (
                                    <div className="w-full relative h-full">
                                    <img
                                        src={taskImage}
                                        className="h-32 w-full object-cover"
                                        alt="uploaded task"
                                    />
                                    <div className="absolute inset-0 flex flex-col justify-center items-center">
                                        <span
                                        onClick={() => setTaskImage("")}
                                        className="p-2 bg-white/80 hover:bg-white hover:shadow-md transition-all duration-500 cursor-pointer text-slate-800"
                                        >
                                        Remove Image
                                        </span>
                                    </div>
                                    </div>
                                ) : (
                                    <div className="absolute inset-0 flex flex-col justify-center items-center">
                                    <label
                                        className="p-2 bg-slate-100 dark:bg-[#11131F] cursor-pointer input-label text-[#181c32] dark:text-white text-[13.975px] leading-[20.9625px] font-semibold"
                                        htmlFor="task_image"
                                    >
                                        Select Task Image
                                    </label>
                                    </div>
                                )}
                                </div>
                                {/* END OF TASK IMAGE */}

                                <div className="field w-2/3">
                                    <AttachFile data={details} />
                                </div>

                            </div>
                            {/* inputs ends here */}
                            </div>
                        </div>
                        {/* ERROR DISPLAY AND SUBMIT BUTTON */}
                        <div className="content-footer w-full">
                            {/* error or success display */}
                            {requestStatus.message != "" &&
                            (!requestStatus.status ? (
                                <div
                                className={`relative p-4 my-4 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
                                >
                                {requestStatus.message}
                                </div>
                            ) : (
                                requestStatus.status && (
                                <div
                                    className={`relative p-4 my-4 text-green-700 bg-slate-200 border-slate-800 mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
                                >
                                    {requestStatus.message}
                                </div>
                                )
                            ))}
                            {/* End of error or success display */}
                        </div>
                    </div>
                    <div className="modal-footer-wrapper justify-end">
                        {requestStatus.loading ? (
                        <LoadingSpinner size="6" color="sky-blue" />
                        ) : (
                        <button
                            type="submit"
                            className="custom-btn btn-gradient text-white"
                            disabled={
                            requestStatus.loading || uploadStatus.loading
                            }
                        >
                            Save
                        </button>
                        )}
                    </div>
                </div>
              </Form>
            )}
          </Formik>
    </ModalCom>
  );
};

export default EditJobPopoutNew;

const publicArray = [
  { duration: 1, name: "1 day" },
  { duration: 2, name: "2 days" },
  { duration: 3, name: "3 days" },
  { duration: 4, name: "4 days" },
  { duration: 5, name: "5 days" },
  { duration: 6, name: "6 days" },
  { duration: 7, name: "1 week" },
  { duration: 14, name: "2 weeks" },
  { duration: 21, name: "3 weeks" },
  { duration: 28, name: "4 weeks" },
];
