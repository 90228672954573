import ChangePasswordTab from "./ChangePasswordTab";
import FaqTab from "./FaqTab";
import LoginActivityTab from "./LoginActivityTab";
import NotificationSettingTab from "./NotificationSettingTab";
import PaymentMathodsTab from "./PaymentMathodsTab";
import PersonalInfoTab from "./PersonalInfoTab";
import PrivacyPolicyTab from "./PrivacyPolicyTab";
import RecentActivitiesTab from "./RecentActivitiesTab";
import TermsConditionTab from "./TermsConditionTab";

export {
  ChangePasswordTab,
  FaqTab,
  LoginActivityTab,
  NotificationSettingTab,
  PaymentMathodsTab,
  PersonalInfoTab,
  PrivacyPolicyTab,
  RecentActivitiesTab,
  TermsConditionTab,
};
