import { useState } from "react";
import PaginatedList from "../../Pagination/PaginatedList";
import { handlePagingFunc } from "../../Pagination/HandlePagination";

function ReferralTable({ history }) {
  const [currentPage, setCurrentPage] = useState(0);
  const indexOfFirstItem = Number(currentPage);
  const indexOfLastItem =
    Number(indexOfFirstItem) + Number(process.env.REACT_APP_ITEM_PER_PAGE);
  const currentReferral = history?.data?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePagination = (e) => {
    handlePagingFunc(e, setCurrentPage);
  };

  return (
    <div className="flex flex-col justify-between min-h-[420px] overflow-x-auto">
      <table className="referral-list w-full table-auto border-collapse text-left">
        <thead className="border-b-2">
          <tr className="text-slate-600">
            <th className="p-3">Added/Name</th>
            <th className="p-3">Email</th>
            <th className="p-3">Status</th>
          </tr>
        </thead>
        <tbody>
          {history.data.length ? (
            currentReferral.map((item, index) => (
              <tr key={index} className="text-slate-500">
                <td className="p-3">
                  {item.added_date} / {item.firstname} {item.lastname}
                </td>
                <td className="p-3">{item.email}</td>
                <td className="p-3">{item.status}</td>
              </tr>
            ))
          ) : history.error ? (
            <tr className="text-slate-500">
              <td colSpan={3}>
                Opps! couldn't get referral history. Try reloading the page
              </td>
            </tr>
          ) : (
            <tr className="text-slate-500">
              <td colSpan={3}>No Item Found on referral List</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* PAGINATION BUTTON */}
      <PaginatedList
        onClick={handlePagination}
        prev={currentPage == 0 ? true : false}
        next={
          currentPage + Number(process.env.REACT_APP_ITEM_PER_PAGE) >=
          history?.data?.length
            ? true
            : false
        }
        data={history?.data}
        start={indexOfFirstItem}
        stop={indexOfLastItem}
      />
      {/* END OF PAGINATION BUTTON */}
    </div>
  );
}

export default ReferralTable;
