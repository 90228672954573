export function NewDateTimeFormatter(isoDateString, addHour = true) {
    const date = new Date(isoDateString);
    if (addHour) {
      date.setTime(date.getTime() + 1 * 60 * 60 * 1000);
    }
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    //   second: "2-digit",
      hour12: true,
      timeZone: "UTC",
    });
    return formattedDate;
  }