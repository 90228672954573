import React from "react";
import Icons from "../../Helpers/Icons";

export default function LoginActivityTab() {
  return (
    <div className="login-activity-tab w-full">
      <div className="relative w-full overflow-x-auto sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            <tr className="text-base text-thin-light-gray border-b dark:border-[#5356fb29]  default-border-b dark:border-[#5356fb29] ottom ">
              <td className="py-4 text-center">Channel</td>
              <td className="py-4 text-center">Date</td>
              <td className="py-4 text-center">Location</td>
            </tr>
            <tr className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
              <td className="text-center py-4">
                <div className="flex items-center justify-center">
                  <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap px-2">
                    Desktop
                  </span>
                </div>
              </td>
              <td className="text-center py-4">
                <div className="flex space-x-1 items-center justify-center">
                  <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap px-2">
                    10-10-2035
                  </span>
                </div>
              </td>
              <td className="text-center py-4">
                <span className="text-base text-dark-gray dark:text-white whitespace-nowrap px-2">
                  10.0.0.1000
                </span>
              </td>
            </tr>
            <tr className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
              <td className="text-center py-4">
                <div className="flex items-center justify-center">
                  <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap px-2">
                    Desktop
                  </span>
                </div>
              </td>
              <td className="text-center py-4">
                <div className="flex space-x-1 items-center justify-center">
                  <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap px-2">
                    10-10-2035
                  </span>
                </div>
              </td>
              <td className="text-center py-4">
                <span className="text-base text-dark-gray dark:text-white whitespace-nowrap px-2">
                  10.0.0.1000
                </span>
              </td>
            </tr>
            <tr className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
              <td className="text-center py-4">
                <div className="flex items-center justify-center">
                  <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap px-2">
                    Desktop
                  </span>
                </div>
              </td>
              <td className="text-center py-4">
                <div className="flex space-x-1 items-center justify-center">
                  <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap px-2">
                    10-10-2035
                  </span>
                </div>
              </td>
              <td className="text-center py-4">
                <span className="text-base text-dark-gray dark:text-white whitespace-nowrap px-2">
                  10.0.0.1000
                </span>
              </td>
            </tr>
            <tr className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
              <td className="text-center py-4">
                <div className="flex items-center justify-center">
                  <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap px-2">
                    Desktop
                  </span>
                </div>
              </td>
              <td className="text-center py-4">
                <div className="flex space-x-1 items-center justify-center">
                  <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap px-2">
                    10-10-2035
                  </span>
                </div>
              </td>
              <td className="text-center py-4">
                <span className="text-base text-dark-gray dark:text-white whitespace-nowrap px-2">
                  10.0.0.1000
                </span>
              </td>
            </tr>
            <tr className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
              <td className="text-center py-4">
                <div className="flex items-center justify-center">
                  <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap px-2">
                    Desktop
                  </span>
                </div>
              </td>
              <td className="text-center py-4">
                <div className="flex space-x-1 items-center justify-center">
                  <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap px-2">
                    10-10-2035
                  </span>
                </div>
              </td>
              <td className="text-center py-4">
                <span className="text-base text-dark-gray dark:text-white whitespace-nowrap px-2">
                  10.0.0.1000
                </span>
              </td>
            </tr>
            <tr className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
              <td className="text-center py-4">
                <div className="flex items-center justify-center">
                  <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap px-2">
                    Mobile
                  </span>
                </div>
              </td>
              <td className="text-center py-4">
                <div className="flex space-x-1 items-center justify-center">
                  <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap px-2">
                    10-10-2035
                  </span>
                </div>
              </td>
              <td className="text-center py-4">
                <span className="text-base text-dark-gray dark:text-white whitespace-nowrap px-2">
                  10.0.0.1000
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
