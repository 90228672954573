import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../Partials/Layout";
import CommonHead from "../UserHeader/CommonHead";
import MyPastDueTaskTable from "./MyPastDueTaskTable";
import CustomBreadcrumb from "../Breadcrumb/CustomBreadcrumb";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import { useSelector } from "react-redux";

export default function MyPastDueTasks(props) {

  const { userDetails: { account_type } } = useSelector((state) => state?.userDetails); // Gets user details

  return (
    <Layout>
      {account_type == 'FULL' &&
        <CommonHead commonHeadData={props.commonHeadData} />
      }
      <div className="notification-page w-full mb-10">
        <div className="notification-wrapper w-full">
          {/* heading */}
          {account_type == 'FULL' ?
          <div className="sm:flex justify-between items-center mb-6">
            <div className="mb-5 sm:mb-0">
              <h1 className="text-26 font-bold   text-dark-gray dark:text-white">
                <span
                >
                  Past Due Task(s)
                </span>
              </h1>
            </div>
          </div>
          :
          <div className="mb-5">
            <CustomBreadcrumb 
            title = {'Past Due Task(s)'}
            breadcrumb={
                [
                { link: "/", title: "Home" },
                { link: "/pastdue", title: "Past Due", active: true},
                ]
            }
            />
          </div>
          }
          {props.loading ?
          <div className="w-full flex justify-center items-center bg-white rounded-2xl">
            <LoadingSpinner size='10' color='sky-blue' height='h-[20rem]' />
          </div>
          :
          <MyPastDueTaskTable MyJobList={props.MyJobList} />
          }
        </div>
      </div>
    </Layout>
  );
}
