import React, { useEffect, useState } from "react";
import usersService from "../../../../services/UsersService";
import InputCom from "../../../Helpers/Inputs/InputCom";
import { useSelector } from "react-redux";

export default function NewTasks({ formState, setFormState }) {

  const { walletDetails } = useSelector((state) => state?.walletDetails); // WALLET STORE

  // let [currency, setCurrency] = useState({
  //   loading: true,
  //   status: false,
  //   data: null,
  // });

  const selectImage = require(`../../../../assets/images/taskbanners/${
    formState.banner || "default.jpg"
  }`);
  const ApiCall = new usersService();

  // FUNCTION TO GET Currency
  // const getUserCurrency = () => {
  //   setCurrency((prev) => ({ ...prev, loading: true }));
  //   ApiCall.getUserWallets()
  //     .then((res) => {
  //       if (res.data.internal_return < 0) {
  //         setCurrency({ loading: false, status: true, data: [] });
  //         return;
  //       }

  //       setCurrency({
  //         loading: false,
  //         status: true,
  //         data: res.data.result_list,
  //       });
  //     })
  //     .catch((err) => {
  //       setCurrency({ loading: false, status: false, data: [] });
  //     });
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // useEffect(() => {
  //   getUserCurrency();
  // }, []);

  return (
    <form className="w-full flex justify-between items-start">
      <div className="w-full block sm:grid grid-cols-3 gap-2">
        
        <div className="w-full flex flex-col gap-2 col-span-2">
          {/* Title  */}
          <div className="field w-full">
            <label htmlFor="title" className="job-label">Title</label>
            <InputCom
              fieldClass="px-6"
              // label="Title"
              // labelClass="tracking-wide"
              inputBg="bg-slate-100"
              type="text"
              name="title"
              value={formState.title}
              inputHandler={handleInputChange}
              //   blurHandler={props.handleBlur}
              //   error={props.errors.title && props.touched.title && props.errors.title}
            />
          </div>

          {/* Description  */}
          <div className="field flex flex-col sm:flex-row w-full gap-2">
            <div className="w-full">
              <label
                htmlFor="description"
                className='job-label'
              >
                Description
              </label>
              <textarea
                id="description"
                rows="5"
                className={`input-field px-3 py-2 placeholder:text-base text-dark-gray dark:text-white w-full h-[80px] bg-slate-100 dark:bg-[#11131F] focus:ring-0 focus:outline-[#dce4e9] rounded-[10px]`}
                style={{ resize: "none" }}
                name="description"
                value={formState.description}
                onChange={handleInputChange}
                // onBlur={props.handleBlur}
              />
            </div>
          </div>

          {/* Details */}
          <div className="field flex flex-col sm:flex-row w-full gap-2">
            <div className="w-full">
              <label
                htmlFor="Job Delivery Details"
                className='job-label'
              >
                Delivery Detail
                {/* {props.errors.job_detail && props.touched.job_detail && <span className="text-[12px] text-red-500">{props.errors.job_detail}</span>} */}
              </label>
              <textarea
                id="Job Delivery Details"
                rows="5"
                className={`input-field px-3 py-2 placeholder:text-base text-dark-gray dark:text-white w-full h-[100px] bg-slate-100 dark:bg-[#11131F] focus:ring-0 focus:outline-[#dce4e9] rounded-[10px]`}
                style={{ resize: "none" }}
                name="job_detail"
                value={formState.job_detail}
                onChange={handleInputChange}
                // onBlur={props.handleBlur}
              />
            </div>
          </div>
        </div>

        {/* inputs starts here */}
        <div className="w-full flex flex-col gap-2">
          <div className="grid grid-cols-2 gap-3">
            {/* Price */}
            <div className="field w-full">
              <label htmlFor="price" className="job-label">Reward</label>
              <InputCom
                fieldClass="px-6 text-right"
                // label="Price"
                // labelClass="tracking-wide"
                inputBg="bg-slate-100"
                type="number"
                name="price"
                placeholder="0"
                value={formState.price}
                inputHandler={handleInputChange}
                // blurHandler={props.handleBlur}
                // error={props.errors.price && props.touched.price && props.errors.price}
              />
            </div>

            {/* Currency */}
            <div className="field w-full">
              <label
                htmlFor="country"
                className="job-label invisible"
              >
                Currency
                {/* {props.errors.country && props.touched.country && <span className="text-[12px] text-red-500">{props.errors.country}</span>} */}
              </label>
              <select
                id="country"
                name="country"
                value={formState.country}
                className={`input-field w-full h-[42px] flex items-center px-2 mt-2 rounded-full placeholder:text-base text-dark-gray dark:text-white bg-slate-100 dark:bg-[#11131F] focus:ring-0 focus:outline-none`}
                onChange={handleInputChange}
                disabled={walletDetails.data.length == 1}
                // onBlur={props.handleBlur}
              >
                {walletDetails?.loading ? (
                  <option className="text-slate-500 text-[13.975px]" value="">
                    Loading...
                  </option>
                ) : walletDetails.data.length ? (
                  <>
                      {walletDetails.data.length == 1 ?
                      <>
                      {walletDetails.data?.map((item, index) => (
                          <option
                          key={index}
                          className="text-slate-500 text-lg"
                          value={item?.country}
                          >
                          {item?.code}
                          </option>
                      ))}
                      </>
                      :
                      <>
                          <option className="text-slate-500 text-[13.975px]" value="">
                              Select
                          </option>
                          {walletDetails.data?.map((item, index) => (
                              <option
                              key={index}
                              className="text-slate-500 text-lg"
                              value={item?.country}
                              >
                              {item?.code}
                              </option>
                          ))}
                      </>
                      }
                  </>
                ) : (
                  <option className="text-slate-500 text-lg" value="">
                    No Options Found! Try Again
                  </option>
                )}
              </select>
            </div>
          </div>

          {/* Duration */}
          <div className="field w-full">
            <label
              htmlFor="timeline_days"
              className="job-label"
            >
              Timeline
              {/* {props.errors.country && props.touched.country && <span className="text-[12px] text-red-500">{props.errors.country}</span>} */}
            </label>
            <select
              id="timeline_days"
              name="timeline_days"
              value={formState.timeline_days}
              className={`input-field w-full h-[42px] flex items-center px-2 mt-2 rounded-full placeholder:text-base text-dark-gray dark:text-white bg-slate-100 dark:bg-[#11131F] focus:ring-0 focus:outline-none`}
              onChange={handleInputChange}
              // onBlur={props.handleBlur}
            >
              {publicArray.length && (
                <>
                  <option className="text-slate-500 text-[13.975px]" value="">
                    Select
                  </option>
                  {publicArray.map(({ name, duration }, idx) => (
                    <option
                      key={idx}
                      className="text-slate-500 text-[13.975px]"
                      value={duration}
                    >
                      {name}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>

          {/* Banner Image */}
          <div className="w-full">
            <div className="h-32 w-full">
              <img
                src={selectImage}
                alt="task_banner_img"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        </div>

      </div>
    </form>
  );
}

const publicArray = [
  { duration: 1, name: "1 day" },
  { duration: 2, name: "2 days" },
  { duration: 3, name: "3 days" },
  { duration: 4, name: "4 days" },
  { duration: 5, name: "5 days" },
  { duration: 6, name: "6 days" },
  { duration: 7, name: "1 week" },
  { duration: 14, name: "2 weeks" },
  { duration: 21, name: "3 weeks" },
  { duration: 28, name: "4 weeks" },
];
