import React, { useEffect, useState } from "react";
import dataImage1 from "../../assets/images/data-table-user-1.png";
import usersService from "../../services/UsersService";
import LoadingSpinner from "../Spinners/LoadingSpinner";

export default function HomeActivities({ className }) {
  const [recentActivitiesData, setRecentActivitiesData] = useState({
    loading: false,
    status: false,
    msg: "",
    data: null,
  });

  const _apiCall = new usersService();

  const getRecentActivities = async () => {
    setRecentActivitiesData((prev) => ({ ...prev, loading: true }));
    let res;
    try {
      res = await _apiCall.getRecentActivities(null);
      res = res.data;
      if (res?.internal_return >= 0) {
        setRecentActivitiesData((prev) => ({
          ...prev,
          loading: false,
          status: true,
          data: res.result_list,
        }));
      } else {
        setRecentActivitiesData((prev) => ({
          ...prev,
          loading: false,
          status: false,
          msg: "Something went wrong",
        }));
      }
    } catch (error) {
      setRecentActivitiesData((prev) => ({
        ...prev,
        loading: false,
        status: false,
        msg: "Unable to see this",
      }));
      throw new Error(`Unable to see this because: ${error}`);
    }
  };

  useEffect(() => {
    getRecentActivities();
  }, []);

  console.log(recentActivitiesData);

  return (
    <div
      className={`update-table w-full p-8 bg-white dark:bg-dark-white   overflow-hidden rounded-2xl relative min-h-[520px] ${
        className || ""
      }`}
    >
      <div className="header w-full sm:flex justify-between items-center mb-5">
        <div className="flex space-x-2 items-center mb-4 sm:mb-0">
          <h1 className="text-center text-2xl font-bold text-dark-gray dark:text-white tracking-wide">
            Recent Activities
          </h1>
        </div>
      </div>
      <div className="relative w-full overflow-x-auto sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            {/* table heading */}
            {/*<tr className="text-base text-thin-light-gray whitespace-nowrap px-2 border-b dark:border-[#5356fb29]  default-border-b dark:border-[#5356fb29] ottom ">*/}
            {/*    <td className="py-4 w-[300px] block whitespace-nowrap">*/}
            {/*        All Product*/}
            {/*    </td>*/}
            {/*    <td className="py-4 whitespace-nowrap text-center">.</td>*/}
            {/*    <td className="py-4 whitespace-nowrap text-center"></td>*/}

            {/*    <td className="py-4 whitespace-nowrap text-right"></td>*/}
            {/*</tr>*/}

            {recentActivitiesData.loading ? (
              <tr>
                <td>
                  <div className="h-[100px] w-full flex justify-center items-center">
                    <LoadingSpinner color="sky-blue" size="16" />
                  </div>
                </td>
              </tr>
            ) : recentActivitiesData.data ? (
              recentActivitiesData.data?.map((item) => {
                let addedDate = item?.added?.split(" ")[0];
                return (
                  <tr
                    className="bg-white dark:bg-dark-white border-b dark:border-[#5356fb29] hover:bg-gray-50"
                    key={item.uid}
                  >
                    <td className="py-3">
                      <div className="flex space-x-2 items-center">
                        {/* <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex justify-center items-center">
                          <img
                            src={dataImage1}
                            alt="data"
                            className="w-full h-full"
                          />
                        </div> */}
                        <div className="flex flex-col">
                          <h1 className="font-bold text-xl text-dark-gray dark:text-white">
                            {item.title ? item.title : "Title"}
                          </h1>
                          <span className="text-sm text-thin-light-gray">
                            {item.description ? item.description : ""}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td className="text-right py-3">
                      <div className="flex space-x-1 items-center justify-end">
                        <span className="text-base text-dark-gray dark:text-white font-medium">
                          {item.added ? addedDate : ""}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
}
