import React from 'react'
import FamMyFiles from '../components/familyResources/FamMyFiles'

export default function FamMyFilesPage() {
  return (
    <>
      <FamMyFiles />
    </>
  )
}
