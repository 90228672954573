import { Navigate, useLocation } from "react-router-dom";
import Routers from "./Routers";
import Toaster from "./components/Helpers/Toaster";
import Default from "./components/Partials/Default";
import SocketIOContextProvider from "./components/Contexts/SocketIOContext";

function App() {
  const { pathname } = useLocation();
  return (
    <Default>
      <SocketIOContextProvider>
        <>
          {pathname.startsWith("/@") ? (
            <Navigate to="/app" replace={true} />
          ) : (
            <Routers />
          )}
          <Toaster />
        </>
      </SocketIOContextProvider>
    </Default>
  );
}

export default App;
