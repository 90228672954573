import React, { lazy, Suspense } from 'react'
import LoadingSpinner from '../components/Spinners/LoadingSpinner';
// import FamGames from '../components/familyResources/FamGames'

const FamGames = lazy(() => import("../components/familyResources/FamGames"));

export default function FamGamesPage() {
  return (
    <>
      <Suspense fallback={<Fallback />}>
          <FamGames />
      </Suspense>
    </>
  )
}


let Fallback = () => {
  return (
    <div className="w-full flex justify-center items-center">
      <LoadingSpinner size='20' color='skyblue' height='h-screen' />
    </div>
  )
}