import ActivitiesTab from "./ActivitiesTab";
import CollectionTab from "./CollectionTab";
import CreatedTab from "./CreatedTab";
import HiddenProductsTab from "./HiddenProductsTab";
import OnSaleTab from "./OnSaleTab";
import OwnTab from "./OwnTab";
import BlogTab from "./BlogTab";
import QuestionsTab from "./QuestionsTab";
import MyUploadedFiles from "./MyUploadedFiles";

export {
  BlogTab,
  QuestionsTab,
  ActivitiesTab,
  CollectionTab,
  CreatedTab,
  HiddenProductsTab,
  OnSaleTab,
  OwnTab,
  MyUploadedFiles,
};