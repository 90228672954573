import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../Spinners/LoadingSpinner'

export default function InfiniteScroll({
    allData=data,
    addItemBy=12,
    intialItemsToShow=39,
    children
}) {

    let [currentPage, setCurrentPage] = useState(intialItemsToShow)

    let [loading, setLoading] = useState(false)

    let dataToShow = allData.slice(0, currentPage)

    // let [winScr, setWinScr] = useState(0)

    const customScroll = ()=>{
        let wi = window.innerHeight + window.scrollY
        // let scrollContainer = document.querySelector('.scroll-container').scrollHeight
        let scrollBody = document.body.offsetHeight
        if(wi >= scrollBody && allData.length > currentPage){
            setLoading(true)
            setTimeout(()=>{
                setCurrentPage(prev => prev+addItemBy)
                setLoading(false)
            },2000)
        }
        // else if(window.scrollY < winScr && currentPage > intialItemsToShow){
        //         setCurrentPage(prev => prev-addItemBy)
        //         window.scrollTo(0,wi)
        //         setWinScr(window.scrollY)
        // }
        // else if(winScr == 0 && currentPage > intialItemsToShow){
        //         setCurrentPage(prev => intialItemsToShow)
        //         window.scrollTo(0,0)
        //         setWinScr(window.scrollY)
        // }
    }
    useEffect(()=>{
        window.addEventListener('scroll', customScroll)
        return () => {
            window.removeEventListener('scroll', customScroll)
        }
    },[currentPage])

  return (
    <div className='w-full'>

        {children({ dataToShow })}
        
        {loading && 
        <div className='w-full my-2 p-2 flex justify-center items-center'>
            <LoadingSpinner size='8' />
        </div>
        }
    </div>
  )
}

// let data = new Array(100).fill(0).map((_,i) => i )
