import React, { useState, useEffect } from "react";
import transaction1 from "../../assets/images/recent-transation-1.png";
import transaction2 from "../../assets/images/recent-transation-2.png";
import transaction3 from "../../assets/images/recent-transation-3.png";

import LoadingSpinner from "../Spinners/LoadingSpinner";
import { handlePagingFunc } from "../Pagination/HandlePagination";
import PaginatedList from "../Pagination/PaginatedList";
import usersService from "../../services/UsersService";
import DeleteIcon from '../../assets/images/icon-delete.svg'
import DeleteTaskModal from "./DeleteTaskModal";
import SendReminderModal from "./SendReminderModal";

export default function ParentWaitingTable() {
    // const transationFilterData = [
    //     {
    //         id: 1,
    //         name: "all",
    //         uniqueId: Math.random(),
    //     },
    //     {
    //         id: 2,
    //         name: "send",
    //         uniqueId: Math.random(),
    //     },
    //     {
    //         id: 3,
    //         name: "recent",
    //         uniqueId: Math.random(),
    //     },
    // ];
    // const [filterActive, setValue] = useState(transationFilterData[0].id);
    // const filterHander = (value) => {
    //     setValue(value);
    // };

    const apiCall = new usersService()

    let [reloadList, setReloadList] = useState(false) // STATE TO DETERMINE WHEN TO RELOAD THE FAMILY SUGGEST LIST

    let [deleteTaskPopout, setDeleteTaskPopout] = useState({show:false, data:{}}) // HOLDS THE INFO OF DELETE TASK POPOUT
    let [sendReminderPopout, setSendReminderPopout] = useState({show:false, data:{}}) // HOLDS THE INFO OF SEND REMINDER POPOUT

    let [familySuggestList, setFamilySuggestList] = useState({loading: true, data:[]})

    const [currentPage, setCurrentPage] = useState(0);
    const indexOfFirstItem = Number(currentPage);
    const indexOfLastItem =
        Number(indexOfFirstItem) + Number(process.env.REACT_APP_ITEM_PER_PAGE);
    const currentList = familySuggestList?.data?.slice(indexOfFirstItem, indexOfLastItem);

    const handlePagination = (e) => {
        handlePagingFunc(e, setCurrentPage);
    };

    useEffect(()=>{
        apiCall.getFamilySuggestList().then(res => {
            setFamilySuggestList({loading: false, data:res.data?.result_list})
        }).catch(err => {
            setFamilySuggestList({loading: false, data:[]})
            console.log('ERROR==>Familysuggestlist', err)
        })
    },[reloadList])

    return (
        <div className="rounded-2xl bg-white dark:bg-dark-white flex flex-col justify-between">
            {/* heading */}
            {/* <div className="heading sm:flex justify-between items-center">
                <div>
                    <ul className="flex space-x-5 items-center">
                        {transationFilterData.map((value) => (
                            <li
                                onClick={() => filterHander(value.id)}
                                key={value.uniqueId}
                                className={`text-base text-thin-light-gray hover:text-purple border-b dark:border-[#5356fb29]  border-transparent hover:border-purple uppercase ${
                                    filterActive === value.id ? "border-purple text-purple" : ""
                                }`}
                            >
                                {value.name}
                            </li>
                        ))}
                    </ul>
                </div>
            </div> */}

            {/* content */}
            <div className="content min-h-[400px]">
                {familySuggestList.loading ?
                    <div className="h-full flex justify-center items-center">
                        <LoadingSpinner size='16' color='sky-blue' />
                    </div>
                    :
                    familySuggestList && familySuggestList.length != 0?
                    <ul>
                        {currentList.map((item)=>{
                              //   Image
                                let Image = require(`../../assets/images/family/${
                                    item.banner || "default.jpg"
                                }`);
                            return (
                            <li key={item.uid} className="content-item py-2 border-b dark:border-[#5356fb29]  border-light-purple hover:border-purple">
                                <div className="w-full flex justify-between items-center">
                                    <div className="account-name flex space-x-4 items-center">
                                        <div className="icon w-14 h-14 flex justify-center items-center">
                                            <img src={Image} alt="" className="w-full h-full" />
                                        </div>
                                        <div className="w-full">
                                            <p className="text-xl font-bold text-dark-gray dark:text-white mb-2 capitalize line-clamp-1">
                                                {item.title}
                                            </p>

                                            <p className="text-sm text-thin-light-gray font-medium">
                                                {item.description}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="min-w-[120px] flex justify-start items-end px-2 gap-1">
                                        <button
                                            type="button"
                                            className="p-1 border-2 border-red-400 rounded-md"
                                            onClick={() => {
                                                setDeleteTaskPopout({
                                                    show: true,
                                                    data: { ...item },
                                                });
                                            }}
                                        >
                                            <img className="min-w-[21px] h-[21px]" src={DeleteIcon} alt='delete-icon' />
                                        </button>

                                        <div className="">
                                            <p className="text-sm font-bold text-dark-gray dark:text-white">
                                                {new Date(item.added).toLocaleString().split(',')[0]}
                                                
                                            </p>
                                            <p 
                                                className="text-xs py-1.5 w-[50px] tracking-wide rounded-full bg-gold text-white flex justify-center items-center cursor-pointer"
                                                onClick={() => {
                                                    setSendReminderPopout({
                                                        show: true,
                                                        data: { ...item },
                                                    });
                                                }}
                                            >{item.status_text}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            )
                        })}
                    </ul>
                    :
                    <p className="w-full flex items-center justify-center text-xl text-dark-gray dark:text-white">No List Found!</p>
                }

                {/* Delete Task Popout */}
                {deleteTaskPopout.show && (
                    <DeleteTaskModal
                    details={deleteTaskPopout.data}
                    onClose={() => {
                        setDeleteTaskPopout({ show: false, data: {} });
                    }}
                    situation={deleteTaskPopout.show}
                    setReloadList={setReloadList}
                    />
                )}
                {/* END of Delete Task Popout */}

                {/* Send Reminder Popout */}
                {sendReminderPopout.show && (
                    <SendReminderModal
                    details={sendReminderPopout.data}
                    onClose={() => {
                        setSendReminderPopout({ show: false, data: {} });
                    }}
                    situation={sendReminderPopout.show}
                    setReloadList={setReloadList}
                    />
                )}
                {/* END of Send Reminder Popout */}
            </div>

            {/* PAGINATION BUTTON */}
            <PaginatedList
            onClick={handlePagination}
            prev={currentPage == 0 ? true : false}
            next={
            currentPage + Number(process.env.REACT_APP_ITEM_PER_PAGE) >=
            familySuggestList?.data?.length
                ? true
                : false
            }
            data={familySuggestList?.data}
            start={indexOfFirstItem}
            stop={indexOfLastItem}
            />
            {/* END OF PAGINATION BUTTON */}

        </div>
    );
}
