import React from 'react'
import FamAIQuestion from '../components/familyResources/FamAIQuestion'

export default function FamAIQuestionPage() {
  return (
    <>
        <FamAIQuestion />
    </>
  )
}
