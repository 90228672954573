import React from 'react'
import Layout from '../Partials/Layout'
import CustomBreadcrumb from '../Breadcrumb/CustomBreadcrumb'

export default function FamWorkInProgress() {
  return (
    <Layout>
      <>
        <div className='mb-4'>
          <CustomBreadcrumb 
            title='Games and Interest' 
            breadcrumb = {
              [
                { link: "/", title: "Home" },
                { link: "/work-in-progress", title: "Games and Interest", active: true},
              ]
            }
          />
        </div>
        <div className='h-[20rem] w-full bg-white shadow-lg flex justify-center items-center rounded-2xl'>Work in Progress Coming Soon</div>
      </>
    </Layout>
  )
}
