import React from "react";
import Accordion from "../../Helpers/Accordion";
import LoadingSpinner from "../../Spinners/LoadingSpinner";

export default function FaqTab({ datas }) {
  return (
    <div className="faq-tab w-full">
      <div className="accordion-items w-full">
        {datas.loading ? 
          <LoadingSpinner color='sky-blue' size={16} />  
        :
        datas &&
          datas?.data?.length > 0 &&
          datas.data.map((value, index) => (
            <Accordion key={index + Math.random()} datas={value} />
          ))}
      </div>
    </div>
  );
}
