import React, { useState } from "react";
import { useSelector } from "react-redux";
import TimeDifference from "../Helpers/TimeDifference";
import { handlePagingFunc } from "../Pagination/HandlePagination";
import PaginatedList from "../Pagination/PaginatedList";
import Layout from "../Partials/Layout";

const tabs = [
  {
    id: 1,
    date: "today",
    title: "Today's",
  },
  {
    id: 2,
    date: "days",
    title: "7 days",
  },
  {
    id: 3,
    date: "all",
    title: "All",
  },
];

export default function Notification() {
  const [selectTab, setValue] = useState(tabs ? tabs[2].title : "");
  const { notifications } = useSelector((state) => state?.notifications);

  const [notificationData, setNotificationData] = useState(notifications?.data || [])

  
  const [currentPage, setCurrentPage] = useState(0);
  const indexOfFirstItem = Number(currentPage);
  const indexOfLastItem = Number(indexOfFirstItem) + Number(process.env.REACT_APP_ITEM_PER_PAGE);
  const currentNotifications = notificationData?.slice(indexOfFirstItem, indexOfLastItem);
  
  const filterHandler = (value) => {
    setValue(value);
    switch(value){
      case `Today's`:
        // setNotificationData(notifications?.data?.slice(0,5))
        setNotificationData(notifications?.data.filter(item => (new Date().getTime() - new Date(item.date).getTime())/(1000*60*60*24) <= 1))
        setCurrentPage(0)
        break;
      case `All`:
        setNotificationData(notifications?.data)
        setCurrentPage(0)
        break;
      case `7 days`:
        // setNotificationData(notifications?.data?.slice(0,2))
        setNotificationData(notifications?.data.filter(item => (new Date().getTime() - new Date(item.date).getTime())/(1000*60*60*24) <= 7))
        setCurrentPage(0)
        break;
      default:
        break;
    }
  };

  const handlePagination = (e) => {
    handlePagingFunc(e, setCurrentPage);
  };

  return (
    <Layout>
      <div className="notification-page w-full mb-10">
        <div className="notification-wrapper w-full">
          {/* heading */}
          <div className="sm:flex justify-between items-center mb-6">
            <div className="mb-5 sm:mb-0">
              <h1 className="text-26 font-bold   text-dark-gray dark:text-white">
                <span>{selectTab + " "}</span> Notifications
              </h1>
            </div>
            <div className="slider-btns flex space-x-4">
              {tabs.map(({ id, title, date }) => (
                <div key={id} onClick={() => filterHandler(title)} className="relative">
                  <span
                    className={`text-thin-light-gray text-18 cursor-pointer ${
                      selectTab === title ? "text-purple" : ""
                    }`}
                  >
                    {title}
                  </span>
                  {date === "today" && (
                    <span className="absolute -right-3 -top-3 text-white text-xs w-5 h-5 rounded-full flex justify-center items-center bg-pink">
                      {notifications?.data?.length}
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Body */}
          <div
            className={`w-full p-8 rounded-xl bg-white dark:bg-dark-white max-h-[680px] h-full`}
          >
            <ul className="min-h-[500px] h-full">
              {currentNotifications?.length > 0 ? (
                <>
                  {currentNotifications?.map(({ date, icon, title }, idx) => {
                    return (
                      <NotificationItem
                        date={date}
                        icon={icon}
                        title={title}
                        length={currentNotifications?.length}
                        key={idx}
                      />
                    );
                  })}
                </>
              ) : (
                "No Notifications Available"
              )}
            </ul>
            <div className="add-money-btn flex justify-center items-center">
              {/* PAGINATION BUTTON */}
              <PaginatedList
                onClick={handlePagination}
                prev={currentPage == 0 ? true : false}
                next={
                  currentPage + Number(process.env.REACT_APP_ITEM_PER_PAGE) >=
                  notificationData.length
                    ? true
                    : false
                }
                data={notificationData}
                start={indexOfFirstItem}
                stop={indexOfLastItem}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const NotificationItem = ({ date, icon, title, length }, idx) => {
  return (
    <li
      className={`content-item ${
        idx === length - 1
          ? "py-5 "
          : "py-4 border-b dark:border-[#5356fb29]  border-light-purple "
      }`}
      key={idx}
    >
      <div className="notifications flex space-x-4 items-center">
        <div className="icon max-w-[52px] max-h-[52px] w-full h-full rounded-full object-cover">
          <img
            src={require(`../../assets/images/notifications/${icon}`)}
            alt="icon"
            className="w-full h-full"
          />
        </div>
        <div className="name cursor-default">
          <p className="text-base text-dark-gray dark:text-white font-medium mb-2">
            {title}
          </p>
          <p className="text-sm text-thin-light-gray font-medium">
            <TimeDifference time={date} key={idx} />
          </p>
        </div>
      </div>
    </li>
  );
};
