import React, { useContext, useState, useEffect } from "react";
import BlogItem from "../components/Blogs";
import { useSelector } from "react-redux";

export default function BlogPage() {
    let {commonHeadBanner} = useSelector(state => state.commonHeadBanner)

    return (
        <>
            <BlogItem  commonHeadData={commonHeadBanner.result_list} />
        </>
    );
}
