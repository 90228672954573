import React, { useRef } from "react";
import top from "../../assets/images/top-buyer-1png.png";
import top2 from "../../assets/images/top-buyer-2.png";
import top3 from "../../assets/images/top-buyer-3.png";
import top4 from "../../assets/images/top-buyer-4.png";
import Icons from "../Helpers/Icons";
import SliderCom from "../Helpers/SliderCom";
import { Link } from "react-router-dom";

export default function RecomendedSliders({ className, bannerData }) {
  const settings = {
    arrows: false,
    dots: false,
    infinite: bannerData.length > 4 ? true : false,
    autoplay: true,
    slidesToShow: bannerData.length > 4 ? 4 : bannerData.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 426,
        settings: {
          infinite: bannerData.length > 2 ? true : false,
          slidesToShow: bannerData.length > 2 ? 2 : bannerData.length,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const sellSlider = useRef(null);
  const buySlider = useRef(null);
  const prevHandler = (value) => {
    if (value === "sell") {
      sellSlider.current.slickPrev();
    }
    if (value === "buy") {
      buySlider.current.slickPrev();
    }
  };
  const nextHandler = (value) => {
    if (value === "sell") {
      sellSlider.current.slickNext();
    }
    if (value === "buy") {
      buySlider.current.slickNext();
    }
  };
  return (
    <>
      {/*<div className="heading flex justify-between items-center mb-4">*/}
      {/*    <h1 className="text-xl font-bold text-dark-gray dark:text-white tracking-wide">*/}
      {/*        Top Seller*/}
      {/*    </h1>*/}
      {/*    <div className="slider-btns flex space-x-4">*/}
      {/*        <button*/}
      {/*            onClick={() => nextHandler("sell")}*/}
      {/*            type="button"*/}
      {/*            className="transform rotate-180 text-dark-gray dark:text-white dark:opacity-25"*/}
      {/*        >*/}
      {/*            <Icons name="arrows" />*/}
      {/*        </button>*/}
      {/*        <button*/}
      {/*            onClick={() => prevHandler("sell")}*/}
      {/*            type="button"*/}
      {/*            className="transform rotate-180"*/}
      {/*        >*/}
      {/*            <div className="  text-dark-gray dark:text-white">*/}
      {/*                <svg*/}
      {/*                    width="11"*/}
      {/*                    height="19"*/}
      {/*                    viewBox="0 0 11 19"*/}
      {/*                    fill="none"*/}
      {/*                    xmlns="http://www.w3.org/2000/svg"*/}
      {/*                >*/}
      {/*                    <path*/}
      {/*                        d="M9.09766 1.1499L1.13307 9.11449L9.09766 17.0791"*/}
      {/*                        stroke="url(#paint0_linear_220_23410)"*/}
      {/*                        strokeWidth="2"*/}
      {/*                        strokeLinecap="round"*/}
      {/*                        strokeLinejoin="round"*/}
      {/*                    />*/}
      {/*                    <defs>*/}
      {/*                        <linearGradient*/}
      {/*                            id="paint0_linear_220_23410"*/}
      {/*                            x1="9.09766"*/}
      {/*                            y1="1.1499"*/}
      {/*                            x2="-4.2474"*/}
      {/*                            y2="7.96749"*/}
      {/*                            gradientUnits="userSpaceOnUse"*/}
      {/*                        >*/}
      {/*                            <stop stopColor="#F539F8" />*/}
      {/*                            <stop offset="0.416763" stopColor="#C342F9" />*/}
      {/*                            <stop offset="1" stopColor="#5356FB" />*/}
      {/*                        </linearGradient>*/}
      {/*                    </defs>*/}
      {/*                </svg>*/}
      {/*            </div>*/}
      {/*        </button>*/}
      {/*    </div>*/}
      {/*</div>*/}
      <div className="slider-content">
        <SliderCom settings={settings} selector={sellSlider}>
          {bannerData.map((item, index) => (
            <Link key={index} to={item.link_path == 'blog-page' ? `/${item.link_path}?blog_id=${item.blog_id}` : `/${item.link_path}`}>
              <div className="item">
                <div
                  className={`commonHeaderSliderItem flex gap-1 flex-col justify-between items-center ${item.short_style}`}
                >
                  {/* title */}
                  <div className="flex justify-center items-center text-center">
                    <p className="text-base font-bold   text-dark-gray dark:text-white">
                      {item.short_title}
                    </p>
                  </div>
                  {/* username */}
                  <div className="flex justify-center mb-1">
                    <p className="text-xs text-thin-light-gray text-justify">
                      {item.short_description}
                    </p>
                  </div>
                  {/* items */}
                  <div className="flex justify-center">
                    <div className="flex space-x-1 items-center text-purple text-xs">
                      <span>{item.short_button_text}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </SliderCom>
      </div>
    </>
  );
}
