import React from "react";
import Calendar from "../components/Calendar";

export default function CalendarPage() {
    return (
        <>
            <Calendar />
        </>
    );
}
