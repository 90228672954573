import React, { useState, useEffect } from "react";
import MyTasks from "../components/MyTasks";
// import UsersService from "../services/UsersService";
import usersService from "../services/UsersService";

import { useSelector } from "react-redux";

export default function MyTaskPage() {
  const { myTaskTable } = useSelector((state) => state.tableReload);

  let { commonHeadBanner } = useSelector((state) => state.commonHeadBanner);

  const [MyActiveJobList, setMyActiveJobList] = useState({
    loading: true,
    status: false,
    data: [],
  });
  const [MyActiveOffersList, setMyActiveOffersList] = useState([]);
  const api = new usersService();

  const getMyActiveJobList = async () => {
    setMyActiveJobList({ loading: true, status: false, data: [] });
    try {
      const res = await api.getMyActiveTaskList();
      setMyActiveJobList({
        loading: false,
        status: true,
        data: res.data.result_list,
      });
    } catch (error) {
      setMyActiveJobList({ loading: false, status: false, data: [] });
      console.log("Error getting tasks");
    }
  };
  const getMyActiveOffersList = async () => {
    // setMyActiveOffersList({loading: true, status:false, data:[]});
    try {
      const res = await api.getOffersList();
      setMyActiveOffersList(res.data);
    } catch (error) {
      // setMyActiveOffersList({loading: false, status:false, data:[]});
      console.log("Error getting offers");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([getMyActiveJobList(), getMyActiveOffersList()]);
    };

    fetchData();
  }, [myTaskTable]);

  //debugger;
  return (
    <MyTasks
      ActiveJobList={MyActiveJobList}
      MyActiveOffersList={MyActiveOffersList}
      commonHeadData={commonHeadBanner?.result_list}
    />
  );
}
