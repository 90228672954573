import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MyReviewDueJobs from "../components/MyActiveJobs/MyReviewDueJobs";
import usersService from "../services/UsersService";

/**
 * React component that fetches a list of review due jobs and renders the `MyReviewDueJobs` component with the fetched data.
 */
export default function MyReviewDueJobsPage() {
  const { commonHeadBanner } = useSelector((state) => state.commonHeadBanner);
  const [myJobList, setMyJobList] = useState([]);

  useEffect(() => {
    const api = new usersService();

    const getMyJobList = async () => {
      try {
        const res = await api.getMyReviewDueJobList();
        setMyJobList(res.data);
      } catch (error) {
        console.log("Error getting mode");
      }
    };

    getMyJobList();
  }, []);

  return (
    <>
      <MyReviewDueJobs
        myJobList={myJobList}
        commonHeadData={commonHeadBanner.result_list}
      />
    </>
  );
}
