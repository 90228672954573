import React from 'react'
import JobGroups from '../components/JobGroups/JobGroups'

export default function JobGroupsPage() {
  return (
    <>
     <JobGroups />
    </>
  )
}
