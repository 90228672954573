/**

Returns a debounced version of a given function, which means that it delays the execution of the function until a certain amount of time has passed without the function being called again. This can be useful for performance optimization, especially when dealing with expensive or resource-intensive functions that are called frequently.
@param {Function} func - The function to debounce.
@param {number} delay - The number of milliseconds to wait before executing the debounced function.
@returns {Function} - The debounced version of the original function.
*/

export default function debounce(func, delay) {
  let timeoutId;

  return function (...args) {
    const context = this;

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}
