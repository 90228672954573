import React, { useState } from "react";
import { Link } from "react-router-dom";
import defaultImg from "../../assets/images/myfiles/default.svg";
import localImgLoad from "../../lib/localImgLoad";
import { PaginatedList, handlePagingFunc } from "../../components/Pagination";
import Layout from "../Partials/Layout";
import CustomBreadcrumb from "../Breadcrumb/CustomBreadcrumb";

export default function FamMyFiles() {

  let uploadedFiles = [] // To be updated Later from replaced with real data from API CALL

  const [currentPage, setCurrentPage] = useState(0);
  const indexOfFirstItem = Number(currentPage);
  const indexOfLastItem =
    Number(indexOfFirstItem) + Number(process.env.REACT_APP_ITEM_PER_PAGE);
  const currentFiles = uploadedFiles?.data?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const handlePagination = (e) => {
    handlePagingFunc(e, setCurrentPage);
  };
  return (
    <Layout>
      <>
        <div className=''>
          <CustomBreadcrumb 
            title='My Files' 
            breadcrumb = {
              [
                { link: "/", title: "Home" },
                { link: "/myfiles", title: "My Files", active: true},
              ]
            }
          />
        </div>
        <div className="mb-4 w-full flex justify-end item-center">
          <Link
            to="/my-uploads"
            className="btn-gradient lg:flex hidden w-[153px] h-[46px] rounded-full text-white justify-center items-center"
          >
            Add My Item
          </Link>
        </div>
        <div
          className={`update-table w-full p-8 bg-white dark:bg-dark-white   overflow-hidden rounded-2xl section-shadow relative min-h-[520px]`}
        >
          <div className="header w-full sm:flex justify-between items-center mb-5">
            <div className="flex space-x-2 items-center mb-2 sm:mb-0">
              <h1 className="text-xl font-bold text-dark-gray dark:text-white tracking-wide">
                My Uploads
              </h1>
            </div>
          </div>
          <div className="relative w-full overflow-x-auto sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <tbody>
                <>
                  {uploadedFiles?.data && uploadedFiles?.data.length ? (
                    currentFiles.map((value, idx) => {
                      let addedDate = value?.added?.split(" ")[0];
                      let formattedSize = formatFileSize(value?.file_size);
                      let imageLink = `${uploadedFiles?.image}${localStorage.getItem('session_token')}/myfile/${value.file_uid}`
                      return (
                        <tr
                          key={value?.file_uid}
                          className="bg-white dark:bg-dark-white border-b dark:border-[#5356fb29]  hover:bg-gray-50"
                        >
                          <td className=" py-4">
                            <div className="flex space-x-2 items-center w-full">
                              <div className="w-[60px] h-[60px] p-2 bg-alice-blue rounded-full overflow-hidden flex justify-center items-center">
                                <img
                                  src={
                                    localImgLoad(
                                      `images/myfiles/${value.banner}`
                                    ) || defaultImg
                                  }
                                  alt="data"
                                  className="w-full h-full rounded-full"
                                />
                              </div>
                              <div className="flex flex-col flex-[0.9]">
                                <h1 className="font-bold text-xl text-dark-gray dark:text-white">
                                  {value.title || "Dummy Text"}
                                </h1>
                                <div>
                                  {value.description || "Dummy Description"}
                                </div>
                                <span className="text-sm text-thin-light-gray flex flext-start gap-1">
                                  Added:{" "}
                                  <span className="text-purple">{addedDate}</span>
                                </span>
                                <div className="flex gap-4 items-center">
                                  <span className="text-sm text-thin-light-gray">
                                    File Name:{" "}
                                    <span className="text-purple">
                                      {" "}
                                      {value.file_name}
                                    </span>
                                  </span>
                                  <span className="text-sm text-thin-light-gray">
                                    File Size:{" "}
                                    <span className="text-purple">
                                      {" "}
                                      {formattedSize}
                                    </span>
                                  </span>
                                  <span className="text-sm text-thin-light-gray">
                                    File Type:{" "}
                                    <span className="text-purple">
                                      {" "}
                                      {value?.file_type}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text-right py-4 px-2">
                            <div className="flex justify-center items-center">
                              <a
                                href={imageLink}
                                title="download"
                                // className="w-20 h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white"
                              >
                                <img 
                                  src={localImgLoad('images/icons/download-arrow.svg')} 
                                  alt='download-link' 
                                  className="w-auto h-6 flex justify-center items-center"
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                      <td className="p-2" colSpan={3}>
                        No Files Currently!
                      </td>
                    </tr>
                  )}
                </>
              </tbody>
            </table>
            {/* PAGINATION BUTTON */}
            <PaginatedList
              onClick={handlePagination}
              prev={currentPage == 0 ? true : false}
              next={
                currentPage + Number(process.env.REACT_APP_ITEM_PER_PAGE) >=
                uploadedFiles?.data?.length
                  ? true
                  : false
              }
              data={uploadedFiles?.data}
              start={indexOfFirstItem}
              stop={indexOfLastItem}
            />
            {/* END OF PAGINATION BUTTON */}
          </div>
        </div>
      </>
    </Layout>
  );
}

const formatFileSize = (sizeInBytes) => {
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} bytes`;
  } else if (sizeInBytes < 1024 * 1024) {
    const sizeInKB = (sizeInBytes / 1024).toFixed(2);
    return `${sizeInKB} KB`;
  } else {
    const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
    return `${sizeInMB} MB`;
  }
};
