import React, {useEffect, useState} from 'react'
import method1 from "../../../assets/images/payment-cards/discover-card.svg";
import LoadingSpinner from '../../Spinners/LoadingSpinner';
import localImgLoad from '../../../lib/localImgLoad';

import usersService from "../../../services/UsersService";
import { handlePagingFunc } from '../../Pagination';
import PaginatedList from '../../Pagination/PaginatedList';

import DeleteCardPopout from './DeleteCardPopout';

function CardList() {
    const api = new usersService();
    
    const [reloadCardList, setReloadCardList] = useState(false) // STATE TO DETERMINE WHEN CARD LIST RELOADS. EG: WHEN USER DELETES A CARD

  const [cardList, setCardList] = useState({loading: true, data: []})

  const [deleteCardModal, setDeleteCardModal] = useState({show: false, data: {}}) // STATE TO HOLD WHEN DELETE MODAL POPS UP
  const handleDeleteCardModal = () => {
    setDeleteCardModal(prev => ({...prev, show:!prev.show}))
  }

  const [currentPage, setCurrentPage] = useState(0);
  const indexOfFirstItem = Number(currentPage);
  const indexOfLastItem =
    Number(indexOfFirstItem) + Number(process.env.REACT_APP_ITEM_PER_PAGE);
  const currentCardList = cardList?.data?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePagination = (e) => {
    handlePagingFunc(e, setCurrentPage);
  };

  useEffect(()=>{
    setCardList({loading: true, data: []})
    api.payListCard().then(res=>{
      setCardList({loading: false, data:res.data?.result_list})
    }).catch(err => {
      setCardList({loading: false, data:[]})
      console.log('ERROR', err)
    })
  },[reloadCardList])
  return (
    <div className='w-full'>
        <ul className="payment-items w-full min-h-[400px]">
            {cardList.loading ?
            <div className='w-full h-[300px] flex justify-center items-center'>
                <LoadingSpinner size='8' color='sky-blue' />
            </div>
            :
            cardList.data.length ?
            currentCardList.map(item =>{
            let image = item.description && localImgLoad(`images/payment-cards/${item.description.toLowerCase()}-card.svg`)
            return(
                <li key={item.card_uid} className="sm:flex justify-between items-center w-full py-3 border-b border-light-purple dark:border-[#5356fb29] ">
                    <div className="flex space-x-5 mb-2 sm:mb-0">
                        <div className="w-[70px] h-[70px] flex items-center justify-center rounded-full sm:bg-light-purple dark:bg-dark-light-purple ">
                            <img src={image ? image : method1} className='w-[50px] h-[50px]' alt="payment" />
                        </div>
                        <div>
                        <p className="sm:text-xl text-lg tracking-wide   text-dark-gray dark:text-white">
                            Added: {item.added}
                        </p>
                        <p className="text-thin-light-gray sm:text-18 text-sm tracking-wide">
                            {item.description} **********{item.digits}
                        </p>
                        {/* <p className="sm:text-18 text-sm tracking-wide text-light-green">
                            Verified
                        </p> */}
                        </div>
                    </div>
                    <div>
                        <button
                        type="button"
                        onClick={()=>{setDeleteCardModal({show: true, data:item})}}
                        className="w-[95px] sm:h-[46px] h-[40px] rounded-full btn-gradient text-white sm:text-18 text-md tracking-wide"
                        >
                        Delete
                        </button>
                    </div>
                </li>
            )}
            )
            :
            <h1 className='font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap'>No Cards Found</h1>
            }
        </ul>

        {/* PAGINATION BUTTON */}
        <PaginatedList
            onClick={handlePagination}
            prev={currentPage == 0 ? true : false}
            next={
            currentPage + Number(process.env.REACT_APP_ITEM_PER_PAGE) >=
            cardList?.data?.length
                ? true
                : false
            }
            data={cardList?.data}
            start={indexOfFirstItem}
            stop={indexOfLastItem}
        />
      {/* END OF PAGINATION BUTTON */}

      {deleteCardModal.show && 
        <DeleteCardPopout action={handleDeleteCardModal} situation={deleteCardModal.show} data={deleteCardModal.data} setReloadCardList={setReloadCardList} />
      }
    </div>
  )
}

export default CardList