export function formattedDate(dateString) {
  const parts = dateString.split(" ");
  const datePart = parts[0];
  const timePart = parts[1];

  const [month, day, year] = datePart.split("-").map(Number);
  let [hour, minute] = timePart.slice(0, -2).split(":").map(Number);

  // Convert 12-hour time to 24-hour time if necessary
  if (timePart.endsWith("PM") && hour !== 12) {
    hour += 12;
  } else if (timePart.endsWith("AM") && hour === 12) {
    hour = 0;
  }

  return new Date(year, month - 1, day, hour, minute);
}

export function formatDateString(inputDateString) {
  // Parse the input date string
  const parsedDate = new Date(inputDateString);

  // Get day, month, year, and time components
  const day = parsedDate.toLocaleDateString(undefined, { weekday: "long" });
  const month = parsedDate.toLocaleDateString(undefined, { month: "short" });
  const date = parsedDate.toLocaleDateString(undefined, { day: "numeric" });
  const year = parsedDate.toLocaleDateString(undefined, { year: "numeric" });

  // Get the time in 12-hour format with 'AM' or 'PM'
  const hours = parsedDate.getHours();
  const minutes = parsedDate.getMinutes();
  const time = `${hours % 12 || 12}:${minutes.toString().padStart(2, "0")} ${
    hours < 12 ? "AM" : "PM"
  }`;

  // Combine the components into the desired format
  const formattedDate = `${day}, ${month} ${date} ${year} - ${time}`;

  return formattedDate;
}