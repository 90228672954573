import { useState, useEffect } from "react";
import usersService from "../services/UsersService";
import { useSelector } from "react-redux";
import MyWaitingJobs from "../components/MyWaitingJobs";

export default function MyWaitingJobsPage() {
  let { commonHeadBanner } = useSelector((state) => state.commonHeadBanner);
  const [MyJobList, setMyJobList] = useState([]);
  const api = new usersService();

  const getMyJobList = async () => {
    try {
      const res = await api.getMyWiatingJobList();
      setMyJobList(res.data);
    } catch (error) {
      console.log("Error getting mode");
    }
  };
  useEffect(() => {
    getMyJobList();
  }, []);

  // debugger;
  return (
    <>
      <MyWaitingJobs
        MyJobList={MyJobList}
        commonHeadData={commonHeadBanner.result_list}
      />
    </>
  );
}
