import React, { useState } from "react";
import { handlePagingFunc } from "../Pagination/HandlePagination";
import PaginatedList from "../Pagination/PaginatedList";
import DeleteMember from "./DeleteMember";
import { localImgLoad } from "../../lib";

export default function GroupMemberTable({ selectedList }) {
  // Handle Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const indexOfFirstItem = Number(currentPage);
  const indexOfLastItem =
    Number(indexOfFirstItem) + Number(process.env.REACT_APP_ITEM_PER_PAGE);

  const currentSelectedList = selectedList?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePagination = (e) => {
    handlePagingFunc(e, setCurrentPage);
  };

  const [deletePopout, setDeletePopout] = useState({
    status: false,
    data: {},
  });

  const handleDeleteMember = (item) => {
    setDeletePopout({
      status: true,
      data: { ...item },
    });
  };

  return (
    <div
      className={`w-full p-8 dark:bg-dark-gray overflow-hidden rounded-2xl section-shado bg-[#fffef6]`}
    >
      <div className="relative w-full overflow-x-auto sm:rounded-lg flex flex-col justify-between min-h-[400px]">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            <>
              {selectedList && selectedList?.length > 0 ? (
                currentSelectedList.map((value, index) => (
                  <tr
                    key={value.uid}
                    className="bg-white dark:bg-dark-white border-b dark:border-[#5356fb29] hover:bg-gray-50"
                  >
                    {/* <td className="p-1">{value?.firstname}</td>
                          <td className="p-1">{value?.lastname}</td>
                          <td className="p-1">{value?.email}</td>
                          <td className="p-1 text-right">
                            <button onClick={()=>{handleDeleteMember(value)}} className='rounded-lg text-sm bg-red-500 hover:bg-red-400 text-white font-bold py-1 px-2.5'>X</button>
                          </td> */}
                    <td className="py-2">
                      <div className="flex flex-col">
                        <h1 className="font-bold text-xl text-dark-gray dark:text-white">
                          {value.firstname && value.firstname}{" "}
                          {value.lastname && value.lastname}
                        </h1>
                        <span className="text-sm text-thin-light-gray">
                          {value.email && value.email}
                        </span>
                      </div>
                    </td>
                    <td className="py-2 text-right">
                      <button
                        onClick={() => {
                          handleDeleteMember(value);
                        }}
                        className="flex relative items-center justify-center border-0 w-8 h-8"
                      >
                        <img
                          src={localImgLoad("images/icons/remove_grp.svg")}
                          alt="remove-icon"
                        />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="font-semibold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                  <td className="p-2">No Members Found. Please add</td>
                </tr>
              )}
            </>
          </tbody>
        </table>

        {/* PAGINATION BUTTON */}
        <PaginatedList
          onClick={handlePagination}
          prev={currentPage == 0 ? true : false}
          next={
            currentPage + Number(process.env.REACT_APP_ITEM_PER_PAGE) >=
            selectedList?.length
              ? true
              : false
          }
          data={selectedList}
          start={indexOfFirstItem}
          stop={indexOfLastItem}
        />
        {/* END OF PAGINATION BUTTON */}
      </div>

      {/* DELETE MEMBER POPOUT */}
      {deletePopout.status && (
        <DeleteMember
          action={() => setDeletePopout({ status: false, data: {} })}
          situation={deletePopout.status}
          details={deletePopout.data}
        />
      )}
      {/* END OF DELETE MEMBER POPOUT */}
    </div>
  );
}
