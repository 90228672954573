import { useState } from "react";
import SuggestTask from "../FamilyPopup/SuggestTask";

export default function FamilyMarketCard({ className, datas, hidden = false }) {
  // debugger;
  const [popUp, setPopUp] = useState(false);
  const popUpHandler = () => {
    setPopUp((prev) => !prev);
  };
  //   Image
  let selectedImage = require(`../../assets/images/family/${
    datas.banner || "default.jpg"
  }`);

  return (
    <>
      <div
        className={`card-style-two w-full h-[336px] p-[20px] bg-white dark:bg-dark-white   rounded-2xl section-shadow ${
          className || ""
        }`}
        key={datas?.uid}
      >
        <div className="flex flex-col justify-between w-full h-full">
          <div className="thumbnail-area w-full">
            <div
              className="w-full h-[236px] p-6  rounded-xl overflow-hidden"
              onClick={popUpHandler}
              style={{
                // background: `url(${selectedImage}) 0% 0% / cover no-repeat`,
                background: `url(${selectedImage}) center / contain no-repeat`,
              }}
            >
              <div className="product-two-options flex justify-between mb-5 relative">
                <div className="status">
                  {/* <span className="text-xs px-3 py-1.5 tracking-wide rounded-full bg-gold text-white">
                    Active
                  </span> */}
                </div>
              </div>
              {hidden && <div className="flex justify-center"></div>}
            </div>
          </div>
          <div className="details-area mt-12 flex justify-between items-center gap-2">
            {/* title */}
            <button onClick={popUpHandler}>
              <h1 className="font-bold text-xl tracking-wide line-clamp-1 text-dark-gray dark:text-white capitalize">
                {datas?.title}
              </h1>
            </button>
            <div className="flex justify-between">
              {/* <div className="flex items-center space-x-2">
                <p className="italic text-gray-400">
                  {getTimeAgo(datas?.added)}
                </p>
              </div> */}
              <div>
                <button
                  type="button"
                  onClick={popUpHandler}
                  className="px-4 py-2.5 text-white text-sm bg-pink rounded-full tracking-wide"
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popUp && (
        <SuggestTask
          onClose={popUpHandler}
          situation={popUp}
          details={{ ...datas, selectedImage }}
        />
      )}
    </>
  );
}
