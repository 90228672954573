import React, { Suspense, lazy, useEffect, useMemo, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { apiConst } from "../../lib/apiConst";
import usersService from "../../services/UsersService";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import AssignTaskPopout from "./FamilyPopout/AssignTaskPopout";
import { useSelector } from "react-redux";

// Lazy Imports for components
const FamilyWaitlist = lazy(() => import("./Tabs/FamilyNewWaitlist"));
const FamilyTasks = lazy(() => import("./Tabs/FamilyNewTasks"));
const FamilyPending = lazy(() => import("./Tabs/FamilyNewPending"));

export default function FamilyTableNew() {

  const {familyList} = useOutletContext() // CONTEXT VALUES FROM OUTLET TO CHILD

  const { jobListTable, pendingListTable, parentFamilyTaskList } = useSelector((state) => state.tableReload); // TABLE RELOAD TRIGGERS


  let { pathname } = useLocation();

  // Initial state for family details
  const initialDetailState = {
    loading: false,
    data: [],
  };

  // State for family details, tasks, waitlist, and pending
  let [familyTasks, setFamilyTasks] = useState({...initialDetailState})

  let [familyPending, setFamilyPending] = useState({...initialDetailState})

  let [familyWaitList, setFamilyWaitList] = useState({...initialDetailState})

  const [updatePage, setUpdatePage] = useState(false); // State to determine when to update the page

  // State for list of created jobs by FULL USER
  const [jobList, setJobList] = useState({ loading: false, data: [] });

  // State for active/selected job
  const [activeTask, setActiveTask] = useState({ id: 0, data: {} });

  // State for family task popout
  const [assignTaskPopout, setAssignTaskPopout] = useState(false);


  // Create an instance of the usersService class
  const apiCall = useMemo(() => new usersService(), []);

  // Function to handle toggling the family task popout
  const familyAssignPopUpHandler = () => {
    setAssignTaskPopout((prev) => !prev);
  };

  // Array of tab names
  const tabs = [
    { id: 1, name: "Tasks" },
    { id: 2, name: "Waiting" },
    { id: 3, name: "Pending" },
  ];

  // State for the currently selected tab
  const [tab, setTab] = useState(tabs[0].name);

  // Function to handle tab changes
  const tabHandler = (value) => {
    setTab(value);
  };

  // Object that maps tab names to their corresponding components
  const tabComponents = {
    Tasks: (
      <FamilyTasks
        familyData={familyTasks}
        action={familyAssignPopUpHandler}
      />
    ),
    Waiting: (
      <FamilyWaitlist
        familyData={familyWaitList}
        setUpdatePage={setUpdatePage}
      />
    ),
    Pending: (
      <FamilyPending
        familyData={familyPending}
      />
    ),
  };

  const defaultTabComponent = tabComponents.Tasks;

  // Selected tab component based on the current 'tab'
  const selectedTabComponent = tabComponents[tab] || defaultTabComponent;


  // Effect to manage active family task details
  useEffect(() => {
    const manageTasks = async () => {
      setFamilyTasks({loading:true, data: []})
      try {
        const response = await apiCall.getMyActiveJobList()
        if(response.status != 200 || !response?.data){
          return setFamilyTasks({loading:false, data: []})
        }
        setFamilyTasks({loading:false, data: response?.data})
      } catch (error) {
        throw new Error(error);
        setFamilyTasks({loading:false, data: []})
      }
    };
    // Invoke the manageFamily function when the component mounts
    manageTasks();
  }, [updatePage, parentFamilyTaskList]);

  // Effect to manage family wait task details
  useEffect(() => {
    const manageFamilyWaitlist = async () => {
      setFamilyWaitList({loading:true, data: []})
      try {
        const response = await apiCall.ManageFamilyWaitlist()
        if(response.status != 200 || !response?.data){
          return setFamilyWaitList({loading:false, data: []})
        }
        setFamilyWaitList({loading:false, data: response?.data})
      } catch (error) {
        throw new Error(error);
        setFamilyWaitList({loading:false, data: []})
      }
    };
    // Invoke the manageFamily function when the component mounts
    manageFamilyWaitlist();
  }, [updatePage]);

  // Effect to manage family pending task details
  useEffect(() => {
    const manageFamilyPending = async () => {
      setFamilyPending({loading:true, data: []})
      try {
        const response = await apiCall.ManageFamilyPending()
        if(response.status != 200 || !response?.data){
          return setFamilyPending({loading:false, data: []})
        }
        setFamilyPending({loading:false, data: response?.data})
      } catch (error) {
        throw new Error(error);
        setFamilyPending({loading:false, data: []})
      }
    };
    // Invoke the manageFamily function when the component mounts
    manageFamilyPending();
  }, [updatePage, pendingListTable]);

  // Effect to get all parent job list
  useEffect(() => {
    const reqData = {
      limit: 30,
      offset: 0,
      job_type: "FAMILY",
      action: apiConst.WRENCHBOARD_PICTURE_FAMMEMBER,
    };

      setJobList({ loading: true });
      apiCall
        .getMyJobList(reqData)
        .then((res) => {
          setJobList({ loading: false, data: res?.data?.result_list });
          if (res?.data?.result_list?.length) {
            setActiveTask((prev) => ({
              ...prev,
              data: res?.data?.result_list[0],
            }));
          }
        })
        .catch((err) => {
          setJobList({ loading: false, data: [] });
          console.log("Error", err);
        });
  }, []);

  // console.log(updatePage);

  return (
    <div
      className={`w-full bg-white dark:bg-dark-white overflow-y-auto rounded-2xl section-shadow h-full`}
    >
      <div className="relative w-full sm:rounded-lg overflow-x-auto">
        <Suspense
          fallback={
            <div className="h-full min-h-[609px] w-full overflow-hidden flex justify-center items-center">
              <LoadingSpinner size="16" color="sky-blue" />
            </div>
          }
        >
          <div className="w-full h-full text-sm text-left text-gray-500 dark:text-gray-400 relative grid grid-cols-3 min-h-[575px]">
            <div className="col-span-3 h-full w-full">
              <div className="flex flex-col w-full">
                <div className="w-full pr-8 flex items-center gap-1 border-b border-b-[#FAFAF]">
                  <ul className="flex gap-2 items-center w-full">
                    {tabs.map(({ name, id }) => (
                      <li
                        onClick={() => tabHandler(name)}
                        className={`p-4 flex hover:text-purple transition-all ease-in-out items-center cursor-pointer overflow-hidden text-xl relative top-[2px] ${
                          tab === name
                            ? "text-purple border-r"
                            : "text-thin-light-gray"
                        }`}
                        key={id}
                      >
                        <h1>{name}</h1>
                      </li>
                    ))}
                  </ul>
                  <button
                    type="button"
                    onClick={familyAssignPopUpHandler}
                    className="p-1 my-1 w-[100px] flex justify-center items-center btn-gradient text-base rounded-full text-white"
                  >
                    Add task
                  </button>
                </div>

                <div className="flex-[0.9] h-full">
                  <div className="h-full relative overflow-y-auto">
                    <Suspense
                      fallback={<LoadingSpinner size="16" color="sky-blue" />}
                    >
                      {selectedTabComponent}
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Suspense>
      </div>

      {assignTaskPopout && (
        <AssignTaskPopout
          action={familyAssignPopUpHandler}
          situation={assignTaskPopout}
          jobList={jobList}
          setActiveTask={setActiveTask}
          activeTask={activeTask}
          familyDetailsData={''}
          setUpdatePage={setUpdatePage}
          familyList={familyList?.result_list}
        />
      )}

    </div>
  );
}
