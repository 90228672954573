import React from "react";
import { Link } from "react-router-dom";

const AccountDashboard = ({ className, bannerList }) => {
  // getting the upper three banners for the home layout
  const getUpperBanner = bannerList?.filter((value, idx) => idx <= 2);
  const getLowerBanner = bannerList?.filter((value, idx) => idx > 2);

  let getImage = ({ banner_location, banner }) => {
    if (banner_location == "LOCAL") {
      return require(`../../assets/images/${banner}`);
    } else {
      return banner;
    }
  };

  console.log(getLowerBanner);
  return (
    <div
      className={`w-full min-h-[450px] flex flex-col justify-between items-center gap-4 rounded-2xl overflow-hidden ${
        className || ""
      }`}
    >
      <div className="w-full grid xxs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 items-center justify-center gap-2 md:gap-4">
        {getUpperBanner?.map((props, idx) => {
          let image = getImage(props);

          let { short_title, short_description, short_button_text, link_path } =
            props;

          return (
            <div key={idx}>
              <TopBanner
                btn={short_button_text}
                image={image}
                title={short_title}
                desc={short_description}
                link_path={link_path}
              />
            </div>
          );
        })}
      </div>
      <div className="w-full grid-cols-1 md:grid-cols-2 2xl::grid-cols-3 grid items-center justify-center gap-2 md:gap-4">
        {getLowerBanner?.map((props, idx) => {
          let image = getImage(props);

          let { short_title, short_description, short_button_text, link_path, card_type, blog_id } =
            props;

          return (
            <div key={idx}>
              <LowerBanner
                btn={short_button_text}
                image={image}
                title={short_title}
                desc={short_description}
                link_path={link_path}
                card_type={card_type}
                blog_id={blog_id}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AccountDashboard;

const TopBanner = ({ image, title = "", desc = "", btn, link_path, key }) => {
  return (
    <div className="flex flex-col shadow-md rounded-xl dark:border-[#5356fb29]" key={key}>
      <Link to={link_path} className="h-[12rem] rounded-t-xl">
        <img
          src={image}
          alt="banner-img"
          loading="lazy"
          className="w-full h-full rounded-t-xl object-cover"
        />
      </Link>
      <div className="h-[7rem] rounded-b-xl bg-white dark:bg-dark-white">
        <div className="border-b border-slate-300 px-2 py-1 h-[5.4rem] flex flex-col gap-2 dark:text-white">
          <Link to={link_path} className="font-bold text-lg">
            {title}
          </Link>
          <Link to={link_path} className="text-sm">
            {desc}
          </Link>
        </div>
        <div className="flex justify-between w-full px-2 items-center pt-[0.2rem]">
          <Link to={link_path} className="text-slate-300 font-semibold text-sm">
            {btn}
          </Link>
          <button className="flex items-center justify-center gap-2">
            <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
            <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
            <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
          </button>
        </div>
      </div>
    </div>
  );
};

const LowerBanner = ({ image, title = "", desc = "", btn, link_path, card_type, blog_id, key }) => {
  const newLinkPath = card_type == 'BLOG' ? `${link_path}?blog_id=${blog_id}` : link_path

  return (
    <div
      key={key}
      className="flex flex-col bg-white shadow-md h-full rounded-xl dark:border-[#5356fb29] dark:bg-dark-white"
    >
      <div className="w-full xxs:flex justify-between items-center border-b border-slate-300 p-2">
        <div className="min-h-[150px] sm:min-h-[130px] flex justify-between items-center">
          <div className="px-2 flex flex-col gap-2 dark:text-white">
            <Link to={newLinkPath} className="text-lg font-bold">
              {title}
            </Link>
            <p to={newLinkPath} className="text-sm">
              {desc}
            </p>
          </div>
        </div>
        <Link to={newLinkPath} className="w-[150px] h-[100px]">
          <img
            src={image}
            alt="banner-img"
            loading="lazy"
            className="w-full h-full rounded-xl"
          />
        </Link>
      </div>
      <div className="flex justify-between w-full px-2 items-center">
        <Link to={newLinkPath} className="text-slate-300 font-semibold text-sm">
          {btn}
        </Link>
        <button className="flex items-center justify-center gap-2">
          <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
          <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
          <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
        </button>
      </div>
    </div>
  );
};

const BannerSection = ({ banners, variant }) => {
  const getImage = ({ banner_location, banner }) => {
    return banner_location === "LOCAL"
      ? require(`../../assets/images/${banner}`)
      : banner;
  };

  return (
    <>
      {banners?.map((props, idx) => {
        const { short_title, short_description, short_button_text, link_path } =
          props;
        const image = getImage(props);

        return (
          <div
            key={idx}
            className={`flex flex-col shadow-md rounded-xl ${
              variant === "top" ? "" : "bg-white"
            }`}
          >
            <div className={`${variant === "top" ? "rounded-t-xl" : ""}`}>
              <Link to={link_path}>
                <img
                  src={image}
                  alt="banner-img"
                  className="w-full h-full rounded-t-xl object-cover"
                />
              </Link>
            </div>
            <div
              className={`${
                variant === "top"
                  ? "rounded-b-xl bg-white"
                  : "border-b border-slate-300"
              } h-[7rem]`}
            >
              <div className="border-b border-slate-300 px-2 py-1 h-[5.4rem] flex flex-col gap-2">
                <Link to={link_path} className="font-bold text-lg">
                  {short_title}
                </Link>
                <Link to={link_path} className="text-sm">
                  {short_description}
                </Link>
              </div>
              <div className="flex justify-between w-full px-2 items-center">
                <Link to={link_path} className="text-slate-300 font-semibold">
                  {short_button_text}
                </Link>
                <button className="flex items-center justify-center gap-2">
                  <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
                  <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
                  <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
