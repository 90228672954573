import React, { useContext, useState, useEffect } from "react";
import usersService from "../services/UsersService";
//import MyJobs from "../components/MyJobs";
import MyActiveJobs from "../components/MyActiveJobs";
import { useSelector } from "react-redux";

export default function MyActiveJobsPage() {
  let { commonHeadBanner } = useSelector((state) => state.commonHeadBanner);
  const [MyJobList, setMyJobList] = useState([]);
  const api = new usersService();
  //TARGET ENDPOINT[POST]http://10.204.5.100:9083/en/wrench/api/v1/jobmanageractive
  const getMyJobList = async () => {
    try {
      const res = await api.getMyActiveJobList();
      setMyJobList(res.data);
    } catch (error) {
      console.log("Error getting mode");
    }
  };
  useEffect(() => {
    getMyJobList();
  }, []);

  // debugger;
  return (
    <>
      <MyActiveJobs
        MyJobList={MyJobList}
        commonHeadData={commonHeadBanner.result_list}
      />
    </>
  );
}
