import { useEffect, useState } from "react";
import usersService from "../../services/UsersService";


const GetMyPageLoad = (reloader) => {
  const api = new usersService();

    const [response, setResponse] = useState({
      loading: true,
      data: null,
      error: null,
    });
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await api.MyPageLoad();

          setResponse({ loading: false, data: res.data, error: null });
        } catch (error) {
          setResponse({ loading: false, data: null, error: error.message });
        }
      };
  
      fetchData();
    }, [reloader]);
  
    return response;
  };
  
  export default GetMyPageLoad;