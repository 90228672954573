import React, { useEffect, useState } from "react";
import UploadProduct from "../components/UploadProduct";
import usersService from "../services/UsersService";

export default function UploadProductPage() {
  const [uploadTypes, setUploadTypes] = useState({loading: true, data: []});
  const api = new usersService();

  const getUploadTypes = async () => {
    try {
      const res = await api.getResourceList();
      setUploadTypes({loading: false, data: res?.data?.upload_types.data});
    } catch (error) {
      setUploadTypes({loading: false, data: []});
      throw new Error("Error getting mode");
    }
  };
  useEffect(() => {
    getUploadTypes();
  }, []);
  return <UploadProduct uploadTypes={uploadTypes} />;
}
