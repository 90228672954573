import CardList from "./CardList";


export default function PaymentMathodsTab() {
  return (
    <>
      <div className="payment-method-tab w-full">
        <div className="payment-item-wrapper w-full">
          <CardList />
        </div>
      </div>
    </>
  );
}
