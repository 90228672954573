import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ModalCom from '../../Helpers/ModalCom'
import LoadingSpinner from '../../Spinners/LoadingSpinner'

import usersService from '../../../services/UsersService'

function CurrentTaskAction({jobDetails}) {

    const apiCall = new usersService()
    const navigate = useNavigate()

    const [checked, setChecked] = useState(false)

    const [reqStatus, setReqStatus] = useState({loading:false, status: false, message: ''})

    let [popUp, setPopUp] = useState(false)

    const popUpHandler = () => {
        if(popUp){
            setChecked(false)
        }
        setPopUp(prev => !prev)
    }
   
    // FUNCTION TO HANDLE WHEN USER CLICKS ON SEND FOR REVIEW AND ACCEPTANCE
    const taskCompletedSubmit = () => {
        setReqStatus({loading:true, status: false, message: ''}) // Sets loading spinner active
        let reqData = {
            contract: jobDetails.contract,
            contract_uid: jobDetails.contract_uid,
            job_action: 'NOTIFY_COMPLETE',
        }
        if(!checked){ // checks that checkbox is selected
            setReqStatus({loading:false, status: false, message: 'Please check the box above'})
            return setTimeout(()=>{
                setReqStatus({loading:false, status: false, message: ''})
            }, 3000)
        }

        // API CALL TO MARK TASK AS COMPLETED BY WORKER
        apiCall.workerJobAction(reqData).then((res)=>{
            if(res.status != 200 || res.data.internal_return < 0){
                setReqStatus({loading:false, status: false, message: 'unable to complete request. Try again'})
                return
            }
            setReqStatus({loading:false, status: true, message: 'Task marked completed successfully'})
            setTimeout(()=>{ // Sets popout to false and navigates user to /mytask after 3 seconds
                popUpHandler()
                navigate('/mytask', {replace: true})
            }, 3000)
        }).catch(err => {
            setReqStatus({loading:false, status: false, message: 'Opps! Network error. Try again'})
        }).finally(()=>{
            setTimeout(()=>{
                setReqStatus({loading:false, status: false, message: ''})
            }, 3000)
        })
    }

  return (
    <div className='h-full dark:bg-black'>

          <div className="h-full w-full text-sm text-left text-gray-500">
            {jobDetails.job_type == 'MEDIA' ?
            <div className="h-full flex justify-center items-center">
                <button onClick={popUpHandler} type="button" className="btn-gradient h-full text-white p-1 lg:p-2 border-4 border-slate-300 text-lg lg:text-xl font-medium rounded-2xl">
                    I have completed this task
                </button>
            </div>
            :
            <div className='job-action active-worker'>
                <div className="flex space-x-2 items-center w-full task_action_panel">
                    <div>
                        I completed this task and ready for review and acceptance.
                    </div>
                </div>

                <div className="flex justify-center items-center">
                    <button onClick={popUpHandler} type="button" className="custom-btn btn-gradient text-white">
                        Send for Review
                    </button>
                </div>
            </div>
            }
          </div>

          {popUp && (
            <ModalCom action={popUpHandler} situation={popUp}>
                <div className="logout-modal-wrapper lg:w-[460px] h-full lg:h-auto bg-white dark:bg-dark-white lg:rounded-2xl">
                    <div className="modal-header-con">
                        <h1 className="modal-title">
                        Confirm Completion
                        </h1>
                        <button
                        type="button"
                        className="modal-clode-btn"
                        onClick={popUpHandler}
                        >
                          <svg
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                                fill="none"
                                className="fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                d="M36 16.16C36 17.4399 36 18.7199 36 20.0001C35.7911 20.0709 35.8636 20.2554 35.8385 20.4001C34.5321 27.9453 30.246 32.9248 22.9603 35.2822C21.9006 35.6251 20.7753 35.7657 19.6802 35.9997C18.4003 35.9997 17.1204 35.9997 15.8401 35.9997C15.5896 35.7086 15.2189 35.7732 14.9034 35.7093C7.77231 34.2621 3.08728 30.0725 0.769671 23.187C0.435002 22.1926 0.445997 21.1199 0 20.1599C0 18.7198 0 17.2798 0 15.8398C0.291376 15.6195 0.214408 15.2656 0.270759 14.9808C1.71321 7.69774 6.02611 2.99691 13.0428 0.700951C14.0118 0.383805 15.0509 0.386897 15.9999 0C17.2265 0 18.4532 0 19.6799 0C19.7156 0.124041 19.8125 0.136067 19.9225 0.146719C27.3 0.868973 33.5322 6.21922 35.3801 13.427C35.6121 14.3313 35.7945 15.2484 36 16.16ZM33.011 18.0787C33.0433 9.77105 26.3423 3.00309 18.077 2.9945C9.78479 2.98626 3.00344 9.658 2.98523 17.8426C2.96667 26.1633 9.58859 32.9601 17.7602 33.0079C26.197 33.0577 32.9787 26.4186 33.011 18.0787Z"
                                fill=""
                                fillOpacity="0.6"
                                />
                                <path
                                d="M15.9309 18.023C13.9329 16.037 12.007 14.1207 10.0787 12.2072C9.60071 11.733 9.26398 11.2162 9.51996 10.506C9.945 9.32677 11.1954 9.0811 12.1437 10.0174C13.9067 11.7585 15.6766 13.494 17.385 15.2879C17.9108 15.8401 18.1633 15.7487 18.6375 15.258C20.3586 13.4761 22.1199 11.7327 23.8822 9.99096C24.8175 9.06632 26.1095 9.33639 26.4967 10.517C26.7286 11.2241 26.3919 11.7413 25.9133 12.2178C24.1757 13.9472 22.4477 15.6855 20.7104 17.4148C20.5228 17.6018 20.2964 17.7495 20.0466 17.9485C22.0831 19.974 24.0372 21.8992 25.9689 23.8468C26.9262 24.8119 26.6489 26.1101 25.4336 26.4987C24.712 26.7292 24.2131 26.3441 23.7455 25.8757C21.9945 24.1227 20.2232 22.3892 18.5045 20.6049C18.0698 20.1534 17.8716 20.2269 17.4802 20.6282C15.732 22.4215 13.9493 24.1807 12.1777 25.951C11.7022 26.4262 11.193 26.7471 10.4738 26.4537C9.31345 25.9798 9.06881 24.8398 9.98589 23.8952C11.285 22.5576 12.6138 21.2484 13.9387 19.9355C14.5792 19.3005 15.2399 18.6852 15.9309 18.023Z"
                                fill="#"
                                fillOpacity="0.6"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="job-action-modal-body w-full px-10 py-8 gap-4">
                        <div className="w-full flex flex-col items-center">
                            <div className="mb-10 flex justify-center items-center gap-2">
                                <input 
                                    type='checkbox' 
                                    checked={checked} 
                                    onChange={()=>{setChecked(prev => !prev)}} 
                                    className='w-6 h-6 text-sky-blue bg-gray-100 focus:ring-sky-blue' 
                                />
                                <p className='font-bold text-base tracking-wide text-dark-gray dark:text-white'>If you have completed this task</p>
                            </div>

                            <div className="mb-10 flex justify-center items-center">
                                {reqStatus.loading ?
                                    <LoadingSpinner color='sky-blue' size='10' />
                                :
                                    <button type="button" onClick={taskCompletedSubmit} className="px-2 py-1 h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white">
                                        Send for Review & Acceptance
                                    </button>
                                }
                            </div>

                            {/* FOR SUCCESS/ERROR DISPLAY SECTION*/}
                            <div className="w-full">
                                <div
                                    className={`relative p-4 text-center text-md font-light leading-[19.5px] text-[13px] ${reqStatus.status ? 'text-green-700':'text-[#912741]'}`}
                                >
                                    {reqStatus.message}
                                </div>
                            </div>
                            {/* END OF FOR SUCCESS/ERROR DISPLAY SECTION*/}
                        </div> 
                    </div> 
                    {/* cancel btn */}
                    <div className='modal-footer-wrapper flex justify-end items-center'>
                        <button onClick={popUpHandler} type="button" className="w-20 h-11 flex justify-center items-center border-gradient text-base rounded-full text-white">
                            <span className='text-gradient'>Cancel</span>
                        </button>
                    </div>
                </div>
            </ModalCom>
        )}
    </div>
  )
}

export default CurrentTaskAction