/* eslint-disable no-unused-expressions */

import React, { useState } from "react";
import Icons from "../../Helpers/Icons";
import PasswordSvg from "../PasswordSvg";
import LoadingSpinner from "../../Spinners/LoadingSpinner";
import usersService from "../../../services/UsersService";
import { useNavigate } from "react-router-dom";

export default function ChangePasswordTab() {
  const apiUrl = new usersService()
  const navigate = useNavigate()

  const [oldPass, setOldPass] = useState("hide-password");
  const [newPass, setNewPass] = useState("hide-password");
  const [confirmPass, setConfirmPass] = useState("hide-password");

  const [requestStatus, setRequestStatus] = useState({loading: false, status: false, message: ''})
  
  const [inputEmpty, setInputEmpty] = useState(false)
  let [inputs, setInputs] = useState({
    old_pwd: '',
    new_pwd: '',
    confirm_new_pwd: ''
  })

  const handleInputChange = ({target:{name, value}}) => { // FUNCTION TO HANDLE WHEN AN INPUT CHANGES
    setInputs(prev => ({...prev, [name]:value}))
  }

  const handleCancel = () => { // FUNCTION TO CLEAR ALL TYPED INPUTS
    setInputs({
      old_pwd: '',
      new_pwd: '',
      confirm_new_pwd: ''
    })
  }

  const showPassword = (value) => {
    const password = document.getElementById(`${value}`);
    if (value && value === "old_password") {
      if (password.type === "password") {
        password.type = "text";
        setOldPass("show-password");
      } else {
        password.type = "password";
        setOldPass("hide-password");
      }
    }
    if (value && value === "new_password") {
      if (password.type === "password") {
        password.type = "text";
        setNewPass("show-password");
      } else {
        password.type = "password";
        setNewPass("hide-password");
      }
    }
    if (value && value === "confirm_password") {
      if (password.type === "password") {
        password.type = "text";
        setConfirmPass("show-password");
      } else {
        password.type = "password";
        setConfirmPass("hide-password");
      }
    }
  };

  const handlePwdUpdate = () => {
    setInputEmpty(false) // SETS THE STATE OF ANY INPUT IS EMPTY TO FALSE
    if(!inputs.old_pwd || !inputs.new_pwd || !inputs.confirm_new_pwd){ // CHECKS IF ANY INPUT FIELD IS EMPTY AND SETS INPUT EMPTY TO TRUE
      setInputEmpty(true)
      return setTimeout(()=>{setInputEmpty(false)},3000)
    }

    if(inputs.new_pwd != inputs.confirm_new_pwd){ // CHECKS IF PASSWORD MATCHES CONFIRM PASSWORD
      setRequestStatus({loading: false, status: false, message: 'new password must match confirm password'})
      return setTimeout(()=>{setRequestStatus({loading: false, status: false, message: ''})},3000)
    }

    if(/^[a-zA-Z0-9]*$/.test(inputs.new_pwd) == false){ // CHECKS IF PASSWORD IS ALPHANUMERIC
      setRequestStatus({loading: false, status: false, message: 'password must be alphanumeric'})
      return setTimeout(()=>{setRequestStatus({loading: false, status: false, message: ''})},3000)
    }

    if(inputs.new_pwd.length < 6){ // CHECKS IF PASSWORD IS UPTO SIX CHARACTERS
      setRequestStatus({loading: false, status: false, message: 'password must be upto six characters'})
      return setTimeout(()=>{setRequestStatus({loading: false, status: false, message: ''})},3000)
    }

    
    let reqData = { // REQUEST PAYLOAD
      current_pass: inputs.old_pwd,
      new_pass: inputs.new_pwd
    }

    
    setRequestStatus({loading: true, status: false, message: ''})

    apiUrl.profilePassChange(reqData).then(res => {
      if(res.status != 200 || res.data.internal_return < 0){
        setRequestStatus({loading: false, status: false, message: 'unable to complete request'})
        return
      }
      setRequestStatus({loading: false, status: true, message: 'password update was successful'})
      setTimeout(()=>{navigate('/settings', {replace: true})}, 5000)
    }).catch(err => {
      setRequestStatus({loading: false, status: false, message: 'unable to complete request'})
    }).finally(()=>{
      setTimeout(()=>{setRequestStatus({loading: false, status: false, message: ''})},4000)
    })
  }
  return (
    <div className="changePasswordTab w-full">
      <div className="w-full flex xxl:flex-row flex-col-reverse space-x-5 xxl:items-start">
        <div className="flex-1 mb-10">
          <div className="input-field mb-6">
            <label
              className="input-label text-dark-gray dark:text-white text-xl font-bold block mb-2.5"
              htmlFor="old_password"
            >
              Old Password <span className="text-[10px] text-red-500 italic px-2">{(inputEmpty && !inputs.old_pwd) && 'required'}</span>
            </label>
            <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  w-full rounded-[50px] h-[58px] overflow-hidden relative ">
              <div className="absolute left-6 bottom-[17px] z-10">
                <Icons name="password" />
              </div>
              <input
                placeholder="● ● ● ● ● ●"
                className="input-field placeholder:text-base text-bese px-12 text-dark-gray dark:text-white w-full h-full bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-0 focus:outline-none"
                type="password"
                id="old_password"
                value={inputs.old_pwd}
                name='old_pwd'
                onChange={handleInputChange}
                autoComplete='false'
              />
              <div
                className="absolute right-6 bottom-[17px] z-10 cursor-pointer"
                onClick={() => showPassword("old_password")}
              >
                <Icons name={oldPass} />
              </div>
            </div>
          </div>
          <div className="input-field mb-6">
            <label
              className="input-label text-dark-gray dark:text-white text-xl font-bold block mb-2.5"
              htmlFor="new_password"
            >
              New Password <span className="text-[10px] text-red-500 italic px-2">{(inputEmpty && !inputs.new_pwd) && 'required'}</span>
            </label>
            <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  w-full rounded-[50px] h-[58px] overflow-hidden relative ">
              <div className="absolute left-6 bottom-[17px] z-10">
                <Icons name="password" />
              </div>
              <input
                placeholder="● ● ● ● ● ●"
                className="input-field placeholder:text-base text-bese px-12 text-dark-gray dark:text-white w-full h-full bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-0 focus:outline-none"
                type="password"
                id="new_password"
                value={inputs.new_pwd}
                name='new_pwd'
                onChange={handleInputChange}
                autoComplete='false'
              />
              <div
                className="absolute right-6 bottom-[17px] z-10 cursor-pointer"
                onClick={() => showPassword("new_password")}
              >
                <Icons name={newPass} />
              </div>
            </div>
          </div>
          <div className="input-field mb-8">
            <label
              className="input-label text-dark-gray dark:text-white text-xl font-bold block mb-2.5"
              htmlFor="confirm_password"
            >
              Confirm Password <span className="text-[10px] text-red-500 italic px-2">{(inputEmpty && !inputs.confirm_new_pwd) && 'required'}</span>
            </label>
            <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  w-full rounded-[50px] h-[58px] overflow-hidden relative ">
              <div className="absolute left-6 bottom-[17px] z-10">
                <Icons name="password" />
              </div>
              <input
                placeholder="● ● ● ● ● ●"
                className="input-field placeholder:text-base text-bese px-12 text-dark-gray dark:text-white w-full h-full bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-0 focus:outline-none"
                type="password"
                id="confirm_password"
                value={inputs.confirm_new_pwd}
                name='confirm_new_pwd'
                onChange={handleInputChange}
                autoComplete='false'
              />
              <div
                className="absolute right-6 bottom-[17px] z-10 cursor-pointer"
                onClick={() => showPassword("confirm_password")}
              >
                <Icons name={confirmPass} />
              </div>
            </div>
          </div>
          <div className="flex justify-center space-x-4 items-center">
            <button
              type="button"
              className="text-light-red text-18 tracking-wide border-b dark:border-[#5356fb29]  border-light-red"
              disabled={requestStatus.loading}
              onClick={handleCancel}
            >
              Cancel
            </button>
            {requestStatus.loading ?
              <LoadingSpinner size='8' color='sky-blue' />
              :
              <button
                type="button"
                className="btn-gradient tracking-wide rounded-full w-[173px] h-[46px] flex justify-center items-center"
                disabled={requestStatus.loading}
                onClick={handlePwdUpdate}
              >
                <span className="font-thin text-18 tracking-wide antialiased text-white">
                  Change Password
                </span>
              </button>
            }
          </div>

          {/* error or success display */}
          {requestStatus.message != "" &&
            (!requestStatus.status ? (
              <div
                className={`relative p-4 my-4 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
              >
                {requestStatus.message}
              </div>
            ) : (
              requestStatus.status && (
                <div
                  className={`relative p-4 my-4 text-green-700 bg-slate-200 border-slate-800 mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
                >
                  {requestStatus.message}
                </div>
              )
            ))}
          {/* End of error or success display */}
        </div>
        <div className="w-[440px] sm:flex hidden justify-end">
          <PasswordSvg />
        </div>
      </div>
    </div>
  );
}
