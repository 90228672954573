import React, { useState } from "react";
import titleShape from "../../../assets/images/shape/title-shape-two.svg";
import InputCom from "../../Helpers/Inputs/InputCom";
import AuthLayout from "../AuthLayout2";
import ThankYou from "../ThankYou";

export default function UpdatePassword() {
  const [updated, setValue] = useState(false);
  const [message, setMessage] = useState(false);
  const updatePassword = () => {
    setValue(!updated);
    setTimeout(() => {
      setMessage(!message);
    }, 100);
  };

  return (
    <>
      <AuthLayout slogan="Welcome to  myFit">
        {updated === false ? (
          <div className="flex place-content-center">
            <div className="w-10/12">
              <div className="title-area relative flex flex-col justify-center items-center mb-7">
                <h1 className="sm:text-5xl text-4xl font-bold leading-[74px]   text-dark-gray dark:text-white">
                  Update Password
                </h1>
                {/* w-[341px] absolute top-14 left-12 */}
                <div className="shape sm:w-[341px] w-[270px] -mt-5 sm:-mt-1 ml-5">
                  <img src={titleShape} alt="shape" />
                </div>
              </div>
              <div className="input-area">
                <div className="input-item mb-5">
                  <InputCom
                    fieldClass="px-6"
                    placeholder="*********"
                    label="Old Password"
                    name="password"
                    type="password"
                    iconName="password"
                  />
                </div>
                <div className="input-item mb-5">
                  <InputCom
                    fieldClass="px-6"
                    placeholder="*********"
                    label="New Password"
                    name="password"
                    type="password"
                    iconName="password"
                  />
                </div>
                <div className="input-item mb-5">
                  <InputCom
                    fieldClass="px-6"
                    placeholder="*********"
                    label="Re-enter Password"
                    name="password"
                    type="password"
                    iconName="password"
                  />
                </div>
                <div className="signin-area mb-3.5">
                  <button
                    onClick={updatePassword}
                    type="button"
                    className="w-full rounded-[50px] mb-5 h-[58px] text-xl text-white font-bold flex justify-center bg-purple items-center"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ThankYou className={`thankyou-section ${message ? "active" : ""}`} />
        )}
      </AuthLayout>
    </>
  );
}
