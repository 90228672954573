/**
 * Renders a modal with information about a credit transaction.
 * @returns {JSX.Element} - The rendered modal component.
 */
function CompleteConfirmCredit({ onClose, confirmCredit }) {
  const { data } = confirmCredit;
  const isSuccess =
    data?.result === "Charge success" || data?.status === "successful";

  return (
    <div className="logout-modal-body w-full">
      <div className="content-wrapper w-full h-[32rem] flex flex-col justify-center">
        <div className="w-full mb-10">
          <div className="add-fund w-full bg-white dark:bg-dark-white rounded-2xl">
            <div className="px-4 md:p-8 py-4 add-fund-info">
              <div className="field w-full mb-3 min-h-[45px]">
                <div
                  className={`flex flex-col gap-4 ${
                    !isSuccess && "h-[328px] items-center justify-center"
                  }`}
                >
                  <div className="flex items-center w-full justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100"
                      height="100"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke={isSuccess ? "green" : "red"}
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className={`feather ${
                        isSuccess ? "feather-check-circle" : "feather-x-circle"
                      }`}
                    >
                      {isSuccess ? (
                        <>
                          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                          <polyline points="22 4 12 14.01 9 11.01"></polyline>
                        </>
                      ) : (
                        <>
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line>
                        </>
                      )}
                    </svg>
                  </div>

                  <div className="w-full flex justify-center items-center">
                    <h1 className="text-xl font-semibold text-dark-gray dark:text-white tracking-tighter my-1">
                      {isSuccess
                        ? "Credit was Successful!"
                        : "Credit was Unsuccessful"}
                    </h1>
                  </div>

                  {data?.internal_return >= 0 &&
                    data?.result !== "Charge failed" && (
                      <div className="w-full max-w-[300px] mx-auto">
                        <div className="flex gap-8 my-2">
                          <h1 className="w-full job-label">
                            Amount({data?.currency || ""})
                          </h1>
                          <span className="text-base text-dark-gray dark:text-white tracking-tighter flex justify-end items-end">
                            {`${data?.symbol || ""} ${
                              Number(data?.amount * 0.01).toFixed(2) || ""
                            }`}
                          </span>
                        </div>
                        
                        {data?.curr_balance &&
                        <div className="flex gap-8 my-2">
                          <h1 className="w-full job-label">
                            Wallet Balance
                          </h1>
                          <span className="text-base text-dark-gray dark:text-white tracking-tighter flex justify-end items-end">
                            {(data?.curr_balance * 0.01).toFixed(2)}
                          </span>
                        </div>
                        }

                        {isSuccess && (
                          <div className="flex gap-8 my-2">
                            <h1 className="w-full job-label">
                              Confirmation Number
                            </h1>
                            <span className="text-base text-dark-gray dark:text-white tracking-tighter flex justify-end items-end">
                              {data?.confirmation}
                            </span>
                          </div>
                        )}
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer-wrapper w-full flex justify-end items-center gap-4">
        <button
          className="custom-btn btn-gradient text-white text-base"
          onClick={onClose}
        >
          Ok
        </button>
      </div>
    </div>
  );
}

export default CompleteConfirmCredit;
